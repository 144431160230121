import React from 'react';
import { useTranslation } from 'react-i18next';
export default function Institutional(props) {
    
    const [ t ] = useTranslation('main');
    return (
        <div className='Institutional'>
            {t('pages.account.sections.subscription.institutional')}
        </div>
    );
}