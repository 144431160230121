import { createContext } from 'react';

const SettingsContext = createContext({
    settings: null,
    setSettings: (val) => {},
});

export const SettingsProvider = SettingsContext.Provider;
export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;