import {  useEffect, useState } from "react";

function useCountdown(until) {
    const [timeLeft, setTimeLeft] = useState(until - (new Date()).getTime());
    
    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = (new Date()).getTime();
            setTimeLeft(until - now);
        }, 1000);
        
        return () => {
            clearInterval(intervalId);
        }
    }, [until]);

    const getString = () => {
        if(timeLeft === null) return '...';
        if(timeLeft <= 0) return '';
        return new Date(timeLeft).toISOString().slice(14,19);
    };

    return getString();
}

export default useCountdown;