export default function useResourceType(file) {
    if(file.rid === 'documents') return 'folder-documents';
    if(file.rid === 'flashcards') return 'folder-flashcards';
    if(file.rid === 'activities') return 'folder-activities';
    if(file.folder) return 'folder';
    if(file.extension === 'pdf') return 'pdf';
    if(file.extension === 'txt') return 'txt';
    if(file.extension === 'mp4') return 'video';
    if(file.extension === 'mp3') return 'audio';
    if(file.extension === 'fc') return 'flashcards';
    if(file.extension === 'mw') return 'missing-words';
    if(file.extension === 'hw') return 'hidden-words';
    if(file.extension === 'xw') return 'crosswords';
    if(file.extension === 'qz') return 'quiz';
    return 'file';
}