import React from 'react';
import { useTranslation } from 'react-i18next';
import Collapsible from '../../../../../utils/Collapsible';
import ScoreDisplay from '../../../../ScoreDisplay';
export default function QuizShortAnswerResult(props) {

    const [ t ] = useTranslation('main');

    const c = props.c;
    const i = props.i;
    const text = props.text;
    const answer = props.answer;
    return (
        <Collapsible header={<h2>#{i} {text}</h2>}>
            <div className='quiz-digest'>
                <div className='analyze'>
                    <div className='correct'>
                        <h3>{t('pages.classes.class_results.correct_answers')}:</h3>
                        <div>{answer}</div>
                        <ScoreDisplay score={Math.round((c.corrects_count / (c.wrongs_count + c.corrects_count)) * 100)} emptyColor={'#eb4034'} colors={{0: '#34c95c'}} />
                    </div>
                    <div className='wrong'>
                        <h3>{t('pages.classes.class_results.wrong_answers')}:</h3>
                        <table>
                            <thead>
                                <tr>
                                    <td>{t('pages.classes.class_results.count')}</td>
                                    <td>{t('pages.classes.class_results.answer')}</td>
                                    <td>{t('pages.classes.class_results.freq')}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(c.wrongs).map((wrongAnswer) => {
                                    return <tr key={wrongAnswer}>
                                        <td>{c.wrongs[wrongAnswer]}</td>
                                        <td>{wrongAnswer}</td>
                                        <td>{Math.round((c.wrongs[wrongAnswer] / (c.wrongs_count + c.corrects_count)) * 100)}%</td>
                                    </tr>;
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Collapsible>
    );
}