import React from 'react';
import LogoLink from '../../layout/LogoLink';
import ResourceInfo from './ResourceInfo';
import './SharedTopBar.scss';
import account_icon from '../../../images/icons/account.svg';
import history_icon from '../../../images/icons/history.svg';
import SharedHistory from './SharedHistory';
import useMountAwareState from '../../../hooks/useMountAwareState';
export default function SharedTopBar(props) {

    const [openHistory, setOpenHistory] = useMountAwareState(false);
    
    return (
        <div className='SharedTopBar'>
            <div className='group'>
                <LogoLink noLink={true} />
                <ResourceInfo dueDate={props.data.share.due_date} resource={props.data.resource} share={props.data.share} token={props.token} />
            </div>
            <div className='group2'>
                <button className={`history${openHistory ? ' active':''}`} onClick={() => setOpenHistory(true)}>
                    <img width={482} height={482} src={history_icon} alt="history" />
                </button>
                {openHistory ?
                    <SharedHistory close={() => {setOpenHistory(false);}} token={props.token} />
                : undefined}
                <button disabled={true} className='account'>
                    <img width={131} height={131} src={account_icon} alt="Account" />
                    {props.data.student.first_name}
                </button>
            </div>
        </div>
    );
}