import React, { useContext } from 'react';
import HideStudentResultsContext from '../../../Contexts/HideStudentResultsContext';
import './HideResultsToggle.scss';
import see_icon from '../../../images/icons/reading.svg';
import hide_icon from '../../../images/icons/hide.svg';
import { useTranslation } from 'react-i18next';
export default function HideResultsToggle(props) {

    const { hideStudentResults, setHideStudentResults } = useContext(HideStudentResultsContext);
    const [ t ] = useTranslation('main');
    
    return (
        <label className={`HideResultsToggle ${hideStudentResults ? 'hide' : 'see'}`}>
            <input type="checkbox" checked={hideStudentResults} onChange={e => setHideStudentResults(e.target.checked)} />
            <div className='content'>
                <div className='see'>
                    <img src={see_icon} alt="" />
                    {t('pages.classes.hide_results.see')}
                </div>
                <div className='hide'>
                    <img src={hide_icon} alt="" />
                    {t('pages.classes.hide_results.hide')}
                </div>
            </div>
        </label>
    );
}