import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import './AccountInstitution.scss';
import TeacherManagement from './AccountInstitutionElements/TeacherManagement';
import API from '../../API/API';
import useFeedback from '../../hooks/useFeedback';
import ConfirmPopup from '../Popup/WarningPopup';

import institution_icon from '../../images/icons/institution.svg';
import copy_icon from '../../images/icons/copy.svg';
import hide_icon from '../../images/icons/hide.svg';
import reading_icon from '../../images/icons/reading.svg';
import refresh_icon from '../../images/icons/refresh.svg';
import info_icon from '../../images/icons/info.svg';
import Popup from '../Popup/Popup';
import useMountAwareState from '../../hooks/useMountAwareState';
import PopupContext from '../../Contexts/PopupContext';

export default function AccountInstitution(props) {

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    const [code, setCode] = useMountAwareState('');
    const [error, setError] = useMountAwareState(null);
    const [lock, setLock] = useMountAwareState(false);
    const { setPopup } = useContext(PopupContext);
    const [showInvitationCode, setShowInvitationCode] = useMountAwareState(false);
    const feedback = useFeedback();

    const handleSubmit = (e) => {
        e.preventDefault();
        setLock(true);
        API.getInstance()
        .post('/users/self/invitation-code', {
            'invitation_code': code
        })
        .then(({status, data}) => {
            if(status === 200) {
                props.updateUser();
            }else{
                setError(data.message);
            }
            setLock(false);
        })
        .catch(err => {
            setLock(false);
            setError(tcommon('error.general.default'));
        });
    };

    const copyInvitationCode = (e) => {
        navigator.clipboard.writeText(user.institution.invitation_code);
        e.target.innerText = '&#10004;'
        feedback.add('Invitation code copied');
    };

    const openRegenerateCodePopup = () => {
        setPopup(
            <ConfirmPopup 
                title={t('pages.account.sections.institution.institutional.invitation_code.regenerate.title')}
                text={t('pages.account.sections.institution.institutional.invitation_code.regenerate.text')}
                close={() => {setPopup(null);}}
                cancel={undefined}
                confirm={regenerateCode}
            />
        );
    };

    const regenerateCode = () => {
        API.getInstance()
        .post(`/institutions/${props.user.institution.pid}/invitation-code/regenerate`)
        .then(({status, data}) => {
            if(status === 201) {
                props.updateUser();
                feedback.add(t('pages.account.sections.institution.institutional.invitation_code.regenerate.success'));
            }else{
                feedback.add(t('pages.account.sections.institution.institutional.invitation_code.regenerate.failure'));
            }
        })
        .catch(err => {
            feedback.add(t('pages.account.sections.institution.institutional.invitation_code.regenerate.failure'));
        });
    };

    const openInvitationCodeHelp = () => {
        setPopup(
            <Popup 
                title={t('pages.account.sections.institution.institutional.invitation_code.help.title')}
                text={t('pages.account.sections.institution.institutional.invitation_code.help.text')}
                close={() => {setPopup(null);}}
                cancel={undefined}
                confirm={undefined}
                buttons={
                    <button className='btn-yellow' onClick={() => {setPopup(null);}}>close</button>
                }
            />
        );
    };

    const getContent = (user) => {
        if(user.institution === null) {
            return <div className='AccountInstitution'>
                You are not member of an institution
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>
                            I have an invitation code:
                            <input type="text" placeholder='code...' value={code} onChange={(e) => setCode(e.target.value)} />
                        </label>
                        <button className='btn-yellow' type="submit" disabled={lock}>Submit</button>
                    </div>
                    
                    {error ? 
                        <div className='error'>
                            {error}
                        </div>
                    : undefined}
                </form>
            </div>;
        }else{
            return <table>
                <tbody>
                    <tr>
                        <td>{t('pages.account.sections.institution.institutional.institution')}:</td> 
                        <td>{user.institution.name} ({user.institution.shortname})</td>
                    </tr>
                    <tr>
                        <td>{t('pages.account.sections.institution.institutional.status')}:</td>
                        <td>{user.type === 2 ? 'Teacher' : 'Admin'}</td>
                    </tr>
                    {user.roles.includes('institutional_admin') ? 
                        <>
                            <tr>
                                <td>{t('pages.account.sections.institution.institutional.members')}:</td>
                                <td>{user.institution.teachers_count}/{user.institution.teachers_limit}</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='row'>
                                        <span>{t('pages.account.sections.institution.institutional.invitation_code.title')}</span>
                                        <button onClick={openInvitationCodeHelp} className='info-button' title='info'><img width={63} height={63} src={info_icon} alt="info" /></button>
                                        <span>:</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='invitation-code'>
                                        <div className={!showInvitationCode ? 'blur' : ''}>
                                            {user.institution.invitation_code}
                                        </div>
                                        <button className='invitation-code-button btn-yellow' onClick={() => setShowInvitationCode(!showInvitationCode)}><img width={81} height={81} className='button-img' src={showInvitationCode ? hide_icon : reading_icon} alt={tcommon(`general.${showInvitationCode ? 'hide' : 'see'}`)} title={tcommon(`general.${showInvitationCode ? 'hide' : 'see'}`)} /></button>
                                    {navigator.clipboard ? <button className='invitation-code-button' onClick={copyInvitationCode}><img width={506} height={506} className='button-img' src={copy_icon} alt={tcommon('general.copy')} title={tcommon('general.copy')} /></button> : undefined}
                                    <button className='invitation-code-button btn-yellow' onClick={openRegenerateCodePopup}><img width={213} height={213} className='button-img' src={refresh_icon} alt={tcommon('general.regenerate')} title={tcommon('general.regenerate')} /></button>
                                    </div>
                                    
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <TeacherManagement />
                                </td>
                            </tr>
                        </>
                    : undefined}
                </tbody>
            </table>;
        }
    };

    const user = props.user;
    return <section className='AccountInstitution'>
        <h2><img width={287} height={287} src={institution_icon} alt="" />{t('pages.account.sections.institution.title')}</h2>
        {getContent(user)}
    </section>;
}