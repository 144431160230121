import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../API/API';
import ErrorPage from '../../ErrorPage';
import Loading from '../../Loading';
import FlashcardsResults from '../../explorer/flashcards/FlashcardsResults';
import './ResultViewer.scss';
import QuizViewer from '../../explorer/activities/quiz/QuizViewer';
import MissingWordsViewer from '../../explorer/activities/missing_words/MissingWordsViewer';
import useMountAwareState from '../../../hooks/useMountAwareState';
import DocumentResultViewer from './DocumentResultViewer';
export default function ResultViewer(props) {
    const [blob, setBlob] = useMountAwareState(null);
    const [display, setDisplay] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);
    const [ tcommon ] = useTranslation('common');
    const [ t ] = useTranslation('main');

    useEffect(() => {
        API.getInstance()
        .file('/resources/' + props.result.resource.rid + '/file', props.auth ?? true)
        .then(({status, data}) => {
            if(status === 200) {
                setBlob(data);
            }else{
                setError(status);
            }
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
        });
    }, [props.result.resource, t, props.auth, tcommon, setBlob, setError]);

    useEffect(() => {
        const getFileDisplay = async () => {
            switch (props.result.resource.type) {
                case 'document':
                    return <DocumentResultViewer result={props.result} />;
                case 'flashcards':
                    if(props.result.share.options.mode !== "lrn") {
                        return <FlashcardsResults results={props.result.data} alreadyEvaluated={true} />;
                    }else{
                        return <DocumentResultViewer result={props.result} />;
                    }
                case 'missing-words':
                    return <MissingWordsViewer result={props.result} resource={props.result.resource} data={JSON.parse(await blob.text())} />;
                case 'hidden-words':
                    return <div>HiddenWordsViewer result={props.result} data={JSON.parse(await blob.text())} </div>;
                case 'quiz':
                    return <QuizViewer result={props.result} answers={props.result.data.answers} resource={props.result.resource} data={JSON.parse(await blob.text())} />;
                default:
                    return <iframe
                        title={props.result.resource.name}
                        src={URL.createObjectURL(blob)}
                        width="100%"
                    ></iframe>;
            }
        }
        if(blob === null) return;
        getFileDisplay()
        .then((display) => {
            setDisplay(display);
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
        });
    
      return () => {
        
      }
    }, [blob, props.result.resource, props.result, t, tcommon, setDisplay, setError]);
    
    if(error !== null) {
        if(error === 403) {
            return <ErrorPage
                code={error}
                error={tcommon('error.file.forbidden_alt')}
                message={t('pages.classes.see_results.error')}
                noReturnToHome={true}
            />;
        }else if(error === 404) {
            return <ErrorPage
                code={error}
                error={tcommon('error.file.not_found_alt')}
                message={t('pages.classes.see_results.error')}
                noReturnToHome={true}
            />;
        }else{
            return <ErrorPage noReturnToHome={true} message={t('pages.classes.see_results.error')} />;
        }
        
    }
    if(null === display) return <Loading />;
    
    return (
        <div className="ResultViewer">
            {display}
        </div>
    );
}