import React from 'react';
import './ResourceInfoPopup.scss';
import Popupable from '../../Popup/Popupable';
import { useTranslation } from 'react-i18next';
import TimeDiffDisplay from '../../utils/TimeDiffDisplay';
export default function ResourceInfoPopup(props) {

    const [ t ] = useTranslation('main');

    const getInstructionIdentifier = () => {
        const notDocuments = ['flashcards', 'missing-words', 'hidden-words', 'crosswords', 'quiz'];
        if(!notDocuments.includes(props.share.resource.type)) {
            if((props.share.options?.questions?.length ?? 0) === 0) return 'document-ok';
            return 'document-questions';
        }
        if(props.share.resource.type === 'flashcards') {
            return `flashcards-${props.share.options.mode}`;
        }
        return props.share.resource.type;
    };

    return (
        <Popupable close={props.close} title="Informations">
            <div className='ResourceInfoPopup'>
                <table>
                    <tbody>
                        <tr>
                            <td>Resource:</td>
                            <td>{props.resource.name}</td>
                        </tr>
                        <tr>
                            <td>Type:</td>
                            <td>{props.resource.type.split(' ').map(v => v.charAt(0).toUpperCase() + v.slice(1)).join(' ')}</td>
                        </tr>
                        <tr>
                            <td>Due on:</td>
                            <td>
                                {new Date(props.share.due_date * 1000).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit'})}
                                <TimeDiffDisplay to={new Date(props.share.due_date * 1000)} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>Instruction:</td>
                        </tr>
                        <tr>
                            <td colSpan={2} className='instructions'>
                                {t(`pages.shared.instructions.${getInstructionIdentifier()}`)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Popupable>
    );
}