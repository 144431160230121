import React from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import API from '../../API/API';
import useMountAwareState from '../../hooks/useMountAwareState';
import './SharedIdentify.scss';
export default function SharedIdentify(props) {

    const navigate = useNavigate();
    
    const [pin1, setPin1] = useMountAwareState('');
    const [pin2, setPin2] = useMountAwareState('');
    const [pin3, setPin3] = useMountAwareState('');
    const [pin4, setPin4] = useMountAwareState('');
    const [pin5, setPin5] = useMountAwareState('');
    const [pin6, setPin6] = useMountAwareState('');
    const pinSetters = [setPin1, setPin2, setPin3, setPin4, setPin5, setPin6];
    const [error, setError] = useMountAwareState(null);
    const [errorCode, setErrorCode] = useMountAwareState(null);
    const [lock, setLock] = useMountAwareState(false);
    const [ tcommon ] = useTranslation('common');

    const { sharePid = undefined } = useParams();

    const identify = (e) => {
        e.preventDefault();
        setLock(true);
        setError(null);
        setErrorCode(null);
        const pin = [pin1, pin2, pin3, pin4, pin5, pin6].join('');
        if(pin.length !== 6) {
            setError('You pin should be 6 characters long');
            setLock(false);
            return;
        }
        API.getInstance()
        .post(`/resource-shares/${sharePid}/identify`, {
            pin: pin
        }, false)
        .then(({status, data}) => {
            if(status === 201) {
                navigate({
                    pathname: "/shared",
                    search: createSearchParams({
                        t: data.token
                    }).toString()
                });
            }else{
                setError(tcommon('error.fetch.default', {code: status}));
                setErrorCode(status);
                setLock(false);
            }
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
            setLock(false);
        });
    };

    const handleChange = (e, i) => {
        if((/[^a-zA-Z0-9]/).test(e.target.value)) return;
        pinSetters[i-1](e.target.value);
        if(i >= 6) {
            document.getElementById('submit').focus();
        }else{
            document.getElementById(`pin${i+1}`).focus();
        }
    };

    const handlePaste = (e) => {
        const paste = (e.clipboardData || window.clipboardData).getData('text');
        if(paste.length < 6) return;
        const split = paste.split('');

        setPin1(split[0]);
        setPin2(split[1]);
        setPin3(split[2]);
        setPin4(split[3]);
        setPin5(split[4]);
        setPin6(split[5]);

        handleChange(6);
    };

    return (
        <div className='SharedIdentify'>
            <div>
                <h2>Please enter your pin:</h2>
                <form onSubmit={identify}>
                    <div className='pin'>
                        <input id="pin1" type="text" maxLength={1} value={pin1} onChange={e => {handleChange(e, 1);}} onFocus={e => e.target.select()} disabled={lock} autoFocus={true} onPaste={handlePaste} />
                        <input id="pin2" type="text" maxLength={1} value={pin2} onChange={e => {handleChange(e, 2);}} onFocus={e => e.target.select()} disabled={lock} />
                        <input id="pin3" type="text" maxLength={1} value={pin3} onChange={e => {handleChange(e, 3);}} onFocus={e => e.target.select()} disabled={lock} />
                        <input id="pin4" type="text" maxLength={1} value={pin4} onChange={e => {handleChange(e, 4);}} onFocus={e => e.target.select()} disabled={lock} />
                        <input id="pin5" type="text" maxLength={1} value={pin5} onChange={e => {handleChange(e, 5);}} onFocus={e => e.target.select()} disabled={lock} />
                        <input id="pin6" type="text" maxLength={1} value={pin6} onChange={e => {handleChange(e, 6);}} onFocus={e => e.target.select()} disabled={lock} />
                    </div>
                    {error ? 
                        <div>
                            {errorCode === 403 ?
                                <div>Invalid pin</div>
                            :
                                error
                            }
                        </div>
                    : undefined}
                    <input id="submit" type="submit" value="Access" disabled={lock} />
                </form>
            </div>
        </div>
    );
}