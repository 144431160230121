import React from 'react';
import API from '../../../API/API';
import Popupable from '../../Popup/Popupable';
import './DocumentAnswerButton.scss';
import Dot3 from '../../utils/Dot3';
import useMountAwareState from '../../../hooks/useMountAwareState';
export default function DocumentAnswerButton(props) {
    
    const questions = props.share.options.questions;

    const [open, setOpen] = useMountAwareState(false);
    const [answers, setAnswers] = useMountAwareState(Array(questions.length).fill(''));
    const [sending, setSending] = useMountAwareState(false);
    const [correction, setCorrection] = useMountAwareState(null);

    const setAnswer = (i, value) => {
        let tmp = [...answers];
        tmp[i] = value;
        setAnswers(tmp);
    };

    const sendAnswers = () => {
        if(answers.filter(a => a === '').length > 0) return;
        setSending(true);
        
        API.getInstance()
        .post(`/resource-verify-answers/${props.resource.rid}${props?.token !== undefined ? `?t=${props.token}` : ''}`, {
            data: {
                answers: answers
            }
        }, false)
        .then(({status, data}) => {
            if(status === 200 || status === 201) {
                setCorrection(data);   
            }
            setSending(false);
        })
        .catch((error) => {
            setSending(false);
        });
    };

    const reset = () => {
        setCorrection(null);
        setAnswers(Array(questions.length).fill(''));
    };

    return (
        <div className='DocumentAnswerButton'>
            <button onClick={() => setOpen(true)}>Answer questions</button>
            {open ? 
                <Popupable title="Answer the questions" close={() => setOpen(false)}>
                    <div className='list'>
                        {questions.map((q, i) => {
                            return <label key={i}>
                                {q.question}
                                {correction === null ?
                                    <input type="input" value={answers[i]} onChange={e => setAnswer(i, e.target.value)} disabled={sending} />    
                                :
                                    <span>{answers[i]}{correction[i][1] ? <>&#10003;</> : <>&#10006; ({q.answer} &#10003;)</>}</span>
                                }
                            </label>;
                        })}
                        {correction === null ?
                            <button onClick={sendAnswers} disabled={sending}>
                                {sending ? <>Sending <Dot3 /></> : 'Send'}
                            </button>
                        :
                            <button onClick={reset}>
                                Retry
                            </button>
                        }
                    </div>
                </Popupable>
            : undefined}
        </div>
    );
}