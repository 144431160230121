import React from 'react';
import './HiddenText.scss';
import see_icon from '../../images/icons/reading.svg';
import hide_icon from '../../images/icons/hide.svg';
import copy_icon from '../../images/icons/copy.svg';
import useFeedback from '../../hooks/useFeedback';
import useMountAwareState from '../../hooks/useMountAwareState';
export default function HiddenText(props) {
    
    const feedback = useFeedback();
    const [hide, setHide] = useMountAwareState(true);

    const copy = () => {
        if(navigator.clipboard) {
            navigator.clipboard.writeText(props.children);
            feedback.add('Pin copied');
        }
    };

    return (
        <div className={`HiddenText ${hide ? 'hidden' : 'visible'}`}>
            <span>{props.children}</span>
            <button onClick={() => setHide(!hide)}>
                <img src={hide ? see_icon : hide_icon} alt={hide ? 'see' : 'hide'} />
            </button>
            {(props.copyButton ?? true) && (navigator.clipboard) ?
                <button onClick={copy}>
                    <img src={copy_icon} alt="copy" />
                </button>
            : false }
        </div>
    );
}