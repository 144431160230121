import React, {  useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import API from '../../API/API';
import UserContext from '../../Contexts/UserContext';
import ErrorPage from '../ErrorPage';
import Loading from '../Loading';
import person_icon from  '../../images/icons/person.svg';
import help_icon from  '../../images/icons/help.svg';
import StringCleaner from '../../helpers/StringCleaner';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import './Classes.scss';
import CreateClass from './CreateClass';
import { HideStudentResultsProvider } from '../../Contexts/HideStudentResultsContext';
import useCookieConsent from '../../hooks/useCookieConsent';
import useMountAwareState from '../../hooks/useMountAwareState';
import PopupContext from '../../Contexts/PopupContext';

import email_icon from '../../images/icons/email.svg';
import links_icon from '../../images/icons/link.svg';

export default function Classes(props) {

    const { user } = useContext(UserContext);
    const { setPopup } = useContext(PopupContext);
    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');
    const [classes, setClasses] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);
    const [search, setSearch] = useMountAwareState('');
    const [hideStudentResults, setHideStudentResults] = useMountAwareState((localStorage.getItem('hide-student-results') ?? 'true') === 'true');
    const [hideClasses, ] = useMountAwareState(true);
    const { functionality } = useCookieConsent();
    const location = useLocation();
    const navigate = useNavigate();

    const { classPid = undefined } = useParams();

    useDocumentTitle('Classes');

    const fetchClasses = useCallback(() => {
        API.getInstance()
        .get('/users/self/classes')
        .then(({status, data}) => {
            if(status === 200) {
                setClasses(data);
            }else{
                setError(tcommon('error.fetch.default', {code: status}));
            }
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
        });
    }, [setClasses, setError, tcommon]);

    useEffect(() => {
        fetchClasses();
        document.addEventListener('class-update', fetchClasses);
        return () => {
            document.removeEventListener('class-update', fetchClasses);
        }
    }, [fetchClasses]);

    const setHideStudentsResultWithLocalStorage = (value) => {
        if(functionality) {
            localStorage.setItem('hide-student-results', value);
        }
        setHideStudentResults(value);
    };


    const closeCreateClassPopup = (uid) => {
        setPopup(null);
        if((typeof uid) === "string") navigate(`/classes/${uid}`);
    };
    const openCreateClass = () => {
        setPopup(
            <CreateClass close={closeCreateClassPopup} />
        );
    };

    if(!user || (!user.roles.includes('institutional_teacher') && !user.roles.includes('institutional_admin'))) {
        return <ErrorPage code={403} error={tcommon('error.page.forbidden')} />;
    }
    
    if(error) return <ErrorPage error={error} />;
    if(classes === null) return <Loading>Fetching classes</Loading>;
    const cleaner = new StringCleaner().removeAccents().normalizeWhitepaces().trimWhitespaces().toLowerCase();
    const filteredClasses = classes.filter(c => search === '' || cleaner.clean(c.name).includes(cleaner.clean(search)));
    return (
        <div className="Classes">
            <section className={`classes-list ${location.pathname === '/classes' ? 'classes-home' : ''} ${hideClasses ? 'hidden' : ''}`}>
                <div className='create'>
                    <button className='create-class' onClick={openCreateClass}>{t('pages.classes.classes_list.create')}</button>
                    <Link className='no-after' to="/help#class-management" target="_blank">
                        <img src={help_icon} alt={tcommon('general.help')} title={tcommon('general.help')} />
                    </Link>
                </div>
                <div className='search-class'>
                    <input type="search" onChange={e => setSearch(e.target.value)} value={search} placeholder={`${tcommon('general.search')}...`}/>
                </div>
                <nav>
                    {filteredClasses.length === 0 ?
                        <div className='no-classes'>
                            {search === '' ?
                                t('pages.classes.classes_list.empty')
                            :
                                t('pages.classes.classes_list.no_match')
                            }
                        </div>
                    :
                        (filteredClasses.map(c => {
                            return <Link className={classPid === c.pid ? 'active' : ''} key={c.pid} to={`/classes/${c.pid}`} title={c.name}>
                                <span className='name'>
                                    {c.type === 'links' ?
                                        <img src={links_icon} alt="links class" title="links class" />
                                    :
                                        <img src={email_icon} alt="email class" title="email class" />
                                    }
                                    {c.name}
                                </span> 
                                <span>({c.students_count} <img width={131} height={131} src={person_icon} alt="students" />)</span>
                            </Link>;
                        }))
                    }
                </nav>
            </section>
            <div className='outlet'>
                <HideStudentResultsProvider value={{ hideStudentResults, setHideStudentResults: setHideStudentsResultWithLocalStorage }}>
                    <Outlet />
                </HideStudentResultsProvider>
            </div>
        </div>
    );
}