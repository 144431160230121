import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../../API/API';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import ErrorPage from '../../ErrorPage';
import Loading from '../../Loading';
import HiddenText from '../../utils/HiddenText';
import ClassesBreadcrumb from '../ClassesBreadcrumb';
import './ClassManageStudent.scss';
import StudentResults from './StudentResults';
import StudentScoreInfo from './StudentScoreInfo';
import refresh_icon from '../../../images/icons/refresh.svg';
import useFeedback from '../../../hooks/useFeedback';
import ContextMenu from '../../ContextMenu';
import ContextMenuContext from '../../../Contexts/ContextMenuContext';
import DeleteStudent from '../DeleteStudent';
import RenameStudent from '../RenameStudent';
import useMountAwareState from '../../../hooks/useMountAwareState';
import PopupContext from '../../../Contexts/PopupContext';
export default function ClassManageStudent(props) {
    
    const feedback = useFeedback();
    const navigate = useNavigate();
    const [student, setStudent] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);
    const [errorCode, setErrorCode] = useMountAwareState(null);
    const [regeneratingPin, setRegeneratingPin] = useMountAwareState(false);
    const { setContextMenu } = useContext(ContextMenuContext);
    const { setPopup } = useContext(PopupContext);
    const [ tcommon ] = useTranslation('common');
    const { classPid, studentPid } = useParams();

    const fetchStudent = useCallback(() => {
        setError(null);
        setErrorCode(null);
        API.getInstance()
        .get(`/users/self/classes/${classPid}/students/${studentPid}`)
        .then(({status, data}) => {
            if(status === 200) {
                setStudent(data);
            }else if(status === 404) {
                setError(tcommon('error.page.not_found'));
                setErrorCode(404);
            }else{
                setError(tcommon('error.fetch.default', {code: status}));
            }
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
        });
    }, [setError, setErrorCode, classPid, studentPid, setStudent, tcommon]);

    useEffect(() => {
        fetchStudent();
        document.addEventListener('student-update', fetchStudent);

        return () => {
            document.removeEventListener('student-update', fetchStudent);
        };
    }, [fetchStudent])
    

    const regeneratePin = () => {
        if(student.type === 'email') return;
        setRegeneratingPin(true);
        API.getInstance()
        .patch(`/users/self/classes/${classPid}/students/${studentPid}`, {
            pin: ''
        })
        .then(({status, data}) => {
            if(status === 200) {
                feedback.add(tcommon('feedback.pin_regenerated'));
                fetchStudent();
            }else{
                feedback.add(tcommon('feedback.unable_to_regenerate_pin_status', {status: status}));
            }
            setRegeneratingPin(false);
        })
        .catch(err => {
            feedback.add(tcommon('feedback.unable_to_regenerate_pin'));
            setRegeneratingPin(false);
        });
    };

    const openRenamePopup = () => {
        setPopup(
            <RenameStudent studentPid={studentPid} student={student} classPid={classPid} close={() => setPopup(null)} />
        );
    };

    const openDeletePopup = () => {
        setPopup(
            <DeleteStudent studentPid={studentPid} student={student} classPid={classPid} close={() => setPopup(null)} success={() => {navigate(`/classes/${classPid}/students`)}} />
        );
    };

    const getContextMenu = (position) => {
        return <ContextMenu position={position}>
            <button onClick={e => {openRenamePopup(); setContextMenu(null);}}>{tcommon('general.rename')}</button>
            <button className='red' onClick={() => {openDeletePopup(); setContextMenu(null);}}>{tcommon('general.remove')}</button>
        </ContextMenu>;
    };

    const handleContextMenu = (e) => {
        e.preventDefault();
        const position = {
            x: e.pageX,
            y: e.pageY
        };
        setContextMenu(
            getContextMenu(position)
        );
    };
    
    useDocumentTitle(student === null ? 'Loading' : `${student.first_name} ${student.last_name}`);
    
    if(error) return <ErrorPage code={errorCode ?? undefined} error={error} />;
    if(student === null) return <Loading>Fetching student data</Loading>;
    return (
        <section className='ClassManageStudent'>
            <header>
                <ClassesBreadcrumb links={[
                    {to: `/classes/${classPid}`, label: student.class.name},
                    {to: `/classes/${classPid}/students`, label: 'Students'},
                    {to: `/classes/${classPid}/students/${studentPid}`, label: `${student.first_name} ${student.last_name}`},
                ]} />
            </header>
            <main>
                <div className='general-info'>
                   <div className='header'>
                        <h1>{student.first_name} {student.last_name}</h1>
                        <button className='dotdotdot' onClick={handleContextMenu}>
                            <div>
                                &#9679;&#9679;&#9679;
                            </div>
                        </button>
                   </div>
                    <table>
                        <tbody>
                            {student.type === 'email' ?
                                <tr>
                                    <td>{tcommon('general.email')}:</td>
                                    <td>{student.email}</td>
                                </tr>
                            : undefined }
                            <tr>
                                <td>{tcommon('general.class')}:</td>
                                <td>
                                    <Link to={`/classes/${student.class.pid}`}>
                                        {student.class.name}
                                    </Link>
                                </td>
                            </tr>
                            {student.type === 'email' ?
                                (student.is_confirmed ?
                                    <tr>
                                        <td>{tcommon('general.joined')}:</td>
                                        <td>{new Date(student.confirmation_date * 1000).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric'})}</td>
                                    </tr>
                                :
                                    <tr>
                                        <td>{tcommon('general.invitation')}:</td>
                                        <td>{tcommon('general.pending')}...</td>
                                    </tr>
                                )
                            :
                                <tr>
                                    <td>{tcommon('general.pin')}:</td>
                                    <td>
                                        <div>
                                            <HiddenText>{student.pin}</HiddenText>
                                            <button onClick={regeneratePin} disabled={regeneratingPin}>
                                                <img src={refresh_icon} alt="regenerate" title="regenerate" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            }
                            
                        </tbody>
                    </table>
                </div>
                <div className='score-info'>
                    <StudentScoreInfo classPid={classPid} studentPid={studentPid} />
                </div>
                <div className='results'>
                    <StudentResults classPid={classPid} studentPid={studentPid} />
                </div>
            </main>
        </section>
    );
}