import React, { useEffect } from 'react';
import useMountAwareState from '../../hooks/useMountAwareState';
export default function Dot3(props) {

    const intervalTime = props.interval ?? 300;

    const [dots, setDots] = useMountAwareState(0);

    useEffect(() => {
        const nextDot = () => setDots(dots => (dots+1) % 4);
        const intervalId = setInterval(nextDot, intervalTime);
        return () => {
            if(intervalId) clearInterval(intervalId);
        }
    }, [intervalTime, setDots]);
    
    return (
        <span style={{whiteSpace: 'pre'}} className='Dot3'>
            <span style={{color: (dots >= 1 ? 'inherit' : 'transparent')}}>.</span>
            <span style={{color: (dots >= 2 ? 'inherit' : 'transparent')}}>.</span>
            <span style={{color: (dots >= 3 ? 'inherit' : 'transparent')}}>.</span>
        </span>
    );
}