import React, { useCallback, useContext, useEffect } from "react";
import "./FlashcardsResults.scss";

import correct_icon from "../../../images/icons/correct.svg";
import wrong_icon from "../../../images/icons/wrong.svg";
import logo from "../../../images/logo.svg";
import ratio from "../../../images/flashcard-ratio.svg";

import StringCleaner from "../../../helpers/StringCleaner";

import Loading from "../../Loading";
import API from "../../../API/API";
import { useTranslation } from "react-i18next";
import useMountAwareState from "../../../hooks/useMountAwareState";
import SettingsContext from "../../../Contexts/SettingsContext";

export default function FlashcardsResults(props) {
  const [results, setResults] = useMountAwareState(null);
  const [error, setError] = useMountAwareState(null);

  const [tcommon] = useTranslation("common");

  const { settings } = useContext(SettingsContext);

  const isAnswerCorrect = useCallback(
    (answer) => {
      const face = props.lang === "fr" ? "front" : "back";

      const cleaner = new StringCleaner()
        .addRule((str) => str.replace(/\([^()]*\)/gim, ""))
        .trimWhitespaces()
        .normalizeWhitepaces();

      let correctAnswer = answer.card[face].text;
      let userAnswer = answer.answer;
      if (!settings.uppercaseSensitivity) {
        correctAnswer = correctAnswer.toLowerCase();
        userAnswer = userAnswer.toLowerCase();
      }
      if (!settings.ponctuationSensitivity) {
        correctAnswer = correctAnswer.replace(/[.,?!]/gm, " ");
        userAnswer = userAnswer.replace(/[.,?!]/gm, " ");
      }

      const correctAnswers = correctAnswer
        .split("/")
        .map((w) => cleaner.clean(w));
      const answers = userAnswer.split("/").map((w) => cleaner.clean(w));

      return (
        correctAnswers.filter((value) => answers.includes(value)).length ===
        answers.length
      );
    },
    [props.lang, settings]
  );

  const verifyAnswersWithToken = useCallback(() => {
    API.getInstance()
      .post(
        `/resource-verify-answers/${props.resource.rid}${
          props?.token !== undefined ? `?t=${props.token}` : ""
        }`,
        {
          data: props.results,
        },
        false
      )
      .then(({ status, data }) => {
        if (status === 200 || status === 201) {
          setResults(data);
        } else {
          setError(tcommon("error.fetch.default", { code: status }));
        }
      })
      .catch((error) => {
        setError(tcommon("error.general.default"));
      });
  }, [
    props?.resource?.rid,
    props?.token,
    props.results,
    setResults,
    setError,
    tcommon,
  ]);

  const verifyAnswers = useCallback(
    (answers) => {
      let evaluatedResults = {
        correct: [],
        wrong: [],
      };

      answers.map((answer) => {
        if (isAnswerCorrect(answer)) evaluatedResults.correct.push(answer);
        else evaluatedResults.wrong.push(answer);
        return true;
      });

      return evaluatedResults;
    },
    [isAnswerCorrect]
  );

  useEffect(() => {
    if (props.token) {
      verifyAnswersWithToken();
    } else {
      if (props.alreadyEvaluated ?? false) {
        setResults(props.results);
      } else {
        setResults(verifyAnswers(props.results));
      }
    }

    return () => {};
  }, [
    props.alreadyEvaluated,
    props.results,
    props.token,
    setResults,
    verifyAnswers,
    verifyAnswersWithToken,
  ]);

  if (error) return <div className="error">{error}</div>;
  if (results === null) return <Loading>Evaluating your answers</Loading>;

  const percent = Math.round(
    (results.correct.length / (results.correct.length + results.wrong.length)) *
      100
  );

  let message = "Excellent!";
  if (percent < 50) message = "Try again!";
  else if (percent < 90) message = "You did good!";

  const guess_face = props.lang === "fr" ? "back" : "front";
  const answer_face = props.lang === "fr" ? "front" : "back";

  return (
    <div
      className={`FlashcardsResults ${props.animateOut ? "animateOut" : ""} ${
        props.animateIn ? "animateIn" : ""
      }`}
    >
      {props.alreadyEvaluated ?? false ? undefined : (
        <button onClick={props.reset}>Play again</button>
      )}
      <h2>
        {results.correct.length}/{results.correct.length + results.wrong.length}{" "}
        ({percent}%) {message}
      </h2>
      <div className="details">
        <div className="col corrects">
          {results.correct.map((r, i, a) => {
            return (
              <div key={i} className="flashcard correct">
                <img
                  width={1778}
                  height={1069}
                  src={ratio}
                  alt=""
                  className="ratio"
                />
                <div className="content">
                  <div className="icon">
                    <img
                      width={123}
                      height={88}
                      src={correct_icon}
                      alt="correct"
                    />
                  </div>
                  <div className="info">
                    <img src={r.card.front.image} alt="" />
                    <div className="texts">
                      <div className="text">{r.card[guess_face].text}</div>
                      <div className="answer correct">
                        <img
                          width={123}
                          height={88}
                          src={correct_icon}
                          alt="The answer is: "
                        />
                        <div>{r.card[answer_face].text}</div>
                      </div>
                    </div>
                  </div>
                  <div className="logo">
                    <img width={128} height={128} src={logo} alt="" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="col wrongs">
          {results.wrong.map((r, i, a) => {
            return (
              <div key={i} className="flashcard wrong">
                <img
                  width={1778}
                  height={1069}
                  src={ratio}
                  alt=""
                  className="ratio"
                />
                <div className="content">
                  <div className="icon">
                    <img
                      width={89}
                      height={85}
                      src={wrong_icon}
                      alt="correct"
                    />
                  </div>
                  <div className="info">
                    <img src={r.card.front.image} alt="" />
                    <div className="texts">
                      <div className="text">{r.card[guess_face].text}</div>
                      <div className="answer wrong">
                        <img
                          width={89}
                          height={85}
                          src={wrong_icon}
                          alt="Incorrect: "
                        />
                        {r.answer}
                      </div>
                      <div className="answer correct">
                        <img
                          width={123}
                          height={88}
                          src={correct_icon}
                          alt="The answer is: "
                        />
                        <div>{r.card[answer_face].text}</div>
                      </div>
                    </div>
                  </div>
                  <div className="logo">
                    <img width={128} height={128} src={logo} alt="" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
