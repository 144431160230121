import React, { useMemo } from 'react';
import Collapsible from '../../../../utils/Collapsible';
import './MissingWordsClassResultsViewer.scss';
import ScoreDisplay from '../../../ScoreDisplay';
import { useTranslation } from 'react-i18next';
export default function MissingWordsClassResultsViewer(props) {

    const [ t ] = useTranslation('main');

    const digest = useMemo(() => {
        const sorters = {
            'scoreAsc': (a, b) => {
                return a.corrects_count - b.corrects_count;
            },
            'scoreDesc': (a, b) => {
                return b.corrects_count - a.corrects_count;
            },
            'no': (a, b) => 0,
        };

        return [...props.digest].sort(sorters[props.sort ?? 'no'] ?? sorters['no']);
    }, [props.digest, props.sort]);

    return (
        <div className='MissingWordsClassResultsViewer'>
            <div className='breakdown'>
                {digest.map((c, i) => {
                    return <Collapsible key={i} header={<h2>#{i+1} {c.correct_answer}</h2>}>
                        <div className='missing-words-digest'>
                            <div className='analyze'>
                                <div className='correct'>
                                    <h3>{t('pages.classes.class_results.correct_answers')}:</h3>
                                    <div>{c.correct_answer}</div>
                                    <ScoreDisplay score={Math.round((c.corrects_count / (c.wrongs_count + c.corrects_count)) * 100)} emptyColor={'#eb4034'} colors={{0: '#34c95c'}} />
                                </div>
                                <div className='wrong'>
                                    <h3>{t('pages.classes.class_results.wrong_answers')}:</h3>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>{t('pages.classes.class_results.count')}</td>
                                                <td>{t('pages.classes.class_results.answer')}</td>
                                                <td>{t('pages.classes.class_results.freq')}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(c.wrongs).map((wrongAnswer) => {
                                                return <tr key={wrongAnswer}>
                                                    <td>{c.wrongs[wrongAnswer]}</td>
                                                    <td>{wrongAnswer}</td>
                                                    <td>{Math.round((c.wrongs[wrongAnswer] / (c.wrongs_count + c.corrects_count)) * 100)}%</td>
                                                </tr>;
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Collapsible>;
                })}
            </div>
        </div>
    );
}

/*
return <table key={i}>
    <thead>
        <tr>
            <td>{i+1}</td>
            <td>{q.question}</td>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Correct:</td>
            <td>{q.answer}</td>
            <td>{c.corrects_count}</td>
        </tr>
        <tr>
            <td>Wrong:</td>
            <td>
                <table>
                    <thead>
                        <tr>
                            <td>Count</td>
                            <td>Answer</td>
                            <td>Freq.</td>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(c.wrongs).map(wrongAnswer => {
                            return <tr key={wrongAnswer}>
                                <td>{c.wrongs[wrongAnswer]}</td>
                                <td>{wrongAnswer}</td>
                                <td>{Math.round((c.wrongs[wrongAnswer] / (c.wrongs_count + c.corrects_count)) * 100)}%</td>
                            </tr>;
                        })}
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>;
*/