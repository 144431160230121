import React, { useCallback, useEffect } from "react";
import "./ExplorerDisplay.scss";
import API from "../../API/API";
import ExplorerFolder from "./ExplorerFolder";
import ExplorerFile from "./ExplorerFile";
import { useParams, useNavigate } from "react-router";
import Breadcrumb from "./Breadcrumb";
import ErrorPage from "../ErrorPage";
import { useTranslation } from "react-i18next";
import Loading from "../Loading";
import useMountAwareState from "../../hooks/useMountAwareState";

export default function ExplorerDisplay(props) {
  let { rid } = useParams();
  if (rid === undefined) rid = props.rid;

  const navigate = useNavigate();
  const [file, setFile] = useMountAwareState(null);
  const [error, setError] = useMountAwareState(null);

  const [t] = useTranslation("common");

  const getCurrentRid = useCallback(() => {
    return rid !== file?.rid ? rid : null;
  }, [file?.rid, rid]);

  useEffect(() => {
    const currentRid = getCurrentRid();
    if (currentRid !== null) {
      API.getInstance()
        .get("/resources/" + currentRid, props.auth ?? true)
        .then(({ status, data }) => {
          if (status === 200) {
            console.log(data);
            setFile(data);
          } else {
            setError(status);
          }
        })
        .catch(() => {
          setError(t("error.general.default"));
        });
    }
  }, [t, props.auth, getCurrentRid, setFile, setError]);

  const manuallySetFile = (file) => {
    // window.history.pushState({rid: file.rid}, '', `/explorer/view/${file.rid}`);
    setFile(file);
    navigate(`/explorer/view/${file.rid}`);
  };

  if (error !== null) {
    if (error === 403) {
      return <ErrorPage code={error} error={t("error.file.forbidden_alt")} />;
    } else if (error === 404) {
      return (
        <ErrorPage
          code={error}
          error={t("error.file.not_found_alt")}
          message={t("tips.url_typo")}
        />
      );
    } else {
      return <ErrorPage />;
    }
  }

  return (
    <>
      {props.auth ?? true ? (
        <Breadcrumb file={file} setFile={manuallySetFile} />
      ) : undefined}
      {file === null ? (
        <Loading />
      ) : file.folder ? (
        <ExplorerFolder file={file} setFile={manuallySetFile} />
      ) : (
        <ExplorerFile file={file} auth={props.auth} />
      )}
    </>
  );
}
