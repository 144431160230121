import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import AuthContext from '../Contexts/AuthContext';
import useCookieConsent from '../hooks/useCookieConsent';

export default function ProtectedElement(props) {
    const authContext = useContext(AuthContext);
    const { functionality } = useCookieConsent();
    const location = useLocation();

    if(!authContext.auth) {
        if(functionality) {
            if(location.pathname.startsWith('/subscribe')) {
                const query = new URLSearchParams(location.search);
                
                localStorage.setItem('requested-subscription', JSON.stringify({
                    subscription: query.get('subscription'),
                    plan: query.get('plan'),
                    source: query.get('source'),
                }));
            }
        }
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <Outlet />;
}