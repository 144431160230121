import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './SubscriptionPlans.scss';

import logo_notext from '../../images/logo.svg';
import institution_icon from '../../images/icons/institution.svg';
import { Link } from 'react-router-dom';

export default function SubscriptionPlans(props) {

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    useEffect(() => {
        const equalizeOffers =() => {
            let max = 0;
            const offers = document.querySelectorAll('.offer');
            offers.forEach(offer => {
                offer.style.minHeight = 'auto';
                if(max < offer.offsetHeight) max = offer.offsetHeight;
            });
            offers.forEach(offer => {
                offer.style.minHeight = max + 'px';
            });
        };
        const equalizePrices =() => {
            let max = 0;
            const prices = document.querySelectorAll('.price');
            prices.forEach(price => {
                price.style.minHeight = 'auto';
                if(max < price.offsetHeight) max = price.offsetHeight;
            });
            prices.forEach(price => {
                price.style.minHeight = max + 'px';
            });
        };
        

        equalizeOffers();
        equalizePrices();
        document.addEventListener('resize', equalizeOffers);
        document.addEventListener('resize', equalizePrices);
        return () => {
            document.removeEventListener('resize', equalizeOffers);
            document.removeEventListener('resize', equalizePrices);
        }
    }, []);
    
    return (
        <section className='SubscriptionPlans'>
            <h2>{t('pages.presentation.plans.title')}:</h2>
            <div className="offers">
                <div className="offer elementary">
                    <h3><img width={128} height={128} src={logo_notext} alt="" />Individual Elementary</h3>
                    <h4>{t('pages.presentation.plans.individual.access_to')}:</h4>
                    <ul>
                        <li>{t('pages.presentation.plans.individual.list.elementary.corrections')}</li>
                        <li>{t('pages.presentation.plans.individual.list.elementary.flashcards')}</li>
                        <li>{t('pages.presentation.plans.individual.list.elementary.activities')}</li>
                    </ul>
                    <div className='select'>{t('pages.presentation.plans.choose_your_plan')}:</div>
                    <div className="price-container">
                        <Link to="/subscribe?subscriptions=elementary&plan=monthly&source=presentation" className="price">
                            <h4 className='time'>{tcommon('general.monthly')}</h4>
                            <span className='amount'><span className="number">${process.env.REACT_APP_MONTHLY_PLAN}</span>/{t('pages.presentation.plans.individual.month')}</span>
                            <span className='billed'>{t('pages.presentation.plans.billed_monthly')}</span>
                            <span className='tax-inc'>{t('pages.presentation.plans.individual.tax_inc')}</span>
                            <div className='subscribe-container'>
                                <span>Subscribe now</span>
                            </div>
                        </Link>
                        <Link to="/subscribe?subscriptions=elementary&plan=yearly&source=presentation" className="price">
                            <h4 className='time'>{tcommon('general.yearly')} <span className='discount'>-30%</span></h4>
                            <span className='amount'><span className="number">${process.env.REACT_APP_YEARLY_PLAN}</span>/{t('pages.presentation.plans.individual.month')}</span>
                            <span className='billed'>{t('pages.presentation.plans.billed_yearly')}: ${Math.round(((process.env.REACT_APP_YEARLY_PLAN * 12) + Number.EPSILON) * 100) / 100}</span>
                            <span className='tax-inc'>{t('pages.presentation.plans.individual.tax_inc')}</span>
                            <div className='subscribe-container'>
                                <span>Subscribe now</span>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="offer advanced">
                    <h3><img width={128} height={128} src={logo_notext} alt="" /> Individual Advanced</h3>
                    <h4>{t('pages.presentation.plans.individual.access_to')}:</h4>
                    <ul>
                        <li>{t('pages.presentation.plans.individual.list.advanced.corrections')}</li>
                        <li>{t('pages.presentation.plans.individual.list.advanced.flashcards')}</li>
                        <li>{t('pages.presentation.plans.individual.list.advanced.activities')}</li>
                    </ul>
                    <div className='select'>{t('pages.presentation.plans.choose_your_plan')}:</div>
                    <div className="price-container">
                        <Link to="/subscribe?subscriptions=advanced&plan=monthly&source=presentation" className="price">
                            <h4 className='time'>{tcommon('general.monthly')}</h4>
                            <span className='amount'><span className="number">${process.env.REACT_APP_MONTHLY_PLAN}</span>/{t('pages.presentation.plans.individual.month')}</span>
                            <span className='billed'>{t('pages.presentation.plans.billed_monthly')}</span>
                            <span className='tax-inc'>{t('pages.presentation.plans.individual.tax_inc')}</span>
                            <div className='subscribe-container'>
                                <span>Subscribe now</span>
                            </div>
                        </Link>
                        <Link to="/subscribe?subscriptions=advanced&plan=yearly&source=presentation" className="price">
                            <h4 className='time'>{tcommon('general.yearly')} <span className='discount'>-30%</span></h4>
                            <span className='amount'><span className="number">${process.env.REACT_APP_YEARLY_PLAN}</span>/{t('pages.presentation.plans.individual.month')}</span>
                            <span className='billed'>{t('pages.presentation.plans.billed_yearly')}: ${Math.round(((process.env.REACT_APP_YEARLY_PLAN * 12) + Number.EPSILON) * 100) / 100}</span>
                            <span className='tax-inc'>{t('pages.presentation.plans.individual.tax_inc')}</span>
                            <div className='subscribe-container'>
                                <span>Subscribe now</span>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="offer institutional">
                    <h3><img width={287} height={287} src={institution_icon} alt="" /> Schools & School Districts</h3>
                    <h4>{t('pages.presentation.plans.institutional.access_to')}:</h4>
                    <ul>
                        <li>{t('pages.presentation.plans.institutional.list.documents')}</li>
                        <li>{t('pages.presentation.plans.institutional.list.flashscards_activities')}</li>
                        <li>{t('pages.presentation.plans.institutional.list.share')}</li>
                    </ul>
                    <div className="price-container center">
                        <a href="https://waeelearning.com/contact?subject=Quote%20for%20WAEE%20French%20Portal" target="_blank" rel="noreferrer noopener" className="price custom no-after">
                            <h4 className='time'>{t('pages.presentation.plans.institutional.custom_pricing')}</h4>
                            <div className='subscribe-container'>
                                <span>{t('pages.presentation.plans.institutional.contact_us')}</span>
                            </div>
                            {/* <span className='amount'>Up to <span className='discount'>-50%</span></span> */}
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}