import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../API/API';
import parseHeaderInt from '../../../helpers/ParseHeaderInt';
import ErrorPage from '../../ErrorPage';
import Loading from '../../Loading';
import Dot3 from '../../utils/Dot3';
import './ClassShareResultsList.scss';
import ResultLine from './ResultLine';
import search_icon from '../../../images/icons/search.svg';
import useCookieConsent from '../../../hooks/useCookieConsent';
import { useParams } from 'react-router-dom';
import useMountAwareState from '../../../hooks/useMountAwareState';
export default function ClassShareResultsList(props) {
    
    const { functionality } = useCookieConsent();

    const [results, setResults] = useMountAwareState(null);
    const [query, setQuery] = useMountAwareState('');
    const [firstOnly, setFirstOnly] = useMountAwareState((localStorage.getItem('see-results-first-only') ?? 'true') === 'true');
    const [error, setError] = useMountAwareState(null);
    const [errorCode, setErrorCode] = useMountAwareState(null);
    const [pagination, setPagination] = useMountAwareState({current: null, previous: null, next: null, count: null, total: null});
    const [loading, setLoading] = useMountAwareState(false);
    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');
    const { classPid, sharePid } = useParams();

    const fetchShares = useCallback(
        (page = 1) => {
            setError(null);
            setErrorCode(null);
            setLoading(true);
            API.getInstance()
            .get(`/users/self/classes/${classPid}/shares/${sharePid}/results?page=${page}&firstOnly=${firstOnly}`)
            .then(({status, data, headers}) => {
                if(status === 200) {
                    setResults(data);
                    setPagination({
                        previous: parseHeaderInt(headers.get('x-pagination-previous')),
                        current: parseHeaderInt(headers.get('x-pagination-current')),
                        next: parseHeaderInt(headers.get('x-pagination-next')),
                        count: parseHeaderInt(headers.get('x-pagination-count')),
                        total: parseHeaderInt(headers.get('x-total-entities')),
                    });
                }else if(status === 404) {
                    setError(tcommon('error.page.not_found'));
                    setErrorCode(404);
                }else{
                    setError(tcommon('error.fetch.default', {code: status}));
                }
                setLoading(false);
            })
            .catch(err => {
                setError(tcommon('error.general.default'));
                setLoading(false);
            });
        },
        [setError, setErrorCode, setLoading, classPid, sharePid, firstOnly, setResults, setPagination, tcommon],
    );

    useEffect(() => {
        fetchShares();
    }, [fetchShares]);    

    const toggleFirstOnly = () => {
        if(functionality) localStorage.setItem('see-results-first-only', !firstOnly);
        setFirstOnly(!firstOnly);
    }
    
    if(error) return <ErrorPage code={errorCode ?? undefined} error={error} noReturnToHome={true} />;
    if(results === null) return <Loading>Fetching shares list</Loading>;
    return (
        <div className='ClassShareResultsList'>
            {results.length === 0 ?
                <div>{tcommon('general.nothing_yet')}</div>
            :
                <>
                    <div className='header'>
                        <div className='search'>
                            <label>
                                <img src={search_icon} alt="" />
                                <input type="search" value={query} onChange={e => setQuery(e.target.value)} placeholder={`${tcommon('general.search')}...`} />
                            </label>
                        </div>
                        <div className='first-only'>
                            <label>
                                {t('pages.classes.first_attempts_only')}:
                                <input type="checkbox" checked={firstOnly} onChange={toggleFirstOnly} />
                            </label>
                        </div>
                    </div>
                    <table>
                            <thead>
                                <tr>
                                    <td>{tcommon('general.student')}</td>
                                    <td>{tcommon('general.score')}</td>
                                    <td>{tcommon('general.returned_on')}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {results.map((result, i) => {
                                    return <ResultLine
                                                key={result.pid} 
                                                result={result} 
                                                classPid={props.classPid}
                                    />;
                                })}
                            </tbody>
                        </table>
                    <div className='footer'>
                        <div className='stats'>
                            <div>{tcommon('pagination.total')}: {pagination.total ? pagination.total : (results === null ? <Dot3 /> : 0) }</div>
                        </div>
                        <div className='controls'>
                            <button 
                                    className={loading ? 'loading' : ''}
                                    onClick={() => {fetchShares(pagination.previous)}}
                                    disabled={pagination.previous === null || loading}
                            >
                                {tcommon('pagination.previous')}
                            </button>
                            <div>
                                {pagination.current}/{pagination.count}
                            </div>
                            <button 
                                    className={loading ? 'loading' : ''}
                                    onClick={() => {fetchShares(pagination.next)}}
                                    disabled={pagination.next === null || loading}
                            >
                                {tcommon('pagination.next')}
                            </button>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}