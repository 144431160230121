import React from 'react';
import { useTranslation } from 'react-i18next';
import './AccountSubscription.scss';

import subscription_icon from '../../images/icons/subscription.svg';
import API from '../../API/API';
import Subscription from './AccountSubscriptionElements/Subscription';
import Institutional from './AccountSubscriptionElements/Institutional';
import useFeedback from '../../hooks/useFeedback';
import useMountAwareState from '../../hooks/useMountAwareState';

export default function AccountSubscription(props) {

    const [redirecting, setRedirecting] = useMountAwareState(false);
    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    const feedback = useFeedback();

    const redirectToCutsomerPortal = () => {
        setRedirecting(true);
        feedback.add(tcommon('feedback.accessing_customer_portal'));
        API.getInstance().get('/users/self/customer-portal')
        .then(({status, data}) => {
            if(status === 200) {
                setRedirecting(false);
                window.location.href = data.url
            }else{
                feedback.add(tcommon('feedback.could_not_access_customer_portal'));
                setRedirecting(false);
            }
        })
        .catch(err => {
            feedback.add(tcommon('feedback.could_not_access_customer_portal'));
            setRedirecting(false);
        });
    };

    const type = props.user.type;

    return (
        <section className='AccountSubscription'>
            <h2><img width={213} height={213} src={subscription_icon} alt="" />{t('pages.account.sections.subscription.title')}</h2>
            {type === 1 || type === 0 ? 
                <>
                    <Subscription redirectToCutsomerPortal={redirectToCutsomerPortal} />
                    <div className='customer-portal-container'>
                        <button disabled={redirecting} onClick={redirectToCutsomerPortal} className='btn-yellow'>{t('pages.account.sections.subscription.manage')}</button>
                    </div>
                </>
            : 
                <Institutional />
            }
        </section>
    );
}