import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Collapsible from '../../../../../utils/Collapsible';
import ScoreDisplay from '../../../../ScoreDisplay';
export default function QuizMultiChoiceResults(props) {

    const [ t ] = useTranslation('main');

    const c = props.c;
    const i = props.i;
    const text = props.text;
    const answer = props.answer;
    const choices = props.choices;

    const list = useMemo(() => {
        let list = [];
        choices.forEach(c => {
            list[c] = {count: 0, correct: false};
        });
        Object.keys(c.wrongs).forEach(key => {
            const elem = JSON.parse(key);
            elem.forEach(a => {
                list[a].count += c.wrongs[key];
            });
        });
        answer.forEach(c => {list[c].correct = true});
        return list;
    }, [answer, c.wrongs, choices]);

    return (
        <Collapsible header={<h2>#{i} {text}</h2>}>
            <div className='quiz-digest'>
                <div className='analyze'>
                    <div className='correct'>
                        <h3>{t('pages.classes.class_results.correct_answers')}:</h3>
                        <div>
                            <ul>
                                {answer.map(a => <li key={a}>{a}</li>)}
                            </ul>
                        </div>
                        <ScoreDisplay score={Math.round((c.corrects_count / (c.wrongs_count + c.corrects_count)) * 100)} emptyColor={'#eb4034'} colors={{0: '#34c95c'}} />
                    </div>
                    <div className='wrong'>
                        <h3>{t('pages.classes.class_results.answers')}:</h3>
                        <table className='multichoiceresults'>
                            <thead>
                                <tr>
                                    <td>{t('pages.classes.class_results.count')}</td>
                                    <td>{t('pages.classes.class_results.answer')}</td>
                                    <td>{t('pages.classes.class_results.freq')}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(list).map((key) => {
                                    const elem = list[key];
                                    const correct = elem.correct;
                                    const value = elem.count + (correct ? c.corrects_count : 0);
                                    return <tr key={key} className={`${correct ? 'correct' : 'wrong'} ${!correct && value === 0 ? 'hide' : ''}`}>
                                        <td>{value}</td>
                                        <td>{key}</td>
                                        <td>{Math.round((value / (c.wrongs_count + c.corrects_count)) * 100)}%</td>
                                    </tr>;
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Collapsible>
    );
}