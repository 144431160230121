import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import HideStudentResultsContext from '../../../Contexts/HideStudentResultsContext';
import TimeDiffDisplay from '../../utils/TimeDiffDisplay';
import ScoreDisplay from '../ScoreDisplay';
import hide_icon from '../../../images/icons/hide.svg';
import './ResultLine.scss';
export default function ResultLine(props) {
    
    const navigate = useNavigate();
    const { hideStudentResults } = useContext(HideStudentResultsContext);

    const result = props.result;

    return <tr className='ResultLine' key={result.pid} onClick={() => navigate(`/classes/${props.classPid}/shares/${result.share.pid}/result-${result.pid}`)}>
        <td className='resource'>
            <div>
                {result.student.first_name} {result.student.last_name}
            </div>
        </td>
        <td className='score'>
            <div>
                <ScoreDisplay score={result.score} noText={true} />
                {hideStudentResults ?
                    <>
                        <img src={hide_icon} alt="hidden" />
                        %
                    </>
                :
                    (result.score >= 0 ?
                        <span>{Math.round(result.score)}%</span>
                    :
                        undefined
                    )
                }
            </div>
        </td>
        <td className='date'>
            <div>
                {new Date(result.date * 1000).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric', hour12: true, hour: 'numeric', minute: 'numeric'})}
                <TimeDiffDisplay from={new Date(result.share.due_date * 1000)} to={new Date(result.date * 1000)} beforeSuffix={'early'} afterSuffix={'late'} /> 
            </div>
        </td>
    </tr>;
}