import React from 'react';
import Popup from './Popup';

export default function ConfirmPopup(props) {
    
    return (
        <Popup 
            title={props.title}
            text={props.text}
            close={props?.close}
            cancel={props?.cancel}
            confirm={props?.confirm}
            buttons={<>
                <button 
                    className='btn-yellow cancel'
                    onClick={() => {
                        props.cancel?.();
                        props.close?.();
                    }}
                >Cancel</button>
                <button 
                    className='btn-yellow confirm'
                    onClick={() => {
                        props.confirm?.();
                        props.close?.();
                    }}
                >Confirm</button>
            </>}
        />
    );
}