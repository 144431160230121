import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function ClassesResultsGraph(props) {

    let labels = [];
    let values = [];

    props.shares.forEach(share => {
        if(share.average_score < 0) return;
        // const date = new Date(share.creation_date * 1000);
        // labels.push(`${date.getFullYear()}-${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}-${date.getDate() < 10 ? '0' : ''}${date.getDate()}`);
        labels.push(`[${share.resource.stylized_type}] ${share.resource.name}`);
        values.push(Math.round(share.average_score));
    });

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top'
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
        },
        scale: {
            min: 0,
            max: 100,
        },
        scales: {
            x: {
                ticks: {
                  display: false,
                }
              }
        }
    };

    const data = {
        labels,
        datasets: [
            {
                label: 'Class average',
                data: values,
                borderColor: '#ffc114',
                backgroundColor: '#ffc114',
            }
        ],
    };
    
    return (
        <div className='ClassesResultsGraph' style={{position: 'relative', height: '100%', width: '100%'}}>
            <Line
                options={options}
                data={data}
            />
        </div>
    );
}