import React from 'react';
import './ErrorPage.scss';

import broken_robot_img from '../images/divers/broken-robot.svg';
import map_img from '../images/divers/map.svg';
import lock_img from '../images/divers/lock.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ErrorPage(props) {

    const [ t ] = useTranslation('common');

    const images = {
        403: lock_img,
        404: map_img,
        500: broken_robot_img
    }
    
    const code = props?.code ?? '';
    const error = props?.error ?? t('error.general.alt_default');
    const message = props?.message ?? '';
    const image = images?.[code] ?? images[500];

    return (
        <div className='ErrorPage'>
            <div className='title'>
                <img src={image} alt="" />
                <h1><span className='code'>{code}</span> {error}</h1>
            </div>
            <p>{message}</p>
            {props.noReturnToHome ?? false ?
                undefined
            :
                <div className='take-me-back'>
                    <Link to="/">{t('tips.back_to_home')}</Link>
                </div>
            }
        </div>
    );
}