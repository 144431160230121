import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import API from '../../../API/API';
import HideStudentResultsContext from '../../../Contexts/HideStudentResultsContext';
import parseHeaderInt from '../../../helpers/ParseHeaderInt';
import FileIcon from '../../FileIcon';
import Loading from '../../Loading';
import Dot3 from '../../utils/Dot3';
import TimeDiffDisplay from '../../utils/TimeDiffDisplay';
import ScoreDisplay from '../ScoreDisplay';
import hide_icon from '../../../images/icons/hide.svg';
import './StudentResults.scss';
import useMountAwareState from '../../../hooks/useMountAwareState';
export default function StudentResults(props) {

    const { hideStudentResults } = useContext(HideStudentResultsContext);
    const [results, setResults] = useMountAwareState(null);
    const [pagination, setPagination] = useMountAwareState({current: null, previous: null, next: null, count: null, total: null});
    const [loading, setLoading] = useMountAwareState(false);
    const [error, setError] = useMountAwareState(null);
    const [ tcommon ] = useTranslation('common');
    const navigate = useNavigate();
    const classPid = props.classPid;
    const studentPid = props.studentPid;

    const fetchShares = useCallback(
        (page = 1) => {
            setResults(null);
            setLoading(true);
            API.getInstance()
            .get(`/users/self/classes/${classPid}/students/${studentPid}/results?page=${page}`)
            .then(({status, data, headers}) => {
                if(status === 200) {
                    setResults(data);
                    setPagination({
                        previous: parseHeaderInt(headers.get('x-pagination-previous')),
                        current: parseHeaderInt(headers.get('x-pagination-current')),
                        next: parseHeaderInt(headers.get('x-pagination-next')),
                        count: parseHeaderInt(headers.get('x-pagination-count')),
                        total: parseHeaderInt(headers.get('x-total-entities')),
                    });
                }else if(status === 404) {
                    setError(tcommon('error.page.not_found'));
                }else{
                    setError(tcommon('error.fetch.default', {code: status}));
                }
                setLoading(false);
            })
            .catch(err => {
                setError(tcommon('error.general.default'));
                setLoading(false);
            });
        },
        [classPid, setError, setLoading, setPagination, setResults, studentPid, tcommon],
    );

    useEffect(() => {
        
        fetchShares();
      
    }, [fetchShares]);
    
    return (
        <div className='StudentResults'>
            <h2>{tcommon('general.results')}:</h2>
            <div className='list'>
                {error ?
                    <div>{error}</div>
                :
                    (results === null ?
                        <Loading />
                    :
                        <table>
                            <thead>
                                <tr>
                                    <td>{tcommon('general.resource')}</td>
                                    <td>{tcommon('general.date')}</td>
                                    <td>{tcommon('general.score')}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {results.map(r => {
                                    const date = new Date(r.date * 1000);
                                    return <tr key={r.pid} onClick={() => navigate(`/classes/${classPid}/students/${studentPid}/result-${r.pid}`)}>
                                        <td>
                                            <div className='vertical-align'>
                                                <FileIcon type={r.resource.type} /> {r.resource.name}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='vertical-align'>
                                                {date.toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit'})}
                                                <TimeDiffDisplay from={date} to={new Date(r.share.due_date * 1000)} />
                                            </div>
                                        </td>
                                        <td className='score'>
                                            <div className='circle'>
                                                <ScoreDisplay score={r.score} noText={true} />
                                            </div>
                                            {hideStudentResults ?
                                                <div className='number'>
                                                    <img src={hide_icon} alt="hidden" />%
                                                </div>
                                            :
                                                <div className='number'>
                                                    {Math.round(r.score)}%
                                                </div>
                                            }
                                        </td>
                                    </tr>;
                                })}
                            </tbody>
                        </table>
                    )
                }
            </div>
            <div className='footer'>
                <div className='stats'>
                    <div>{tcommon('pagination.total')}: {pagination.total ? pagination.total : (results === null ? <Dot3 /> : 0) }</div>
                </div>
                <div className='controls'>
                    <button 
                            className={loading ? 'loading' : ''}
                            onClick={() => {fetchShares(pagination.previous)}}
                            disabled={pagination.previous === null || loading}
                    >
                        {tcommon('pagination.previous')}
                    </button>
                    <div>
                        {pagination.current}/{pagination.count}
                    </div>
                    <button 
                            className={loading ? 'loading' : ''}
                            onClick={() => {fetchShares(pagination.next)}}
                            disabled={pagination.next === null || loading}
                    >
                        {tcommon('pagination.next')}
                    </button>
                </div>
            </div>
        </div>
    );
}