import React, { useMemo } from 'react';
import './QuizClassResultsViewer.scss';
import QuizShortAnswerResult from './modules/QuizShortAnswerResults';
import QuizLongAnswerResult from './modules/QuizLongAnswerResults';
import QuizSingleChoiceResults from './modules/QuizSingleChoiceResults';
import QuizMultiChoiceResults from './modules/QuizMultiChoiceResults';
import QuizMultiChoiceGridResults from './modules/QuizMultiChoiceGridResults';
import QuizConjugationResults from './modules/QuizConjugationResults';
import QuizImageResults from './modules/QuizImageResults';
import QuizVideoResults from './modules/QuizVideoResults';
export default function QuizClassResultsViewer(props) {

    const digest = useMemo(() => {
        const fusion = props.digest.answers.map((a, i) => {return {answer: a, module: props.digest.modules[i], index: i}});

        if(props.sort === 'no') return fusion;
        const sorters = {
            'scoreAsc': (a, b) => {
                return a.answer.corrects_count - b.answer.corrects_count;
            },
            'scoreDesc': (a, b) => {
                return b.answer.corrects_count - a.answer.corrects_count;
            },
            'no': (a, b) => 0,
        };
        return [...fusion].sort(sorters[props.sort ?? 'no'] ?? sorters['no']);
    }, [props.digest, props.sort]);

    return (
        <div className='QuizClassResultsViewer'>
            <div className='breakdown'>
                {digest.map(d => {
                    const module = d.module;
                    const c = d.answer;
                    const i = d.index;
                    switch (module.type) {
                        case 'shortanswer':
                            return <QuizShortAnswerResult key={i} i={i+1} c={c} text={module.text} answer={module.answer} />;
                        case 'longanswer':
                            return <QuizLongAnswerResult key={i} i={i+1} c={c} text={module.text} answer={module.answer} />;
                        case 'singlechoice':
                            return <QuizSingleChoiceResults key={i} i={i+1} c={c} text={module.text} answer={module.answer} choices={module.choices} />;
                        case 'multichoice':
                            return <QuizMultiChoiceResults key={i} i={i+1} c={c} text={module.text} answer={module.answer} choices={module.choices} />;
                        case 'multichoicegrid':
                            return <QuizMultiChoiceGridResults key={i} i={i+1} c={c} text={module.text} answer={module.answer} choices={module.choices} />;
                        case 'conjugation':
                            return <QuizConjugationResults key={i} i={i+1} c={c} text={module.text} answer={module.answer} pronouns={module?.pronouns} />;
                        case 'image':
                            return <QuizImageResults key={i} i={i+1} c={c} text={module.text} url={module.url} alt={module?.alt} />;
                        case 'video':
                            return <QuizVideoResults key={i} i={i+1} c={c} text={module.text} url={module.url} />;
                        default:
                            return <div key={i}>Could not display results</div>;
                    }
                })}
            </div>
        </div>
    );
}