import React from 'react';
import './ExplorerEmptyFolder.scss';
import { useTranslation } from 'react-i18next';
export default function ExplorerEmptyFolder(props) {

    const [ t ] = useTranslation('main');
    
    return (
        <div className='ExplorerEmptyFolder'>
            <div className='empty'>
                {t('pages.explorer.empty')}
            </div>
        </div>
    );
}