import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router';
import './FlashcardsViewerReducedMenu.scss';

import chevron from '../../../images/icons/chevron.svg';
import { Link } from 'react-router-dom';
import useMountAwareState from '../../../hooks/useMountAwareState';

export default function FlashcardsViewerReducedMenu(props) {
    const [focus, setFocus] = useMountAwareState(false);

    const wrapperRef = useRef(null);

    const { subsection } = useParams();

    const handleClickOutside = useCallback((event) => {
        if (wrapperRef && !wrapperRef.current.contains(event.target)) {
            setFocus(false);
        }
    }, [setFocus]);

    const handleFocus = () => {
        setFocus(true);
    };

    const handleFocusOut = (event) => {
        if (event.currentTarget.contains(event.relatedTarget)) {
            setFocus(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [handleClickOutside])
    
    const buttons = props.buttons;

    const options = useMemo(() => {
        return Object.keys(buttons).map((key) => {
            return <Link to={`/explorer/view/${props.resource.rid}/${key}`} key={key} className={subsection === key ? 'active' : ''} onClick={() => setFocus(false)}>{buttons[key]}</Link>;
        });
    }, [buttons, props.resource.rid, setFocus, subsection]);

    return (
        <div ref={wrapperRef} className='FlashcardsViewerReducedMenu'>
            <button className="active" onFocus={handleFocus} onClick={() => {setFocus(true)}} onBlur={handleFocusOut}><img width={49} height={85} className="chevron" src={chevron} alt="Select mode" />{buttons[subsection] ?? ""}</button>
            {focus ?
                <div className="options">
                    {options}
                </div>
            : ''}
        </div>
    );
}