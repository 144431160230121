import React, { createRef, useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ContextMenuContext from '../Contexts/ContextMenuContext';
import './ContextMenu.scss';
export default function ContextMenu(props) {

    const navigate = useNavigate();
    const wrapperRef = createRef();
    const { setContextMenu } = useContext(ContextMenuContext);

    const handleClickOutside = useCallback((event) => {
        if (wrapperRef && !wrapperRef.current.contains(event.target)) {
            setContextMenu(null);
        }
    }, [wrapperRef, setContextMenu]);

    const handleClick = e => {
        if(e.target.tagName === "A") {
            e.preventDefault();
            const href = e.target?.href ?? "/";
            const url = new URL(href);
            navigate(href.toString().replace(url.origin, ""));
            setContextMenu(null);
        }
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if(e.code === 'Escape') {
                setContextMenu(null);
            }
        };

        wrapperRef.current.querySelector('a, button').focus();
        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('keydown', handleKeyDown);
    
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [wrapperRef, handleClickOutside, setContextMenu]);

    const handleBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setContextMenu(null);
        }
    };

    let style = {};
    if(props.position.x <= window.innerWidth * 0.75) {
        style = {left: props.position.x, top: props.position.y}
    }else{
        style = {right: window.innerWidth - props.position.x, top: props.position.y}
    }
    if(props.position.y >= window.innerHeight * 0.75) {
        delete style.top;
        style.bottom = window.innerHeight - props.position.y;
    }else{

    }
    return (
        <div ref={wrapperRef} style={style} className='ContextMenu' onBlur={handleBlur} onClick={handleClick}>
            {props.children}
        </div>
    );
}