import React, { useCallback, useContext, useEffect, useRef } from 'react';
import API from '../../API/API';
import './AccountBox.scss';
import account_icon from '../../images/icons/account.svg';
import help_icon from '../../images/icons/help.svg';
import classes_icon from '../../images/icons/classes.svg';
import logout_icon from '../../images/icons/logout.svg';
import settings_icon from '../../images/icons/settings.svg';
import AuthContext from '../../Contexts/AuthContext';
import UserContext from '../../Contexts/UserContext';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMountAwareState from '../../hooks/useMountAwareState';
import LoggingOut from './LoggingOut';
import useFeedback from '../../hooks/useFeedback';
import PopupContext from '../../Contexts/PopupContext';
import Settings from '../settings/Settings';

export default function AccountBox(props) {
    const [focus, setFocus] = useMountAwareState(false);
    const [ loggingOut, setLoggingOut ] = useMountAwareState(false);

    const auth = useContext(AuthContext);
    const { user } = useContext(UserContext);
    const { setPopup } = useContext(PopupContext);

    const [ t ] = useTranslation('main');

    const feedback = useFeedback();

    let wrapperRef = useRef(null);

    const handleOutsideInteraction = useCallback(
        (e) => {
            if (wrapperRef && !wrapperRef.current.contains(e.target)) {
                setFocus(false);
            }
        },
        [setFocus],
    );

    const handleClick = (e) => {
        setFocus(true);
    };

    const handleBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setFocus(false);
        }
    };

    const openSettings = () => {
        setPopup(
            <Settings close={() => setPopup(null)} />
        );
    };

    const logout = (e) => {
        setLoggingOut(true);
        API.getInstance().logout()
        .then(({status, data}) => {
            if(status === 200) auth.setAuth(false);
            else {
                setLoggingOut(false);
                feedback.add(t(`layout.logout.unable`));
            }
        })
        .catch(err => {
            setLoggingOut(false);
            feedback.add(t(`layout.logout.unable`));
        });
    };
    
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideInteraction);
        return () => {
            document.removeEventListener('mousedown', handleOutsideInteraction);
        }
        
    }, [handleOutsideInteraction]);

    return <>
        <div ref={wrapperRef} className={`AccountBox type${user?.type ?? 0}`} onBlur={handleBlur}>
            <button onClick={handleClick} className={`${focus ? 'focus' : 'no-focus'}`} onFocus={handleClick}>
                <img width={131} height={131} src={account_icon} alt="Account: "/> 
                <span className='identity'>{user?.first_name ?? 'Loading...'}</span>
                {user.pending_invitations > 0 ?
                    <div className='notif'>
                        {user.pending_invitations}
                    </div>
                : undefined}
            </button>
            
            {focus ?
                <div className="actions">
                    <Link to="/account" onClick={() => {setFocus(false);}}>
                        <img width={131} height={131} src={account_icon} alt="My account"/>
                        <div>{t('layout.header.account_box.my_account')}</div>
                        {user.pending_invitations > 0 ?
                        <div className='notif'>
                                {user.pending_invitations}
                            </div>
                        : undefined}
                    </Link>
                    {user.roles.includes('institutional_teacher') || user.roles.includes('institutional_admin') ?
                        <Link to="/classes" onClick={() => {setFocus(false);}}>
                            <img width={335} height={324} src={classes_icon} alt="" />
                            <div>{t('layout.header.account_box.classes')}</div>
                        </Link>
                    : undefined }
                    <Link to="/help" onClick={() => {setFocus(false);}} target="_blank">
                        <img width={574} height={574} src={help_icon} alt="" />
                        <div>{t('layout.header.account_box.help')}</div>
                    </Link>
                    <button className='settings' onClick={openSettings}>
                        <img src={settings_icon} alt="" />
                        <div>{t('settings.title')}</div>
                    </button>
                    <div className="separator"></div>
                    <button className="logout" onClick={logout}>
                        <img width={141} height={182} src={logout_icon} alt="" />
                        <div>{t('layout.header.account_box.logout')}</div>
                    </button>
                </div>
            : ''}
        </div>
        {loggingOut ?
            <LoggingOut />
        : undefined }
    </>;
}