import React, { useContext, useEffect } from 'react';
import API from '../../API/API';
import './ReLogin.scss';
import AuthContext from '../../Contexts/AuthContext';
import logo from '../../images/logo.svg';
import { useTranslation } from 'react-i18next';
import Dot3 from '../utils/Dot3';
import useMountAwareState from '../../hooks/useMountAwareState';
export default function ReLogin(props) {
    
    const auth = useContext(AuthContext);

    const [username, setUsername] = useMountAwareState(null);
    const [password, setPassword] = useMountAwareState('');
    const [attempting, setAttempting] = useMountAwareState(false);
    const [error, setError] = useMountAwareState(null);

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    const handleSubmit = (e) => {
        e.preventDefault();
        login();
    };

    useEffect(() => {
        setUsername(localStorage.getItem('User') ? null : '');
        
        return () => {
            
        }
    }, [setUsername]);

    const login = () => {
        setAttempting(true);
        setError(null);
        if(password === '' || username === '') {
            setError('A field is missing');
            setAttempting(false);
            return;
        }
        API.getInstance().login({
            username: username ? username : localStorage.getItem('User'),
            password: password
        })
        .then(({status, data}) => {
            if(status === 201) {
                auth.setAuth(true);
                props.close();
                document.dispatchEvent(new CustomEvent('log_in'));
            }else{
                setError(tcommon('form.error.invalid_credentials'));
            }
            setAttempting(false);
        })
        .catch((error) => {
            setError(tcommon('error.general.default'));
            setAttempting(false);
        });
    };
    

    return (
        <div className='ReLogin'>
            <form onSubmit={handleSubmit}>
                <img className='logo' src={logo} alt=""/>
                <div className='text'>
                    <h1>{t('relogin.title')}</h1>
                    <p>{t('relogin.please_log_back_in')}</p>
                    {error ? <div className='error'>{error}</div> : undefined}
                </div>
                <div className='inputs'>
                    {username !== null ?
                        <input type="text" name="email" value={username} onChange={(e) => setUsername(e.target.value)} placeholder={`${tcommon('form.email')}...`} autoComplete="email" autoFocus={true} />
                    : undefined }
                    <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder={`${tcommon('form.password')}...`} autoComplete="password" autoFocus={username === null} />
                    <button onClick={login} disabled={attempting}>
                        {attempting ? <>{tcommon('form.logging_in')}<Dot3 /></> : tcommon('form.log_in')}
                    </button>
                </div>
            </form>
        </div>
    );
}