import React, {  useEffect, useMemo, useContext } from 'react';
import './ClassScoreInfo.scss';
import API from '../../../API/API';
import { useTranslation } from 'react-i18next';
import Loading from '../../Loading';
import ClassesResultsGraph from '../ClassesResultsGraph';
import useCookieConsent from '../../../hooks/useCookieConsent';
import score_icon from '../../../images/icons/score.svg';
import getPeriodStart from '../../../helpers/DateAgoHelper';
import ScoreDisplay from '../ScoreDisplay';
import HideStudentResultsContext from '../../../Contexts/HideStudentResultsContext';
import useMountAwareState from '../../../hooks/useMountAwareState';
export default function ClassScoreInfo(props) {

    const { functionality } = useCookieConsent();

    const { hideStudentResults } = useContext(HideStudentResultsContext);
    const [shares, setShares] = useMountAwareState(null);
    const [average, setAverage] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);
    const [statsPeriod, setStatsPeriod] = useMountAwareState(localStorage.getItem('see-results-period') ?? '30-days');
    const [ tcommon ] = useTranslation('common');
    const [ t ] = useTranslation('main');

    const fromDate = useMemo(() => getPeriodStart(statsPeriod), [statsPeriod]);

    useEffect(() => {
        setShares(null);
        setAverage(null);
        setError(null);

        API.getInstance()
        .get(`/users/self/classes/${props.classPid}/shares?${fromDate  ? `from=${Math.round(fromDate.getTime() / 1000)}&` : ''}order=asc`)
        .then(({status, data}) => {
            if(status === 200) {
                let total = 0;
                let div = 0;
                data.forEach(s => {
                    if(s.average_score < 0) return;
                    total += s.average_score;
                    div++;
                });
                if(div > 0) setAverage(Math.round(total / div));
                setShares(data);
            }else if(status === 404) {
                setError(tcommon('error.page.not_found'));
            }else{
                setError(tcommon('error.fetch.default', {code: status}));
            }
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
        });
    }, [props.classPid, tcommon, fromDate, setShares, setAverage, setError]);

    const changePeriod = (e) => {
        if(functionality) localStorage.setItem('see-results-period', e.target.value);
        setStatsPeriod(e.target.value);
    };
    
    return (
        <div className='ClassScoreInfo'>
            <h2>
                <span>
                    <img src={score_icon} alt="" />
                    {t('pages.classes.class_details.score.title')}:
                </span>
                <select onChange={changePeriod} value={statsPeriod}>
                    <option value="7-days">{tcommon('duration.day', {count: 7})}</option>
                    <option value="30-days">{tcommon('duration.day', {count: 30})}</option>
                    <option value="3-months">{tcommon('duration.month', {count: 3})}</option>
                    <option value="6-months">{tcommon('duration.month', {count: 6})}</option>
                    <option value="9-months">{tcommon('duration.month', {count: 9})}</option>
                    <option value="all-time">{tcommon('duration.all_time')}</option>
                </select>
            </h2>
            <div className='info'>
                    {error ?
                        <div className='error'>{error}</div>
                    :
                        (shares === null ?
                            <Loading />
                        :
                            <>
                                <ScoreDisplay score={average} />
                                <div className='lines'>
                                    <div>
                                        <div className={`chart-container ${hideStudentResults ? 'blur' : 'normal'}`}>
                                            <ClassesResultsGraph shares={shares} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
            </div>
        </div>
    );
}