import React from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../API/API';
import StringCleaner from '../../../helpers/StringCleaner';
import useFeedback from '../../../hooks/useFeedback';
import useMountAwareState from '../../../hooks/useMountAwareState';
import Popupable from '../../Popup/Popupable';
import './AddStudent.scss';
export default function AddStudent(props) {
    const cleaner = new StringCleaner().normalizeWhitepaces().trimWhitespaces();

    const feedback = useFeedback();
    const [firstName, setFirstName] = useMountAwareState('');
    const [lastName, setLastName] = useMountAwareState('');
    const [error, setError] = useMountAwareState(null);
    const [lock, setLock] = useMountAwareState(false);
    const [ tcommon ] = useTranslation('common');
    const [ t ] = useTranslation('main');

    const invite = (e) => {
        e.preventDefault();
        setLock(true);
        setError(null);

        const fn = cleaner.clean(firstName);
        const ln = cleaner.clean(lastName);

        if(fn === '') {
            setError('A first name must be provided');
            setLock(false);
            return;
        }
        if(ln === '') {
            setError('A last name must be provided');
            setLock(false);
            return;
        }


        API.getInstance()
        .post(`/users/self/classes/${props.classPid}/students`, {
            first_name: fn,
            last_name: ln,
            email: null
        })
        .then(({status, data}) => {
            if(status === 201) {
                feedback.add('Invitation sent');
                document.dispatchEvent(new CustomEvent('student-update'));
                document.dispatchEvent(new CustomEvent('class-update'));
                props.close();
            }else{
                setError(tcommon('error.fetch.default', {code: status}));
                setLock(false);
            }
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
            setLock(false);
        });
    };

    return (
        <Popupable title={t('pages.classes.dialogs.add_student.title')} close={props.close}>
            <form className='InviteStudent' onSubmit={invite}>
                <label>
                    <span>{tcommon('form.first_name')}:</span>
                    <input type="text" value={firstName} onChange={e => setFirstName(e.target.value)} required={true} disabled={lock} />
                </label>
                <label>
                    <span>{tcommon('form.last_name')}:</span>
                    <input type="text" value={lastName} onChange={e => setLastName(e.target.value)} required={true} disabled={lock} />
                </label>
                {error ?
                    <div>{error}</div>
                : undefined}
                <input type="submit" value={tcommon('general.add')} disabled={lock} />
            </form>
        </Popupable>
    );
}