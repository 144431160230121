import { useContext,  useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AuthContext from '../Contexts/AuthContext';
import UserContext from '../Contexts/UserContext';
export default function useAuthDisplay(props) {

    const auth = useContext(AuthContext);
    const { user } = useContext(UserContext);
    const [authDisplay, setAuthDisplay] = useState((auth.auth && user !== null && user.type !== 0) || document.location.pathname !== '/');
    const location = useLocation();

    useEffect(() => {
        setAuthDisplay(document.location.pathname !== '/' || (auth.auth && user !== null && user.type !== 0));
    }, [location, auth.auth, user]);
  
    return authDisplay;
}