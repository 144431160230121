import React, { useState } from 'react';
import CrosswordsCell from './CrosswordsCell';
import './CrosswordsGrid.scss';
export default function CrosswordsGrid(props) {

    const [t, setT] = useState("");
    const [direction, setDirection] = useState(null);
    const [lockDirection, setLockDirection] = useState(false);

    console.log(props.verification);
    
    return (
        <table className='CrosswordsGrid'>
            {/* <thead>
                <tr>
                    <td colSpan={4}>{direction ?? "null"}</td>
                    <td></td>
                    <td  colSpan={4}>{lockDirection ? "locked" : "unlocked"}</td>
                </tr>
            </thead> */}
            <tbody>
                {props.grid.map((col, y) => {
                    return <tr key={y}>
                        {col.map((cell, x) => {
                            const neighbors = {
                                top: (props.grid?.[y-1]?.[x]?.letter ?? null) !== null,
                                bottom: (props.grid?.[y+1]?.[x]?.letter ?? null) !== null,
                                left: (props.grid?.[y]?.[x-1]?.letter ?? null) !== null,
                                right: (props.grid?.[y]?.[x+1]?.letter ?? null) !== null,
                            };
                            return <td key={`${x}${y}`} className={`${cell.letter ? "show" : "hide"}`}>
                                {cell.letter ?
                                    <CrosswordsCell 
                                        grid={props.grid}
                                        cell={cell} 
                                        input={t} 
                                        onChange={setT} 
                                        tabIndex={cell.tabIndex} 
                                        x={x} y={y} 
                                        direction={direction} 
                                        setDirection={setDirection} 
                                        neighbors={neighbors} 
                                        lockDirection={lockDirection}
                                        setLockDirection={setLockDirection}
                                        verification={props.verification}
                                    />
                                : undefined }
                            </td>
                        })}
                    </tr>;
                })}
            </tbody>
        </table>
    );
}