import React from 'react';
import './AssistedTextInputIndicator.scss';

import icon from '../../images/icons/assisted-input.svg';

export default function AssistedTextInputIndicator(props) {
    
    return (
        <div ref={props.indicatorRef} className='AssistedTextInputIndicator'>
            <img width={680} height={680} src={icon} alt="You are using an assisted input" />
            <div className='tooltip'>
                You are using an assisted input. Click to learn more
            </div>
        </div>
    );
}