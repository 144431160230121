import React from 'react';
import './ClassesBreadcrumb.scss';
import classes_icon from '../../images/icons/classes.svg';
import chevron_icon from '../../images/icons/chevron.svg';
import { Link } from 'react-router-dom';
import HideResultsToggle from './layout/HideResultsToggle';
import { useTranslation } from 'react-i18next';
export default function ClassesBreadcrumb(props) {

    const [ tcommon ] = useTranslation('common');
    
    return (
        <div className='ClassesBreadcrumb'>
            <div className='breadcrumb'>
                <Link to="/classes" className='back-to-list'>
                    <img src={classes_icon} width={335} height={324} alt="Back to classes" />
                    <div>{tcommon('general.classes')}</div>
                </Link>
                {props.links.map((l,i,a) => {
                    return <React.Fragment key={i}>
                        <img className='chevron' src={chevron_icon} alt=""/>
                        {i === a.length-1 ?
                            <span className='breadcrumb-element' title={l.label}>{l.label}</span>
                        :
                            <Link to={l.to}>
                                <span className='breadcrumb-element' title={l.label}>{l.label}</span>
                            </Link>
                        }
                    </React.Fragment>;
                })}
            </div>
            <div className='buttons'>
                <HideResultsToggle />
            </div>
        </div>
    );
}