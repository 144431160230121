import { v4 as uuidv4 } from 'uuid';

function useFeedback() {
    const add = (feedback) => {
        document.dispatchEvent(new CustomEvent('feedback', { detail: {id: uuidv4(), text: feedback} }));
    }
    return {
        add: add
    };
}

export default useFeedback;