import React, { useContext, useEffect, useState } from "react";
import "./BookletShortcutsMenu.scss";
import UserContext from "../../../../Contexts/UserContext";
import { Link } from "react-router-dom";

const baseUrl = window.location.hostname.includes("localhost")
  ? "https://localhost:8000"
  : "https://waeelearning.com";

export default function BookletShortcutsMenu(props) {
  const { user } = useContext(UserContext);
  const [bookletData, setBookletData] = useState({
    elementary: [],
    advanced: [],
    others: [],
  });

  useEffect(() => {
    if (!props.booklets) return;
    setBookletData({
      elementary: props.booklets.filter((b) =>
        b.name.toLowerCase().includes("elementary")
      ),
      advanced: props.booklets.filter((b) =>
        b.name.toLowerCase().includes("advanced")
      ),
      others: props.booklets.filter(
        (b) =>
          !b.name.toLowerCase().includes("elementary") &&
          !b.name.toLowerCase().includes("advanced")
      ),
    });
  }, [props.booklets]);

  return (
    <div className="BookletShortcutsMenu">
      <h2>Booklet shortcuts</h2>
      <div className="booklets">
        <div
          className={`group ${
            user.type > 1 ||
            user.subscriptions.includes("resources-subscription-elementary")
              ? "access"
              : "locked"
          }`}
        >
          {bookletData.elementary.map((b) => (
            <button
              onClick={() => {
                props.selectBooklet(b.id);
              }}
              className="booklet"
              key={b.id}
            >
              <img
                width={840}
                height={1080}
                src={`${baseUrl}${b.path}`}
                alt=""
              />
              <h3>{b.name}</h3>
            </button>
          ))}

          {user.type <= 1 &&
          !user.subscriptions.includes("resources-subscription-elementary") ? (
            <div className="locked">
              <Link
                className="subscribe"
                to="/subscribe?subscriptions=elementary"
              >
                Get access to the Elementary resources
              </Link>
            </div>
          ) : undefined}
        </div>
        <div
          className={`group ${
            user.type > 1 ||
            user.subscriptions.includes("resources-subscription-advanced")
              ? "access"
              : "locked"
          }`}
        >
          {bookletData.advanced.map((b) => (
            <button
              onClick={() => {
                props.selectBooklet(b.id);
              }}
              className="booklet"
              key={b.id}
            >
              <img
                width={840}
                height={1080}
                src={`${baseUrl}${b.path}`}
                alt=""
              />
              <h3>{b.name}</h3>
            </button>
          ))}
          {user.type <= 1 &&
          !user.subscriptions.includes("resources-subscription-advanced") ? (
            <div className="locked">
              <Link
                className="subscribe"
                to="/subscribe?subscriptions=advanced"
              >
                Get access to the Advanced resources
              </Link>
            </div>
          ) : undefined}
        </div>
        <div className="group access">
          {bookletData.others.map((b) => (
            <button
              onClick={() => {
                props.selectBooklet(b.id);
              }}
              className="booklet"
            >
              <img
                width={840}
                height={1080}
                src={`${baseUrl}${b.path}`}
                alt=""
              />
              <h3>{b.name}</h3>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
