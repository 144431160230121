import React from 'react';
import './SelectClassDialog.scss';
import Loading from '../Loading';
import StringCleaner from '../../helpers/StringCleaner';
import Popupable from '../Popup/Popupable';
import { useTranslation } from 'react-i18next';
import useMountAwareState from '../../hooks/useMountAwareState';
export default function SelectClassDialog(props) {

    const classes = props.classes;
    const error = props.error;
    const [search, setSearch] = useMountAwareState('');

    const [ tcommon ] = useTranslation('common');
    const [ t ] = useTranslation('main');

    const getFilteredList = (list, query) => {
        if(list === null) return null;
        if(query === '') return list;

        query = (new StringCleaner())
                .removeAccents()
                .removeWhiteSpaces()
                .clean(query.toLowerCase())
        ;

        return list.filter((c) => {
            return StringCleaner.removeAccents(c.name.toLowerCase()).includes(query);
        });
    };

    const list = getFilteredList(classes, search);
    
    return (
        <Popupable title="Select a class" close={props.close}>
            <div className='SelectClassDialog'>
                <div className='controls'>
                    <input type="search" value={search} onChange={e => setSearch(e.target.value)} placeholder="Filter..." />
                    <button onClick={props.refreshClasses}>{tcommon('general.refresh')}</button>
                </div>
                <div className='classes'>
                    {error ?
                        <div>{error}</div>
                    :
                        (classes === null ? 
                            <Loading />
                        : 
                            (list === null || list.length === 0 ? 
                                <div>{search === '' ? t('layout.share.select_class.empty') : t('layout.share.select_class.nothing_found')}</div>
                            :
                                <ul>
                                    {list.map((c, i) => {
                                        return <li key={i}>
                                            <button onClick={() => {props.setClass(c);props.close();}}>
                                                {c.name} ({c.students_count})
                                            </button>
                                        </li>;
                                    })}
                                </ul>
                            )
                        )
                    }
                </div>
            </div>
        </Popupable>
    );
}