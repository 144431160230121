import React, { useContext, useRef } from 'react';
import API from '../../../API/API';
import ContextMenuContext from '../../../Contexts/ContextMenuContext';
import useFeedback from '../../../hooks/useFeedback';
import ContextMenu from '../../ContextMenu';
import Countdown from '../../Countdown';
import Popup from '../../Popup/Popup';
import Dot3 from '../../utils/Dot3';
import './StudentLine.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ScoreDisplay from '../ScoreDisplay';
import DeleteStudent from '../DeleteStudent';
import HideStudentResultsContext from '../../../Contexts/HideStudentResultsContext';
import hide_icon from '../../../images/icons/hide.svg';
import useMountAwareState from '../../../hooks/useMountAwareState';
export default function StudentLine(props) {

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');
    const { hideStudentResults } = useContext(HideStudentResultsContext);

    const resendDelay = 1000 * 60 * 15;

    const student = props.student;
    const setPopup = props.setPopup;
    const fetchStudents = props.fetchStudents;

    const navigate = useNavigate();

    const resendButtonRef = useRef(null);
    const dotDotDotButtonRef = useRef(null);

    const feedback = useFeedback();
    const { setContextMenu } = useContext(ContextMenuContext);
    const [lockResend, setLockResend] = useMountAwareState(false);

    const canResend = (!lockResend) && (!student.is_confirmed) && (student.last_invitation_success === null || (student.last_invitation_success !== null && (student.last_invitation_success * 1000 < ((new Date()).getTime() - resendDelay))));

    // const canResend = useMemo(() => {
    //     return (!lockResend) && (!student.is_confirmed) && (student.last_invitation_success === null || (student.last_invitation_success !== null && (student.last_invitation_success * 1000 < ((new Date()).getTime() - resendDelay))))
    // }, [lockResend, resendDelay, student.is_confirmed, student.last_invitation_success]);

    const sendStudentInvitation = (student) => {
        setLockResend(true);
        API.getInstance()
        .get(`/users/self/classes/${props.classPid}/students/${student.pid}/invitation-link?send`)
        .then(({status, data}) => {
            if(status === 200) {
                feedback.add(tcommon('feedback.invitation_sent'));
                fetchStudents();
            }else{
                feedback.add(tcommon('feedback.could_not_send_invitation'));
            }
            setLockResend(false);
        })
        .catch((error) => {
            feedback.add(tcommon('feedback.could_not_send_invitation'));
            setLockResend(false);
        });
    };
      
    const getContextMenu = (position, student) => {
        return <ContextMenu position={position}>
            {/* <button>{tcommon('context_menu.details')}</button> */}
            {!student.is_confirmed ? <button onClick={() => {sendStudentInvitation(student);setContextMenu(null);}} disabled={!canResend}>{tcommon('context_menu.resend_invitation')}</button> : undefined}
            {!student.is_confirmed ? <button onClick={() => {openInvitationLinkPopup(student);setContextMenu(null);}}>{tcommon('context_menu.create_invitation_link')}</button> : undefined}
            <button className='red' onClick={() => {openRemoveStudentPopup(student)}}>{tcommon('context_menu.remove')}</button>
        </ContextMenu>;
    };

    const handleContextMenu = (e, student) => {
        e.preventDefault();
        const position = {
            x: e.pageX,
            y: e.pageY
        };
        setContextMenu(
            getContextMenu(position, student)
        );
    };
    
    const openRemoveStudentPopup = (student) => {
        setContextMenu(null);
        setPopup(
            <DeleteStudent classPid={props.classPid} student={student} close={() => setPopup(null)} />
        );
    };

    const openInvitationLinkPopup = (student) => {
        feedback.add(`${tcommon('feedback.creating_invitation_link')}...`);
        API.getInstance()
        .get(`/users/self/classes/${props.classPid}/students/${student.pid}/invitation-link`)
        .then(({status, data}) => {
            if(status === 200 || status === 204) {
                setPopup(
                    <Popup 
                        title={t('pages.account.sections.institution.institutional.class_details.tabs.students.invitation_link_popup.title')}
                        text={
                            <div className='row'>
                                <input type="text" value={data} readOnly/>
                                {navigator.clipboard ? <button onClick={() => {navigator.clipboard.writeText(data);feedback.add(tcommon('feedback.link_copied'))}}>{tcommon('general.copy')}</button> : undefined}
                            </div>
                        }
                        close={() => {setPopup(null);}}
                        cancel={undefined}
                        confirm={undefined}
                        buttons={
                            <button 
                                className='confirm'
                                onClick={() => {
                                    setPopup(null);
                                }}
                            >{tcommon('general.close')}</button>
                        }
                    />
                );
            }else{
                feedback.add(tcommon('feedback.link_creation_failure', {code: status}));
            }
        })
        .catch((error) => {
            feedback.add(tcommon('feedback.link_creation_failure', {code: error.code ?? ''}));
        });
    };

    const hanldeClick = (e) => {
        if(resendButtonRef.current && resendButtonRef.current.contains(e.target)) return;
        if(dotDotDotButtonRef.current &&dotDotDotButtonRef.current.contains(e.target)) return;
    
        navigate(`/classes/${props.classPid}/students/${student.pid}`);
    };

    return <tr className='StudentLine' key={student.pid} onContextMenu={(e) => {handleContextMenu(e, student)}} onClick={hanldeClick}>
        <td className='student'>
            <div className='identity'>
                {student.first_name} {student.last_name}
            </div>
            <div className='status'>
                {student.is_confirmed ?
                    tcommon('general.joined')
                :
                    <>
                        {t('pages.account.sections.institution.institutional.class_details.tabs.students.pending')}... 
                        <button ref={resendButtonRef} disabled={!canResend || lockResend} onClick={() => {sendStudentInvitation(student);}}>
                            {t('pages.account.sections.institution.institutional.class_details.tabs.students.resend')}
                            {!canResend ? 
                                <>&nbsp;({lockResend ? <Dot3 /> : <Countdown until={(student.last_invitation_success * 1000) + resendDelay} />})</> 
                            : undefined}
                        </button>
                    </>
                }
            </div>
        </td>
        <td className='email'>{student.email}</td>
        <td className='score'>
            <div>
                <ScoreDisplay score={student.average_score} noText={true} />
                {hideStudentResults ?
                    <>
                        <img src={hide_icon} alt="hidden" />
                        %
                    </>
                :
                    (student.average_score >= 0 ?
                        <span>{Math.round(student.average_score)}%</span>
                    :
                        undefined
                    )
                }
            </div>
        </td>
        <td className='options'>
            <button ref={dotDotDotButtonRef} className='dotdotdot' onClick={(e) => {handleContextMenu(e, student)}}>
                <div>
                    &#9679;&#9679;&#9679;
                </div>
            </button>
        </td>
    </tr>;
}