import React from 'react';
import './Explorer.scss';
import { Outlet } from 'react-router-dom';

export default class Explorer extends React.Component {
    render(){
        return <div className='Explorer'>
            <Outlet />
        </div>;
    }
}