import React, { createRef, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import AuthContext from '../../Contexts/AuthContext';
import API from '../../API/API';
import ReCAPTCHA from "react-google-recaptcha";
import './Register.scss';
import Dot3 from '../utils/Dot3';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import logo from '../../images/logo.svg';
import useMountAwareState from '../../hooks/useMountAwareState';
export default function Register(props) {

    useDocumentTitle('Register');

    const authContext = useContext(AuthContext);

    const [firstName, setFirstName] = useMountAwareState('');
    const [lastName, setLastName] = useMountAwareState('');
    const [email, setEmail] = useMountAwareState('');
    const [password, setPassword] = useMountAwareState('');
    const [confirmPassword, setConfirmPassword] = useMountAwareState('');
    const [acceptTos, setAcceptTos] = useMountAwareState(false);
    const [captcha, setCaptcha] = useMountAwareState(null);

    const [error, setError] = useMountAwareState(null);

    const [lock, setLock] = useMountAwareState(false);
    const [confirmation, setConfirmation] = useMountAwareState(false);

    const [ t ] = useTranslation('main');
    const [tcommon, i18ncommon] = useTranslation('common');

    const recaptchaRef = createRef();

    if(authContext.auth) return <Navigate to="/" replace />;

    const passwordRules = {
        minimumLength: (pw) => {
            return pw.length >= 8;
        },
        upperCase: (pw) => {
            return /[A-Z]/.test(pw);
        },
        lowerCase: (pw) => {
            return /[a-z]/.test(pw)
        },
        numeric: (pw) => {
            return /[0-9]/.test(pw);
        }
    };

    const evalPassword = (pw) => {
        let list = [];
        if(!passwordRules.minimumLength(pw)) list.push(
            tcommon('form.rules.chars_min', {min: 8})
        );
        if(!passwordRules.upperCase(pw)) list.push(
            tcommon('form.rules.upper_min', {min: 1})
            
        );
        if(!passwordRules.lowerCase(pw)) list.push(
            tcommon('form.rules.lower_min', {min: 1})
        );
        if(!passwordRules.numeric(pw)) list.push(
            tcommon('form.rules.numeric_min', {min: 1})
        );
        return list;
    };

    const stateMap = {
        firstName: setFirstName,
        lastName: setLastName,
        email: setEmail,
        password: setPassword,
        confirmPassword: setConfirmPassword,
        acceptTos: setAcceptTos
    };

    const setStateWithKey = (key, value) => {
        stateMap[key](value);
    };
    
    const handleChange = (e, key) => {
        if(e.target.type === 'checkbox') {
            setStateWithKey(key, e.target.checked);
        }else{
            setStateWithKey(key, e.target.value);
        }
    };

    const captchaChange = (value) => {
        setCaptcha(value);
    }; 

    const handleSubmit = (e) => {
        e.preventDefault();
        setLock(true);
        if( firstName === '' ||
            lastName === '' ||
            email === '' ||
            password === '' ||
            confirmPassword === '' ||
            acceptTos !== true ||
            captcha === null
        ){
            setError('form.error.incomplete');
            setLock(false);
            return;
        }
        if(password !== confirmPassword) {
            setError('form.error.password_match');
            setLock(false);
            return;
        }

        for (const [, rule] of Object.entries(passwordRules)) {
            if(!rule(password)) {
                setError('form.error.password_rules');
                setLock(false);
                return;
            }
        }
        API.getInstance().post('/register', {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            acceptTos: acceptTos,
            'g-recaptcha-response': captcha
        }, false)
        .then(({status, data}) => {
            if(status === 201){
                if(props.onSuccess === undefined) {
                    setConfirmation(true);
                }else{
                    props.onSuccess();
                }
            }else{
                if(data.error === 'passwordRules') {
                    setError(`${data.message} (${data.list.toString().replace(/,/gm, ', ')})`);
                }else{
                    setError(data.message);
                }
                setLock(false);
                recaptchaRef.current.reset();
            }
        })
        .catch((error) => {
            setLock(false);
        });
    };

    const passwordRulesNotMet = evalPassword(password);

    return (
        <div className='content register'>
            <img width={128} height={128} src={logo} alt="" />
            <div className='display'>
                <form className='Register' onSubmit={handleSubmit}>
                    {confirmation ?
                        <div className='confirmation'>
                            <h1>{t('pages.sign_up.confirmation.title')}</h1>
                            <h2>{t('pages.sign_up.confirmation.created')}</h2>
                            <div>
                                {t('pages.sign_up.confirmation.email')}
                            </div>
                            <div>
                                <Trans t={t} i18nKey="pages.sign_up.confirmation.login">
                                    You can now <Link to="/login">log in here</Link>
                                </Trans>
                            </div>
                        </div>
                    : ''}
                    <h1>{t('pages.sign_up.welcome')}</h1>
                    <label>
                        <input type="text" 
                                name="fname" 
                                placeholder={`${tcommon('form.first_name')}...`}
                                onChange={e => handleChange(e, 'firstName')} 
                                value={firstName} 
                                required
                                disabled={lock}
                                autoComplete="given-name"
                        />
                    </label>
                    <label>
                        <input type="text" 
                                name="lname" 
                                placeholder={`${tcommon('form.last_name')}...`}
                                onChange={e => handleChange(e, 'lastName')} 
                                value={lastName} 
                                required
                                disabled={lock}
                                autoComplete="family-name"
                        />
                    </label>
                    <label>
                        <input type="email" 
                                name="email" 
                                placeholder={`${tcommon('form.email')}...`}
                                onChange={e => handleChange(e, 'email')} 
                                value={email} 
                                required
                                disabled={lock}
                                autoComplete="email"
                        />
                    </label>
                    <label>
                        <input type="password" 
                                name="password" 
                                placeholder={`${tcommon('form.password')}...`} 
                                onChange={e => handleChange(e, 'password')} 
                                value={password} 
                                required
                                disabled={lock}
                                autoComplete="new-password"
                        />
                    </label>
                    {password !== '' && passwordRulesNotMet.length > 0 ? 
                        <div className='passwordFeedback'>
                            {tcommon('form.rules.password_must_meet')}: 
                            <ul>
                                {passwordRulesNotMet.map((r, i) => {
                                    return <li key={i}>{r}</li>;
                                })}
                            </ul>
                        </div>
                    :
                        undefined
                    }
                    
                    <label>
                        <input type="password" 
                                name="confirmPassword" 
                                placeholder={`${tcommon('form.confirm_password')}...`}
                                onChange={e => handleChange(e, 'confirmPassword')} 
                                value={confirmPassword} 
                                required
                                disabled={lock}
                                autoComplete="new-password"
                        />
                    </label>
                    <div className='passwordFeedback'>
                        {confirmPassword !== '' && password !== confirmPassword ?
                            <span>{tcommon('form.error.passwords_not_match')}</span>
                        : undefined}
                    </div>
                    <label className='row'>
                        <input type="checkbox"
                                name="acceptTos"
                                onChange={e => handleChange(e, 'acceptTos')}
                                checked={acceptTos}
                                required
                                disabled={lock}
                        />
                        <p>
                            <Trans t={tcommon} i18nKey="form.user_agreement">
                                By ticking this box you agree to 
                                our <a target="_blank" rel='noreferrer' href="https://waeelearning.com/terms-of-service">Terms of Service</a> 
                                and <a target="_blank" rel='noreferrer' href="https://waeelearning.com/privacy-policy">Privacy Policy</a>
                            </Trans>
                            
                        </p>
                    </label>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY} //6LfaYpQbAAAAANZiNNRxXnUx2sTeL-EIKTYqEVtf
                        name="captcha"
                        hl={i18ncommon.language}
                        onChange={captchaChange}
                    />
                    {error ?
                        <div className='error'>
                            <div className='bubble error'>
                                <div><span>error</span></div>
                                <div>{tcommon(error)}</div>
                            </div>
                        </div>
                    : ''}
                    <button disabled={lock} className='btn-yellow'>{lock ? <>{tcommon('form.registering')}<Dot3 /></> : tcommon('form.register')}</button>
                    <div className='no-account'>
                        {tcommon('form.already_have_account')} 
                        {props.changeMode === undefined ? 
                            <Link to="/login">{tcommon('form.sign_in_here')}</Link>
                        :
                            <button onClick={props.changeMode}>{tcommon('form.sign_in_here')}</button>
                        }
                    </div>
                    <div className="legals">
                        <a target="_blank" rel='noreferrer' href="https://waeelearning.com/terms-of-service">{tcommon('form.tos')}</a>
                        <span className='separator'></span>
                        <a target="_blank" rel='noreferrer' href="https://waeelearning.com/privacy-policy">{tcommon('form.privacy_policy')}</a>
                    </div>
                </form>
            </div>
        </div>
    );
}