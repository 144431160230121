import React from 'react';
import { useTranslation } from 'react-i18next';
import './Activities.scss';

import ResourceIcon from '../ResourceIcon';
import fade_left from '../../images/waves/fade-left.svg';
import separator_top from '../../images/waves/separator-top1.svg';
import separator_bottom from '../../images/waves/separator-bottom1.svg';
import missing_words from '../../images/gifs/missing_words_demo.gif';
import hidden_words from '../../images/presentation/hiddenwords.webp';
import cross_words from '../../images/presentation/crosswords.webp';
import quizzes from '../../images/presentation/quizzes.gif';
import ai_icon from '../../images/icons/assisted-input.svg';
import { Link } from 'react-router-dom';
import Popupable from '../Popup/Popupable';
import HelpAssistedTextInput from '../help/HelpAssistedTextInput';
import useMountAwareState from '../../hooks/useMountAwareState';
export default function Activities(props) {

    const [tab, setTab] = useMountAwareState('missing-words');
    const [openAssistedInputHelp, setOpenAssistedInputHelp] = useMountAwareState(false);

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');
    
    return (
        <section className='Activities'>
            <img className='background' width={900} height={600} src={fade_left} alt="" />
            <img className='separator top' width={1440} height={288} src={separator_top} alt="" />
            <div className='content'>
                <div className='box'>
                    <h2>{t('pages.presentation.activities.title')}</h2>
                    <div className='explore'>
                        <div className='tabs'>
                            <button className={`tab ${tab === 'missing-words' ? 'active' : ''}`} onClick={() => setTab('missing-words')}><ResourceIcon type="missing-words" noAlt={true} />Missing words</button>
                            <button className={`tab ${tab === 'quizzes' ? 'active' : ''}`} onClick={() => setTab('quizzes')}><ResourceIcon type="quiz" noAlt={true} />Quizzes</button>
                            <button className={`tab ${tab === 'hidden-words' ? 'active' : ''}`} onClick={() => setTab('hidden-words')}><ResourceIcon type="hidden-words" noAlt={true} />Hidden words</button>
                            <button className={`tab ${tab === 'crosswords' ? 'active' : ''}`} onClick={() => setTab('crosswords')}><ResourceIcon type="crosswords" noAlt={true} />Crosswords</button>
                        </div>
                        <div className={`display${tab !== 'missing-words' ? ' round-top-left' : ''}`}>
                            <div className={`missing-words${tab === 'missing-words' ? ' active' : ''}`}>
                                <div className='image'>
                                    <img width={835} height={506} src={missing_words} alt="" />
                                    <Link className='try-it' to="/preview/missing-words"><span>{t('pages.presentation.try_it')}</span></Link>
                                </div>
                                <h3><ResourceIcon type="missing-words" noAlt={true} />{t('pages.presentation.activities.list.missing_words.title')}</h3>
                                <div className='educational-interest'>
                                    {t('pages.presentation.activities.list.missing_words.educational_interest')}
                                </div>
                                <div className='try-it'>
                                    <Link to="/preview/missing-words" className='btn-yellow'>{t('pages.presentation.try_it')}</Link>
                                </div>
                            </div>
                            <div className={`quizzes${tab === 'quizzes' ? ' active' : ''}`}>
                                <div className='image'>
                                    <img width={600} height={339} src={quizzes} alt="" />
                                    <Link className='try-it' to="/preview/quizzes"><span>{t('pages.presentation.try_it')}</span></Link>
                                </div>
                                <h3><ResourceIcon type="quiz" noAlt={true} />{t('pages.presentation.activities.list.quizzes.title')}</h3>
                                <div className='educational-interest'>
                                    {t('pages.presentation.activities.list.quizzes.educational_interest')}
                                </div>
                                <div className='try-it'>
                                    <Link to="/preview/quizzes" className='btn-yellow'>{t('pages.presentation.try_it')}</Link>
                                </div>
                            </div>
                            <div className={`hidden-words${tab === 'hidden-words' ? ' active' : ''}`}>
                                <div className='image'>
                                    <img width={953} height={759} src={hidden_words} alt="" />
                                    {/* <Link className='try-it' to="/preview/hidden-words"><span>{t('pages.presentation.try_it')}</span></Link> */}
                                </div>
                                <h3><ResourceIcon type="hidden-words" noAlt={true} />{t('pages.presentation.activities.list.hidden_words.title')}<span className='coming-soon'>{tcommon('general.coming_soon')}</span></h3>
                                <div className='educational-interest'>
                                    {t('pages.presentation.activities.list.hidden_words.educational_interest')}
                                </div>
                                <div className='try-it disabled'>
                                    <Link to="/preview/hidden-words" className='btn-yellow'>{tcommon('general.coming_soon')}</Link>
                                </div>
                            </div>
                            <div className={`crosswords${tab === 'crosswords' ? ' active' : ''}`}>
                                <div className='image'>
                                    <img width={829} height={645} src={cross_words} alt="" />
                                    {/* <Link className='try-it' to="/preview/crosswords"><span>{t('pages.presentation.try_it')}</span></Link> */}
                                </div>
                                <h3><ResourceIcon type="crosswords" noAlt={true} />{t('pages.presentation.activities.list.crosswords.title')}<span className='coming-soon'>{tcommon('general.coming_soon')}</span></h3>
                                <div className='educational-interest'>
                                    {t('pages.presentation.activities.list.crosswords.educational_interest')}
                                </div>
                                <div className='try-it disabled'>
                                    <Link to="/preview/crosswords" className='btn-yellow'>{tcommon('general.coming_soon')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='assisted-input'>
                        <img width={680} height={680} src={ai_icon} alt="" />
                        <div className='text'>
                            <span>{t('pages.presentation.activities.assisted_input.text')}</span>
                            <button onClick={() => setOpenAssistedInputHelp(true)} className='btn-yellow'>{t('pages.presentation.activities.assisted_input.learn_more')}</button>
                        </div>
                        {openAssistedInputHelp ? 
                            <Popupable title="Assisted input help" close={() => setOpenAssistedInputHelp(false)}>
                                <HelpAssistedTextInput title={false} input={true} />
                            </Popupable>
                        : undefined}
                    </div>
                </div>
            </div>
            <img className='separator bottom' width={1440} height={320} src={separator_bottom} alt="" />
        </section>
    );
}

// <div className='carousel'>
//     <div className='top'>
//         <div className='button'>
//             <button  onClick={() => {setTab(tab === 0 ? tabCount-1 : tab-1);}}>
//                 &lt;
//             </button>
//         </div>
//         <ul className='display'>
//             <li style={{transform: `translateX(-${100 * tab}%)`}}>
//                 <div className='image'>
//                     <img src={missing_words} alt="" />
//                 </div>
//                 <h3><ResourceIcon type="missing-words" noAlt={true} />{t('pages.presentation.activities.list.missing_words.title')}</h3>
//                 <div className='try-it'>
//                     <Link to="/preview/missing-words">Try it!</Link>
//                 </div>
//             </li>
//             <li style={{transform: `translateX(-${100 * tab}%)`}}>
//                 <div className='image'>
//                     <img src={hidden_words} alt="" />
//                     <div className="coming-soon">{tcommon('general.coming_soon')}</div>
//                 </div>
//                 <h3><ResourceIcon type="hidden-words" noAlt={true} />{t('pages.presentation.activities.list.hidden_words.title')}<br/><span className='coming-soon'>{tcommon('general.coming_soon')}</span></h3>
//                 {/* <div className='try-it'>
//                     <Link to="/preview/hidden-words">Try it!</Link>
//                 </div> */}
//             </li>
//             <li style={{transform: `translateX(-${100 * tab}%)`}}>
//                 <div className='image'>
//                     <img src={cross_words} alt="" />
//                     <div>{tcommon('general.coming_soon')}</div>
//                 </div>
//                 <h3><ResourceIcon type="crosswords" noAlt={true} />{t('pages.presentation.activities.list.crosswords.title')}<br/><span className='coming-soon'>{tcommon('general.coming_soon')}</span></h3>
//                 {/* <div className='try-it'>
//                     <Link to="/preview/crosswords">Try it!</Link>
//                 </div> */}
//             </li>
//         </ul>
//         <div className='button'>
//             <button onClick={() => {setTab(tab === tabCount-1 ? 0 : tab+1);}}>&gt;</button>
//         </div>
//     </div>
//     <div className='controls'>
//         <button disabled={tab === 0} onClick={() => setTab(0)}>&#8226;</button>
//         <button disabled={tab === 1} onClick={() => setTab(1)}>&#8226;</button>
//         <button disabled={tab === 2} onClick={() => setTab(2)}>&#8226;</button>
//     </div>
// </div>