import React from 'react';
import './SearchBox.scss';
import API from '../../API/API';
import { useRef, useEffect,  useCallback } from "react";
import FileLink from '../FileLink';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Searcher from '../../helpers/Searcher';
import Loading from '../Loading';
import useMountAwareState from '../../hooks/useMountAwareState';

export default function SearchBox(props) {
    let navigate = useNavigate();
    const [search, setSearch] = useMountAwareState('');
    const [results, setResults] = useMountAwareState([]);
    const [focus, setFocus] = useMountAwareState(false);
    const [resources, setResources] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);
    const [typing, setTyping] = useMountAwareState(false);

    const wrapperRef = useRef(null);
    const inputRef = useRef(null);
    const timeoutId = useRef(null);

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    const query = (value) => {
        if(value === '') return [];

        const searcher = new Searcher(value);
        return searcher.queryIn(resources);
    };

    const close = useCallback(() => {
        setSearch('');
        setFocus(false);
        setResults([]);
    }, [setFocus, setResults, setSearch]);

    const handleClick = e => {
        close();
    };

    const handleChange = (event) => {
        setSearch(event.target.value);

        setTyping(true);
        if(timeoutId.current) {
            clearTimeout(timeoutId.current);
        }
        timeoutId.current = setTimeout(() => {
            setResults(query(event.target.value));
            setTyping(false);
        }, 200);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(search === '') return;
        navigate("/explorer/search?s=" + encodeURIComponent(search));
        inputRef.current.blur();
        close();
    };

    const handleFocus = (e) => {
        setFocus(true);
    };

    const handleOutsideInteraction = useCallback(
        (e) => {
            const contextMenu = document.querySelector(".ContextMenu");
            if (wrapperRef && !wrapperRef.current.contains(e.target)) {
                if(contextMenu !== null && contextMenu.contains(e.target) && e.target.tagName !== "A") return;
                close();
            }
        },
        [close],
    );

    const handleBlur = (e) => {
        const contextMenu = document.querySelector(".ContextMenu");
        if (e.relatedTarget !== null && !e.currentTarget.contains(e.relatedTarget) && !(contextMenu !== null && contextMenu.contains(e.relatedTarget) && e.target.tagName !== "A")) {//.contains('ContextMenu')
            const cm = document.querySelector('.ContextMenu');
            if(cm && cm.contains(e.relatedTarget)) return;
            close();
        }
    };

    useEffect(() => {
        API.getInstance().get('/resources')
        .then(({status, data}) => {
            if(status === 200) setResources(data.filter(val => val.rid !== 'root'));
            else setError(tcommon('error.fetch.default', {code: status}));
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
        });
    }, [setError, setResources, tcommon]);

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideInteraction);
    
      return () => {
        document.removeEventListener('mousedown', handleOutsideInteraction);
      }
    }, [handleOutsideInteraction]);

    return (
        <form ref={wrapperRef} className={`SearchBox ${focus ? 'focus' : ''}`} onSubmit={handleSubmit} onBlur={handleBlur}>
            <input type="search"
                    name="search"
                    value={search}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    placeholder={`${t('layout.header.searchbar.search')}...`}
                    autoComplete="off"
                    ref={inputRef}
            />
            {focus ?
                <div className="SearchResults">
                    {error !== null ?
                        <div>{error}</div>
                    :
                        (resources === null ?
                            <>{tcommon('info.loading')}...</>
                        :
                            results.length === 0 ?
                                (search === '' ?
                                    t('layout.header.searchbar.type_in')
                                :
                                    (typing ?
                                        <Loading />
                                    :   
                                        t('layout.header.searchbar.no_result')
                                    )
                                )
                            :
                                <>
                                    {results.slice(0, 15).map((r, i) => {
                                        return <FileLink tabIndex={i} onClick={handleClick} key={r.rid} file={r} />;
                                    })}
                                    {results.length > 15 ?
                                        <button tabIndex={results.length} onClick={handleSubmit}>{tcommon('general.see_all')} ({tcommon('general.x_more', {value: results.length - 15})})</button>
                                    : undefined}
                                </>
                        )
                    }
                </div>
            :
                <></>
            }
            
        </form>
    );
}