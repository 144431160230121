import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Flashcard from './Flashcard';
import './FlashcardsLearning.scss';

import chevron from '../../../../images/icons/chevron.svg';
import useMountAwareState from '../../../../hooks/useMountAwareState';

export default function FlashcardsLearning(props) {

    const cards = props.data.cards;
    const cardsCount = useMemo(() => cards.length, [cards.length]);

    const [ cardIndex, setCardIndex ] = useMountAwareState({
        current: 0,
        previous: null
    });
    const [audios, setAudios] = useMountAwareState(Object.assign({}, {
        loaded: false,
        error: false,
        audio: null,
    }));

    const touchStart = useRef(null);

    const previousCard = useCallback(() => {
        document.dispatchEvent(new CustomEvent('change-flashcard'));
        if(cardIndex.current - 1 < 0) {
            // if not sync, use an argument to keep track of current index
            setCardIndex(curr => {
                return {
                    current: cardsCount - 1,
                    previous: curr.current
                }
            });
        }else{
            setCardIndex(curr => {
                return {
                    current: curr.current - 1,
                    previous: curr.current
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardIndex, cardsCount]);

    const nextCard = useCallback(() => {
        document.dispatchEvent(new CustomEvent('change-flashcard'));
        if(cardIndex.current + 1 >= cardsCount) {
            setCardIndex(curr => {
                return {
                    current: 0,
                    previous: curr.current
                };
            });
        }else{
            setCardIndex(curr => {
                return {
                    current: curr.current + 1,
                    previous: curr.current
                };
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardIndex, cardsCount]);

    const handleKeyDown = useCallback(e => {
        if(e.code === 'ArrowRight') {
            nextCard();
        }else if(e.code === 'ArrowLeft') {
            previousCard();
        }
    }, [nextCard, previousCard]);

    const loadAudios = useCallback(() => {
        let loadedAudios = Array(cardsCount).fill(null);
        props.data.cards.forEach((card, i) => {
            loadedAudios[i] = {
                loaded: false,
                error: false,
                audio: null,
            };
            loadedAudios[i].audio = new Audio(card.audio ?? "");
            loadedAudios[i].audio.addEventListener('loadeddata', () => {
                loadedAudios[i].loaded = true;
            });
            loadedAudios[i].audio.addEventListener('error', () => {
                loadedAudios[i].error = true;
            });
        });
        setAudios(loadedAudios);
    }, [cardsCount, props, setAudios]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [handleKeyDown]);

    useEffect(() => {
        loadAudios();
    }, [loadAudios])

    const handleTouchStart = (e) => {
        touchStart.current = {x: e.touches[0].pageX, y: e.touches[0].pageY}
    };

    const handleTouchMove = (e) => {

    };

    const handleTouchEnd = (e) => {
        if(touchStart.current === null) return;

        const distanceX = touchStart.current.x - e.changedTouches[0].pageX;
        const distanceY = touchStart.current.y - e.changedTouches[0].pageY;

        if(Math.abs(distanceX) < 80 && Math.abs(distanceY) < 80) return;

        e.preventDefault();

        if(Math.abs(distanceX) > Math.abs(distanceY)) {
            if(distanceX > 0) nextCard();
            else previousCard();
        }else{
            if(distanceY > 0) nextCard();
            else previousCard();
        }

        touchStart.current = null;
    };

    let direction = null;
    if(cardIndex.previous !== null) {
        if(cardIndex.previous === 0 && cardIndex.current === (cardsCount - 1)) {
            direction = 'next';
        }else if(cardIndex.previous === (cardsCount - 1) && cardIndex.current === 0) {
            direction = 'previous';
        }else{
            if(cardIndex.previous < cardIndex.current) direction = 'previous';
            else direction = 'next';
        }
    }

    const buttonKey1 = useMemo(() => cardIndex.current + 2 * cardIndex.current + 'button1', [cardIndex]);
    const buttonKey2 = useMemo(() => cardIndex.previous + 3 * cardIndex.current + 'button2', [cardIndex]);
    
    if(audios)
    return <div className='FlashcardsLearning' onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
            <button key={buttonKey1} className={`previous animate-${direction}`} onClick={previousCard}><img width={49} height={85} src={chevron} alt="Previous" /></button>
            {cardIndex.previous !== null ? 
                <Flashcard 
                    key={cardIndex.previous ?? 'previous'} 
                    number={cardIndex.previous} 
                    totalCards={cards.length} 
                    data={cards[cardIndex.previous]} 
                    animate={`${cardIndex.previous} ${direction}-out`}
                    audio={audios[cardIndex.previous]}
                /> 
            : undefined}
            <Flashcard 
                key={cardIndex.current} 
                number={cardIndex.current} 
                totalCards={cards.length} 
                data={cards[cardIndex.current]} 
                animate={`${cardIndex.current} ${direction}-in`} 
                audio={audios[cardIndex.current]}
            />
            <button key={buttonKey2} className={`next animate-${direction}`} onClick={nextCard}><img width={49} height={85} src={chevron} alt="Next" /></button>
    </div>;
}