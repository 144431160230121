export default class HiddenWordsGenerator {
    static fillingChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZÉÈ';

    constructor(words) {
        this.words = words;     
        
        this.grid = null;

        this.total = 0; 
        this.words.map((w) => {
            this.total += w.length;
            return null;
        });
    }

    createEmptyGrid() {
        this.grid = [];
        for (let i = 0; i < 20; i++) {
            this.grid.push([]);
            for (let j = 0; j < 20; j++) {
                this.grid[i].push(null);
            }
        }
    }

    generate() {
        let placedWords;
        do {
            this.createEmptyGrid();
            placedWords = 0;
            const successList = this.words.map((word, i) => {
                const point = this.getRandomPoint();
                const direction = this.getRandomDirection();
    
                const points = this.wordDoesFit(word, point, direction);
                if(null !== points) {
                    word.split('').map((l, i) => {
                        this.grid[points[i].x][points[i].y] = l.toUpperCase();
                        return null;
                    });
                    return true;
                }else{
                    return false;
                }
            });
            placedWords = successList.filter((v) => v).length;
        }while(placedWords < this.words.length);

        for (let i = 0; i < this.grid.length; i++) {
            for (let j = 0; j < this.grid[i].length; j++) {
                if(this.grid[i][j] !== null) continue;
                this.grid[i][j] = HiddenWordsGenerator.fillingChars.charAt(
                    Math.floor(Math.random() * HiddenWordsGenerator.fillingChars.length)
                );
                // this.grid[i][j] = '-';
            }
        }

        return this.grid;
    }

    getRandomPoint() {
        return {
            x:  Math.floor(Math.random() * this.grid.length),
            y: Math.floor(Math.random() * this.grid[0].length),
        };
    }
    getRandomDirection() {
        return Math.floor(Math.random() * 8);
    }
    
    wordDoesFit(word, point, direction) {
        let points = [];
        for (let i = 0; i < word.length; i++) {
            let testPoint;
            switch (direction) {
                case 0:
                    testPoint = {
                        x: point.x,
                        y: point.y - i
                    };
                    break;
                case 1:
                    testPoint = {
                        x: point.x + i,
                        y: point.y - i
                    };
                    break;
                case 2:
                    testPoint = {
                        x: point.x + i,
                        y: point.y
                    };
                    break;
                case 3:
                    testPoint = {
                        x: point.x + i,
                        y: point.y + i
                    };
                    break;
                case 4:
                    testPoint = {
                        x: point.x,
                        y: point.y + i
                    };
                    break;
                case 5:
                    testPoint = {
                        x: point.x - i,
                        y: point.y + i
                    };
                    break;
                case 6:
                    testPoint = {
                        x: point.x - i,
                        y: point.y
                    };
                    break;
                case 7:
                    testPoint = {
                        x: point.x - i,
                        y: point.y - i
                    };
                    break;
                default:
                    throw new Error('no direction match');
            }
            if(
                testPoint.x < 0 ||
                testPoint.x >= this.grid.length ||
                testPoint.y < 0 ||
                testPoint.y >= this.grid[0].length ||
                this.grid[testPoint.x][testPoint.y] !== null
            ) return null;
            points.push(testPoint);
        }
        return points;
    }
}