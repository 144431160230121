import { useCallback, useState } from 'react';
import useIsMounted from './useIsMounted';
export default function useMountAwareState(defaultValue) {

    const isMounted = useIsMounted();
    const [state, setState] = useState(defaultValue);

    const setStateIfMounted = useCallback((newState) => {
        if(isMounted.current) setState(newState);
    }, [isMounted]);
    
    return [state, setStateIfMounted];
}