import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsContext from '../../Contexts/SettingsContext';
import useCookieConsent from '../../hooks/useCookieConsent';
import useMountAwareState from '../../hooks/useMountAwareState';
import useSettings from '../../hooks/useSettings';
import Popupable from '../Popup/Popupable';
import Switch from '../utils/Switch';
import './Settings.scss';
export default function Settings(props) {
    
    const { setUppercaseSensitivity, setPonctuationSensitivity } = useSettings();
    const { settings } = useContext(SettingsContext);
    const [ t, i18n ] = useTranslation('main');

    const [lang, setLang] = useMountAwareState(i18n.language);
    const { functionality } = useCookieConsent();
    const changeLang = (e) => {
        const lang = e.target.value;
        if(functionality) localStorage.setItem('lang', lang);
        i18n.changeLanguage(lang);
        setLang(lang);
    };

    useEffect(() => {
        if(functionality) localStorage.setItem('settings.uppercase-sensitivity', settings.uppercaseSensitivity);
    }, [settings.uppercaseSensitivity, functionality]);
    useEffect(() => {
        if(functionality) localStorage.setItem('settings.ponctuation-sensitivity', settings.ponctuationSensitivity);
    }, [settings.ponctuationSensitivity, functionality]);

    return (
        <Popupable title={t('settings.title')} close={props.close}>
            <div className="Settings">
                <table>
                    <tbody>
                        <tr className='title'>
                            <td colSpan={2}><h2>{t('settings.sections.general.title')}:</h2></td>
                        </tr>
                        <tr>
                            <td>{t('settings.sections.general.language')}:</td>
                            <td>
                                <select value={lang} onChange={changeLang}>
                                    <option value="en">English</option>
                                    <option value="fr">Français</option>
                                    <option value="zh">中文</option>
                                </select>
                            </td>
                        </tr>
                        <tr className='separator'><td colSpan={2}></td></tr>
                        <tr className='title'>
                            <td colSpan={2}><h2>{t('settings.sections.activities.title')}:</h2></td>
                        </tr>
                        <tr>
                            <td>{t('settings.sections.activities.upper_case_sensitivity')}:</td>
                            <td>
                                <Switch value={settings.uppercaseSensitivity} setter={setUppercaseSensitivity} />
                            </td>
                        </tr>
                        <tr>
                            <td>{t('settings.sections.activities.ponctuation_sensitivity')}:</td>
                            <td>
                                <Switch value={settings.ponctuationSensitivity} setter={setPonctuationSensitivity} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Popupable>
    );
}