import React, { useContext, useEffect } from 'react';
import './Account.scss';
import Cookies from 'js-cookie';

import UserContext from '../../Contexts/UserContext';
import AccountSubscription from './AccountSubscription';
import AccountInstitution from './AccountInstitution';
import AccountIdentity from './AccountIdentity';
import Loading from '../Loading';
import API from '../../API/API';
import AccountInstitutionInvitation from './AccountInstitutionInvitation';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import CookieConsentContext from '../../Contexts/CookieConsentContext';
import AccountSecurity from './AccountSecurity/AccountSecurity';
import useMountAwareState from '../../hooks/useMountAwareState';

export default function Account(props) {
    
    useDocumentTitle('Account');
    const userContext = useContext(UserContext);

    const [invitations, setInvitations] = useMountAwareState([]);

    const [ t ] = useTranslation('common');

    const { setCookieConsent } = useContext(CookieConsentContext);

    const resetCookiePreferences = () => {
        Cookies.remove('CookieConsent');
        setCookieConsent(null);
    };

    const updateUser = () => {
        API.getInstance().get('/users/self')
        .then(({status, data}) => {
            if(status === 200) {
                userContext.setUser(data);
            }
        });
    };

    useEffect(() => {
        API.getInstance().get('/users/self/institution-codes')
        .then(({status, data}) => {
            if(status === 200) {
                setInvitations(data);
            }
        });
    }, [setInvitations]);

    if(userContext.user === null) return <Loading />;
    const user = userContext.user;
    return (
        <div className='Account'>
            {invitations.map((invitation, i) => {
                return <AccountInstitutionInvitation updateUser={updateUser} data={invitation} key={i} />
            })}
            <AccountIdentity updateUser={updateUser} user={user} />
            <AccountInstitution updateUser={updateUser} user={user} />
            <AccountSubscription updateUser={updateUser} user={user} />
            <AccountSecurity updateUser={updateUser} user={user} />
            <section className='legals'>
                <div>
                    <a target="_blank" rel='noreferrer noopener' href="https://waeelearning.com/terms-of-service">{t('legals.tos')}</a>
                    |
                    <a target="_blank" rel='noreferrer noopener' href="https://waeelearning.com/privacy-policy">{t('legals.privacy_policy')}</a>
                </div>
                <button onClick={resetCookiePreferences}>Reset cookie preferences</button> 
                
            </section>
        </div>
    );
}