import React from 'react';
import { useTranslation } from 'react-i18next';
import './SharedFlashcardsStart.scss';

import sound_icon from '../../../images/icons/sound.svg';

export default function SharedFlashcardsStart(props) {

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    return (
        <div className={`SharedFlashcardsStart ${props.animateOut ? 'animateOut' : ''} ${props.animateIn ? 'animateIn' : ''}`}>
            <h2>{t(`pages.shared.display.flashcards.mode.${props.mode}.title`)}</h2>
            <div className='instruction'>
                <div className='translation-way'>
                    <div className='source'>
                        {props.mode === 'listening' ? <img width={109} height={109} src={sound_icon} alt="Sound in french" /> : undefined}
                        {tcommon(`language.${props.source}`)}
                    </div>
                    <div className='destination'>
                        {tcommon(`language.${props.destination}`)}
                    </div>
                </div>
                <div className='start-container'>
                    <button onClick={props.start}>{t(`pages.shared.display.flashcards.start`)}</button>
                </div>
            </div>
        </div>
    );
}