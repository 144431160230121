import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../../API/API';
import SettingsContext from '../../../../Contexts/SettingsContext';
import StringCleaner from '../../../../helpers/StringCleaner';
import FileIcon from '../../../FileIcon';
import Loading from '../../../Loading';
import CrosswordsGrid from './CrosswordsGrid';
import './CrosswordsViewer.scss';
export default function CrosswordsViewer(props) {

    const [grid, setGrid] = useState(null);
    const [error, setError] = useState(null);
    const [verifying, setVerifying] = useState(false);
    const [verification, setVerification] = useState(null);
    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    const { settings } = useContext(SettingsContext);

    useEffect(() => {
        setGrid(() => {
            let g = [];
            for (let i = 0; i < props.data.size.height; i++) {
                g.push([]);
                for (let j = 0; j < props.data.size.width; j++) {
                    g[i].push({
                        indexes: {
                            vertical: null,
                            horizontal: null,
                        },
                        nextPos: {
                            horizontal: null,
                            vertical: null
                        },
                        letter: null,
                        direction: null,
                        lastCell: {
                            vertical: false,
                            horizontal: false,
                        }
                    });
                }
            }

            props.data.words.forEach((wordData, i, words) => {
                g[wordData.pos.y][wordData.pos.x].indexes[wordData.direction] = i;
                wordData.word.split("").forEach((letter, letterIndex, word) => {
                    const x = wordData.pos.x + (wordData.direction === "horizontal" ? letterIndex : 0);
                    const y = wordData.pos.y + (wordData.direction === "vertical" ? letterIndex : 0);
                    g[y][x].letter = letter;

                    if(letterIndex === 0) g[y][x].direction = wordData.direction;

                    if(letterIndex < word.length - 1) {
                        g[y][x].nextPos[wordData.direction] = {
                            x: x + (wordData.direction === "vertical" ? 0 : 1),
                            y: y + (wordData.direction === "horizontal" ? 0 : 1),
                        };
                    }else{
                        g[y][x].lastCell[wordData.direction] = true;
                        if(i < words.length - 1) {
                            g[y][x].nextPos[wordData.direction] = {
                                x: words[i+1].pos.x,
                                y: words[i+1].pos.y,
                            };
                        }
                    }
                });
            });

            return g;
        });
    }, [props.data.size.height, props.data.size.width, props.data.words]);

    const reset = () => {
        props.data.words.forEach((wordData) => {
            wordData.word.split("").forEach((letter, letterIndex, word) => {
                const x = wordData.pos.x + (wordData.direction === "horizontal" ? letterIndex : 0);
                const y = wordData.pos.y + (wordData.direction === "vertical" ? letterIndex : 0);
                
                const elem = document.getElementById(`cw-cell-${x}-${y}`);
                if(elem) elem.value = "";
            });
        });
    }

    const focusWord = word => {
        const elem = document.getElementById(`cw-cell-${word.pos.x}-${word.pos.y}`);
        if(elem) elem.focus();
    };

    const handleClick = () => {
        if(verification) {
            setVerification(null);
            reset();
        }else{
            setVerification(true);
            verifyAnswers();
        }
    };

    const gatherAnswers = () => {
        let answers = [];
        props.data.words.forEach((wordData, i, words) => {
            let a = [];
            wordData.word.split("").forEach((letter, letterIndex, word) => {
                const x = wordData.pos.x + (wordData.direction === "horizontal" ? letterIndex : 0);
                const y = wordData.pos.y + (wordData.direction === "vertical" ? letterIndex : 0);

                const elem = document.getElementById(`cw-cell-${x}-${y}`);
                if(elem && elem.value.length > 0) a.push(elem.value);
            });
            answers.push(a.join(""));
        });

        for (let i = 0; i < answers.length; i++) {
            const a = answers[i];
            if(a.length === 0) return null;
        }
        return answers;
    };

    const checkAnswer = (answer, correction) => {
        const cleaner = (new StringCleaner()).trimWhitespaces().normalizeWhitepaces();
        answer = answer.toLowerCase();
        correction = correction.toLowerCase();

        
        if(!settings.ponctuationSensitivity) {
            answer = answer.replace(/[.,?!]/gm, ' ');
            correction = correction.replace(/[.,?!]/gm, ' ');
        }

        return cleaner.clean(answer) === cleaner.clean(correction);
    };

    const verifyAnswers = () => {
        setError(null);
        const answers = gatherAnswers();
        if(!answers) {
            setError(t("pages.explorer.apps.crosswords.incomplete"));
            return;
        }

        setVerifying(true);

        if(props?.token === undefined) {
            setVerification({
                words: props.data.words,
                answers: JSON.parse( JSON.stringify( answers ) ),
                corrects: props.data.words.filter((w, i) => checkAnswer(answers[i], w.word)).length,
            });
            setVerifying(false);
        }else{
            API.getInstance()
            .post(`/resource-verify-answers/${props.resource.rid}${props?.token !== undefined ? `?t=${props.token}` : ''}`, {
                data: JSON.parse( JSON.stringify( answers ) )
            }, props?.token === undefined)
            .then(({status, data}) => {
                if(status === 200 || status === 201) {
                    setVerification(data);
                }else{
                    setError(data.message);
                }
                setVerifying(false);
            })
            .catch((error) => {
                setError(tcommon('general.default'));
                setVerifying(false);
            });
        }
    };
    
    
    if(grid === null) return <Loading />;
    return (
        <div className='CrosswordsViewer'>
            <div className='header'>
                <h1>
                    <FileIcon type="crosswords" />
                    {props.resource.name}
                </h1>
            </div>
            <div className='grid'>
                <CrosswordsGrid grid={grid} verification={verification} />
            </div>
            <div className='lists'>
                <div>
                    <h2>Vertical</h2>
                    <ul>
                        {props.data.words.map((wordData, i) => {
                            if(wordData.direction !== "vertical") return undefined;
                            return <li key={wordData.hint}>
                                <button onClick={() => focusWord(wordData)}>
                                    <div className='number'>{i + 1}</div>
                                    <div className='word'>{wordData.hint.toUpperCase()}</div>
                                </button>
                            </li>;
                        })}
                    </ul>
                </div>
                <div>
                    <h2>Horizontal</h2>
                    <ul>
                        {props.data.words.map((wordData, i) => {
                            if(wordData.direction !== "horizontal") return undefined;
                            return <li key={wordData.hint}>
                                <button onClick={() => focusWord(wordData)}>
                                    <div className='number'>{i + 1}</div>
                                    <div className='word'>{wordData.hint.toUpperCase()}</div>
                                </button>
                            </li>;
                        })}
                    </ul>
                </div>
            </div>
            <div className="verify">
                {error ?? undefined}
                {verification ? 
                    <div className="results">
                        {verification.corrects}/{props.data.words.length} ({Math.round((100 * verification.corrects) / props.data.words.length)}%)
                    </div>
                : undefined}
                {props?.result?.data === undefined ?
                    <button onClick={handleClick} id="button" disabled={verifying}>
                        {verification ? 
                            t('pages.explorer.apps.missing_words.reset')
                        :
                            t('pages.explorer.apps.missing_words.validate')
                        }
                        
                    </button>
                :
                    undefined
                }
                
            </div>
        </div>
    );
}