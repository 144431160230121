import React from 'react';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Activities from './Activities';
import Counters from './Counters';
import Documents from './Documents';
import Flashcards from './Flashcards';
import Footer from './Footer';
import Gradient from './Gradient';
import Introduction from './Introduction';
// import Institution from './Institution';
// import PlanComparison from './PlanComparison';
import './Presentation.scss';
import StayTuned from './StayTuned';
import SubscriptionPlans from './SubscriptionPlans';
import Welcome from './Welcome';
export default function Presentation(props) {
    
    useDocumentTitle('Presentation');

    return (
        <main className='Presentation'>
            <Gradient />
            <Welcome />
            <Introduction />
            <Counters />
            <SubscriptionPlans />
            <StayTuned />
            <Documents />
            <Flashcards />
            <Activities />
            {/* <Institution /> */}
            <Footer />
        </main>
    );
}