import React from 'react';
import { Link } from 'react-router-dom';
import './LoginBox.scss';
export default function LoginBox(props) {
    
    return (
        <div className='LoginBox'>
            <Link to="/login" className='signin'>Sign in</Link>
            <Link to="/register" className='signup'>Sign up</Link>
        </div>
    );
}