import React from 'react';
import { useTranslation } from 'react-i18next';
import useMountAwareState from '../../hooks/useMountAwareState';
import Login from '../authentication/Login';
import Register from '../authentication/Register';
import './CheckoutAuthentication.scss';
export default function CheckoutAuthentication(props) {
    
    const [mode, setMode] = useMountAwareState('register');
    const [accountCreated, setAccountCreated] = useMountAwareState(false);

    const [ t ] = useTranslation('main');

    const afterCreatingAccount = () => {
        setAccountCreated(true);
        setMode('login');
    }

    return (
        <div className='CheckoutAuthentication'>
            {mode === 'register' ?
                <Register changeMode={() => setMode('login')} onSuccess={afterCreatingAccount} />
            :
                <>
                    {accountCreated ? <div className='info'>{t('pages.checkout.authentication.success')}</div> : undefined}
                    <Login changeMode={() => setMode('register')} onSuccess={props.next} alternativeTitle={t('pages.checkout.authentication.log_in')} />
                </>
            }
        </div>
    );
}