import React from 'react';
import Collapsible from '../../../../../utils/Collapsible';
import ZoomableImage from '../../../../../utils/ZoomableImage';
export default function QuizImageResults(props) {
    
    return (
        <Collapsible header={<h2>#{props.i} {props.text}</h2>}>
            <div className='quiz-digest image'>
                <ZoomableImage src={props.url} alt={props?.alt ?? ''} />
            </div>
        </Collapsible>
    );
}