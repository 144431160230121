import React from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../API/API';
import StringCleaner from '../../helpers/StringCleaner';
import useMountAwareState from '../../hooks/useMountAwareState';
import Popupable from '../Popup/Popupable';
import './RenameClass.scss';
export default function RenameClass(props) {

    const cleaner = new StringCleaner().normalizeWhitepaces().trimWhitespaces();

    const [name, setName] = useMountAwareState(props.class.name);
    const [lock, setLock] = useMountAwareState(false);
    const [error, setError] = useMountAwareState(null);
    const [formError, setFormError] = useMountAwareState(null);
    const [ tcommon ] = useTranslation('common');
    const [ t ] = useTranslation('main');

    const rename = (e) => {
        e.preventDefault();
        setLock(true);
        setFormError(null);
        setError(null);

        const n = cleaner.clean(name);
        if(n.length === 0) {
            setFormError(t('pages.classes.dialogs.create_class.errors.missing_name'));
            setLock(false);
            return;
        }
        API.getInstance()
        .patch(`/users/self/classes/${props.class.pid}`, {
            name: n
        })
        .then(({status, data}) => {
            if(status === 200 || status === 204) {
                const refreshEvent = new CustomEvent('class-update');
                document.dispatchEvent(refreshEvent);
                props.close();
            }else{
                setError(tcommon('error.fetch.default', {code: status}))
            }
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
        });
    };
    
    return (
        <Popupable title={t('pages.classes.dialogs.rename_class.title')} close={props.close}>
            <form className='RenameClass' onSubmit={rename}>
                <div className='name'>
                    <h2>{tcommon('form.name')}:</h2>
                    <div>
                        <input type="text" value={name} required={true} onChange={e => setName(e.target.value)} disabled={lock} placeholder={t('pages.classes.dialogs.create_class.name_your_class')} />
                    </div>
                </div>
                {formError ?
                    <div className='error'>{formError}</div>
                : undefined}
                {error ?
                    <div className='error'>{error}</div>
                : undefined}
                <div className='submit'>
                    <input type="submit" value={tcommon('general.rename')} disabled={lock} />
                </div>
            </form>
        </Popupable>
    );
}