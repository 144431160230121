import React from 'react';
import { useTranslation } from 'react-i18next';
export default function QuizVideo(props) {
    
    const [ t ] = useTranslation('main');

    const m = props.module;
    return (
        <section className='QuizVideo quizz-module'>
            <header>
                <h2>{props.id + 1}. {m.text}</h2>
            </header>
            {props.error !== null ?
                <div className='error'>
                    {t(`pages.explorer.apps.quiz.errors.${m.type}.${props.error}`)}
                </div>
            : undefined}
            <div className='widget video'>
                <video width="320" height="240" controls>
                    <source src={m.url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </section>
    );
}