import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import GeneralHelpSection from './GeneralHelpSection';
import './Help.scss';
import HelpAssistedTextInput from './HelpAssistedTextInput';
import InstitutionalHelpSection from './InstitutionalHelpSection';
import add_favorite_img from '../../images/help/add_favorite.png';
import remove_favorite_img from '../../images/help/remove_favorite.png';
import classes_section_img from '../../images/help/classes_section.png';
import class_types_img from '../../images/help/class_types.png';
import create_class_img from '../../images/help/create_class.png';
import manage_students_img from '../../images/help/manage_students.png';
import add_invite_student_img from '../../images/help/add_invite_student.png';
import { useLocation } from 'react-router-dom';

export default function Help(props) {

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');
    useDocumentTitle(tcommon('general.help'));
    const location = useLocation();
    const ref = useRef();

    useEffect(() => {
        // ALL THIS BECAUSE THE SCROLL WAS NOT GOING DOWN ENOUGH
        // TODO: MAYBE FIND SOMETHING BETTER THAN WAITING FOR ALL IMAGES TO LOAD BEFORE SCROLLING
        // PROBABLY SOME LAYOUT SHIFT BUT EVERY IMAGE HAVE WIDTH AND HEIGHT ATTR SET
        if(ref.current) {
            const images = [...ref.current.getElementsByTagName('img')];
            let loadCount = 0;
            const incrementLoadCount = () => {
                loadCount++;
                if(loadCount === images.length) {
                    if(location?.hash) {
                        const hash = location.hash.slice(1);
                        const target = document.getElementById(hash);
                        if(target === null) return;
                        const y = target.offsetTop;
                        document.getElementById("display").scrollTo({top: y, behavior: 'smooth'});
                    }
                }
            };
            images.forEach(img => {
                img.addEventListener('load', incrementLoadCount);
            });

            return () => {
                images.forEach(img => {
                    img.removeEventListener('load', incrementLoadCount);
                });
            };
        }
    }, [location.hash, ref]);
    
    return (
        <div ref={ref} className='Help'>
            <HelpAssistedTextInput />
            <GeneralHelpSection id="favorites">
                <header>
                    <h2>{t('pages.help.favorites_management.title')}</h2>
                </header>
                <main>
                    {t('pages.help.favorites_management.text')}
                    <ul>
                        <li>
                            <h3>{t('pages.help.favorites_management.add')}</h3>
                            <div className='illustrations'>
                                <div className='illustration' width={154} height={124}>
                                    <img width={154} height={124} src={add_favorite_img} alt="" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <h3>{t('pages.help.favorites_management.remove')}</h3>
                            <div className='illustrations'>
                                <div className='illustration' width={193} height={115}>
                                    <img width={193} height={115} src={remove_favorite_img} alt="" />
                                </div>
                            </div>
                        </li>
                    </ul>
                </main>
            </GeneralHelpSection>
            <InstitutionalHelpSection id="class-management">
                <header>
                    <h2>{t('pages.help.class_management.title')}</h2>
                </header>
                <main>
                    {t('pages.help.class_management.text')}
                    <ul>
                        <li>
                            <h3>{t('pages.help.class_management.create')}</h3>
                            <div className='illustrations'>
                                <div className='illustration' width={120} height={148}>
                                    <img width={120} height={148} src={classes_section_img} alt="" />
                                </div>
                                <div className='illustration' width={377} height={118}>
                                    <img width={377} height={118} src={create_class_img} alt="" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <h3>{t('pages.help.class_management.class_types')}</h3>
                            <div className='illustrations'>
                                <div className='illustration' width={560} height={115}>
                                    <img width={560} height={115} src={class_types_img} alt="" />
                                </div>
                            </div>
                            <ul>
                                <li>{t('pages.help.class_management.email_class')}</li>
                                <li>{t('pages.help.class_management.links_class')}</li>
                            </ul>
                        </li>
                        <li>
                            <h3>{t('pages.help.class_management.invite_student')}</h3>
                            <p>{t('pages.help.class_management.invite_student_details')}</p>
                            <div className='illustrations'>
                                <div className='illustration' width={450} height={437}>
                                    <img width={450} height={437} src={manage_students_img} alt="" />
                                </div>
                                <div className='illustration' width={113} height={90}>
                                    <img width={113} height={90} src={add_invite_student_img} alt="" />
                                </div>
                            </div>
                        </li>
                    </ul>
                </main>
            </InstitutionalHelpSection>
            <section className='contact-support' id="support">
                <h2>{t('pages.help.support.having_a_question')}</h2>
                <div>
                    <Trans t={t} i18nKey="pages.help.support.contact_us">
                        Contact us at <a target="_blank" rel="noreferrer noopener" href="mailto:support@waeelearning.com">support@waeelearning.com</a>
                    </Trans>
                </div>
            </section>
        </div>
    );
}