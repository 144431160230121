import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../API/API';
import './InviteTeacherForm.scss';
import UserContext from '../../../Contexts/UserContext';
import useFeedback from '../../../hooks/useFeedback';
import useMountAwareState from '../../../hooks/useMountAwareState';
export default function InviteTeacherForm(props) {
    
    const [lock, setLock] = useMountAwareState(false);
    const [email, setEmail] = useMountAwareState('');
    const [formResult, setFormResult] = useMountAwareState(undefined);

    const userContext = useContext(UserContext);

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    const feedback = useFeedback();

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setLock(true);
        setFormResult(tcommon('form.sending'));
        API.getInstance().post('/institutions/' + userContext.user.institution.pid + '/institution-codes', {
            email: email
        })
        .then(({status, data}) => {
            setLock(false);
            if(status === 201) {
                setEmail('');
                props.fetchTeachers();
                setFormResult(tcommon('feedback.invitation_sent'));
                feedback.add(tcommon('feedback.invitation_sent'));
            }else if(status === 404 || status === 409) {
                setFormResult(data.message);
            }else{
                setFormResult(tcommon('error.general.default'));
            }
        });
    };

    return (
        <form className='InviteTeacherForm' onSubmit={handleSubmit}>
            <span>{t('pages.account.sections.institution.institutional.admin.invite')}:</span>
            <input disabled={lock} type="email" onChange={handleChange} value={email} placeholder={`${t('pages.account.sections.institution.institutional.admin.teacher_email')}...`}/>
            <button className='btn-yellow' disabled={lock}>{tcommon('form.send')}</button>
            <div className='feedback'>
                {formResult}
            </div>
        </form>
    );
}