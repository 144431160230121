import React, { useCallback, useEffect, useRef } from 'react';
import './Popup.scss';
export default function Popup(props) {

    const wrapperRef = useRef(null);

    const handleOutsideInteraction = useCallback(
        (e) => {
            if (wrapperRef && !wrapperRef.current.contains(e.target)) {
                props.cancel?.();
                props.close?.();
            }
        },
        [props],
    );

    const handleBlur = (e) => {
        if (e.relatedTarget !== null && !e.currentTarget.contains(e.relatedTarget)) {
            props.cancel?.();
            props.close?.();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideInteraction);
        return () => {
            document.removeEventListener('mousedown', handleOutsideInteraction);
        }
    }, [handleOutsideInteraction]);

    useEffect(() => {
        if(props?.type && props.type === 'input') {
            wrapperRef.current.querySelector('input:first-child')?.focus();
        }else{
            wrapperRef.current.querySelector('.actions>button:first-child').focus();
        }
        
        return () => {
            
        }
    }, [props?.type]);
    
    return (
        <div className='Popup' onBlur={handleBlur}>
            <div ref={wrapperRef} className='box'>
                <h1>{props.title}</h1>
                <div>{props.text}</div>
                <div className='actions'>
                    {props.buttons}
                </div>
            </div>
        </div>
    );
}