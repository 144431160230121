import React, {  useEffect, useCallback } from 'react';
import './AccountSecurity.scss';
import Loading from '../../Loading';
import API from '../../../API/API';
import { useTranslation } from 'react-i18next';
import AccountSecurityConnection from './AccountSecurityConnection';
import security_icon from '../../../images/icons/security.svg';
import useMountAwareState from '../../../hooks/useMountAwareState';
export default function AccountSecurity(props) {

    const [connections, setConnections] = useMountAwareState(null);
    const [loading, setLoading] = useMountAwareState(true);
    const [error, setError] = useMountAwareState(null);

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    const updateConnections = useCallback(
        () => {
            API.getInstance()
            .get('/users/self/active-connections')
            .then(({status, data}) => {
                if(status === 200) {
                    setConnections(data);
                }else{
                    setError(tcommon('error.fetch.default', {code: status}));
                }
                setLoading(false);
            })
            .catch(err => {
                setError(tcommon('error.general.default'));
                setLoading(false);
            })
        },
        [setConnections, setError, setLoading, tcommon],
    );
    
    useEffect(() => {
        updateConnections();
    }, [updateConnections]);
    
    return (
        <section className='AccountSecurity'>
            <h2><img src={security_icon} alt="" />{t('pages.account.sections.security.title')}</h2>
            <h3>{t('pages.account.sections.security.active_connections')}:</h3>
            <div className='list'>
                {error !== null || loading ? 
                    (error !== null ? <div>{error}</div> : <Loading /> )
                : 
                    (connections.lenght === 0 ?
                        <div>{tcommon('error.general.default')}</div>
                    :
                        (connections.map((c, i) => {
                            return <AccountSecurityConnection key={c.pid} connection={c} updateConnections={updateConnections} />;
                        }))
                    )
                }
            </div>
        </section>
    );
}