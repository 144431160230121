import React, {  useCallback, useEffect } from 'react';
import API from '../../API/API';
import SelectClassDialog from './SelectClassDialog';
import './ShareResourceDialog.scss';
import Dot3 from '../utils/Dot3';
import useFeedback from '../../hooks/useFeedback';

import reading_img from '../../images/icons/reading.svg';
import writing_img from '../../images/icons/writing.svg';
import listening_img from '../../images/icons/listening.svg';
import learning_img from '../../images/icons/learning.svg';
import Popupable from '../Popup/Popupable';
import QandA from './shareDialogComponents/QandA';
import StringCleaner from '../../helpers/StringCleaner';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useMountAwareState from '../../hooks/useMountAwareState';
import parseHeaderInt from '../../helpers/ParseHeaderInt';
import Switch from '../utils/Switch';
import useCookieConsent from '../../hooks/useCookieConsent';

export default function ShareResourceDialog(props) {

    const today = new Date();
    const nextWeek = new Date(today.getTime() + (1000 * 60 * 60 * 24 * 7));

    const { functionality } = useCookieConsent();
    const feedback = useFeedback();
    const navigate = useNavigate();
    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');
    

    const [pagination, setPagination] = useMountAwareState({current: null, previous: null, next: null, count: null, total: null});
    const [dueDate, setDueDate] = useMountAwareState(`${nextWeek.getFullYear()}-${nextWeek.getMonth() + 1 < 10 ? '0' : ''}${nextWeek.getMonth() + 1}-${nextWeek.getDate() < 10 ? '0' : ''}${nextWeek.getDate()}`);
    const [dueTime, setDueTime] = useMountAwareState('23:59');
    const [cla, setCla] = useMountAwareState(null); //class
    const [message, setMessage] = useMountAwareState('');
    const [ uppercaseSensitivity, setUppercaseSensitivity ] = useMountAwareState((localStorage.getItem('share.uppercase-sensitivity') ?? 'true') === 'true');
    const [ ponctuationSensitivity, setPonctuationSensitivity ] = useMountAwareState((localStorage.getItem('share.ponctuation-sensitivity') ?? 'true') === 'true');
    const [sending, setSending] = useMountAwareState(false);
    const [error, setError] = useMountAwareState(null);
    const [shares, setShares] = useMountAwareState(null);
    const [fetchSharesError, setFetchSharesError] = useMountAwareState(null);
    const [subPopup, setSubPopup] = useMountAwareState(null);
    const [loading, setLoading] = useMountAwareState(false);
    // load classes list to not fetch on every popup opening
    const [classes, setClasses] = useMountAwareState(null);
    const [fetchClassesError, setFetchClassesError] = useMountAwareState(null);

    // Type dependant fields
    const [fcMode, setFcMode] = useMountAwareState('lrn');
    const [questions, setQuestions] = useMountAwareState([]);

    const fetchClasses = useCallback(() => {
        setFetchClassesError(null);

        API.getInstance()
        .get('/users/self/classes')
        .then(({status, data}) => {
            if(status === 200) {
                setClasses(data);
            }else{
                setFetchClassesError(tcommon('error.fetch.default', {code: status}));
            }
        })
        .catch((error) => {
            setFetchClassesError(tcommon('error.general.default'));
        });
    }, [setClasses, setFetchClassesError, tcommon]);

    const fetchShares = useCallback(
        (page = 1) => {
            setLoading(true);
            API.getInstance()
            .get(`/users/self/shares?resource=${encodeURIComponent(props.resource.rid)}&page=${page}&pageSize=5`)
            .then(({status, data, headers}) => {
                if(status === 200) {
                    setShares(data);
                    setPagination({
                        previous: parseHeaderInt(headers.get('x-pagination-previous')),
                        current: parseHeaderInt(headers.get('x-pagination-current')),
                        next: parseHeaderInt(headers.get('x-pagination-next')),
                        count: parseHeaderInt(headers.get('x-pagination-count')),
                        total: parseHeaderInt(headers.get('x-total-entities')),
                    });
                }else{
                    setFetchSharesError(tcommon('error.fetch.default', {code: status}));
                }
                setLoading(false);
            })
            .catch((error) => {
                setFetchSharesError(tcommon('error.general.default'));
                setLoading(false);
            });
        },
        [props.resource.rid, setFetchSharesError, setLoading, setPagination, setShares, tcommon]
    );

    useEffect(() => {
        fetchShares();
        fetchClasses();
    }, [fetchShares, props.resource.rid, tcommon, fetchClasses]);

    useEffect(() => {
        if(functionality) {
            localStorage.setItem('share.uppercase-sensitivity', uppercaseSensitivity);
            localStorage.setItem('share.ponctuation-sensitivity', ponctuationSensitivity);
        }
    }, [uppercaseSensitivity, ponctuationSensitivity, functionality]);
    

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const openSelectClass = () => {
        if(sending) return;
        if(classes === null) fetchClasses();
        setSubPopup(
            <SelectClassDialog setClass={setCla} classes={classes} refreshClasses={fetchClasses} error={fetchClassesError} close={() => {setSubPopup(null)}} />
        );
    }

    const send = () => {
        if(cla === null) return;
        setError(null);
        setSending(true);

        let options = {
            uppercase_sensitivity: uppercaseSensitivity,
            ponctuation_sensitivity: ponctuationSensitivity,
        };
        if (props.resource.extension === 'fc') options.mode = fcMode;
        else if (['pdf', 'mp4', 'mp3'].includes(props.resource.extension)) {
            const cleaner = (new StringCleaner()).normalizeWhitepaces().trimWhitespaces();
            options.questions = questions.map((qa) => {return {question: cleaner.clean(qa.question), answer: cleaner.clean(qa.answer)}});
        }

        let [year, month, day] = dueDate.split('-');
        const [hour, minute] = dueTime.split(':');
        const dueDateTime = (new Date(year, --month, day, hour, minute)).getTime();

        if(dueDateTime <= (new Date()).getTime()) {
            setError(t('layout.share.error.due_date_in_past'));
            setSending(false);
            return;
        }

        if(cla.type === 'email') feedback.add(`${tcommon('feedback.sending_invitations')}...`);
        
        API.getInstance()
        .post(`/users/self/classes/${cla.pid}/shares`, {
            resource: props.resource.rid,
            due_date: Math.round(dueDateTime / 1000),
            message: message,
            options: options
        })
        .then(({status, data}) => {
            if(status === 201) {
                if(data?.notification_report) {
                    feedback.add(tcommon('feedback.resource_shared_with', {name: cla.name}));
                    data.notification_report.forEach((student) => {
                        feedback.add(tcommon('feedback.could_not_send_invitation_to', {first_name: student.first_name, last_name: student.last_name}));
                    });
                    if(data.failure_notified) {
                        feedback.add(tcommon('feedback.a_report_was_sent'));
                    }
                }else{
                    navigate(`/classes/${cla.pid}/shares/${data.pid}`);
                }
                
                props.close();
            }else{
                setError(tcommon('error.fetch.default', {code: status}));
            }
            setSending(false);
        })
        .catch((error) => {
            setError(tcommon('error.general.default'));
            setSending(false);
        });
    };

    const getTypeDependant = () => {
        switch (props.resource.extension) {
            case 'fc':
                return <>
                    <div className='selection'>
                        <button className={`fcMode ${fcMode === 'lrn' ? 'active' : ''}`} onClick={e => setFcMode('lrn')} disabled={sending}>
                            <img width={58} height={58} src={learning_img} alt="Learning" />
                            <div>{t('pages.explorer.share.learn')}</div>
                        </button>
                        <button className={`fcMode ${fcMode === 'ref' ? 'active' : ''}`} onClick={e => setFcMode('ref')} disabled={sending}>
                            <img width={537} height={537} src={writing_img} alt="Writing" />
                            <div>{t('pages.explorer.share.english_to_french')}</div>
                        </button>
                        <button className={`fcMode ${fcMode === 'rfe' ? 'active' : ''}`} onClick={e => setFcMode('rfe')} disabled={sending}>
                            <img width={81} height={81} src={reading_img} alt="Reading" />
                            <div>{t('pages.explorer.share.french_to_english')}</div>
                        </button>
                        <button className={`fcMode ${fcMode === 'lff' ? 'active' : ''}`} onClick={e => setFcMode('lff')} disabled={sending}>
                            <img width={63} height={63} src={listening_img} alt="Listening" />
                            <div>{t('pages.explorer.share.french_to_french')}</div>
                        </button>
                        <button className={`fcMode ${fcMode === 'lfe' ? 'active' : ''}`} onClick={e => setFcMode('lfe')} disabled={sending}>
                            <img width={63} height={63} src={listening_img} alt="Listening" />
                            <div>{t('pages.explorer.share.french_to_english')}</div>
                        </button>
                    </div>
                </>;
            case 'pdf':
                return <QandA questions={questions} setQuestions={setQuestions} />;
            case 'mp4':
                return <QandA questions={questions} setQuestions={setQuestions} />;
            case 'mp3':
                return <QandA questions={questions} setQuestions={setQuestions} />;
            default:
                break;
        }
    };

    return (
        <Popupable title={`Share: ${props.resource.name}`} close={props.close}>
            <div className='ShareResourceDialog'>
                <form className='ShareResourceDialog' onSubmit={handleSubmit}>
                    {getTypeDependant()}
                    <label>
                        {t('pages.explorer.share.due_on')}:
                        <input  type="date" 
                                value={dueDate} 
                                onChange={(e) => setDueDate(e.target.value)} 
                                disabled={sending}
                                min={`${today.getFullYear()}-${today.getMonth() + 1 < 10 ? '0' : ''}${today.getMonth() + 1}-${today.getDate() < 10 ? '0' : ''}${today.getDate()}`}
                        />
                        <input  type="time" 
                                value={dueTime} 
                                onChange={(e) => setDueTime(e.target.value)} 
                                disabled={sending}
                        />
                    </label>
                    <label>
                        {cla === null ? 
                            t('pages.explorer.share.select_a_class')
                        :
                            cla.name
                        }
                        <button onClick={openSelectClass} disabled={sending}>{t('pages.explorer.share.select')}</button>
                    </label>
                    <label className='message'>
                        {t('pages.explorer.share.message')}:
                        <div>
                            <textarea maxLength={255} value={message} onChange={e => setMessage(e.target.value)} placeholder='Have fun!' disabled={sending}>

                            </textarea>
                            <div>{message.length}/255</div>
                        </div>
                    </label>
                    <div className='settings'>
                        <h2>Settings</h2>
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Uppercase sensible:</td>
                                        <td>
                                            <div>
                                                <Switch value={uppercaseSensitivity} setter={setUppercaseSensitivity} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Ponctuation sensible:</td>
                                        <td>
                                            <div>
                                                <Switch value={ponctuationSensitivity} setter={setPonctuationSensitivity} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {error ? <div style={{marginBottom: '10px'}}>{error}</div> : undefined}
                    <button type='submit' disabled={cla === null || sending} onClick={send}>
                        {sending ? <>{tcommon('form.sending')}<Dot3/></> : 'Send'}
                    </button>
                </form>
                <div className='shares'>
                    {shares === null || fetchSharesError !== null ?
                        (fetchSharesError !== null ?
                            <div>{fetchSharesError}</div>
                        :
                            <div>
                                <div>{tcommon('general.loading')}<Dot3 /></div>
                            </div>
                        )
                    :
                        <div>
                            {shares.length === 0 ?
                                <div>{t('pages.explorer.share.never_shared')}</div>
                            :
                                <>
                                    <div className='list'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td>{t('pages.explorer.share.headers.class')}</td>
                                                    <td>{t('pages.explorer.share.headers.created')}</td>
                                                    <td>{t('pages.explorer.share.headers.due_date')}</td>
                                                    <td>{t('pages.explorer.share.headers.results')}</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shares.map((s) => {
                                                    return <tr key={s.pid} onClick={() => {navigate(`/classes/${s.class.pid}/shares/${s.pid}`); props.close();}}>
                                                        <td>{s.class.name}</td>
                                                        <td>{new Date(s.creation_date * 1000).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric'})}</td>
                                                        <td>{new Date(s.due_date * 1000).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit'})}</td>
                                                        <td>{s.results_count}/{s.students_count_at_sending}</td>
                                                    </tr>;
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='pagination'>
                                        <div className='stats'>
                                            <div>{tcommon('pagination.total')}: {pagination.total ? pagination.total : (shares === null ? <Dot3 /> : 0) }</div>
                                        </div>
                                        <div className='controls'>
                                            <button 
                                                    className={loading ? 'loading' : ''}
                                                    onClick={() => {fetchShares(pagination.previous)}}
                                                    disabled={pagination.previous === null || loading}
                                            >
                                                {tcommon('pagination.previous')}
                                            </button>
                                            <div>
                                                {pagination.current}/{pagination.count}
                                            </div>
                                            <button 
                                                    className={loading ? 'loading' : ''}
                                                    onClick={() => {fetchShares(pagination.next)}}
                                                    disabled={pagination.next === null || loading}
                                            >
                                                {tcommon('pagination.next')}
                                            </button>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    }
                </div>
            </div>
            {subPopup ?? undefined}
        </Popupable>
    );
}