import React from 'react';
import useCountdown from '../hooks/useCountdown';

export default function Countdown(props) {
    
    const countdown = useCountdown(props.until ?? (new Date()).getTime() - 1000 * 60);

    return (
        <span className='Countdown'>
            {countdown}
        </span>
    );
}