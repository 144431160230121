import React, {  useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ExplorerDisplay from '../explorer/ExplorerDisplay';
import institutional_icon from '../../images/icons/institution.svg';
import home_icon from '../../images/icons/home.svg';
import './Preview.scss';
import Loading from '../Loading';
import FileIcon from '../FileIcon';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useMountAwareState from '../../hooks/useMountAwareState';
export default function Preview(props) {

    useDocumentTitle('Preview');

    const { type } = useParams();
    const [ rid, setRid ] = useMountAwareState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const rids = {
            'lessons': 'qiztuxpwpxeUL5nsv7UX5EJE',
            'corrections': 'j595yDI5GlklzSIel2gFO2sL',
            'exercises': 'l2G4JvyrpAlGscTOl4Ky1pMT',
            'flashcards': 'wlQAz7BMdcP5yrNKFSiMDW0q',
            'missing-words': 'JQwFSkdtNUEaoUz4K8MODhuU',
            'hidden-words': '',
            'crosswords': '',
            'quizzes': 'lTsOYrJ3i3rnx8B42wknEYnv',
        };

        if(type === undefined) navigate('/preview/lessons', {replace: true});
        if(!(type in rids)) navigate('/preview/lessons', {replace: true});

        setRid(rids[type]);
    }, [type, navigate, setRid]);
    
    
    return (
        <div className='Preview'>
            <div className='menu'>
                <div className='header'>
                    <Link to="/"><img src={home_icon} alt="Back to home" title="Back to home" /></Link>
                    <h1>Try our activities and documents:</h1>
                </div>
                <ul>
                    <li className={type === 'lessons' ? 'active' : 'inactive'}><Link to="/preview/lessons"><img src={institutional_icon} alt="Schools and disctricts only:" title="Schools and disctricts only" /><FileIcon type="pdf" />Lessons</Link></li>
                    <li className={type === 'exercises' ? 'active' : 'inactive'}><Link to="/preview/exercises"><img src={institutional_icon} alt="Schools and disctricts only:" title="Schools and disctricts only" /><FileIcon type="pdf" />Exercise sheets</Link></li>
                    <li className={type === 'corrections' ? 'active' : 'inactive'}><Link to="/preview/corrections"><FileIcon type="pdf" />Corrections</Link></li>
                    <li className={type === 'flashcards' ? 'active' : 'inactive'}><Link to="/preview/flashcards"><FileIcon type="flashcards" />Flashcards</Link></li>
                    <li className={type === 'missing-words' ? 'active' : 'inactive'}><Link to="/preview/missing-words"><FileIcon type="missing-words" />Missing words</Link></li>
                    <li className={type === 'quizzes' ? 'active' : 'inactive'}><Link to="/preview/quizzes"><FileIcon type="quiz" />Quizzes</Link></li>
                    {/* <li className={type === 'hidden-words' ? 'active' : 'inactive'}><Link to="/preview/hidden-words"}><FileIcon type="hidden-words" />Hidden words</Link></li> */}
                    {/* <li className={type === 'crosswords' ? 'active' : 'inactive'}><Link to="/preview/crosswords"}><FileIcon type="crosswords" />Crosswords</Link></li> */}
                </ul>
            </div>
            <div className='preview'>
                {rid === null ?
                    <Loading />
                :
                    <ExplorerDisplay key={rid} rid={rid} auth={false} />
                }
            </div>
        </div>
    );
}