import React from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../API/API';
import StringCleaner from '../../helpers/StringCleaner';
import Popupable from '../Popup/Popupable';

import email_icon from '../../images/icons/email.svg';
import links_icon from '../../images/icons/link.svg';

import './CreateClass.scss';
import useFeedback from '../../hooks/useFeedback';
import useMountAwareState from '../../hooks/useMountAwareState';
export default function CreateClass(props) {

    const cleaner = new StringCleaner().normalizeWhitepaces().trimWhitespaces();

    const feedback = useFeedback();
    const [type, setType] = useMountAwareState('email');
    const [name, setName] = useMountAwareState('');
    const [formError, setFormError] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);
    const [lock, setLock] = useMountAwareState(false);
    const [ tcommon ] = useTranslation('common');
    const [ t ] = useTranslation('main');

    const create = (e) => {
        e.preventDefault();
        setLock(true);
        setFormError(null);
        setError(null);

        const n = cleaner.clean(name);
        if(n.length === 0) {
            setFormError(t('pages.classes.dialogs.create_class.errors.missing_name'));
            setLock(false);
            return;
        }
        if(!['email', 'links'].includes(type)) {
            setFormError(t('pages.classes.dialogs.create_class.errors.invalid_type'));
            setLock(false);
            return;
        }

        API.getInstance()
        .post('/users/self/classes', {
            name: n,
            type: type,
        })
        .then(({status, data}) => {
            if(status === 201) {
                const refreshEvent = new CustomEvent('class-update');
                document.dispatchEvent(refreshEvent);
                feedback.add(t('pages.classes.dialogs.create_class.class_created'));
                props.close(data.pid);
            }else{
                setError(tcommon('error.fetch.default', {code: status}))
            }
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
        });
    };

    return (
        <Popupable title={t('pages.classes.dialogs.create_class.title')}  close={props.close}>
            <form className='CreateClass' onSubmit={create}>
                <div className='type'>
                    <h2>{t('pages.classes.dialogs.create_class.types.title')}:</h2>
                    <div>
                        <label className={type === 'email' ? 'active' : 'inactive'}>
                            <input type="radio" name="type" checked={type === 'email'} value="email" onChange={e => setType('email')} />
                            <img src={email_icon} alt="email" />
                            <p>
                                <span>{t('pages.classes.dialogs.create_class.types.email.description')}</span>
                                <span>{t('pages.classes.dialogs.create_class.types.useful_for')}: {t('pages.classes.dialogs.create_class.types.email.useful_for')}</span>
                            </p>
                        </label>
                        <label className={`${type === 'links' ? 'active' : 'inactive'}`}>
                            <input type="radio" name="type" checked={type === 'links'} value="links" onChange={e => setType('links')} />
                            <img src={links_icon} alt="links" />
                            <p>
                                <span>{t('pages.classes.dialogs.create_class.types.links.description')}</span>
                                <span>{t('pages.classes.dialogs.create_class.types.useful_for')}: {t('pages.classes.dialogs.create_class.types.links.useful_for')}</span>
                            </p>
                        </label>
                    </div>
                </div>
                <div className='name'>
                    <h2>{tcommon('form.name')}:</h2>
                    <div>
                        <input type="text" value={name} onChange={e => setName(e.target.value)} required={true} disabled={lock} placeholder={`${t('pages.classes.dialogs.create_class.name_your_class')}...`} />
                    </div>
                </div>
                {formError ?
                    <div className='error'>{formError}</div>
                : undefined}
                {error ?
                    <div className='error'>{error}</div>
                : undefined}
                <div className='submit'>
                    <input type="submit" value={tcommon('general.create')} disabled={lock} />
                </div>
            </form>
        </Popupable>
    );
}