import React, {  useEffect } from 'react';
import './Counters.scss';
import API from '../../API/API';
import GrowingNumber from '../utils/GrowingNumber';
import { Trans, useTranslation } from 'react-i18next';
import useMountAwareState from '../../hooks/useMountAwareState';
export default function Counters(props) {

    const [stats, setStats] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);
    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    useEffect(() => {
        const emergencyData = {
            resources: {
                total: 270,
                elementary: 105,
                advanced: 165,
                institutional: 270,
            }
        };

        API.getInstance()
        .get('/resources-stats', false)
        .then(({status, data}) => {
            if(status === 200) {
                setStats(data);
            }else{
                setError(true);
                setStats(emergencyData);
            }
        })
        .catch(err => {
            setError(true);
            setStats(emergencyData);
        });
    }, [setError, setStats]);
    
    return (
        <section className='Counters'>
            <div className='total'>
                <span>{t('pages.presentation.counters.title.providing')}</span> 
                <span>
                    {stats ? 
                        <>
                            {error ? '>' : ''}<GrowingNumber n={stats.resources.total} />
                        </>
                    : 
                        <div className='load'></div>
                    } 
                    {tcommon('general.resources').toLowerCase()}
                </span>
                <span>{t('pages.presentation.counters.title.more')}</span>
            </div>
            <div className='counters'>
                <div className='box elementary'>
                    <div className='number'>
                        {stats ? 
                            <>
                                {error ? '>' : ''}<GrowingNumber n={stats.resources.elementary} />
                            </>
                        : 
                            <div className='load'></div>
                        }
                    </div>
                    <div className='text'>
                        <Trans t={t} i18nKey={'pages.presentation.counters.elementary'}>
                            For <b>Elementary subscribers</b>
                        </Trans>
                    </div>
                </div>
                <div className='box advanced'>
                    <div className='number'>
                        {stats ? 
                            <>
                                {error ? '>' : ''}<GrowingNumber n={stats.resources.advanced} />
                            </>
                        : 
                            <div className='load'></div>
                        }
                    </div>
                    <div className='text'>
                        <Trans t={t} i18nKey={'pages.presentation.counters.advanced'}>
                            For <b>Advanced subscribers</b>
                        </Trans>
                    </div>
                </div>
                <div className='box institutional'>
                    <div className='number'>
                        {stats ? 
                            <>
                                {error ? '>' : ''}<GrowingNumber n={stats.resources.institutional} />
                            </>
                        : 
                            <div className='load'></div>
                        }
                    </div>
                    <div className='text'>
                        <Trans t={t} i18nKey={'pages.presentation.counters.schools'}>
                            For <b>Schools and School Districts</b>
                        </Trans>
                        
                    </div>
                </div>
            </div>
        </section>
    );
}