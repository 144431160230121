import React, { useEffect, useRef } from 'react';
import API from '../../API/API';
import './AccountInstitutionInvitation.scss';

import square_ratio from '../../images/square-ratio.svg';

import checked_icon from '../../images/icons/correct.svg';
import cross_icon from '../../images/icons/wrong.svg';
import { useTranslation } from 'react-i18next';
import useFeedback from '../../hooks/useFeedback';
import useMountAwareState from '../../hooks/useMountAwareState';

export default function AccountInstitutionInvitation(props) {

    const feedback = useFeedback();

    const [validated, setValidated] = useMountAwareState(false);
    const [awaiting, setAwaiting] = useMountAwareState(false);
    const [result, setResult] = useMountAwareState(null);
    const [choiceBoxHeight, setChoiceBoxHeight] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);

    const buttonRef = useRef(null);

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    const makeChoice = (choice) => {
        if(validated) return;
        setAwaiting(true);
        setError(null);
        API.getInstance().patch('/users/self/institution-codes/' + props.data.code, {
            choice: choice ? 'accept' : 'deny'
        })
        .then(({status, data}) => {
            setAwaiting(false);
            if(status === 204) {
                setValidated(true);
                setResult(choice ? 'accepted' : 'declined');
                feedback.add(choice ? tcommon('feedback.invitation_accepted') : tcommon('feedback.invitation_declined'));
                props.updateUser();
            }else{
                if(status === 409) {
                    setError(data.message);
                }else{
                    setError(tcommon('error.fetch.default', {code: status}));
                }
            }
        })
        .catch((error) => {
            setError(tcommon('error.general.default'));
            setAwaiting(false);
        });
    };

    useEffect(() => {
        const updateChoiceBoxHeight = () => {
            setChoiceBoxHeight(buttonRef.current.offsetHeight);
        };
        updateChoiceBoxHeight();
        window.addEventListener('resize', updateChoiceBoxHeight);
        return () => {
            window.removeEventListener('resize', updateChoiceBoxHeight);
        }
    }, [setChoiceBoxHeight]);
    
    const data = props.data;
    return (
        <section className="AccountInstitutionInvitation">
            {data.admin ? 
                <h2>{t('pages.account.sections.institution.invitation.as_admin', {name: data.institution.name})}</h2>
            :
                <h2>{t('pages.account.sections.institution.invitation.as_teacher', {name: data.institution.name})}</h2>
            }
            <div className='notes'>
            {t('pages.account.sections.institution.invitation.notes.notes')}:
                <ul>
                    <li>{t('pages.account.sections.institution.invitation.notes.only_one')}</li>
                    <li>
                        {t('pages.account.sections.institution.invitation.notes.if_subscribed')}
                    </li>
                </ul>
            </div>
            <div style={{height: choiceBoxHeight === null ? 'auto' : choiceBoxHeight}} className={`choices${awaiting || result ? ' awaiting' : ''}`}>
                <button ref={buttonRef} className='accept' disabled={validated} onClick={() => {makeChoice(true)}}>{t('pages.account.sections.institution.invitation.choices.accept')}</button>
                <button className='decline' disabled={validated} onClick={() => {makeChoice(false)}}>{t('pages.account.sections.institution.invitation.choices.decline')}</button>
                <div className='feedback' style={{width: choiceBoxHeight === null || result !== null ? 'auto' : choiceBoxHeight}}>
                    {awaiting ? 
                        <>
                            <img width={100} height={100} src={square_ratio} alt="" />
                            <div className='reduced-motion'>{tcommon('general.waiting')}...</div>
                            <div className='waiter'>
                                <div className='dot'></div>
                            </div>
                        </>
                    : undefined}
                    {result !== null ? 
                        <>
                            <div className={`result ${result}`}>
                                <img 
                                    width={result === 'accepted' ? 123 : 89}  height={result === 'accepted' ? 88 : 85} 
                                    src={result === 'accepted' ? checked_icon : cross_icon} 
                                    alt={result}
                                />
                                <div>
                                    {result === 'accepted' ? t('pages.account.sections.invitation.accepted') : t('pages.account.sections.invitation.declined')}
                                </div>
                            </div>
                        </>
                    : undefined}
                </div>
            </div>
            {error ? 
                <div>
                    {error}
                </div>
            : undefined}
        </section>
    );
}