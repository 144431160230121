import React, { useContext } from 'react';
import './Topbar.scss';
import LogoLink from './LogoLink';
import SearchBox from './SearchBox';
import AccountBox from './AccountBox';
import AuthContext from '../../Contexts/AuthContext';
import LoginBox from './LoginBox';
import LanguageBox from './LanguageBox';
import UserContext from '../../Contexts/UserContext';
import SubscribeButton from './SubscribeButton';

export default function Topbar(props) {

    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);

    return (
        <header className={`Topbar ${(authContext.auth && userContext.user !== null && userContext.user.type !== 0) || document.location.pathname !== '/' ? 'auth' : 'ano'}`}>
            <div className='static-top'>
                <LogoLink authDisplay={authContext.auth !== null} />
                {authContext.auth ?
                    <>
                        {userContext.user === null || userContext.user.type === 0 ? 
                            <div className='wrapper'>
                                <SubscribeButton />
                                <AccountBox />
                            </div> 
                        : 
                            <>
                                <SearchBox />
                                <AccountBox />
                            </>
                        }
                        
                    </>
                :
                    <div className='wrapper'>
                        <SubscribeButton />
                        <LoginBox />
                        <LanguageBox />
                    </div>
                }
            </div>
        </header>
    );
}