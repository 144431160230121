import React from 'react';
import './TermsOfService.scss';
export default function TermsOfService(props) {
    
    return (
        <div className='TermsOfService'>
            <h1>Terms of Service</h1>
            
        </div>
    );
}