import React from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../API/API';
import StringCleaner from '../../helpers/StringCleaner';
import useMountAwareState from '../../hooks/useMountAwareState';
import Popupable from '../Popup/Popupable';
import './RenameStudent.scss';
export default function RenameStudent(props) {

    const cleaner = new StringCleaner().normalizeWhitepaces().trimWhitespaces();

    const [firstName, setFirstName] = useMountAwareState(props.student.first_name);
    const [lastName, setLastName] = useMountAwareState(props.student.last_name);
    const [lock, setLock] = useMountAwareState(false);
    const [error, setError] = useMountAwareState(null);
    const [formError, setFormError] = useMountAwareState(null);
    const [ tcommon ] = useTranslation('common');
    const [ t ] = useTranslation('main');

    const rename = (e) => {
        e.preventDefault();
        setLock(true);
        setFormError(null);
        setError(null);

        const fn = cleaner.clean(firstName);
        const ln = cleaner.clean(lastName);
        if(fn.length === 0) {
            setFormError(tcommon('form.error.missing_first_name'));
            setLock(false);
            return;
        }
        if(ln.length === 0) {
            setFormError(tcommon('form.error.missing_last_name'));
            setLock(false);
            return;
        }
        API.getInstance()
        .patch(`/users/self/classes/${props.classPid}/students/${props.student.pid}`, {
            first_name: fn,
            last_name: ln,
        })
        .then(({status, data}) => {
            if(status === 200 || status === 204) {
                document.dispatchEvent(new CustomEvent('student-update'));
                props.close();
            }else{
                setError(tcommon('error.fetch.default', {code: status}))
            }
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
        });
    };
    
    return (
        <Popupable title={t('pages.classes.dialogs.rename_student.title')} close={props.close}>
            <form className='RenameStudent' onSubmit={rename}>
                <div className='name'>
                    <h2>{tcommon('form.first_name')}:</h2>
                    <div>
                        <input type="text" value={firstName} required={true} onChange={e => setFirstName(e.target.value)} disabled={lock} />
                    </div>
                </div>
                <div className='name'>
                    <h2>{tcommon('form.last_name')}:</h2>
                    <div>
                        <input type="text" value={lastName} required={true} onChange={e => setLastName(e.target.value)} disabled={lock} />
                    </div>
                </div>
                {formError ?
                    <div className='error'>{formError}</div>
                : undefined}
                {error ?
                    <div className='error'>{error}</div>
                : undefined}
                <div className='submit'>
                    <input type="submit" value={tcommon('general.rename')} disabled={lock} />
                </div>
            </form>
        </Popupable>
    );
}