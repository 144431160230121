import React, { useContext, useEffect } from 'react';
import './ResourcesRoot.scss';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Presentation from './presentation/Presentation';
import Home from './home/Home';
import Authentication from './authentication/Authentication';
import Login from './authentication/Login';
import Register from './authentication/Register';
import Explorer from './explorer/Explorer';
import ExplorerDisplay from './explorer/ExplorerDisplay';
import ExplorerSearch from './explorer/ExplorerSearch';
import ContextMenuContext from '../Contexts/ContextMenuContext';
import Account from './account/Account';
import RequireAuth from './RequireAuth';
import ErrorPage from './ErrorPage';
import Help from './help/Help';
import UserContext from '../Contexts/UserContext';
import RequestQuote from './subscribe/RequestQuote';
import Feedback from './layout/Feedback';
import WithTopbar from './WithTopbar';
import Shared from './shared/Shared';
import { useTranslation } from 'react-i18next';
import API from '../API/API';
import useAuthDisplay from '../hooks/useAuthDisplay';
import AuthContext from '../Contexts/AuthContext';
import Loading from './Loading';
import Checkout from './checkout/Checkout';
import Preview from './preview/Preview';
import Classes from './classes/Classes';
import ClassDetails from './classes/ClassDetails/ClassDetails';
import ClassManageStudents from './classes/ClassManageStudents/ClassManageStudents';
import ClassManageStudent from './classes/ClassManageStudent/ClassManageStudent';
import SeeStudentResult from './classes/SeeStudentResult/SeeStudentResult';
import ClassManageShares from './classes/ClassManageShares/ClassManageShares';
import ClassManageShare from './classes/ClassManageShare/ClassManageShare';
import SharedUnauthenticated from './shared/SharedUnauthenticated';
import ClassesSummary from './classes/ClassesSummary/ClassesSummary';
import SeeClassResults from './classes/SeeClassResults/SeeClassResults';
import TermsOfService from './legals/TermsOfService';
import PrivacyPolicy from './legals/PrivacyPolicy';
// import ComingSoon from './presentation/ComingSoon';

export default function ResourcesRoot(props){

    const [ t ] = useTranslation('common');
    const { user, setUser } = useContext(UserContext);
    const { auth } = useContext(AuthContext);
    const contextMenuContext = useContext(ContextMenuContext);    
    const authDisplay = useAuthDisplay();
    const location = useLocation();

    useEffect(() => {
        if(!auth) return;
        API.getInstance()
        .get('/users/self')
        .then(({status, data}) => {
            if(status === 200) {
                setUser(data);
            }
        });
    }, [setUser, location, auth]);

    if(auth === true && user === null) return <Loading />;

    return <div className={`ResourcesRoot${authDisplay ? ' auth' : ''}`}>
        <Routes>
            <Route path="/shared">
                <Route path="direct-link/:sharePid" element={<SharedUnauthenticated />} />
                <Route index element={<Shared />} />
            </Route>
            <Route path="/" element={<WithTopbar authDisplay={authDisplay} />}>
                <Route path="/" element={auth ? <Home /> : <Presentation />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/request-a-quote" element={<RequestQuote />} />
                <Route path="/subscribe" element={<Checkout />} />
                <Route path="/preview" element={<Preview />} />
                <Route path="/preview/:type/*" element={<Preview />} />
                <Route path="/" element={<RequireAuth />}>
                    <Route path="explorer" element={<Explorer />}>
                        <Route path="view/:rid/*" element={<ExplorerDisplay />} />
                        <Route path="view/:rid/:subsection/*" element={<ExplorerDisplay />} />
                        <Route path="search" element={<ExplorerSearch />} />
                        <Route path="view/root" element={<Navigate to="/explorer" />} />
                        <Route index element={<ExplorerDisplay rid="root"/>} />
                    </Route>
                    <Route path="/classes" element={<Classes />}>
                        <Route path=":classPid/shares/:sharePid/result-:resultPid" element={<SeeStudentResult source="share" />} />
                        <Route path=":classPid/shares/:sharePid/results-summary" element={<SeeClassResults />} />
                        <Route path=":classPid/shares/:sharePid" element={<ClassManageShare />} />
                        <Route path=":classPid/shares" element={<ClassManageShares />} />
                        <Route path=":classPid/students/:studentPid/result-:resultPid" element={<SeeStudentResult source="student" />} />
                        <Route path=":classPid/students/:studentPid" element={<ClassManageStudent />} />
                        <Route path=":classPid/students" element={<ClassManageStudents />} />
                        <Route path=":classPid" element={<ClassDetails />} />
                        <Route index element={<ClassesSummary />} />
                    </Route>
                    <Route path="account" element={<Account />} />
                    <Route path="help" element={<Help />} />
                </Route>
                <Route path="/" element={<Authentication />}>
                    <Route path="login" element={<Login />} />
                    <Route path="register" element={<Register />} />
                </Route>
                <Route path="*" element={<ErrorPage code={404} error={t('error.page.not_found')} message={t('tips.url_typo')} />} />
            </Route>
        </Routes>
        {contextMenuContext.contextMenu !== null ? contextMenuContext.contextMenu : ''}
        <Feedback />
    </div>;
}