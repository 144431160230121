import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import useMountAwareState from '../../hooks/useMountAwareState';
export default function ParallaxElement(props) {

    //-> To fix firefox display issue on presentation
    const ref = useRef(null); 
    const [ loaded, setLoaded ] = useMountAwareState(true);
    const [ float, setFloat ] = useMountAwareState(false);

    const animDelay = useMemo(() => +(Math.random() * 1.5).toFixed(2) , []);

    const resizeImages = useCallback(() => {
        if(ref.current) {
            const img = ref.current.querySelector('img');
            if(!img) return;
            const { width } = img.getBoundingClientRect();
            ref.current.style.width = Math.round((width / (1 / props.depth))) + 'px';
        }
    },[props.depth]);
    
    useEffect(() => {
        window.addEventListener('resize', resizeImages);
        resizeImages();
        const img = ref.current.querySelector('.container>img');
        let floatTimeoutId = setTimeout(() => setFloat(true), 3000);
        const handleImageLoad = () => {
            setLoaded(true);
            floatTimeoutId = setTimeout(() => setFloat(true), 3000);
        };
        if(img) img.addEventListener('load', handleImageLoad);
    
        return () => {
            window.removeEventListener('resize', resizeImages);
            if(img) img.removeEventListener('load', handleImageLoad);
            if(floatTimeoutId !== null) clearTimeout(floatTimeoutId);
        }
    }, [resizeImages, setFloat, setLoaded]);
    //<- To fix firefox display issue on presentation
    
    const incidence = props.incidence * (props.modificator ?? 0.6);
    return (
        <div 
            ref={ref}
            className={`ParallaxElement ${props.type ?? ''}`}
            style={{
                top: `${props.position.y}%`,
                left: `${props.position.x}%`,
                zIndex: `${props.zIndex}`,
                transform: `scale(${1 / props.depth}) translate(-50%, calc(-50% - ${Math.round(incidence / props.depth)}px))`,
            }}
        >
            <div className={`container ${loaded ? 'img-loaded' : 'img-loading'} ${float ? 'float' : ''}`} style={{animationDelay: `${0.25 + animDelay}s`}}>
                {props.children}
            </div>
        </div>
    );
}