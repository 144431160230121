export default function getPeriodStart(period) {
    if(period === 'all-time') return null;

    let [ valueString = null, unit = null ] = period.split('-');
    if(valueString === null || unit === null) throw new Error(`Invalid period: ${period}`);
    
    const value = parseInt(valueString);
    if(value < 0)  throw new Error(`Period cannot be negative: ${period}`);
    if(value === 0) return new Date();

    const periods = {
        'days': 1000 * 60 * 60 * 24,
        'months': null,
        'hours': 1000 * 60 * 60,
        'minutes': 1000 * 60,
        'seconds': 1000,
   };

   if(!unit.endsWith('s')) unit = `${unit}s`;
   if(periods[unit] === undefined) throw new Error(`Invalid unit: ${period}`);

    const today = new Date();
    switch (unit) {
        case 'seconds':
            return new Date(today.getTime() - (periods.seconds * value));
        case 'minutes':
            return new Date(today.getTime() - (periods.minutes * value));
        case 'hours':
            return new Date(today.getTime() - (periods.hours * value));
        case 'days':
            return new Date(today.getTime() - (periods.days * value));
        case 'months':
            let month = today.getMonth() - value;
            const targetMonth = month < 0 ? month + 12 : month;

            let targetDate = new Date(today.getFullYear(), month, today.getDate());
            while(targetDate.getMonth() !== targetMonth) {
                targetDate.setDate(targetDate.getDate() - 1);
            }

            return targetDate;
        default:
            throw new Error('Impossible case');
    }
};