import React, { useEffect, useState } from "react";
import BookletShortcutsButton from "./BookletShortcutsButton";
import "./BookletShortcutsExplorer.scss";
import BookletShortcutsLinks from "./BookletShortcutsLinks";

export default function BookletShortcutsExplorer(props) {
  const { booklet } = props;
  const [activeLesson, setActiveLesson] = useState("");

  useEffect(() => {
    if (booklet.lessons) {
      setActiveLesson(Object.keys(booklet.lessons)[0]);
    }
  }, [booklet]);

  return (
    <section className="BookletShortcutsExplorer">
      <header>
        <div className="controls">
          <button
            onClick={() => props.setIndex("booklets")}
            className="btn-yellow"
          >
            <div className="arrow">&#9650;</div>back
          </button>
          <h2>{booklet.name}</h2>
        </div>
        <div className="tabs">
          {Object.keys(booklet.lessons || {}).map((rid) => (
            <BookletShortcutsButton
              handleClick={() => setActiveLesson(rid)}
              active={activeLesson === rid}
              key={rid}
            >
              {booklet.lessons[rid].name}
            </BookletShortcutsButton>
          ))}
        </div>
      </header>
      {booklet.lessons && (
        <BookletShortcutsLinks links={booklet.lessons[activeLesson]} />
      )}
    </section>
  );
}
