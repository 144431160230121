import React, { useEffect } from 'react';
import './Documents.scss';

import separator_top from '../../images/waves/separator-top1.svg';
import separator_bottom from '../../images/waves/separator-bottom1.svg';
import fade_right from '../../images/waves/fade-right.svg';
import institution_icon from '../../images/icons/institution.svg';
import ZoomableImage from '../utils/ZoomableImage';

import lesson_full_img from '../../images/presentation/documents/lesson_full.webp';
import lesson_thumbnail_img from '../../images/presentation/documents/lesson_thumbnail.webp';
import correction_full_img from '../../images/presentation/documents/correction_full.webp';
import correction_thumbnail_img from '../../images/presentation/documents/correction_thumbnail.webp';
import exercise_sheet_full_img from '../../images/presentation/documents/exercise_sheet_full.webp';
import exercise_sheet_thumbnail_img from '../../images/presentation/documents/exercise_sheet_thumbnail.webp';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMountAwareState from '../../hooks/useMountAwareState';

export default function Documents(props) {

    const [deploy, setDeploy] = useMountAwareState(false);
    const [ t ] = useTranslation('main');

    useEffect(() => {
        const checkScroll = () => {
            const scrollY = window.scrollY;
            const trigger = document.querySelector('.img-group');

            const triggerPosition = trigger.offsetTop + (window.innerHeight / 2) + (trigger.offsetHeight / 2);

            if(scrollY >= triggerPosition) {
                setDeploy(true);
                document.removeEventListener('scroll', checkScroll);
            }
        };

        document.addEventListener('scroll', checkScroll);
        return () => {
            document.removeEventListener('scroll', checkScroll);
        }
    }, [setDeploy])
    
    return (
        <section className='Documents'>
            <img className='background' width={336} height={600} src={fade_right} alt="" />
            <img className='separator top' width={1440} height={288} src={separator_top} alt="" />
            <div className='content'>
                <div className="text">
                    <div className="text-wrapper">
                        <h2>{t('pages.presentation.documents.title')}</h2>
                        <p></p>
                        <ul>
                            <li><span>{t('pages.presentation.documents.list.corrections.key')}</span>: {t('pages.presentation.documents.list.corrections.value')}</li>
                            <li><span className="asterisk"><img width={287} height={287} src={institution_icon} alt="Available for institutions:" />{t('pages.presentation.documents.list.lessons.key')}</span>: {t('pages.presentation.documents.list.lessons.value')}</li>
                            <li><span className="asterisk"><img width={287} height={287} src={institution_icon} alt="Available for institutions:" />{t('pages.presentation.documents.list.exercises.key')}</span>: {t('pages.presentation.documents.list.exercises.value')}</li>
                        </ul>
                        <div className='disclaimer'>
                            <img width={287} height={287} src={institution_icon} alt="Icon schools:" />: {t('pages.presentation.documents.list.school_disclaimer')}
                        </div>
                        <div className='try-it'>
                            <Link to="/preview/lessons" className='btn-yellow'>{t('pages.presentation.try_it')}</Link>
                        </div>
                    </div>
                </div>
                <div className="illustration">
                    <div className={`img-group${deploy ? ' deploy' : ''}`}>
                        <ZoomableImage width={835} height={1080} className="img1" fullSrc={lesson_full_img} thumbnailSrc={lesson_thumbnail_img} alt="" />
                        <ZoomableImage width={835} height={1080} className="img2" fullSrc={correction_full_img} thumbnailSrc={correction_thumbnail_img} alt="" />
                        <ZoomableImage width={835} height={1080} className="img3" fullSrc={exercise_sheet_full_img} thumbnailSrc={exercise_sheet_thumbnail_img} alt="" />
                    </div>
                </div>
            </div>
            <img className='separator bottom' width={1440} height={320} src={separator_bottom} alt="" />
        </section>
    );
}