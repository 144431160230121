import StringCleaner from "./StringCleaner";

export default class Searcher {
    constructor(query) {
        this.stringCleaner = new StringCleaner()
                                .trimWhitespaces()
                                .normalizeWhitepaces()
                                .removeAccents()
                                .toLowerCase()
                                .removeSpecialChars()
        ;
        this.query = this.stringCleaner.clean(query);
    }

    getResourceScore = (resource) => {
        const cleanName = this.stringCleaner.clean(resource.name);
        const splitCleanName = cleanName.split(' ');
        const keywords = [...splitCleanName, ...resource.keywords, resource.type].filter((v, i, a) => a.indexOf(v) === i || !isNaN(v));
        const queryWords = this.query.split(' ');

        let score = 0.0;
        let continuity = true;

        for (let i = 0; i < queryWords.length; i++) {
            const word = queryWords[i];
            const wordIsNumber = !isNaN(word);
            if(i < queryWords.length - 1 || wordIsNumber) {
                // If a number and its preceding word appear as "word number", add 2 points
                if(wordIsNumber && i > 0) {
                    if(keywords.includes(`${queryWords[i-1]} ${word}`)) score+=2;
                }
                if(!keywords.includes(word)) return 0.0;
                else score++;
            }else{
                if(keywords.filter(k => k.startsWith(word)).length === 0) return 0.0;
                else score+=0.5;
            }
            // As long as query and name match from start, add extra point for each word
            if(continuity && word === queryWords[i]) {
                score++;
            }else{
                continuity = false;
            }
            
        }
        if(cleanName.startsWith(this.query)) score+= queryWords.length; 

        score *= queryWords.length / keywords.length;

        return score;
    };

    queryIn = (resources) => {
        return resources.map((resource) => [this.getResourceScore(resource), resource])
                .filter(val => val[0] > 0)        
                .sort((a, b) => {
                    if(a[0] < b[0]) return 1;
                    else if(a[0] > b[0]) return -1;
                    return 0;
                })
                .map(val => val[1]);
    }
}

/* 
type -> partial match 1p | full match 2p
name -> each word 
keywords
*/
