import React, {  useEffect, useMemo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../API/API';
import StringCleaner from '../../../helpers/StringCleaner';
import ErrorPage from '../../ErrorPage';
import Loading from '../../Loading';
import './ClassStudentsList.scss';
import StudentLine from './StudentLine';
import search_icon from '../../../images/icons/search.svg';
import InviteStudent from './InviteStudent';
import AddStudent from './AddStudent';
import useMountAwareState from '../../../hooks/useMountAwareState';
import PopupContext from '../../../Contexts/PopupContext';
export default function ClassStudentsList(props) {
    
    const [students, setStudents] = useMountAwareState(null);
    const [query, setQuery] = useMountAwareState('');
    const [error, setError] = useMountAwareState(null);
    const [errorCode, setErrorCode] = useMountAwareState(null);
    const { setPopup } = useContext(PopupContext);

    const filteredStudents = useMemo(() => {
        if(students === null || query === '') return students;
        const cleaner = new StringCleaner().normalizeWhitepaces().removeAccents().trimWhitespaces().toLowerCase();
        const cleanQuery = cleaner.clean(query);
        return students.filter(s => cleaner.clean(`${s.first_name} ${s.last_name}`).includes(cleanQuery));
    }, [query, students]);
    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');
    const classPid = props.classPid;

    const fetchStudents = useCallback(
        () => {
            setError(null);
            setErrorCode(null);
            API.getInstance()
            .get(`/users/self/classes/${classPid}/students`)
            .then(({status, data}) => {
                if(status === 200) {
                    setStudents(data);
                }else if(status === 404) {
                    setError(tcommon('error.page.not_found'));
                    setErrorCode(404);
                }else{
                    setError(tcommon('error.fetch.default', {code: status}));
                }
            })
            .catch(err => {
                setError(tcommon('error.general.default'));
            });
        },
        [classPid, setError, setErrorCode, setStudents, tcommon],
    );

    const openInvitePopup = () => {
        if(props.class?.type === 'links') {
            setPopup(<AddStudent classPid={classPid} close={() => setPopup(null)} />);
        }else{
            setPopup(<InviteStudent classPid={classPid} close={() => setPopup(null)} />);
        }
    };

    useEffect(() => {
        fetchStudents();

        document.addEventListener('student-update', fetchStudents);
        return () => {
            document.removeEventListener('student-update', fetchStudents);
        };
    }, [fetchStudents]);
    
    if(error) return <ErrorPage code={errorCode ?? undefined} error={error} />;
    if(students === null) return <Loading>Fetching students list</Loading>;
    return (
        <div className='ClassStudentsList'>
            <div className='header'>
                <div className='search'>
                    <label>
                        <img src={search_icon} alt="" />
                        <input type="search" value={query} onChange={e => setQuery(e.target.value)} placeholder={`${tcommon('general.search')}...`} />
                    </label>
                </div>
                <button onClick={openInvitePopup}>
                    {props.class.type === 'email' ? 
                        t('pages.classes.manage_students.invite_student')
                    :
                        t('pages.classes.manage_students.add_student')
                    }
                </button>
            </div>
            <div className='list'>
                <div className='table-overflow-wrapper'>
                    <table>
                        <thead>
                            <tr>
                                <td>{tcommon('general.student')}</td>
                                <td>{tcommon('general.email')}</td>
                                <td>{tcommon('general.score')}</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredStudents.length === 0 ?
                                <tr className='empty'>
                                    <td colSpan='4'>
                                        {query === '' ?
                                            t('pages.classes.class_details.previews.no_students')
                                        :
                                            tcommon('search.no_match', {query})
                                        }
                                    </td>
                                </tr>
                            :
                                filteredStudents.map((student, i) => {
                                    return <StudentLine
                                                key={student.pid} 
                                                student={student} 
                                                classPid={props.classPid}
                                                setPopup={setPopup} 
                                                setError={setError}  
                                                fetchStudents={fetchStudents}
                                    />;
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}