import React from 'react';
import './FlashcardsListening.scss';

import FlashcardsListeningLangSelection from './FlashcardsListeningLangSelection';
import FlashcardsListeningGame from './FlashcardsListeningGame';
import FlashcardsResults from '../FlashcardsResults';

export default class FlashcardsListening extends React.Component {
    constructor(props) {
        super(props);
        
        this.cards = this.props.data.cards;

        this.state = {
            lang: 'en',
            cards: null,
            shuffleCount: 0,
            stage: 0,
            previousStage: null,
            results: null
        };

        this.selectLanguage = this.selectLanguage.bind(this);
        this.setResults = this.setResults.bind(this);
        this.reset = this.reset.bind(this);
    }

    selectLanguage(lang) {
        this.setState({
            lang: lang,
            cards: this.shuffleCards(this.cards),
            shuffleCount: this.state.shuffleCount + 1
        }, () => {
            this.setStage(1);
        });
    }

    setStage(stage) {
        this.setState({
            previousStage: this.state.stage,
            stage: stage
        });
    }

    setResults(results) {
        this.setState({
            results: results
        }, () => {
            this.setStage(2);
        });
    }

    reset() {
        this.setStage(0);
    }

    shuffleCards(cards) {
        for (var i = cards.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = cards[i];
            cards[i] = cards[j];
            cards[j] = temp;
        }
        return cards;
    }

    render() {
        const stage = this.state.stage;
        const previousStage = this.state.previousStage;

        return <div className='FlashcardsListening'>
            {(stage === 0 || previousStage === 0) && 
                <FlashcardsListeningLangSelection 
                    setLang={this.selectLanguage}
                    animateIn={stage === 0 && previousStage != null}
                    animateOut={previousStage === 0}
                />
            }
            {(stage === 1 || previousStage === 1) && 
                <FlashcardsListeningGame 
                    key={this.state.shuffleCount}
                    cards={this.state.cards}
                    lang={this.state.lang}
                    reset={this.reset}
                    setResults={this.setResults}
                    animateIn={stage === 1}
                    animateOut={previousStage === 1}
                />
            }
            {(stage === 2 || previousStage === 2) && 
                <FlashcardsResults
                    results={this.state.results}
                    lang={this.state.lang}
                    reset={this.reset}
                    animateIn={stage === 2}
                    animateOut={previousStage === 2}
                />
            }
            
        </div>;
    }
}