import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import API from '../../../API/API';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useMountAwareState from '../../../hooks/useMountAwareState';
import ErrorPage from '../../ErrorPage';
import Loading from '../../Loading';
import ClassesBreadcrumb from '../ClassesBreadcrumb';
import './ClassManageShares.scss';
import ClassSharesList from './ClassSharesList';
export default function ClassManageShares(props) {
    
    const [classData, setClassData] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);
    const [errorCode, setErrorCode] = useMountAwareState(null);
    const [ tcommon ] = useTranslation('common');
    const { classPid } = useParams();

    useEffect(() => {
        setError(null);
        setErrorCode(null);
        API.getInstance()
        .get(`/users/self/classes/${classPid}`)
        .then(({status, data}) => {
            if(status === 200) {
                setClassData(data);
            }else if(status === 404) {
                setError(tcommon('error.page.not_found'));
                setErrorCode(404);
            }else{
                setError(tcommon('error.fetch.default', {code: status}));
            }
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
        });
    }, [classPid, setClassData, setError, setErrorCode, tcommon]);
    
    useDocumentTitle(classData === null ? 'Loading' : `${classData.name} shares`);
    
    if(error) return <ErrorPage code={errorCode ?? undefined} error={error} />;
    if(classData === null) return <Loading>Fetching class data</Loading>;
    return (
        <section className='ClassManageShares'>
            <header>
                <ClassesBreadcrumb links={[
                    {to: `/classes/${classPid}`, label: classData.name},
                    {to: `/classes/${classPid}/shares`, label: 'Shares'},
                ]} />
            </header>
            <main>
                <ClassSharesList classPid={classPid} />
            </main>
        </section>
    );
}