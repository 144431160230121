import React, { useMemo } from 'react';
import './HiddenWordsHighlight.scss';
export default function HiddenWordsHighlight({h, div}) {

    // const [rotation, setRotation] = useMountAwareState(0);

    // useEffect(() => {
    //     const incrementRotation = () => {
    //         setRotation(curr => {
    //             curr++;
    //             if(curr >= 360) curr = 0;
    //             return curr;
    //         });
    //     };
        
    //     const id = setInterval(incrementRotation, 10);
    //   return () => {
    //     clearInterval(id);
    //   }
    // }, []);
    

    const style = useMemo(() => {
        switch (h.direction) {
            case 'lr':
                return {
                    height: `${div}%`,
                    width: `${h.length * div}%`,
                    top: `${div * h.y}%`,
                    left: `${div * h.x}%`,
                };
            case 'rl':
                return {
                    height: `${div}%`,
                    width: `${h.length * div}%`,
                    top: `${div * h.y}%`,
                    left: `${div * (h.x - (h.length - 1))}%`
                };
            case 'tb':
                return {
                    width: `${div}%`,
                    height: `${h.length * div}%`,
                    top: `${div * h.y}%`,
                    left: `${div * h.x}%`
                };
            case 'bt':
                return {
                    width: `${div}%`,
                    height: `${h.length * div}%`,
                    top: `${div * (h.y - (h.length - 1))}%`,
                    left: `${div * h.x}%`
                };
            case 'tlbr':
                return {
                    width: `${div}%`,
                    height: `${(h.length) * div * 1.41421356237}%`,
                    top: `${(div * h.y)}%`,
                    left: `${((div * (h.x - 0.5)))}%`,
                    transformOrigin: "50% 0",
                    transform: `rotateZ(-45deg)`,
                };
            case 'bltr':
                return {
                    width: `${div}%`,
                    height: `${(h.length) * div * 1.41421356237}%`,
                    top: `${(div * (h.y + 1))}%`,
                    left: `${((div * (h.x  - 0.5)))}%`,
                    transformOrigin: "50% 0",
                    transform: `rotateZ(-135deg)`,
                };
            case 'trbl':
                return {
                    width: `${div}%`,
                    height: `${(h.length) * div * 1.41421356237}%`,
                    top: `${div * h.y}%`,
                    left: `${((div * (h.x + 0.5)))}%`,
                    transformOrigin: "50% 0%",
                    transform: `rotateZ(45deg)`,
                };
            case 'brtl':
                return {
                    width: `${div}%`,
                    height: `${(h.length) * div * 1.41421356237}%`,
                    top: `${div * (h.y + 1)}%`,
                    left: `${((div * (h.x + 0.5)))}%`,
                    transformOrigin: "50% 0%",
                    transform: `rotateZ(135deg)`,
                };
            default:
                break;
        }
        return undefined;
    }, [h, div]);

    return (
        <div    
            className={`HiddenWordsHighlight ${h.direction}`}
            style={style}
        ></div>
    );
}