import { useRef, useEffect } from 'react'

function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);
  const titleSuffix = 'WAEE French Portal';

  useEffect(() => {
    document.title = `${title.slice(0, 50)}${title.length >= 53 ? '...' : ''} - ${titleSuffix}`;
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [prevailOnUnmount]);
}

export default useDocumentTitle;