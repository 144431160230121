import React from 'react';
import Cookies from 'js-cookie';
import './CookieConsent.scss';
import CookiePreferencesManager from './CookiePreferencesManager';
import useCookieConsent from '../hooks/useCookieConsent';
import { Trans, useTranslation } from 'react-i18next';
import useMountAwareState from '../hooks/useMountAwareState';
export default function CookieConsent(props) {

    const [openPreferences, setOpenPreferences] = useMountAwareState(false);
    const { list } = useCookieConsent();
    const [ t ] = useTranslation('main');

    const setPreferences = (functionality = false, tracking = false, marketing = false) => {
        setOpenPreferences(false);
        
        props.setCookieConsent({
            functionality: functionality,
            tracking: tracking,
            marketing: marketing
        });

        Cookies.set(
            'CookieConsent',
            parseInt([
                marketing ? 1 : 0,
                tracking ? 1 : 0,
                functionality ? 1 : 0,
            ].join(''), 2),
            {
                expires: 180,
                domain: process.env.REACT_APP_DOMAIN.split('https://')[0],
                secure: true,
                sameSite: 'strict',
            }
        );

        if(functionality && tracking && marketing) return;

        let cookiesToRemove = [];
        let localStorageToRemove = []

        if(!functionality) {
            cookiesToRemove = cookiesToRemove.concat(list.cookies.functionality);
            localStorageToRemove = localStorageToRemove.concat(list.localStorage.functionality);
        }
        if(!tracking) {
            cookiesToRemove = cookiesToRemove.concat(list.cookies.tracking);
            localStorageToRemove = localStorageToRemove.concat(list.localStorage.tracking);
        }
        if(!marketing) {
            cookiesToRemove = cookiesToRemove.concat(list.cookies.marketing);
            localStorageToRemove = localStorageToRemove.concat(list.localStorage.marketing);
        }
        cookiesToRemove.forEach(element => {
            Cookies.remove(element);
        });
        localStorageToRemove.forEach(element => {
            localStorage.removeItem(element);
        });
    };
    
    if(props.cookieConsent !== null) return null;
    return (
        <>
            <section className='CookieConsent'>
                <div className='text'>
                    <h1>{t('cookie_consent.title')}</h1>
                    <p>
                        {t('cookie_consent.text.explication')}<br/>
                        <Trans t={t} i18nKey="cookie_consent.text.change_preferences">
                            You can change your preferences any time by visiting <a href={`${process.env.REACT_APP_DOMAIN}/cookie-consent`}>https://portal.waeelearning.com/cookie-consent</a> (link 
                            available in the footer of our <a href={`${process.env.REACT_APP_DOMAIN}`}>home page</a>)
                        </Trans>
                    </p>
                    <div className='choices'>
                        <button onClick={() => setPreferences(true, true, false)}>{t('cookie_consent.buttons.agree')}</button>
                        <button onClick={() => setPreferences(false, false, false)}>{t('cookie_consent.buttons.decline')}</button>
                        <button onClick={() => setOpenPreferences(true)}>{t('cookie_consent.buttons.manage_preferences')}</button>
                    </div>
                </div>
                {openPreferences ?
                    <CookiePreferencesManager cookieConsent={props.cookieConsent} setPreferences={setPreferences} />
                : undefined}
            </section>
        </>
    );
}