import React from 'react';
import { useTranslation } from 'react-i18next';
export default function Greetings(props) {

    const [ t ] = useTranslation('main');

    const getMessage = () => {
        const d = new Date();
        if(d.getHours() < 12) return t('pages.home.greetings.morning', {name: props.name});
        else if(d.getHours() < 18) return t('pages.home.greetings.afternoon', {name: props.name});
        else return t('pages.home.greetings.evening', {name: props.name});
    };

    return (<div className='Greetings'>
        {getMessage()}
    </div>);
}