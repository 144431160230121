import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../API/API';
import Loading from '../../Loading';
import InviteTeacherForm from './InviteTeacherForm';
import WarningPopup from '../../Popup/WarningPopup';
import './TeacherManagement.scss';
import UserContext from '../../../Contexts/UserContext';
import useFeedback from '../../../hooks/useFeedback';
import useMountAwareState from '../../../hooks/useMountAwareState';
import PopupContext from '../../../Contexts/PopupContext';

export default function TeacherManagement(props) {

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    const [teachers, setTeachers] = useMountAwareState(null);
    const [invitedTeachers, setInvitedTeachers] = useMountAwareState(null);
    const [search, setSearch] = useMountAwareState('');
    const { setPopup } = useContext(PopupContext);

    const userContext = useContext(UserContext);

    const feedback = useFeedback();

    const removeTeacher = (teacher) => {
        API.getInstance().delete(`/institutions/${userContext.user.institution.pid}/teachers/${teacher.pid}`)
        .then(({status, data}) => {
            if(status === 200) {
                fetchTeachers();
                feedback.add(tcommon(feedback.teacher_removed));
            }
        });
    };

    const disableInstitutionCode = (code) => {
        API.getInstance().patch(`/institutions/${userContext.user.institution.pid}/institution-codes/${code.code}`, {
            disabled: true
        })
        .then(({status, data}) => {
            if(status === 200) {
                fetchTeachers();
                feedback.add(tcommon('feedback.invitation_revoked'));
            }
        });
    };

    const openRemoveTeacherBox = (teacher) => {
        setPopup(
            <WarningPopup 
                title={tcommon('warning.are_you_sure')}
                text={t('pages.account.sections.institution.institutional.teachers_management.about_to_remove_teacher', {first_name: teacher.first_name, last_name: teacher.last_name})}
                close={closeRemoveTeacherBox}
                cancel={undefined}
                confirm={() => {removeTeacher(teacher)}}
            />
        );
    };

    const openDeleteInstitutionCodeBox = (code) => {
        setPopup(
            <WarningPopup 
                title={tcommon('warning.are_you_sure')}
                text={t('pages.account.sections.institution.institutional.teachers_management.about_to_revoke_invitation', {first_name: code.user.first_name, last_name: code.user.last_name})}
                close={closeRemoveTeacherBox}
                cancel={undefined}
                confirm={() => {disableInstitutionCode(code)}}
            />
        );
    };

    const closeRemoveTeacherBox = () => {
        setPopup(undefined);
    };

    const changeSearch = (e) => {
        setSearch(e.target.value);
    };

    const fetchTeachers = useCallback(() => {
        API.getInstance().get(`/institutions/${userContext.user.institution.pid}/teachers`)
        .then(({status, data}) => {
            if(status === 200) {
                setTeachers(data);
            }
        });
        API.getInstance().get(`/institutions/${userContext.user.institution.pid}/institution-codes?open-only`)
        .then(({status, data}) => {
            if(status === 200) {
                setInvitedTeachers(data);
            }
        });
    }, [setInvitedTeachers, setTeachers, userContext.user.institution.pid]);

    useEffect(() => {
        fetchTeachers();

        return () => {
            //cleanup
        }
    }, [fetchTeachers]);

    const getList = () => {
        if(teachers === null || invitedTeachers === null) return null;
        const list = [].concat(
            invitedTeachers.filter((code) => {
                if(search === '') return true;
                else{
                    return `${code.user.first_name} ${code.user.last_name}`.toLowerCase().includes(search.toLowerCase());
                }
            })
            .map((code, i) => {
                const teacher = code.user;
                return {
                    identity: `${teacher.first_name} ${teacher.last_name} (${tcommon('general.pending')})`,
                    button: <button className='btn-yellow' onClick={() => {openDeleteInstitutionCodeBox(code)}}>{tcommon('context_menu.remove')}</button>
                };
            }),
            teachers.filter((teacher) => {
                if(search === '') return true;
                else{
                    return `${teacher.first_name} ${teacher.last_name}`.toLowerCase().includes(search.toLowerCase());
                }
            })
            .map((teacher, i) => {
                return {
                    identity: `${teacher.first_name} ${teacher.last_name}`,
                    button: <button className='btn-yellow' onClick={() => {openRemoveTeacherBox(teacher)}}>{tcommon('context_menu.remove')}</button>
                };
            })
        );
        return list;
    };

    const list = getList();
    return (
        <div className='TeacherManagement'>
            <div>{t('pages.account.sections.institution.institutional.admin.teachers')}:</div>
            <section className='teachers'>
                <header>
                    <input type="search" placeholder={`${tcommon('general.search')}...`} value={search} onChange={changeSearch} />
                </header>
                <main>
                    <ul className='list'>
                        {list === null ?
                            <Loading />
                        :
                            (list.length === 0 ? 
                                (search !== '' ?
                                    <div className='empty'>{t('pages.account.sections.institution.institutional.teachers_management.no_teacher_match')}</div>
                                :
                                    <div className='empty'>{t('pages.account.sections.institution.institutional.teachers_management.no_teacher')}</div>
                                )
                            :
                                list.map((teacher, i) => {
                                    return <React.Fragment key={`teacher${i}-${teacher.identity}`}>
                                        <li>
                                            <div className='name'>{teacher.identity}</div>
                                            <div className='actions'>{teacher.button}</div>
                                        </li>
                                        <div className='separator'><div></div></div>
                                    </React.Fragment>;
                                })
                            )
                        }
                    </ul>
                </main>
                <footer>
                    <InviteTeacherForm fetchTeachers={fetchTeachers}/>
                </footer>
            </section>
        </div>
    );
}