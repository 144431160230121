import React from 'react';
import useMountAwareState from '../../hooks/useMountAwareState';
import './Collapsible.scss';
export default function Collapsible(props) {

    const [collapse, setCollapse] = useMountAwareState(props.defaultCollapsed ?? true);
    
    return (
        <div className={`Collapsible ${collapse ? 'collapse' : 'retract'}`}>
            <div className='header'>
                <button className='collapse-button' onClick={() => {setCollapse(!collapse)}}>
                    <div className='chevron'>&lt;</div>
                    {props.header}
                </button>
            </div>
            <div className='content'>
                {props.children}
            </div>
        </div>
    );
}