import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import API from '../../API/API';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useMountAwareState from '../../hooks/useMountAwareState';
import ErrorPage from '../ErrorPage';
import Loading from '../Loading';
import SharedTopBar from './layout/SharedTopBar';
import './Shared.scss';
import SharedDisplay from './SharedDisplay';
export default function Shared(props) {

    let [ searchParams ] = useSearchParams();

    const token = searchParams.get('t');

    const [data, setData] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);

    useDocumentTitle(`Shared: ${data === null ? 'loading...' : `[${data.resource.type}] ${data.resource.name}`}`);

    useEffect(() => {
        if(token) {
            API.getInstance()
            .get(`/resource-shares/${token}/data`, false)
            .then(({status, data}) => {
                if(status === 200) {
                    setData(data);
                }else{
                    setError(data.message);
                }
            })
            .catch((error) => {
                setError('Something went wrong');
            });
        }
    
        return () => {
            
        }
    }, [setData, setError, token]);
    
    if(!token) return <ErrorPage code={404} error="This link is not valid" />;
    if(error !== null) return <ErrorPage error={error} />
    if(data === null) return <Loading/>;

    return (
        <div className='Shared'>
            <SharedTopBar token={token} data={data} />
            <div className='content'>
                <SharedDisplay token={token} resource={data.resource} share={data.share} />
            </div>
        </div>
    );
}