import React from 'react';
import './FlashcardsReading.scss';

import FlashcardsReadingGame from './FlashcardsReadingGame';
import FlashcardsResults from '../FlashcardsResults';

export default class FlashcardsReading extends React.Component {
    constructor(props) {
        super(props);
        
        this.cards = this.props.data.cards;

        this.state = {
            lang: props.lang,
            cards: this.shuffleCards(this.cards),
            shuffleCount: 0,
            stage: 1,
            previousStage: null,
            results: null
        };

        this.selectLanguage = this.selectLanguage.bind(this);
        this.setResults = this.setResults.bind(this);
        this.reset = this.reset.bind(this);
    }

    selectLanguage(lang) {
        this.setState({
            lang: lang,
            cards: this.shuffleCards(this.cards),
            shuffleCount: this.state.shuffleCount + 1
        }, () => {
            this.setStage(1);
        });
    }

    setStage(stage) {
        this.setState({
            previousStage: this.state.stage,
            stage: stage
        });
    }

    setResults(results) {
        this.setState({
            results: results
        }, () => {
            this.setStage(2);
        });
    }

    reset() {
        this.selectLanguage(this.state.lang);
    }

    shuffleCards(cards) {
        let copy = [...cards];
        for (var i = copy.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = copy[i];
            copy[i] = copy[j];
            copy[j] = temp;
        }
        return copy;
    }

    render() {
        const stage = this.state.stage;
        const previousStage = this.state.previousStage;

        return <div className='FlashcardsReading'>
            {(stage === 1 || previousStage === 1) && 
                <FlashcardsReadingGame 
                    key={this.state.shuffleCount}
                    cards={this.state.cards}
                    lang={this.state.lang}
                    reset={this.reset}
                    setResults={this.setResults}
                    animateIn={stage === 1}
                    animateOut={previousStage === 1}
                />
            }
            {(stage === 2 || previousStage === 2) && 
                <FlashcardsResults
                    results={this.state.results}
                    lang={this.state.lang}
                    reset={this.reset}
                    animateIn={stage === 2}
                    animateOut={previousStage === 2}
                />
            }
            
        </div>;
    }
}