import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import useFeedback from '../../../hooks/useFeedback';
import FileIcon from '../../FileIcon';
import TimeDiffDisplay from '../../utils/TimeDiffDisplay';
import ScoreDisplay from '../ScoreDisplay';
import './ClassManageShareInfo.scss';
import RetrieveShareEmailLinks from './RetrieveShareEmailLinks';
import copy_icon from '../../../images/icons/copy.svg';
import PopupContext from '../../../Contexts/PopupContext';
export default function ClassManageShareInfo(props) {

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');
    const feedback = useFeedback();
    const { classPid, sharePid } = useParams();
    const { setPopup } = useContext(PopupContext);

    const copyShareLink = e => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_DOMAIN}/shared/direct-link/${props.share.pid}`);
        feedback.add(tcommon('feedback.link.copied'));
    };

    const openRetrieveEmailLinksPopup = () => {
        setPopup(<RetrieveShareEmailLinks sharePid={sharePid} classPid={classPid} close={() => setPopup(null)}/>);
    };
    
    return (
        <div className='ClassManageShareInfo'>
            <div className='score'>
                <h2>{t('pages.classes.manage_share.class_score')}:</h2>
                <ScoreDisplay score={props.share.average_score} />
                {props.share.average_score >= 0 ?
                    <div className='see-results-summary'>
                        <Link to={`/classes/${props.share.class.pid}/shares/${props.share.pid}/results-summary`}>
                            See results summary
                        </Link>
                    </div>
                : undefined}
            </div>
            <div className='list'>
                <h2>{tcommon('general.informations')}:</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>{tcommon('general.resource')}:</td>
                            <td>
                                <Link to={`/explorer/view/${props.share.resource.rid}`}>
                                    <FileIcon type={props.share.resource.type} /> {props.share.resource.name}
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>{tcommon('general.created')}:</td>
                            <td>
                                {new Date(props.share.creation_date * 1000).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric', hour12: true, hour: 'numeric', minute: 'numeric'})}
                            </td>
                        </tr>
                        <tr>
                            <td>{tcommon('general.due_on')}:</td>
                            <td>
                                {new Date(props.share.due_date * 1000).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric', hour12: true, hour: 'numeric', minute: 'numeric'})}
                                <TimeDiffDisplay to={new Date(props.share.due_date * 1000)} beforeSuffix={'ago'} afterSuffix={'left'} updateInterval={1000} /> 
                            </td>
                        </tr>
                        <tr>
                            <td>{t('pages.classes.manage_share.received_results')}:</td>
                            <td>
                                {props.share.students_with_results.length} / {props.share.students_count_at_sending}
                            </td>
                        </tr>
                        {props.share.class.type === 'links' ?
                            <tr>
                                <td>{tcommon('general.link')}:</td>
                                <td>
                                    <div className='link'>
                                        <input type="text" onFocus={e => e.target.select()} readOnly={true} value={`${process.env.REACT_APP_DOMAIN}/shared/direct-link/${props.share.pid}`} />
                                        {navigator.clipboard ?
                                            <button onClick={copyShareLink}>
                                                <img src={copy_icon} alt={tcommon('general.copy')} title={tcommon('general.copy')} />
                                            </button>
                                        : undefined }
                                    </div>
                                </td>
                            </tr>
                        : 
                            <tr>
                                <td>{t('pages.classes.manage_share.a_student_did_not_receive')}:</td>
                                <td>
                                    <div className='link'>
                                        <button onClick={openRetrieveEmailLinksPopup}>{t('pages.classes.manage_share.retrieve_it_here')}</button>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}