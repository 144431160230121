import { createContext } from 'react';

const ContextMenuContext = createContext({
    contextMenu: null,
    setContextMenu: (val) => {},
});

export const ContextMenuProvider = ContextMenuContext.Provider;
export const ContextMenuConsumer = ContextMenuContext.Consumer;

export default ContextMenuContext;