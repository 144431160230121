import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useFocusTrap from '../../hooks/useFocusTrap';
import Dot3 from '../utils/Dot3';
import './LoggingOut.scss';
export default function LoggingOut(props) {

    const [ t ] = useTranslation('main');
    const focusTrapRef = useFocusTrap();

    const timePeriod = useMemo(() => {
        const d = new Date();
        if(d.getHours() < 18) return 'day';
        else return 'evening';
    }, []);
    
    return (
        <div ref={focusTrapRef} className='LoggingOut'>
            <div className='message'>
                <h1>{t(`layout.logout.logging_out`)}<Dot3 /></h1>
                <p>{t(`layout.logout.messages.${timePeriod}`)}</p>
            </div>
            <div tabIndex={0}>{/* For the focus trap to work */}</div>
        </div>
    );
}