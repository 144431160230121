import React, { useEffect } from 'react';
import useMountAwareState from '../../../../hooks/useMountAwareState';
import HiddenWordsChar from './HiddenWordsChar';
import './HiddenWordsGrid.scss';
import HiddenWordsHighlight from './HiddenWordsHighlight';

export default function HiddenWordsGrid(props) {

    const [selectionStart, setSelectionStart] = useMountAwareState(null);
    const [validated, setValidated] = useMountAwareState(props.grid.map(v => new Array(v.length).fill(false)));
    const [highlights, setHighlights] = useMountAwareState([]);

    useEffect(() => {
      console.log(props.words);
    }, [props.words])
    

    const getWordBetweenPoints = (start, end) => {
        let points = [];
        let direction = null;
        let reversedDirection = null;
        if(start.x === end.x) {
            if(end.y < start.y) {
                // Vertical bottom to top
                direction = 'bt';
                reversedDirection = 'tb';
                for (let y = start.y; y >= end.y ; y--) {
                    points.push({
                        letter: props.grid[y][start.x],
                        coords: {
                            x: start.x,
                            y: y
                        }
                    });
                }
            }else{
                // Vertical tpp to bottom
                direction = 'tb';
                reversedDirection = 'bt';
                for (let y = start.y; y <= end.y ; y++) {
                    points.push({
                        letter: props.grid[y][start.x],
                        coords: {
                            x: start.x,
                            y: y
                        }
                    });
                }
            }
        }else if(start.y === end.y) {
            if(end.x < start.x) {
                // Horizontal right to left
                direction = 'rl';
                reversedDirection = 'rl';
                for (let x = start.x; x >= end.x ; x--) {
                    points.push({
                        letter: props.grid[start.y][x],
                        coords: {
                            x: x,
                            y: start.y
                        }
                    });
                }
            }else{
                // Horizontal left to right
                direction = 'lr';
                reversedDirection = 'rl';
                for (let x = start.x; x <= end.x ; x++) {
                    points.push({
                        letter: props.grid[start.y][x],
                        coords: {
                            x: x,
                            y: start.y
                        }
                    });
                }
            }
        }else{
            if(end.x > start.x && end.y > start.y) {
                // Diagonal top-left to bottom-right
                direction = 'tlbr';
                reversedDirection = 'brtl';
                for (let x = start.x; x <= end.x ; x++) {
                    points.push({
                        letter: props.grid[start.y - (start.x - x)][x],
                        coords: {
                            x: x,
                            y: start.y - (start.x - x)
                        }
                    });
                }
            }else if(end.x < start.x && end.y > start.y) {
                // Diagonal top-right to bottom-left
                direction = 'trbl';
                reversedDirection = 'bltr';
                for (let x = start.x; x >= end.x ; x--) {
                    points.push({
                        letter: props.grid[start.y + (start.x - x)][x],
                        coords: {
                            x: x,
                            y: start.y + (start.x - x)
                        }
                    });
                }

            }else if(end.x > start.x && end.y < start.y) {
                // Diagonal bottom-left to top-right
                direction = 'bltr';
                reversedDirection = 'trbl';
                for (let y = start.y; y >= end.y ; y--) {
                    points.push({
                        letter: props.grid[y][start.x + (start.y - y)],
                        coords: {
                            x: start.x + (start.y - y),
                            y: y
                        }
                    });
                }
            }else if(end.x < start.x && end.y < start.y) {
                // Diagonal bottom-right to top-left
                direction = 'brtl';
                reversedDirection = 'tlbr';
                for (let y = start.y; y >= end.y ; y--) {
                    points.push({
                        letter: props.grid[y][start.x - (start.y - y)],
                        coords: {
                            x: start.x - (start.y - y),
                            y: y
                        }
                    });
                }
            }else{
                console.log('error');
            }
        }
        return {points, direction, reversedDirection};
    };

    const endSelection = (x, y) => {
        const { points, direction, reversedDirection } = getWordBetweenPoints(selectionStart, {x, y});
        const word = points.map(v => v.letter).join("").toLowerCase();
        const reversedWord = points.map(v => v.letter).reverse().join("").toLowerCase();

        let validated = null;
        if(props.words.includes(word) && !props.validatedWords.includes(word)) validated = {word, direction};
        else if (props.words.includes(reversedWord)&& !props.validatedWords.includes(reversedWord)) validated = {word: reversedWord, direction: reversedDirection};
        
        if(validated !== null) {
            setValidated(curr => {
                points.forEach(point => {
                    curr[point.coords.y][point.coords.x] = true;
                });
                return curr;
            });
            props.addValidatedWord(validated.word);
            console.log('validated', validated.word);
            setHighlights(curr => [...curr, {
                x: validated.direction === direction ? selectionStart.x : x,
                y: validated.direction === direction ? selectionStart.y : y,
                length: validated.word.length,
                direction: validated.direction
            }]);
        }else{
            console.log('nothing to validate', word, reversedWord);
        }
        setSelectionStart(null);
    };


    const handleClick = (e, x, y) => {
        if(selectionStart === null) {
            setSelectionStart({x, y});
        }else{
            if(x === selectionStart.x && y === selectionStart.y) {
                setSelectionStart(null);
            }else{
                endSelection(x, y);
            }
        }
    };

    const handleKeyDown = (e) => {
        if(e.code === 'Escape') {
            if(selectionStart) {
                setSelectionStart(null);
            }
        }
    };

    const div = 100 / props.grid.length;

    return <div className='HiddenWordsGrid' onKeyDown={handleKeyDown}>
        <div className='highlights'>
            {highlights.map((h, i) => {
                return <HiddenWordsHighlight h={h} key={i} div={div} />;
            })}
        </div>
        {props.grid.map((e, i) => {
            return <div className="line" key={i}>
                {e.map((f, j) => {
                    return <HiddenWordsChar 
                                key={j} 
                                x={j}
                                y={i}
                                onClick={(e) => handleClick(e, j, i)}
                                locked={validated[i][j]}
                                selected={selectionStart !== null && selectionStart.x === j && selectionStart.y === i}
                            >
                                {f}
                            </HiddenWordsChar>;
                })}
            </div>;
        })}
    </div>;
}