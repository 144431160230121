import React, { useMemo } from 'react';
import Collapsible from '../../../../utils/Collapsible';
import './FlashcardsClassResultsViewer.scss';
import ratio from '../../../../../images/flashcard-ratio.svg';
import ScoreDisplay from '../../../ScoreDisplay';
import { useTranslation } from 'react-i18next';
import CircularPercentage from '../../../CircularPercentage';
export default function FlashcardsClassResultsViewer(props) {

    const [ t ] = useTranslation('main');

    const digest = useMemo(() => {
        if(props.share.options.mode === "lrn") return props.digest;
        const sorters = {
            'scoreAsc': (a, b) => {
                return a.analyze.score - b.analyze.score;
            },
            'scoreDesc': (a, b) => {
                return b.analyze.score - a.analyze.score;
            },
            'no': (a, b) => 0,
        };

        return [...props.digest].sort(sorters[props.sort ?? 'no'] ?? sorters['no']);
    }, [props.digest, props.share.options.mode, props.sort]);

    const mode = (props.share.options?.mode ?? 'ref').split('');
    const face = mode[1] === 'f' ? 'back' : 'front';

    return (
        <div className='FlashcardsClassResultsViewer'>
            {props.share.options.mode === "lrn" ?
                <div className='reads'>
                    <CircularPercentage value={(props.digest.reads / props.share.students_count_at_sending) * 100} noText={true} />
                    <div className='count'>{props.digest.reads} / {props.share.students_count_at_sending} students have read</div>
                </div>
            :
                <div className='breakdown'>
                    {digest.map((c, i) => {
                        return <Collapsible key={i} header={<h2>{c.card.front.text} - {c.card.back.text}</h2>}>
                            <div className='fc-digest'>
                                <div className='card'>
                                    <div key={i} className="flashcard">
                                        <img width={1778} height={1069} src={ratio} className="ratio" alt="" />
                                        <div className='display'>
                                            <div className='number'>
                                                <div>{i+1}/{digest.length}</div>
                                            </div>
                                            <div className='content'>
                                                {face === 'front' && c.card.front?.image !== undefined ?
                                                    <img src={c.card.front.image} alt="" /> 
                                                : undefined}
                                                <span>{c.card[face].text}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='analyze'>
                                    <div className='correct'>
                                        <h3>{t('pages.classes.class_results.correct_answers')}:</h3>
                                        <ScoreDisplay score={c.analyze.score} emptyColor={'#eb4034'} colors={{0: '#34c95c'}} />
                                    </div>
                                    <div className='wrong'>
                                        <h3>{t('pages.classes.class_results.wrong_answers')}:</h3>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td>{t('pages.classes.class_results.count')}</td>
                                                    <td>{t('pages.classes.class_results.answer')}</td>
                                                    <td>{t('pages.classes.class_results.freq')}</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(c.analyze.wrong.counts).map((key) => {
                                                    return <tr key={key}>
                                                        <td>{c.analyze.wrong.counts[key]}</td>
                                                        <td>{key}</td>
                                                        <td>{Math.round(100 * c.analyze.wrong.frequencies[key])}%</td>
                                                    </tr>;
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Collapsible>;
                    })}
                </div>
            }
        </div>
    );
}