import React from "react";
import "./BookletShortcutsButton.scss";
export default function BookletShortcutsButton(props) {
  const hanldeClick = () => {
    props.handleClick();
  };

  return (
    <button
      className={`BookletShortcutsButton tab ${props.active ? "active" : ""}`}
      onClick={hanldeClick}
    >
      <span className="text">{props.children}</span>
    </button>
  );
}
