import React from 'react';
import useGrowingNumber from '../../hooks/useGrowingNumber';
import './GrowingNumber.scss';
export default function GrowingNumber(props) {
    
    const n = useGrowingNumber(props.n);
    return (
        <span className='GrowingNumber'>
            <span className='placeholder'>
                {props.n}
            </span>
            <span className='n'>
                {n}
            </span>
        </span>
    );
}