import React, {  useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import AuthContext from '../../Contexts/AuthContext';
import UserContext from '../../Contexts/UserContext';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useMountAwareState from '../../hooks/useMountAwareState';
import Loading from '../Loading';
import './Checkout.scss';
import CheckoutAuthentication from './CheckoutAuthentication';
import CheckoutSelection from './CheckoutSelection';
import CheckoutSuccess from './CheckoutSuccess';
export default function Checkout(props) {

    useDocumentTitle('Subscribe');

    const [step, setStep] = useMountAwareState(0);
    const { user } = useContext(UserContext);
    const { auth } = useContext(AuthContext);
    let [ searchParams, setSearchParams ] = useSearchParams();

    useEffect(() => {
        if(auth && user !== null) {
            if(searchParams.has('status')) {
                const status = searchParams.get('status');
                if(status === 'success') {
                    setStep(3);
                }else if(status === 'cancelled'){
                    setStep(2);
                }else{
                    searchParams.delete('status');
                    setSearchParams(searchParams);
                    setStep(2);
                }
            }else {
                setStep(2);
            };
        }else{
            setStep(1);
        }
    }, [user, auth, searchParams, setSearchParams, setStep]);
    
    return (
        <div className='Checkout'>
            <div className='background'></div>
            <div className='content'>
                <div className='step-indicator'>
                    <div className={`step ${step === 1 ? 'active' : (step > 1 ? 'previous' : '')}`}>
                        <div className='number'>1</div>
                        <div>Authentication</div>
                    </div>
                    <div className={`separator ${step > 1 ? 'active' : ''}`}></div>
                    <div className={`step ${step === 2 ? 'active' : (step > 2 ? 'previous' : '')}`}>
                        <div className='number'>2</div>
                        <div>Selection</div>
                    </div>
                    <div className={`separator ${step > 2 ? 'active' : ''}`}></div>
                    <div className={`step ${step === 3 ? 'active' : (step > 3 ? 'previous' : '')}`}>
                        <div className='number'>3</div>
                        <div>Payment</div>
                    </div>
                </div>
                <div className='current'>
                    {step === 0 ? <Loading/> : undefined}
                    {step === 1 ? <CheckoutAuthentication next={() => setStep(2)} /> : undefined}
                    {step === 2 ? <CheckoutSelection next={() => setStep(3)} /> : undefined}
                    {step === 3 ? <CheckoutSuccess previous={() => {setStep(2)}} searchParams={searchParams} /> : undefined}
                </div>
            </div>
        </div>
    );
}