import React, { useEffect } from 'react';
export default function Printer(props) {

    useEffect(() => {
        window.onafterprint = () => props.close();
        window.print();
    }, [props]);
    
    
    return (
        <div className='print Printer'>
            <button className='close' onClick={() => props.close()}>
                Close print page
            </button>
            {props.children}
        </div>
    );
}