import { createContext } from 'react';

const AuthContext = createContext({
    auth: false,
    setAuth: (val) => {},
});

export const AuthProvider = AuthContext.Provider;
export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;