import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useMountAwareState from '../hooks/useMountAwareState';
import './CookiePreferencesManager.scss';
export default function CookiePreferencesManager(props) {

    const [functionality, setFunctionality] = useMountAwareState(false);
    const [tracking, setTracking] = useMountAwareState(false);
    const [marketing,] = useMountAwareState(false);
    const [ t ] = useTranslation('main');
    
    return (
        <div className='CookiePreferencesManager'>
            <div>
                <h2>{t('cookie_consent.preferences_management.title')}</h2>
                <ul>
                    <li>
                        <h3>{t('cookie_consent.preferences_management.categories.necessary.title')}</h3>
                        <p>{t('cookie_consent.preferences_management.categories.necessary.explication')}</p>
                        <label className='selection lock-checked'>
                            <div className="selector lock-checked">
                                <div className='cursor'></div>
                                <span className='checkmark'>&#10003;</span>
                            </div>
                            <span>{t('cookie_consent.preferences_management.categories.necessary.always_on')}</span>
                        </label>
                    </li>
                    <li>
                    <h3>{t('cookie_consent.preferences_management.categories.functionality.title')}</h3>
                        <p>{t('cookie_consent.preferences_management.categories.functionality.explication')}</p>
                        <label className='selection'>
                            <input type="checkbox" checked={functionality} onChange={e => setFunctionality(e.target.checked)} />
                            <div className={`selector ${functionality ? 'checked' : 'unchecked'}`}>
                                <div className='cursor'></div>
                                <span className='cross'>&#10005;</span>
                                <span className='checkmark'>&#10003;</span>
                            </div>
                        </label>
                    </li>
                    <li>
                    <h3>{t('cookie_consent.preferences_management.categories.tracking.title')}</h3>
                        <p>{t('cookie_consent.preferences_management.categories.tracking.explication')}</p>
                        <label className='selection'>
                            <input type="checkbox" checked={tracking} onChange={e => setTracking(e.target.checked)} />
                            <div className={`selector ${tracking ? 'checked' : 'unchecked'}`}>
                                <div className='cursor'></div>
                                <span className='cross'>&#10005;</span>
                                <span className='checkmark'>&#10003;</span>
                            </div>
                        </label>
                    </li>
                    <li>
                        <h3>{t('cookie_consent.preferences_management.learn_more.title')}</h3>
                        <p>
                            <Trans t={t} i18nKey="cookie_consent.preferences_management.learn_more.see_privacy_policy">
                                See our <a rel="noreferrer noopener" href="https://waeelearning.com/privacy-policy">Privacy Policy</a>
                            </Trans>
                        </p>
                    </li>
                </ul>
                <div className='save-container'>
                    <button onClick={() => props.setPreferences(functionality, tracking, marketing)}>Save my preferences</button>
                </div>
            </div>
        </div>
    );
}