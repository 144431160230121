import jwt_decode from "jwt-decode";

export default class API {
    constructor() {
        this.queue = [];
        this.baseUrl = `${process.env.REACT_APP_API_HOST}/api`;
        this.refreshRequest = null;
        this.cookieConsent = 0;
        API.instance = this;
    }

    setCookieConsent(value) {
        this.cookieConsent = value;
    }

    static getInstance() {
        if(!API.instance) {
            API.instance = new API();
        }
        return API.instance;
    }

    async post (url = '', data = {}, provideKey = true, askRelogin = true) {
        const response = await fetch(this.baseUrl + url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: await this.getHeaders(provideKey),
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
        if(process.env.NODE_ENV === 'development') {
            const requestEvent = new CustomEvent('dev-new-request', {
                detail: {
                    url: url,
                    status: response.status,
                    headers: response.headers
                }
            });
            document.dispatchEvent(requestEvent);
        }
        if(response.status === 401 && askRelogin) {
            document.dispatchEvent(new CustomEvent('must_login'));
            return await this.waitForReLogIn()
            .then(() => {
                return this.post(url, data, provideKey, false);
            });
        }
        return {status: response.status, data: await response.json()};
    }

    async patch(url = '', data = {}, provideKey = true, askRelogin = true) {
        const response = await fetch(this.baseUrl + url, {
            method: 'PATCH',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: await this.getHeaders(provideKey),
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
        if(process.env.NODE_ENV === 'development') {
            const requestEvent = new CustomEvent('dev-new-request', {
                detail: {
                    url: url,
                    status: response.status,
                    headers: response.headers
                }
            });
            document.dispatchEvent(requestEvent);
        }
        if(response.status === 401 && askRelogin) {
            document.dispatchEvent(new CustomEvent('must_login'));
            return await this.waitForReLogIn()
            .then(() => {
                return this.patch(url, data, provideKey, false);
            });
        }
        return {status: response.status, data: response.status === 204 ? null : await response.json()};
    }

    async put(url = '', data = {}, provideKey = true, askRelogin = true) {
        const response = await fetch(this.baseUrl + url, {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: await this.getHeaders(provideKey),
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
        if(process.env.NODE_ENV === 'development') {
            const requestEvent = new CustomEvent('dev-new-request', {
                detail: {
                    url: url,
                    status: response.status,
                    headers: response.headers
                }
            });
            document.dispatchEvent(requestEvent);
        }
        if(response.status === 401 && askRelogin) {
            document.dispatchEvent(new CustomEvent('must_login'));
            return await this.waitForReLogIn()
            .then(() => {
                return this.put(url, data, provideKey, false);
            });
        }
        return {status: response.status, data: await response.json()};
    }

    async get(url = '', provideKey = true, askRelogin = true) {
        const response = await fetch(this.baseUrl + url, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: await this.getHeaders(provideKey),
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        });
        if(process.env.NODE_ENV === 'development') {
            const requestEvent = new CustomEvent('dev-new-request', {
                detail: {
                    url: url,
                    status: response.status,
                    headers: response.headers
                }
            });
            document.dispatchEvent(requestEvent);
        }
        if(response.status === 401 && askRelogin) {
            document.dispatchEvent(new CustomEvent('must_login'));
            return await this.waitForReLogIn()
            .then(() => {
                return this.get(url, provideKey, false);
            });
        }
        return {status: response.status, data: await response.json(), headers: response.headers};
    }

    async file(url = '', provideKey = true, askRelogin = true) {
        const response = await fetch(this.baseUrl + url, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: await this.getHeaders(provideKey),
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        });
        if(process.env.NODE_ENV === 'development') {
            const requestEvent = new CustomEvent('dev-new-request', {
                detail: {
                    url: url,
                    status: response.status,
                    headers: response.headers
                }
            });
            document.dispatchEvent(requestEvent);
        }
        if(response.status === 401 && askRelogin) {
            document.dispatchEvent(new CustomEvent('must_login'));
            return await this.waitForReLogIn()
            .then(() => {
                return this.file(url, provideKey, false);
            });
        }
        return {status: response.status, data: await response.blob()};
    }

    async delete (url = '', provideKey = true, askRelogin = true) {
        const response = await fetch(this.baseUrl + url, {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: await this.getHeaders(provideKey),
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        });
        if(process.env.NODE_ENV === 'development') {
            const requestEvent = new CustomEvent('dev-new-request', {
                detail: {
                    url: url,
                    status: response.status,
                    headers: response.headers
                }
            });
            document.dispatchEvent(requestEvent);
        }
        if(response.status === 401 && askRelogin) {
            document.dispatchEvent(new CustomEvent('must_login'));
            return await this.waitForReLogIn()
            .then(() => {
                return this.delete(url, provideKey, false);
            });
        }
        return {status: response.status, data: await response.json()};
    }

    async login(credentials) {
        return await this.post('/login', credentials, false, false)
        .then(({status, data}) => {
            if(status === 201) {
                localStorage.setItem('User', data.user);
                localStorage.setItem('AccessToken', data.token);
            }
            return {status: status, data: data.token};
        })
        .catch((error) => {
            return null;
        });
    }

    async getHeaders(provideKey = true) {
        let headers = {
            'Content-Type': 'application/json',
            'X-COOKIE-CONSENT-LEVEL': this.cookieConsent 
        };

        if(provideKey) {
            const token = await this.getAccessToken();
            if(token === null) throw new Error('must_login');
            headers['X-AUTH-TOKEN'] = token;
        }

        return headers;
    }

    tokenIsValid(token) {
        let payload = jwt_decode(token); 
        return payload.exp && (payload.exp * 1000 > Date.now());
    }

    async getAccessToken(requestLogin = true) {
        let token = localStorage.getItem('AccessToken');
        if(token && token !== 'undefined' && this.tokenIsValid(token)) return token;

        // No valid token, refresh
        if(this.refreshRequest === null) this.refreshRequest = this.get('/refresh-token', false);
        token = await this.refreshRequest
                .then(({status, data}) => {
                    this.refreshRequest = null;
                    if(status === 201) {
                        localStorage.setItem('AccessToken', data.token);
                        return data.token;
                    }else{
                        if(requestLogin) document.dispatchEvent(new CustomEvent('must_login'));
                    }
                    return null;
                })
                .catch((error) => {
                    this.refreshRequest = null;
                    if(requestLogin) document.dispatchEvent(new CustomEvent('must_login'));
                });
        if(token) return token;
        return null;
    }

    async isLoggedIn() {
        try {
            return (null !== await this.getAccessToken(false));
        }catch(error){
            return false;
        }
    }

    async logout() {
        return await this.get('/logout', true)
            .then(({status, data}) => {
                if(status === 200) {
                    localStorage.removeItem('AccessToken');
                }
                return {status: status, data: null};
            })
        ;
    }

    async waitForReLogIn() {
        return new Promise((resolve) => {
            const listener = () => {
                document.removeEventListener('log_in', listener);
                resolve();
            };
            document.addEventListener('log_in', listener);
        });
    }
}