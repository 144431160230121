import React from 'react';
import './HelpAssistedTextInput.scss';
import AssistedTextInput from '../assistedInput/AssistedTextInput';
import icon from '../../images/icons/assisted-input.svg';
import { Trans, useTranslation } from 'react-i18next';
export default function HelpAssistedTextInput(props) {
    const accentsMap = {
        'E': {
            '/': 'É',
            '\\': 'È',
            '`': 'È',
            '^': 'Ê',
            '"': 'Ë',
            ':': 'Ë',
        },
        'A': {
            '\\': 'À',
            '`': 'À',
            '^': 'Â',
        },
        'U': {
            '^': 'Û',
            '\\': 'Ù',
            '`': 'Ù',
            '"': 'Ü',
            ':': 'Ü',
        },
        'I': {
            '"': 'Ï',
            ':': 'Ï',
            '^': 'Î',
        },
        'O': {
            '^': 'Ô',
        },
        'C': {
            ',': 'Ç',
        },
    };

    const [ t ] = useTranslation('main');
    
    return (
        <section className={`HelpAssistedTextInput ${props.short ?? false ? 'short' : ''}`} id="assisted-input">
            {(props.title ?? true) ?
                <header>
                    <h2>{t('pages.help.assisted_input.title')}</h2>
                </header>
            : undefined}
            <main>
                <div>
                    <Trans t={t} i18nKey="pages.help.assisted_input.text">
                        Assisted inputs allow you to easily type characters used in French but not in English.<br/>
                        When using it, you will see the icon <img width={680} height={680} src={icon} alt="You are using an assisted input" /> at the bottom left corner of the window.<br/>
                        To write a special character, simply type the key character followed by the letter you want to apply it to.<br />
                        For example: <span className='char'>/e</span> would be replaced by <span className='char'>é</span>.<br />
                    </Trans>
                    {(props.input ?? true) ? <div>{t('pages.help.assisted_input.try')}: <AssistedTextInput /></div> : undefined}
                </div>
                <ul>
                    {Object.keys(accentsMap).map((key) => {
                        return <li key={key}>
                            <span className='key'>{key}</span>
                            <table>
                                <tbody>
                                    {Object.keys(accentsMap[key]).map((specialChar) => {
                                        return <tr key={specialChar}>
                                            <td><div><span className='char'>{accentsMap[key][specialChar]}</span></div></td>
                                            <td><div>:</div></td>
                                            <td><div><span className='char'>{specialChar}</span></div></td>
                                            <td><div>+</div></td>
                                            <td><div><span className='char'>{key}</span></div></td>
                                        </tr>;
                                    })}
                                </tbody>
                            </table>
                        </li>;
                    })}
                </ul>
            </main>
        </section>
    );
}