import React, { useContext } from 'react';
import './AccountSecurityConnection.scss';
import computer_icon from '../../../images/icons/computer.svg';
import mobile_icon from '../../../images/icons/mobile.svg';
import unknown_icon from '../../../images/icons/unknown-device.svg';
import DateDiff from 'date-diff';
import { getRelevantDiffUnit } from '../../../helpers/DateDiffHelper';
import { useTranslation } from 'react-i18next';
import parser from 'ua-parser-js';
import API from '../../../API/API';
import useFeedback from '../../../hooks/useFeedback';
import ContextMenu from '../../ContextMenu';
import ContextMenuContext from '../../../Contexts/ContextMenuContext';
export default function AccountSecurityConnection(props) {

    const { setContextMenu } = useContext(ContextMenuContext);
    const [ t ] = useTranslation('main');
    const translationPrefix = 'pages.account.sections.security';
    const [ tcommon ] = useTranslation('common');
    const feedback = useFeedback();

    const logout = () => {
        setContextMenu(null);
        if(props.connection.is_current) return;
        API.getInstance()
        .patch(`/users/self/active-connections/${props.connection.pid}`, {invalidated: true})
        .then(({status, data}) => {
            if(status === 200) {
                props.updateConnections();
            }else{
                feedback.add(tcommon('error.fetch.default', {code: status}));
                feedback.add('Could not log you out');
            }
        })
        .catch(err => {
            feedback.add(tcommon('error.general.default'));
            feedback.add('Could not log you out');
        });
    };

    const getContextMenu = (position) => {
        return <ContextMenu position={position}>
            <button onClick={logout} disabled={props.connection.is_current}>{t(`${translationPrefix}.connections.logout`)}</button>
        </ContextMenu>;
    };

    const handleContextMenu = (e) => {
        e.preventDefault();
        const position = {
            x: e.pageX,
            y: e.pageY
        };
        setContextMenu(
            getContextMenu(position)
        );
    };


    const ua = parser(props.connection.user_agent);
    const type = ua.device?.type ?? 'computer';
    const browser = ua.browser?.name ?? 'Unknown';
    const os = ua.os?.name ?? 'Unknown';

    const now = new Date();
    const lastUdpdateDiff = new DateDiff(new Date(props.connection.creation_date * 1000), now);
    const lastUdpdateDiffRelevantUnit = getRelevantDiffUnit(lastUdpdateDiff);

    return (
        <div className='AccountSecurityConnection' onContextMenu={handleContextMenu}>
            {props.connection.is_current ? 
                <div className='is-current' title={t(`${translationPrefix}.connections.current`)}>Current</div> 
            : 
                <div className='options'>
                    <button className='dotdotdot' onClick={handleContextMenu}>
                        <div>&#9679;&#9679;&#9679;</div>
                    </button>
                </div>
            }
            <div className='type'>
                {type === 'computer' ?
                    <img width={620} height={620} src={computer_icon} alt="Device type: Computer" />
                :
                    (type === 'mobile' ?
                        <img width={620} height={620} src={mobile_icon} alt="Device type: Mobile" />
                    :
                        <img width={620} height={620} src={unknown_icon} alt="Device type: Unknown" />
                    )
                }
            </div>
            <div className='os'><b>{t('pages.account.sections.security.connections.system')}:</b> {os}</div>
            <div className='browser'><b>{t('pages.account.sections.security.connections.browser')}:</b> {browser}</div>
            <div className='last-update'>
                <b>{t('pages.account.sections.security.connections.last_updated')}: </b>
                <span title={new Date(props.connection.creation_date * 1000).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric', hour12: true, hour: 'numeric', minute: 'numeric'})}>
                    {tcommon(`duration.ago`, {count: Math.abs(Math.round(lastUdpdateDiffRelevantUnit.value)), unit: lastUdpdateDiffRelevantUnit.unit})}
                </span>
            </div>
            <div className='first-connection'><b>{t('pages.account.sections.security.connections.connected_since')}:</b> {new Date(props.connection.first_connection_date * 1000).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric', hour12: true, hour: 'numeric', minute: 'numeric'})}</div>
        </div>
    );
}