import React, {  useEffect ,useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useMountAwareState from '../../../../hooks/useMountAwareState';
import correct_icon from '../../../../images/icons/correct.svg';
import wrong_icon from '../../../../images/icons/wrong.svg';
export default function QuizMultiChoice(props) {

    const [answer, setAnswer] = useMountAwareState(props.answer ?? []);
    const [ t ] = useTranslation('main');
    const isCorrect = useMemo(() => props.verifier(answer, props.module.answer), [answer, props]);

    useEffect(() => {
        const reset = (e) => {
            if(e.detail !== props.rid) return;
            setAnswer([]);
        };

        document.addEventListener('quiz-reset', reset);
        return () => {
            document.removeEventListener('quiz-reset', reset);
        }
    }, [props.rid, setAnswer]);
    
    const handleChange = (e) => {
        if(e.target.checked) {
            setAnswer([...answer, e.target.value]);
            props.setAnswer([...answer, e.target.value]);
        }else{
            const newAnswer = answer.filter(v => v !== e.target.value);
            setAnswer(newAnswer);
            props.setAnswer(newAnswer.length === 0 ? null : newAnswer);   
        }
    };

    const m = props.module;
    return (
        <div className='QuizMultiChoice quizz-module'>
            <header>
                <h2>{props.id + 1}. {m.text}</h2>
                <div className='result'>
                    {props.showAnswers ?
                        (isCorrect ?
                            <img width={123} height={88} src={correct_icon} alt="correct" />
                        :
                            <img width={89} height={85} src={wrong_icon} alt="wrong" />
                        )
                    : undefined}
                </div>
            </header>
            {props.error !== null ?
                <div className='error'>
                    {t(`pages.explorer.apps.quiz.errors.${m.type}.${props.error}`)}
                </div>
            : undefined}
            <div className='widget multichoice'>
                {m.choices.map((c, i) => {
                    return <label key={i} className={`${props.showAnswers ? (m.answer.includes(c) ? 'correct' : 'incorrect') : ''} ${answer.includes(c) ? 'checked' : ''}`}>
                        <input 
                            type="checkbox" 
                            name={props.id} 
                            onChange={handleChange} 
                            value={c} 
                            readOnly={props.showAnswers}
                            disabled={props.showAnswers}
                            checked={answer.includes(c)}
                        />
                        <span>{c}</span>
                    </label>;
                })}
            </div>
        </div>
    );
}