import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Collapsible from '../../../../../utils/Collapsible';
import ScoreDisplay from '../../../../ScoreDisplay';
export default function QuizConjugationResults(props) {

    const [ t ] = useTranslation('main');

    const c = props.c;
    const i = props.i;
    const text = props.text;
    const answer = props.answer;
    const pronouns = useMemo(() => props?.pronouns ?? ["Je", "Tu", "Il / Elle / On", "Nous", "Vous", "Ils / Elles"], [props?.pronouns]);

    const list = useMemo(() => {
        let list = {};
        pronouns.forEach(pronoun => {
            list[pronoun] = {};
        });
        Object.keys(c.wrongs).forEach(key => {
            const elem = JSON.parse(key);
            elem.forEach((a, i) => {
                const pronoun = pronouns[i];
                if(a in list[pronoun]) list[pronoun][a]++;
                else list[pronoun][a] = 1;
            });
        });
        // if correct answer does not appear, add it to the list with 0 wrong answer
        answer.forEach((a, i) => {
            if(!(a in list[pronouns[i]])) list[pronouns[i]][a] = 0;
        });
        return list;
    }, [answer, c.wrongs, pronouns]);

    return (
        <Collapsible header={<h2>#{i} {text}</h2>}>
            <div className='quiz-digest'>
                <div className='analyze'>
                    <div className='correct'>
                        <h3>{t('pages.classes.class_results.correct_answers')}:</h3>
                        <div>
                            <ul>
                                {answer.map((a, i) => <li key={i}>{a}</li>)}
                            </ul>
                        </div>
                        <ScoreDisplay score={Math.round((c.corrects_count / (c.wrongs_count + c.corrects_count)) * 100)} emptyColor={'#eb4034'} colors={{0: '#34c95c'}} />
                    </div>
                    <div className='wrong'>
                        <h3>{t('pages.classes.class_results.answers')}:</h3>
                        <table className='conjugationresults'>
                            <thead>
                                <tr>
                                    <td>{t('pages.classes.class_results.pronoun')}</td>
                                    <td>{t('pages.classes.class_results.count')}</td>
                                    <td>{t('pages.classes.class_results.answer')}</td>
                                    <td>{t('pages.classes.class_results.freq')}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(list).map((pronoun, i) => {
                                    const elem = list[pronoun];
                                    const keys = Object.keys(elem);
                                    return keys.map((a, j) => {
                                        const correct = a === answer[i];
                                        const count = elem[a] + (correct ? c.corrects_count : 0);
                                        return <tr key={j} style={{borderBottom: j === keys.length - 1 ? '1px solid #000000aa' : undefined}}>
                                            {j === 0 ? <td style={{fontWeight: 'bold', borderRight: '1px solid #e4e4e4'}} rowSpan={keys.length}>{pronoun}</td> : undefined}
                                            <td className={correct ? 'correct' : 'wrong'}>{count}</td>
                                            <td className={correct ? 'correct' : 'wrong'}>{a}</td>
                                            <td className={correct ? 'correct' : 'wrong'}>{Math.round((count / (c.wrongs_count + c.corrects_count)) * 100)}%</td>
                                        </tr>;
                                    })
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Collapsible>
    );
}