import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Collapsible from '../../../../../utils/Collapsible';
import ScoreDisplay from '../../../../ScoreDisplay';
export default function QuizMultiChoiceGridResults(props) {

    const [ t ] = useTranslation('main');

    const c = props.c;
    const i = props.i;
    const text = props.text;
    const answer = props.answer;
    const choices = props.choices;

    const grid = useMemo(() => {
        const verif = answer.map(a => a.join(' #---# '));
        let grid = {};
        choices.vertical.forEach(v => {
            grid[v] = {};
            choices.horizontal.forEach(h => {
                grid[v][h] = {count: 0, correct: verif.includes(`${v} #---# ${h}`)};
            });
        });
        
        Object.keys(c.wrongs).forEach(key => {
            const elem = JSON.parse(key);
            elem.forEach(a => {
                grid[a[0]][a[1]].count += c.wrongs[key];
            });
        });


        
        return grid;
    }, [answer, c.wrongs, choices.horizontal, choices.vertical]);

    return (
        <Collapsible header={<h2>#{i} {text}</h2>}>
            <div className='quiz-digest'>
                <div className='analyze'>
                    <div className='correct'>
                        <h3>{t('pages.classes.class_results.correct_answers')}:</h3>
                        <div>
                            <ul>
                                {answer.map(a => <li key={a}>{a.join(' - ')}</li>)}
                            </ul>
                        </div>
                        <ScoreDisplay score={Math.round((c.corrects_count / (c.wrongs_count + c.corrects_count)) * 100)} emptyColor={'#eb4034'} colors={{0: '#34c95c'}} />
                    </div>
                    <div className='wrong'>
                        <h3>{t('pages.classes.class_results.answers')}:</h3>
                        <table className='choicegridresults'>
                            <tbody>
                                <tr>
                                    <td></td>
                                    {choices.horizontal.map((v1, i) => {
                                        return <td key={i}>{v1}</td>;
                                    })}
                                </tr>
                                {choices.vertical.map((v1, j) => {
                                    return <tr key={j}>
                                        <td>{v1}</td>
                                        {choices.horizontal.map((v2, i) => {
                                            const cell = grid[v1][v2];
                                            const value = cell.count + (cell.correct ? c.corrects_count : 0);
                                            const correct = cell.correct;
                                            return <td key={i} className={correct ? 'correct' : 'wrong'}>
                                                <label className={value === 0 ? 'hide' : ''}>
                                                    {value} ({Math.round((value / (c.corrects_count + c.wrongs_count)) * 100)}%)
                                                </label>
                                            </td>;
                                        })}
                                    </tr>;
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Collapsible>
    );
}