import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../API/API';
import useMountAwareState from '../../../hooks/useMountAwareState';
import Loading from '../../Loading';
import Popupable from '../../Popup/Popupable';
import './RetrieveShareEmailLinks.scss';
export default function RetrieveShareEmailLinks(props) {
    
    const [links, setLinks] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);
    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    useEffect(() => {
        API.getInstance()
        .get(`/users/self/classes/${props.classPid}/shares/${props.sharePid}/links`)
        .then(({status, data}) => {
            if(status === 200) {
                setLinks(data);
            }else{
                setError(tcommon('error.fetch.default', {code: status}));
            }
        })
        .catch(err => {
            setError(tcommon('error.general.default'))
        });
    }, [props.classPid, props.sharePid, setError, setLinks, tcommon]);
    

    return (
        <Popupable title={t('pages.classes.dialogs.retrieve_email_links.title')} close={props.close}>
            <div className='RetrieveShareEmailLinks'>
                {error ? 
                    <div>{error}</div>
                :
                    (links === null || links.length === 0 ?
                        (links === null ?
                            <Loading />
                        :
                            <div>{tcommon('general.nothing_yet')}</div>
                        )
                    :
                        <table>
                            <thead>
                                <tr>
                                    <td>{tcommon('general.student')}</td>
                                    <td>{tcommon('general.link')}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {links.map(l => {
                                    return <tr key={l.student.pid}>
                                        <td>{l.student.first_name} {l.student.last_name}</td>
                                        <td>
                                            <input  type="text"
                                                    readOnly={true}
                                                    value={l.link}
                                                    onFocus={e => e.target.select()}
                                            />
                                        </td>
                                    </tr>;
                                })}
                            </tbody>
                        </table>
                    )
                }
            </div>
        </Popupable>
    );
}