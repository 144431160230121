import React from 'react';
import './FlashcardsListeningLangSelection.scss';

import sound_icon from '../../../../images/icons/sound.svg';
import { useTranslation } from 'react-i18next';

export default function FlashcardsListeningLangSelection(props) {

    const [ t ] = useTranslation('main');

    return (
        <div className={`FlashcardsListeningLangSelection${props.animateOut ? ' animateOut' : ''}${props.animateIn ? ' animateIn' : ''}`}>
            <h2>{t('pages.explorer.apps.flashcards.select_translation_direction')}:</h2>
            <div className="selection">
                <button onClick={() => {props.setLang('en')}}>
                    <div className="source">
                        <img width={109} height={109} src={sound_icon} alt="Sound in french" />
                        <span>French</span>
                    </div>
                    <div className="destination">{t('pages.explorer.apps.flashcards.to_french')}...</div>
                </button>
                <div className="or">{t('pages.explorer.apps.flashcards.OR')}</div>
                <button onClick={() => {props.setLang('fr')}}>
                    <div className="source">
                        <img width={109} height={109} src={sound_icon} alt="Sound in french" />
                        <span>French</span>
                    </div>
                    <div className="destination">{t('pages.explorer.apps.flashcards.to_english')}...</div>
                </button>
            </div>
        </div>
    );
}