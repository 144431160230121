import './App.scss';
import * as React from "react";
import Cookies from 'js-cookie';
import './App.scss';
import ResourcesRoot from './components/ResourcesRoot';
import API from './API/API';
import ApiRequests from './components/dev/ApiRequests';

import AuthContext from './Contexts/AuthContext';
import CookieConsentContext from './Contexts/CookieConsentContext';
import UserContext from './Contexts/UserContext';
import PopupContext from './Contexts/PopupContext';
import ContextMenuContext from './Contexts/ContextMenuContext';
import ZoomImageContext from './Contexts/ZoomImageContext';
import Loading from './components/Loading';
import ReLogin from './components/authentication/ReLogin';
import CookieConsent from './components/CookieConsent';
import ZoomedImage from './components/utils/ZoomedImage';
import useMountAwareState from './hooks/useMountAwareState';
import SettingsContext from './Contexts/SettingsContext';
import PrintContext from './Contexts/PrintContext';
import Printer from './components/Printer';

function App() {

    const parseCookieConsent = (value) => {
        if(value === undefined) return undefined;
        if(value < 0 || value > 7) return undefined;
        let bin = parseInt(value).toString(2);
        while(bin.length < 3) {
            bin = `0${bin}`;
        }
        return {
            marketing: bin[0] === '1',
            tracking: bin[1] === '1',
            functionality: bin[2] === '1',
        }
    };

    const [ cookieConsent, setCookieConsent ] = React.useState(parseCookieConsent(Cookies.get('CookieConsent')) ?? null);
    const [ auth, setAuth ] = React.useState(localStorage.getItem('AccessToken') === null ? false : null);
    const [ user, setUser ] = React.useState(null);
    const [ contextMenu, setContextMenu ] = React.useState(null);
    const [ relogIn, setRelogIn ] = React.useState(false);
    const [ zoomImage, setZoomImage ] = React.useState(null);
    const [ settings, setSettings ] = React.useState({
        uppercaseSensitivity: (localStorage.getItem('settings.uppercase-sensitivity') ?? "false") === "true",
        ponctuationSensitivity: (localStorage.getItem('settings.ponctuation-sensitivity') ?? "false") === "true",
    });
    const [ popup, setPopup ] = useMountAwareState(null);
    const [ toPrint, setToPrint ] = useMountAwareState(null);

    const checkLoggedIn = () => {
        API.getInstance().isLoggedIn()
        .then((answer) => {
            setRelogIn(false);
            setAuth(answer);
        })
        .catch((error) => {
            setAuth(false);
        });
    };

    // Check authentication status
    React.useEffect(() => {
        const mustLogin = () => {
            setRelogIn(true);
        };
        
        if(localStorage.getItem('AccessToken')) {
            checkLoggedIn();
        }else{
            setAuth(false);
        }
        
        document.addEventListener('must_login', mustLogin);
        return () => {
            document.removeEventListener('must_login', mustLogin);
        }
    }, [setToPrint]);

    if(auth === null) return <div className="App"><Loading>Welcome!</Loading></div>;

    return (
        <div className="App">
            <CookieConsentContext.Provider value={{ cookieConsent, setCookieConsent }}>
                <AuthContext.Provider value={{ auth, setAuth }}>
                    <UserContext.Provider value={{ user, setUser }}>
                        <ContextMenuContext.Provider value={{ contextMenu, setContextMenu }}>
                            <ZoomImageContext.Provider value={{ zoomImage, setZoomImage }}>
                                <PopupContext.Provider value={{ popup, setPopup }}>
                                    <PrintContext.Provider value={{ toPrint, setToPrint }}>
                                        <SettingsContext.Provider value={{ settings, setSettings }}>
                                            {relogIn && auth ?
                                                <ReLogin close={() => {setRelogIn(false);}} />
                                            : undefined }
                                            <ResourcesRoot />
                                            <CookieConsent cookieConsent={cookieConsent} setCookieConsent={setCookieConsent} />
                                            {zoomImage !== null ?
                                                <ZoomedImage src={zoomImage.src ?? ''} alt={zoomImage.alt ?? ''} rounded={true} />
                                            : undefined}
                                            {popup ?? undefined}
                                            {toPrint ?
                                                <Printer className='print' close={() => setToPrint(null)}>
                                                    {toPrint}
                                                </Printer>
                                            : undefined}
                                        </SettingsContext.Provider>
                                    </PrintContext.Provider>
                                </PopupContext.Provider>
                            </ZoomImageContext.Provider>
                        </ContextMenuContext.Provider>
                    </UserContext.Provider>
                </AuthContext.Provider>
            </CookieConsentContext.Provider>
            {process.env.NODE_ENV === 'development' ?
                <ApiRequests />
            : undefined }
        </div>
    );
}

export default App;