import React, { useEffect, useState } from "react";
import "./BookletShortcutsLinks.scss";
import FileLink from "../../../FileLink";
import Loading from "../../../Loading";
export default function BookletShortcutsLinks(props) {
  const links = props.links;
  const [documents, setDocuments] = useState([]);
  const [flashcards, setFlashcards] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [missingWords, setMissingWords] = useState([]);

  useEffect(() => {
    if (!links) return;
    setDocuments(links.documents);
    setFlashcards(links.exercise.filter((d) => d.type === "flashcards"));
    setQuizzes(links.exercise.filter((d) => d.type === "quiz"));
    setMissingWords(links.exercise.filter((d) => d.type === "missing-words"));
  }, [links]);

  if (links === null)
    return (
      <div className="BookletShortcutsLinks">
        <Loading />
      </div>
    );
  return (
    <div className="BookletShortcutsLinks">
      <section className="col">
        <h2>Documents</h2>
        <div className="list-horizontal">
          {documents.map((link) => {
            return (
              <React.Fragment key={link.rid}>
                <FileLink file={link} />
              </React.Fragment>
            );
          })}
          {/* {links?.self !== undefined ? <FileLink key={links.self.rid} file={links.self} /> : undefined}
                    {links?.document ? links.document.map((link) => {
                        return <React.Fragment key={link.rid}>
                            <FileLink file={link} />
                        </React.Fragment>;
                    }): undefined} */}
        </div>
      </section>
      <section>
        <h2>Exercises</h2>
        <div className="list-vertical exercises-list">
          <div className="exercises-list-section">
            <h3>
              <span className="bull">&bull;</span>Flashcards
            </h3>
            <div className="list-horizontal">
              {flashcards.map((link) => {
                return (
                  <React.Fragment key={link.rid}>
                    <FileLink file={link} />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <div className="exercises-list-section">
            <h3>
              <span className="bull">&bull;</span>Missing Words
            </h3>
            <div className="list-horizontal">
              {missingWords.map((link) => {
                return (
                  <React.Fragment key={link.rid}>
                    <FileLink file={link} />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <div className="exercises-list-section">
            <h3>
              <span className="bull">&bull;</span>Quiz
            </h3>
            <div className="list-horizontal">
              {quizzes.map((link) => {
                return (
                  <React.Fragment key={link.rid}>
                    <FileLink file={link} />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className='list-vertical exercises-list'>
                    {exercises ? Object.keys(exercises).map((key) => {
                        return <div key={key} className='exercises-list-section'>
                            <h3><span className='bull'>&bull;</span>{exercises[key].title}</h3>

                            <div key={key} className='list-horizontal'>
                                {exercises[key].links.map((link) => {
                                    return <React.Fragment key={link.rid}>
                                        <FileLink file={link} />
                                    </React.Fragment>;
                                })}
                            </div>
                        </div>;
                    }): undefined}
                </div> */}
      </section>
    </div>
  );
}
