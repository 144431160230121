import React from 'react';
import './Switch.scss';
export default function Switch(props) {
    
    return (
        <label className={`Switch ${props.value ? 'checked' : 'unchecked'}`}>
            <input 
                type='checkbox'
                onChange={e => {props.setter(e.target.checked)}}
                checked={props.value}
            />
            <div className='slider'></div>
        </label>
    );
}