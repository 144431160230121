import React from 'react';
export default function ShareHistoryEntry(props) {
    
    return (
        <div className='ShareHistoryEntry'>
            {props.result.type === 'document' && props.result.data?.read !== undefined ?
                <>Read</>
            :
                <>{props.result.score}%</>
            }
            <div className='date'>
                {new Date(props.result.date * 1000).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit'})}
            </div>
        </div>
    );
}