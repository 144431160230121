import React, { useEffect } from "react";
import API from "../../API/API";
import "./ExplorerFile.scss";
import FlashcardsViewer from "./flashcards/FlashcardsViewer";
import Loading from "../Loading";
import MissingWordsViewer from "./activities/missing_words/MissingWordsViewer";
import HiddenWordsViewer from "./activities/hidden_words/HiddenWordsViewer";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import ErrorPage from "../ErrorPage";
import { useTranslation } from "react-i18next";
import QuizViewer from "./activities/quiz/QuizViewer";
import PDFViewer from "./pdf/PDFViewer";
import useMountAwareState from "../../hooks/useMountAwareState";
import CrosswordsViewer from "./activities/crosswords/CrosswordsViewer";

export default function ExplorerFile(props) {
  useDocumentTitle(props.file.name);

  const [display, setDisplay] = useMountAwareState(null);
  const [error, setError] = useMountAwareState(null);
  const [t] = useTranslation("common");

  useEffect(() => {
    setDisplay(null);
    API.getInstance()
      .file("/resources/" + props.file.rid + "/file", props.auth ?? true)
      .then(({ status, data }) => {
        if (status === 200) {
          const getFileDisplay = async () => {
            switch (props.file.extension) {
              case "pdf":
                return (
                  <PDFViewer
                    data={URL.createObjectURL(data)}
                    resource={props.file}
                  />
                );
              case "fc":
                return (
                  <FlashcardsViewer
                    resource={props.file}
                    data={JSON.parse(await data.text())}
                  />
                );
              case "mw":
                return (
                  <MissingWordsViewer
                    resource={props.file}
                    data={JSON.parse(await data.text())}
                  />
                );
              case "xw":
                return (
                  <CrosswordsViewer
                    resource={props.file}
                    data={JSON.parse(await data.text())}
                  />
                );
              case "hw":
                return (
                  <HiddenWordsViewer data={JSON.parse(await data.text())} />
                );
              case "qz":
                return (
                  <QuizViewer
                    resource={props.file}
                    data={JSON.parse(await data.text())}
                  />
                );
              default:
                return (
                  <iframe
                    title={props.file.name}
                    src={URL.createObjectURL(data)}
                    width="100%"
                  ></iframe>
                );
            }
          };
          getFileDisplay()
            .then((display) => {
              setDisplay(display);
            })
            .catch((err) => {
              setError(t("error.general.default"));
              console.log(err);
            });
        } else {
          setError(status);
        }
      })
      .catch((err) => {
        setError(t("error.general.default"));
      });
  }, [props.file, t, props.auth, setDisplay, setError]);

  if (error !== null) {
    if (error === 403) {
      return <ErrorPage code={error} error={t("error.file.forbidden_alt")} />;
    } else if (error === 404) {
      return <ErrorPage code={error} error={t("error.file.not_found_alt")} />;
    } else {
      return <ErrorPage />;
    }
  }
  if (null === display) return <Loading />;

  return <div className="ExplorerFile">{display}</div>;
}
