import React from 'react';
import './ExplorerFolderBox.scss';
import FileLink from '../FileLink';

export default class ExplorerFolderBox extends React.Component {
    render(){
        let file = this.props.file;

        return <div className='ExplorerFolderBox'>
                <FileLink file={file} setFile={this.props?.setFile}  updateFavorites={this.props?.updateFavorites} />
        </div>;
    }
}