import React, { useCallback, useEffect } from 'react';
import './PDFViewerControls.scss';
import zoom_icon from '../../../images/icons/zoom.svg';
export default function PDFViewerControls(props) {

    // const [ctrlDown, setCtrlDown] = useMountAwareState(false);

    const handleScroll = useCallback(
        (e) => {
            if(e.ctrlKey) {
                e.preventDefault();

                if(e.deltaY > 0) {
                    if(props.zoomRangeDisplay <= 0.2) return;
                    props.handleZoom(props.zoomRangeDisplay - 0.02);
                }else if(e.deltaY < 0){
                    if(props.zoomRangeDisplay >= 2) return;
                    props.handleZoom(props.zoomRangeDisplay + 0.02);
                }
            }
        },
        [props],
    );
    

    useEffect(() => {
        if(props.displayRef.current) {
            const display = props.displayRef.current;
            // const handleKeyDown = e => {
            //     switch (e.key) {
            //         case "Control":
            //             setCtrlDown(true);
            //             break;
            //         default:
            //             break;
            //     }
            // };
            // const handleKeyUp = e => {
            //     switch (e.key) {
            //         case "Control":
            //             setCtrlDown(false);
            //             break;
            //         default:
            //             break;
            //     }
            // };
        
            display.addEventListener('wheel', handleScroll);
            // document.addEventListener('keydown', handleKeyDown);
            // document.addEventListener('keyup', handleKeyUp);
            return () => {
                display.removeEventListener('wheel', handleScroll);
                // document.removeEventListener('keydown', handleKeyDown);
                // document.removeEventListener('keyup', handleKeyUp);
            }
        }
    }, [handleScroll, props.displayRef]);
    
    
    return (
        <div className='PDFViewerControls'>
            <label className='zoom'>
                <img src={zoom_icon} alt="zoom:" />
                <input 
                    type="range" 
                    min={0.2} 
                    max={3} 
                    step={0.01} 
                    value={props.zoomRangeDisplay} 
                    onChange={e => props.handleZoom(parseFloat(e.target.value))} 
                    onMouseUp={e => props.handleZoom(parseFloat(e.target.value), true)}
                /> 
                <select value={`${Math.round(props.zoomRangeDisplay * 100)}%`} onChange={e => {props.setZoomRangeDisplay(parseFloat(e.target.value)); props.setZoom(parseFloat(e.target.value))}}>
                    <option value={`${props.zoomRangeDisplay}`} style={{display: 'none'}}>{`${Math.round(props.zoomRangeDisplay * 100)}%`}</option>
                    <option value="1.2">120%</option>
                    <option value="1.0">100%</option>
                    <option value="0.80">80%</option>
                    <option value="0.60">60%</option>
                    <option value="0.40">40%</option>
                </select>
                
            </label>
        </div>
    );
}