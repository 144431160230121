import { useEffect, useRef, useState } from "react";

export default function useGrowingNumber(target, growth = 'exponential', interval = 100) {
    const [n, setN] = useState(target);
    const timeoutId = useRef(null);

    useEffect(() => {
        let p = 0.1;
        let inter = interval;

        const grow = () => {
            const percent = Math.exp(p/1.4) - 1;
            setN(Math.round(Math.min(percent * target, target)));
            p+=0.01;
            if(growth === 'exponential') inter *= 0.9;
            else if(growth === 'reverse-exponential') inter *= 1.03;

            if(p < 1) {
                timeoutId.current = setTimeout(() => {
                    grow();
                }, inter);
            }
        };

        grow();
    
        return () => {
            if(timeoutId.current) clearTimeout(timeoutId.current);
        }
    }, [target, growth, interval]);

    return n;
}