import React from 'react';
import { useTranslation } from 'react-i18next';
import './AccountIdentity.scss';

import account_icon from '../../images/icons/account.svg';

export default function AccountIdentity(props) {

    const [ t ] = useTranslation('main');

    const user = props.user;
    
    const getType = () => {
        if(props.user.roles.includes('super_admin')) return t('pages.account.sections.identity.types.super_admin');
        if(props.user.roles.includes('admin')) return t('pages.account.sections.identity.types.admin');
        if(props.user.roles.includes('institutional_teacher')) return t('pages.account.sections.identity.types.institutional_teacher');
        if(props.user.roles.includes('institutional_admin')) return t('pages.account.sections.identity.types.institutional_admin');
        if(props.user.roles.includes('subscribed_elementary')) return t('pages.account.sections.identity.types.individual');
        if(props.user.roles.includes('subscribed_advanced')) return t('pages.account.sections.identity.types.individual');
        if(props.user.roles.includes('user')) return t('pages.account.sections.identity.types.unsubscribed');
    };

    return (
        <section className='AccountIdentity'>
            <h2><img width={131} height={131} src={account_icon} alt="" />{t('pages.account.sections.identity.title', {type: getType()})}</h2>
            <table>
                <tbody>
                    <tr>
                        <td>{t('pages.account.sections.identity.first_name')}:</td>
                        <td>{user.first_name}</td>
                    </tr>
                    <tr>
                        <td>{t('pages.account.sections.identity.last_name')}:</td>
                        <td>{user.last_name}</td>
                    </tr>
                    <tr>
                        <td>{t('pages.account.sections.identity.email')}:</td>
                        <td>{user.username}</td>
                    </tr>
                    {user.institution !== null ? 
                        <tr>
                            <td>{t('pages.account.sections.identity.institution')}:</td>
                            <td>{user.institution.name}</td>
                        </tr>
                    : undefined }
                </tbody>
            </table>
        </section>
    );
}