import React, {  useEffect } from 'react';
import API from '../../../API/API';
import useMountAwareState from '../../../hooks/useMountAwareState';
import Dot3 from '../../utils/Dot3';
import './DocumentReadButton.scss';
export default function DocumentReadButton(props) {
    
    const [read, setRead] = useMountAwareState(null);
    const [sending, setSending] = useMountAwareState(false);

    useEffect(() => {
        API.getInstance()
        .get(`/resource-shares/${props.token}/history`, false)
        .then(({status, data}) => {
            if(status === 200) {
                setRead(data.length > 0);
            }else{
                setRead(false);
            }
        }) 
        .catch((error) => {
            setRead(false);
        });
    
        return () => {
            
        }
    }, [props.token, setRead]);
    
    const sendConfirmation = () => {
        if(read) return;
        setSending(true);
        
        API.getInstance()
        .post(`/resource-verify-answers/${props.resource.rid}${props?.token !== undefined ? `?t=${props.token}` : ''}`, {
            data: {
                read: true
            }
        }, false)
        .then(({status, data}) => {
            if(status === 200 || status === 201) {
                setRead(true);
            }
            setSending(false);
        })
        .catch((error) => {
            setSending(false);
        });
    };

    return (
        <button className={`DocumentReadButton${read ? ' read' : ''}`} disabled={read === true || read === null} onClick={sendConfirmation}>
            {read === null ?
                <Dot3 />
            :
                (sending ? 
                    <>Sending <Dot3 /></>
                :
                    <>
                        {read ? <>&#10003;</> : undefined}
                        I have read the document
                    </>
                )
            }
        </button>
    );
}