import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import common_en from './translations/en/common.json';
import main_en from './translations/en/main.json';
import common_zh from './translations/zh/common.json';
import main_zh from './translations/zh/main.json';
import common_fr from './translations/fr/common.json';
import main_fr from './translations/fr/main.json';

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    fallbackLng: 'en',
    lng: localStorage.getItem('lang') || 'en',  // language to use
    debug: true,
    resources: {
        en: {
            common: common_en,
            main: main_en,
        },
        fr: {
            common: common_fr,
            main: main_fr,
        },
        zh: {
            common: common_zh,
            main: main_zh,
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <React.StrictMode>
                <I18nextProvider i18n={i18next}>
                    <App />
                </I18nextProvider>
            </React.StrictMode>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();