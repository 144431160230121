import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageBox.scss';

import useCookieConsent from '../../hooks/useCookieConsent';

import language_icon from '../../images/icons/language.svg';
import useMountAwareState from '../../hooks/useMountAwareState';

export default function LanguageBox(props) {

    const [, i18n] = useTranslation('main');

    const { functionality } = useCookieConsent();

    let wrapperRef = useRef(null);

    const [focus, setFocus] = useMountAwareState(false);
    const [lang, setLang] = useMountAwareState(i18n.language);

    const changeLang = (l) => {
        if(functionality) localStorage.setItem('lang', l);
        i18n.changeLanguage(l);
        setLang(l);
    };

    const handleOutsideInteraction = useCallback(
        (e) => {
            if (wrapperRef && !wrapperRef.current.contains(e.target)) {
                setFocus(false);
            }
        },
        [setFocus],
    );

    const handleBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setFocus(false);
        }
    };

    const handleClick = (e) => {
        setFocus(true);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideInteraction);
        return () => {
            document.removeEventListener('mousedown', handleOutsideInteraction);
        }
    }, [handleOutsideInteraction]);
    
    return (
        <div ref={wrapperRef} className='LanguageBox' onBlur={handleBlur}>
            <button onClick={handleClick} onFocus={handleClick}>
                <img width={416} height={482} src={language_icon} alt="Change language" />
            </button>
            {focus ?
                <div className='selection'>
                    <button className={`${lang === 'en' ? 'active' : 'inactive'}`} onClick={() => {changeLang('en')}}>English</button>
                    <button className={`${lang === 'fr' ? 'active' : 'inactive'}`} onClick={() => {changeLang('fr')}}>Français</button>
                    <button className={`${lang === 'zh' ? 'active' : 'inactive'}`} onClick={() => {changeLang('zh')}}>中文</button>
                </div>
            : ''}
        </div>
    );
}