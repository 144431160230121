import React from 'react';
import Collapsible from '../../../../../utils/Collapsible'
export default function QuizVideoResults(props) {
    
    return (
        <Collapsible header={<h2>#{props.i} {props.text}</h2>}>
            <div className='quiz-digest video'>
                <video width="320" height="240" controls>
                    <source src={props.url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </Collapsible>
    );
}