import React, { useEffect } from 'react';
import './Flashcards.scss';

import learning from '../../images/gifs/flashcards-demo/learning.gif';
import writing from '../../images/gifs/flashcards-demo/writing.gif';
import reading from '../../images/gifs/flashcards-demo/reading.gif';
import listening from '../../images/gifs/flashcards-demo/listening.gif';

import learning_icon from '../../images/icons/learning.svg';
import reading_icon from '../../images/icons/reading.svg';
import writing_icon from '../../images/icons/writing.svg';
import listening_icon from '../../images/icons/listening.svg';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FileIcon from '../FileIcon';
import useMountAwareState from '../../hooks/useMountAwareState';

export default function Flashcards(props) {

    const [tab, setTab] = useMountAwareState(0);

    const [ t ] = useTranslation('main');

    const tabCount = 4;

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTab(tab === 3 ? 0 : tab+1);
        }, 8000);
    
        return () => {
            clearInterval(intervalId);
        }
    }, [setTab, tab]);
    
    return (
        <section className='Flashcards'>
            <div className='content'>
                <div className="illustrations-container">
                    <div className='carousel'>
                        <div className='top'>
                            <div className='button'>
                                <button  onClick={() => {setTab(tab === 0 ? tabCount-1 : tab-1);}}>
                                    &lt;
                                </button>
                            </div>
                            <ul className='display'>
                                <li style={{transform: `translateX(-${100 * tab}%)`}}>
                                    <div className='image'>
                                        <img width={600} height={475} src={learning} alt="" />
                                    </div>
                                    <h3><img width={58} height={58} src={learning_icon} alt="" />{t('pages.presentation.flashcards.list.learning.title')}</h3>
                                    <div className='try-it'>
                                        <Link to="/preview/flashcards/learning" className='btn-yellow'>{t('pages.presentation.try_it')}</Link>
                                    </div>
                                </li>
                                <li style={{transform: `translateX(-${100 * tab}%)`}}>
                                    <div className='image'>
                                        <img width={600} height={475} src={reading} alt="" />
                                    </div>
                                    <h3><img width={81} height={81} src={reading_icon} alt="" />{t('pages.presentation.flashcards.list.reading.title')}</h3>
                                    <div className='try-it'>
                                        <Link to="/preview/flashcards/reading" className='btn-yellow'>{t('pages.presentation.try_it')}</Link>
                                    </div>
                                </li>
                                <li style={{transform: `translateX(-${100 * tab}%)`}}>
                                    <div className='image'>
                                        <img width={600} height={475} src={writing} alt="" />
                                    </div>
                                    <h3><img width={537} height={537} src={writing_icon} alt="" />{t('pages.presentation.flashcards.list.writing.title')}</h3>
                                    <div className='try-it'>
                                        <Link to="/preview/flashcards/writing" className='btn-yellow'>{t('pages.presentation.try_it')}</Link>
                                    </div>
                                </li>
                                <li style={{transform: `translateX(-${100 * tab}%)`}}>
                                    <div className='image'>
                                        <img width={600} height={475} src={listening} alt="" />
                                    </div>
                                    <h3><img width={63} height={63} src={listening_icon} alt="" />{t('pages.presentation.flashcards.list.listening.title')}</h3>
                                    <div className='try-it'>
                                        <Link to="/preview/flashcards/listening" className='btn-yellow'>{t('pages.presentation.try_it')}</Link>
                                    </div>
                                </li>
                            </ul>
                            <div className='button'>
                                <button onClick={() => {setTab(tab === tabCount-1 ? 0 : tab+1);}}>&gt;</button>
                            </div>
                        </div>
                        <div className='controls'>
                            <button disabled={tab === 0} onClick={() => setTab(0)}>&#8226;</button>
                            <button disabled={tab === 1} onClick={() => setTab(1)}>&#8226;</button>
                            <button disabled={tab === 2} onClick={() => setTab(2)}>&#8226;</button>
                            <button disabled={tab === 3} onClick={() => setTab(3)}>&#8226;</button>
                        </div>
                    </div>
                </div>
                <div className="texts-container">
                    <div className='texts'>
                        <div className="content">
                            <div className='title-container'>
                                <FileIcon type="flashcards" />
                                <h2>{t('pages.presentation.flashcards.title')}</h2>
                            </div>
                            <p>
                                Flashcards are a famous way of memorizing and reviewing knowledge.
                                With our interactive flashcards, students are able to learn vocabulary and listen
                                to the words pronounced by native French speakers. The students can then practice 
                                their reading, writing and listening with the exercises:
                            </p>
                            <ul>
                                <li>
                                    <h3><img src={learning_icon} alt="" />{t('pages.presentation.flashcards.list.learning.title')}</h3>
                                    <p>{t('pages.presentation.flashcards.list.learning.text')}</p>
                                </li>
                                <li>
                                    <h3><img src={reading_icon} alt="" />{t('pages.presentation.flashcards.list.reading.title')}</h3>
                                    <p>{t('pages.presentation.flashcards.list.reading.text')}</p>
                                </li>
                                <li>
                                    <h3><img src={writing_icon} alt="" />{t('pages.presentation.flashcards.list.writing.title')}</h3>
                                    <p>{t('pages.presentation.flashcards.list.writing.text')}</p>
                                </li>
                                <li>
                                    <h3><img src={listening_icon} alt="" />{t('pages.presentation.flashcards.list.listening.title')}</h3>
                                    <p>{t('pages.presentation.flashcards.list.listening.text')}</p>
                                </li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    );
}