import React, {  useContext, useEffect, useRef } from 'react';
import './FlashcardsReadingGame.scss';

import AssistedTextInput from '../../../assistedInput/AssistedTextInput';

import ratio from '../../../../images/flashcard-ratio.svg';
import { useTranslation } from 'react-i18next';
import useMountAwareState from '../../../../hooks/useMountAwareState';
import SettingsContext from '../../../../Contexts/SettingsContext';

import uppercase_sensitivity_icon from '../../../../images/icons/uppercase_sensitivity.svg';
import ponctuation_sensitivity_icon from '../../../../images/icons/ponctuation_sensitivity.svg';

export default function FlashcardsReadingGame(props) {
    
    const [answers, setAnswers] = useMountAwareState([]);
    const [currentAnswer, setCurrentAnswer] = useMountAwareState('');
    const [index, setIndex] = useMountAwareState(0);
    const [lock, setLock] = useMountAwareState(false);
    const [cardSize, setCardSize] = useMountAwareState(null);
    const { settings } = useContext(SettingsContext);

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    const ref = useRef(null);

    useEffect(() => {
        const focusDelayer = setTimeout(() => {
            const elem = document.getElementById('input');
            if(elem) elem.focus();
        }, 800);

        const sizeFlashcards = (e) => {
            const sizer = document.querySelector('.flashcard-sizer');
            
            setCardSize({
                width: sizer.clientWidth,
                height: sizer.clientHeight === 0 ? (sizer.clientWidth / 5) * 3 : sizer.clientHeight
            });
        };

        sizeFlashcards();
        window.addEventListener('resize', sizeFlashcards);
        
        return () => {
            if(focusDelayer !== null) clearTimeout(focusDelayer);
            window.removeEventListener('resize', sizeFlashcards);
        }
    }, [setCardSize]);
    

    const handleSubmit = (e) => {
        e.preventDefault();

        if(currentAnswer.length === 0) return;

        if(index < props.cards.length - 1) {
            setAnswers(prevAnswers => ([...prevAnswers, currentAnswer]));
            setCurrentAnswer('');
            setIndex(index+1);
        }else {
            end([...answers, currentAnswer]); 
        }
    };

    const handleChange = (e) => {
        setCurrentAnswer(e.target.value);
    };

    const end = (a) => {
        const results = a.map((answer, i, a) => {
            return {
                card: props.cards[i],
                answer: answer
            };
        });
        setLock(true);
        if(ref) {
            ref.current.querySelector('input[type="text"]').blur();
        }
        props.setResults(results);
    };

    let face = 'front';
    if(props.lang === 'fr') {
        face = 'back';
    }

    return (
        <div ref={ref} className={`FlashcardsReadingGame ${props.animateOut ? 'animateOut' : ''} ${props.animateIn ? 'animateIn' : ''}`}>
            {/* <button className="back" onClick={props.reset}>{t('pages.explorer.apps.flashcards.back')}</button> */}
            <div className='instructions'>
                <span>{t(`pages.explorer.apps.flashcards.instructions.${props.lang === 'fr' ? 'reading' : 'writing'}`)}</span>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="flashcards-container">
                    <div className='flashcard flashcard-sizer'>
                        <img width={1778} height={1069} src={ratio} alt="" />
                    </div>
                    {/* The actual displayed flashcard */}
                    {props.cards.map((c, i, a) => {
                        return <div key={i} style={cardSize} className={`flashcard${index === i ? ' current' : ''}${index > i ? ' previous' : ''}${index < i ? ' coming' : ''}`}>
                            <div className='top'>
                                <div className='number'>{i+1}/{a.length}</div>
                                <div className='warnings'>
                                {(props?.token === undefined && settings.uppercaseSensitivity) || (props?.token && (props?.share?.options?.uppercase_sensitivity ?? false)) ?
                                    <img src={uppercase_sensitivity_icon} alt="uppercase on" title={tcommon('settings_instructions.uppercase')} />
                                : undefined}
                                {(props?.token === undefined && settings.ponctuationSensitivity) || (props?.token && (props?.share?.options?.ponctuation_sensitivity ?? false)) ?
                                    <img src={ponctuation_sensitivity_icon} alt="ponctuation on" title={tcommon('settings_instructions.ponctuation')} />
                                : undefined}
                                </div>
                            </div>
                            <div className='content'>
                                {face === 'front' && c.front?.image !== undefined ?
                                    <img src={c.front.image} alt="" /> 
                                : undefined}
                                <h2>{c[face].text}</h2>
                            </div>
                        </div>;
                    })}
                </div>
                <div className='input'>
                    <AssistedTextInput 
                        id="input"
                        value={currentAnswer}
                        onChange={(e) => {handleChange(e)}}
                        autoComplete="off"
                        autoCorrect="false"
                        disabled={lock}
                    />
                    <input type="submit" value="&#10004;"/>
                </div>
            </form>
        </div>
    );
}