import React from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../API/API';
import Popupable from '../Popup/Popupable';
import './DeleteStudent.scss';
import useFeedback from '../../hooks/useFeedback'; 
import useMountAwareState from '../../hooks/useMountAwareState';
export default function DeleteStudent(props) {

    const feedback = useFeedback();
    const [error, setError] = useMountAwareState(null);
    const [lock, setLock] = useMountAwareState(false);
    const [ tcommon ] = useTranslation('common');
    const [ t ] = useTranslation('main');

    const deleteStudent = () => {
        setLock(true);
        API.getInstance()
        .delete(`/users/self/classes/${props.classPid}/students/${props.student.pid}`)
        .then(({status, data}) => {
            if(status === 200 || status === 204) {
                feedback.add(t('pages.classes.dialogs.remove_student.student_removed'));
                document.dispatchEvent(new CustomEvent('class-update'));
                document.dispatchEvent(new CustomEvent('student-update'));
                props.close();
                props?.success?.();
            }else{
                setError(tcommon('error.fetch.default', {code: status}));
            }
            setLock(false);
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
            setLock(false);
        });
    };
    
    return (
        <Popupable title={t('pages.classes.dialogs.remove_student.title')} close={props.close}>
            <form className='DeleteStudent' onSubmit={e => e.preventDefault()}>
                <div>
                    <h2>{t('pages.classes.dialogs.remove_student.confirm', {first_name: props.student.first_name, last_name: props.student.last_name})}</h2>
                    <p>
                        {t('pages.classes.dialogs.remove_student.warning')}
                    </p>
                    {error ?
                        <div>{error}</div>
                    : undefined}
                    <div>
                        <button onClick={props.close} disabled={lock}>{tcommon('general.cancel')}</button>
                        <button className='red' onClick={deleteStudent} disabled={lock}>{tcommon('general.remove')}</button>
                    </div>
                </div>
            </form>
        </Popupable>
    );
}