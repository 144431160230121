import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import API from '../../../API/API';
import useCookieConsent from '../../../hooks/useCookieConsent';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useMountAwareState from '../../../hooks/useMountAwareState';
import ErrorPage from '../../ErrorPage';
import Loading from '../../Loading';
import ClassesBreadcrumb from '../ClassesBreadcrumb';
import './SeeClassResults.scss';
import DocumentClassResultsViewer from './viewers/document/DocumentClassResultsViewer';
import FlashcardsClassResultsViewer from './viewers/flashcards/FlashcardsClassResultsViewer';
import MissingWordsClassResultsViewer from './viewers/missing_words/MissingWordsClassResultsViewer';
import QuizClassResultsViewer from './viewers/quiz/QuizClassResultsViewer';
export default function SeeClassResults(props) {

    const [ t ] = useTranslation('main');

    const [data, setData] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);
    const [ tcommon ] = useTranslation('common');
    const { classPid, sharePid } = useParams();
    const [sort, setSort] = useMountAwareState(localStorage.getItem('see-class-results-sort') ?? 'no');

    const { functionality } = useCookieConsent();

    useDocumentTitle(tcommon('general.results_summary'));

    useEffect(() => {
        API.getInstance()
        .get(`/users/self/classes/${classPid}/shares/${sharePid}/results-digest`)
        .then(({status, data}) => {
            if(status === 200) {
                setData(data);
            }else{
                setError(tcommon('error.fetch.default', {code: status}));
            }
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
        });
    }, [classPid, setData, setError, sharePid, tcommon]);

    useEffect(() => {
        if(functionality) {
            localStorage.setItem('see-class-results-sort', sort);
        }
    }, [sort, functionality]);
    

    const display = useMemo(() => {
        if(data === null) return <Loading />;
        if(data.digest === null) return <div className='nothing'>{tcommon('general.nothing_yet')}</div>;
        switch (data.share.resource.type) {
            case 'quiz':
                return <QuizClassResultsViewer digest={data.digest} share={data.share} sort={sort} />;
            case 'flashcards':
                return <FlashcardsClassResultsViewer digest={data.digest} share={data.share} sort={sort} />;
            case 'document': 
                return <DocumentClassResultsViewer digest={data.digest} share={data.share} sort={sort} />;
            case 'missing-words': 
                return <MissingWordsClassResultsViewer digest={data.digest} share={data.share} sort={sort} />;
            default:
                return <div>{tcommon('error.general.default')}</div>;
        }
    }, [data, sort, tcommon]);
    
    if(error) return <ErrorPage error={error} />;
    if(data === null) return <Loading />;
    const share = data.share;
    return (
        <section className='SeeClassResults'>
            <header>
                <ClassesBreadcrumb links={[
                    {to: `/classes/${classPid}`, label: share.class.name},
                    {to: `/classes/${classPid}/shares`, label: tcommon('general.shares')},
                    {to: `/classes/${classPid}/shares/${sharePid}`, label: `[${share.resource.stylized_type}] ${share.resource.name}`},
                    {to: `/classes/${classPid}/shares/${sharePid}/results-summary`, label: tcommon('general.results_summary')},
                ]} />
            </header>
            <main>
                <div className='header'>
                    <h1>{tcommon('general.results_summary')}:</h1>
                    <label>
                        {t('pages.classes.class_results.sort')}:
                        <select onChange={e => setSort(e.target.value)} value={sort}>
                            <option value="no">{t('pages.classes.class_results.sort_options.no')}</option>
                            <option value="scoreAsc">{t('pages.classes.class_results.sort_options.score_asc')}</option>
                            <option value="scoreDesc">{t('pages.classes.class_results.sort_options.score_desc')}</option>
                        </select>
                    </label>
                </div>
                <div className='display'>
                    {display}
                </div>
            </main>
        </section>
    );
}