import React, { useMemo } from 'react';
import Collapsible from '../../../../utils/Collapsible';
import './DocumentClassResultsViewer.scss';
import CircularPercentage from '../../../CircularPercentage';
import ScoreDisplay from '../../../ScoreDisplay';
import { useTranslation } from 'react-i18next';
export default function DocumentClassResultsViewer(props) {

    const [ t ] = useTranslation('main');

    const readsMode = useMemo(() => (props.share.options?.questions ?? []).length === 0, [props.share.options]);
    const digest = useMemo(() => {
        if(readsMode || props.sort === 'no') return props.digest;
        const sorters = {
            'scoreAsc': (a, b) => {
                return a.corrects_count - b.corrects_count;
            },
            'scoreDesc': (a, b) => {
                return b.corrects_count - a.corrects_count;
            },
            'no': (a, b) => 0,
        };

        return [...props.digest].sort(sorters[props.sort ?? 'no'] ?? sorters['no']);
    }, [readsMode, props.digest, props.sort]);

    return (
        <div className='DocumentClassResultsViewer'>
            {readsMode ?
                <div className='reads'>
                    <CircularPercentage value={(props.digest.reads / props.share.students_count_at_sending) * 100} noText={true} />
                    <div className='count'>{props.digest.reads} / {props.share.students_count_at_sending} students have read</div>
                </div>
            :
                <div className='breakdown'>
                    {digest.map((c, i) => {
                        const q = props.share.options?.questions?.[i] ?? {
                            question: "Unable to retrieve question",
                            answer: "",
                        };
                        return <Collapsible key={i} header={<h2>{q.question}</h2>}>
                            <div className='document-digest'>
                                <div className='analyze'>
                                    <div className='correct'>
                                        <h3>{t('pages.classes.class_results.correct_answers')}:</h3>
                                        <div>{q.answer}</div>
                                        <ScoreDisplay score={Math.round((c.corrects_count / (c.wrongs_count + c.corrects_count)) * 100)} emptyColor={'#eb4034'} colors={{0: '#34c95c'}} />
                                    </div>
                                    <div className='wrong'>
                                        <h3>{t('pages.classes.class_results.wrong_answers')}:</h3>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td>{t('pages.classes.class_results.count')}</td>
                                                    <td>{t('pages.classes.class_results.answer')}</td>
                                                    <td>{t('pages.classes.class_results.freq')}</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(c.wrongs).map((wrongAnswer) => {
                                                    return <tr key={wrongAnswer}>
                                                        <td>{c.wrongs[wrongAnswer]}</td>
                                                        <td>{wrongAnswer}</td>
                                                        <td>{Math.round((c.wrongs[wrongAnswer] / (c.wrongs_count + c.corrects_count)) * 100)}%</td>
                                                    </tr>;
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Collapsible>;
                    })}
                </div>
            }
        </div>
    );
}

/*
return <table key={i}>
    <thead>
        <tr>
            <td>{i+1}</td>
            <td>{q.question}</td>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Correct:</td>
            <td>{q.answer}</td>
            <td>{c.corrects_count}</td>
        </tr>
        <tr>
            <td>Wrong:</td>
            <td>
                <table>
                    <thead>
                        <tr>
                            <td>Count</td>
                            <td>Answer</td>
                            <td>Freq.</td>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(c.wrongs).map(wrongAnswer => {
                            return <tr key={wrongAnswer}>
                                <td>{c.wrongs[wrongAnswer]}</td>
                                <td>{wrongAnswer}</td>
                                <td>{Math.round((c.wrongs[wrongAnswer] / (c.wrongs_count + c.corrects_count)) * 100)}%</td>
                            </tr>;
                        })}
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>;
*/