import { createContext } from 'react';

const CookieConsentContext = createContext({
    consent: null,
    setConsent: (val) => {},
});

export const CookieConsentProvider = CookieConsentContext.Provider;
export const CookieConsentConsumer = CookieConsentContext.Consumer;

export default CookieConsentContext;