import React from 'react';
import { useTranslation } from 'react-i18next';
import './StayTuned.scss';
export default function StayTuned(props) {
    
    const [ t ] = useTranslation('main');

    return (
        <section className='StayTuned'>
            <div>{t('pages.presentation.stay_tuned.title')}</div>
            <div>{t('pages.presentation.stay_tuned.text')}</div>
        </section>
    );
}