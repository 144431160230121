import React, { useContext } from 'react';
import UserContext from '../../Contexts/UserContext';
import './InstitutionalHelpSection.scss';
export default function InstitutionalHelpSection(props) {
    
    const { user } = useContext(UserContext);

    if(user === null || user.type < 2) return null;
    return (
        <section className='InstitutionalHelpSection' id={props.id}>
            {props.children}
        </section>
    );
}