import React, { useEffect, useRef } from 'react';
export default function FeedbackMessage(props) {
    
    let timeoutId = useRef(null);

    useEffect(() => {
        resume();
        
        return () => {
            freeze();
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    
    const removeSelf = () => {
        props.remove(props.feedback.id);
    };

    const freeze = () => {
        if(timeoutId.current) clearTimeout(timeoutId.current);
    };
    const resume = () => {
        timeoutId.current = setTimeout(() => {
            removeSelf();
        }, 5000);
    };

    return (
        <div className='feedback' onMouseEnter={freeze} onMouseLeave={resume}>
            {props.feedback.text}
        </div>
    );
}