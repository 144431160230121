import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../API/API';
import parseHeaderInt from '../../../helpers/ParseHeaderInt';
import ErrorPage from '../../ErrorPage';
import Loading from '../../Loading';
import Dot3 from '../../utils/Dot3';
import './ClassSharesList.scss';
import ShareLine from './ShareLine';
import search_icon from '../../../images/icons/search.svg';
import useMountAwareState from '../../../hooks/useMountAwareState';
export default function ClassSharesList(props) {
    
    const [shares, setShares] = useMountAwareState(null);
    const [query, setQuery] = useMountAwareState('');
    const [error, setError] = useMountAwareState(null);
    const [errorCode, setErrorCode] = useMountAwareState(null);
    const [pagination, setPagination] = useMountAwareState({current: null, previous: null, next: null, count: null, total: null});
    const [loading, setLoading] = useMountAwareState(false);
    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');
    const classPid = props.classPid;

    const fetchShares = useCallback(
        (page = 1) => {
            setError(null);
            setErrorCode(null);
            setLoading(true);
            API.getInstance()
            .get(`/users/self/classes/${classPid}/shares?page=${page}`)
            .then(({status, data, headers}) => {
                if(status === 200) {
                    setShares(data);
                    setPagination({
                        previous: parseHeaderInt(headers.get('x-pagination-previous')),
                        current: parseHeaderInt(headers.get('x-pagination-current')),
                        next: parseHeaderInt(headers.get('x-pagination-next')),
                        count: parseHeaderInt(headers.get('x-pagination-count')),
                        total: parseHeaderInt(headers.get('x-total-entities')),
                    });
                }else if(status === 404) {
                    setError(tcommon('error.page.not_found'));
                    setErrorCode(404);
                }else{
                    setError(tcommon('error.fetch.default', {code: status}));
                }
                setLoading(false);
            })
            .catch(err => {
                setError(tcommon('error.general.default'));
                setLoading(false);
            });
        },
        [classPid, setError, setErrorCode, setLoading, setPagination, setShares, tcommon],
    );

    useEffect(() => {
        fetchShares();
    }, [fetchShares]);    
    
    if(error) return <ErrorPage code={errorCode ?? undefined} error={error} />;
    if(shares === null) return <Loading>Fetching shares list</Loading>;
    return (
        <div className='ClassSharesList'>
            <div className='header'>
                <div className='search'>
                    <label>
                        <img src={search_icon} alt="" />
                        <input type="search" value={query} onChange={e => setQuery(e.target.value)} placeholder={`${tcommon('general.search')}...`} />
                    </label>
                </div>
            </div>
            <div className='list'>
                <table>
                    <thead>
                        <tr>
                            <td>{tcommon('general.resource')}</td>
                            <td>{tcommon('general.score')}</td>
                            <td>{tcommon('general.created')}</td>
                            <td>{tcommon('general.due_on')}</td>
                            <td>{tcommon('general.results')}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {shares.length === 0 ?
                            <tr className='empty'>
                                <td colSpan={5}>
                                    {query === '' ?
                                        t('pages.classes.class_details.previews.no_shares')
                                    :
                                        tcommon('search.no_match', {query})
                                    }
                                </td>
                            </tr>
                        :
                            shares.map((share, i) => {
                                return <ShareLine
                                            key={share.pid} 
                                            share={share} 
                                            classPid={props.classPid}
                                            setPopup={null} 
                                            setError={setError}  
                                            fetchStudents={null}
                                />;
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className='footer'>
                <div className='stats'>
                    <div>{tcommon('pagination.total')}: {pagination.total ? pagination.total : (shares === null ? <Dot3 /> : 0) }</div>
                </div>
                <div className='controls'>
                    <button 
                            className={loading ? 'loading' : ''}
                            onClick={() => {fetchShares(pagination.previous)}}
                            disabled={pagination.previous === null || loading}
                    >
                        {tcommon('pagination.previous')}
                    </button>
                    <div>
                        {pagination.current}/{pagination.count}
                    </div>
                    <button 
                            className={loading ? 'loading' : ''}
                            onClick={() => {fetchShares(pagination.next)}}
                            disabled={pagination.next === null || loading}
                    >
                        {tcommon('pagination.next')}
                    </button>
                </div>
            </div>
        </div>
    );
}