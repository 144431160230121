import React from 'react';
import useMountAwareState from '../../hooks/useMountAwareState';
import './RequestQuote.scss';
export default function RequestQuote(props) {
    
    const [firstName, setFirstName] = useMountAwareState('');
    const [lastName, setLastName] = useMountAwareState('');
    const [email, setEmail] = useMountAwareState('');
    const [phone, setPhone] = useMountAwareState('');
    const [preferredContact, setPreferredContact] = useMountAwareState('email');
    const [province, setProvince] = useMountAwareState('BC');
    const [district, setDistrict] = useMountAwareState('');
    const [institution, setInstitution] = useMountAwareState('');
    const [message, setMessage] = useMountAwareState('');

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <div className='RequestQuote'>
            <div className='background'></div>
            <form onSubmit={handleSubmit}>
                <h1>Request a quote</h1>
                <table>
                    <tbody>
                        <tr>
                            <td colSpan={2}><h2>Contact information:</h2></td>
                        </tr>
                        <tr>
                            <td>
                                First name:
                            </td>
                            <td>
                                <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Last name:
                            </td>
                            <td>
                                <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Email:
                            </td>
                            <td>
                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Phone:
                            </td>
                            <td>
                                <input type="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Preferably, contact me by:
                            </td>
                            <td>
                                <label>
                                    <input type="radio" name="preferredContact" value="mail" onChange={(e) => setPreferredContact('email')} checked={preferredContact === 'email'} />
                                    Email
                                </label>
                                <label>
                                    <input type="radio" name="preferredContact" value="phone" onChange={(e) => setPreferredContact('phone')} checked={preferredContact === 'phone'} />
                                    Phone
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}><h2>Institutions:</h2></td>
                        </tr>
                        <tr>
                            <td>
                                Province:
                            </td>
                            <td>
                                <select value={province} onChange={(e) => {setProvince(e.target.value)}}>
                                    <option value="AB">Alberta</option>
                                    <option value="BC">British Columbia</option>
                                    <option value="MB">Manitoba</option>
                                    <option value="NB">New Brunswick</option>
                                    <option value="NL">Newfoundland And Labrador</option>
                                    <option value="NS">Nova Scotia</option>
                                    <option value="ON">Ontario</option>
                                    <option value="PE">Prince Edward Island</option>
                                    <option value="QC">Quebec</option>
                                    <option value="SK">Saskatchewan</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                School District:
                            </td>
                            <td>
                                <input type="district" value={district} onChange={(e) => setDistrict(e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                
                                Institution:
                            </td>
                            <td>
                                <input type="institution" value={institution} onChange={(e) => setInstitution(e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>More information:</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <textarea value={message} onChange={(e) => setMessage(e.target.value)}>

                                </textarea>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <button onClick={handleSubmit}>Send</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
}