import React, {  useEffect, useRef } from 'react';
import API from '../../../API/API';
import Loading from '../../Loading';
import SharedHistoryEntry from './ShareHistoryEntry';
import './SharedHistory.scss';
import useMountAwareState from '../../../hooks/useMountAwareState';
export default function SharedHistory(props) {

    const [list, setList] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);

    const ref = useRef(null);

    useEffect(() => {
        API.getInstance()
        .get(`/resource-shares/${props.token}/history`, false)
        .then(({status, data}) => {
            if(status === 200) {
                setList(data);
            }else{
                setError(data.message);
            }
        })
        .catch((error) => {
            setError('Something went wrong');
        });

        const handleClickOutside = (e) => {
            if(ref && !ref.current.contains(e.target)) {
                props.close();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [props, setError, setList]);
    
    return (
        <div ref={ref} className={`SharedHistory${list === null ? ' loading':''}`}>
            <h2>Results history</h2>
            {error ? 
                <div>{error}</div>

            :
                (list === null ? 
                    <Loading />
                :
                    (list.length === 0 ?
                        <div>You have no result history yet.</div>
                    :
                        <ul>
                            {list.map((r, i) => {
                                return <li key={r.pid}>
                                    <SharedHistoryEntry result={r} />
                                </li>;
                            })}
                        </ul>
                    )   
                )
            }
        </div>
    );
}