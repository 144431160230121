import React, { useCallback, useEffect } from 'react';
import useMountAwareState from '../../hooks/useMountAwareState';
import './Feedback.scss';
import FeedbackMessage from './FeedbackMessage';
export default function Feedback(props) {
    
    const [list, setList] = useMountAwareState([]);
    const [toAdd, setToAdd] = useMountAwareState(null);
    const [toRemove, setToRemove] = useMountAwareState(null);
    
    const setNewFeedback = useCallback((feedback) => setToAdd(feedback), [setToAdd]);
    
    useEffect(() => {
        if(toAdd) {
            setList(list => [...list, toAdd.detail]);
        }

        return () => {
            
        }
    }, [setList, toAdd]);

    useEffect(() => {
        if(toRemove !== null) {
            setList(list => list.filter(elem => {return elem.id !== toRemove;}));
        }

        return () => {
            
        }
    }, [setList, toRemove]);
    
    useEffect(() => {
        document.addEventListener('feedback', setNewFeedback);
        
        return () => {
            document.removeEventListener('feedback', setNewFeedback);
        }
    }, [setNewFeedback]);

    const removeFeedback = (f) => {
        setToRemove(f);
    };
    
    return (
        <div className='Feedback'>
            {list.map((feedback, i) => {
                return <FeedbackMessage key={feedback.id} remove={removeFeedback} feedback={feedback} />
            })}
        </div>
    );
}