import { createContext } from 'react';

const PopupContext = createContext({
    popup: null,
    setPopup: (val) => {},
});

export const PopupProvider = PopupContext.Provider;
export const PopupConsumer = PopupContext.Consumer;

export default PopupContext;