import React, {  useEffect } from 'react';
import './Introduction.scss';
import ParallaxElement from './ParallaxElement';

import { Trans, useTranslation } from 'react-i18next';
import useMountAwareState from '../../hooks/useMountAwareState';
import usePrefersReducedMotion from '../../hooks/usePrefersReducedMotion';

export default function Introduction(props) {

    const [ t ] = useTranslation('main');
    const [paralaxIncidence, setParalaxIncidence] = useMountAwareState(window.scrollY);
    const prefersReducedMotion = usePrefersReducedMotion();

    useEffect(() => {
        const changeIncidence = (e) => {
            // Do not move if user prefers reduced motion
            if(prefersReducedMotion) return;
            setParalaxIncidence(window.scrollY);
        };
        document.addEventListener('scroll', changeIncidence);
        return () => {
            document.removeEventListener('scroll', changeIncidence);
        }
    }, [prefersReducedMotion, setParalaxIncidence]);
    
    
    return (
        <section className='Introduction'>
            <div className='container'>
                <div className='parallax-container left'>
                    <ParallaxElement type="word" incidence={paralaxIncidence} position={{x: 70, y: 85}} depth={1} zIndex={16}>
                        <span>Lessons</span>
                    </ParallaxElement>
                    <ParallaxElement type="word" incidence={paralaxIncidence} position={{x: 5, y: 50}} depth={1.5} zIndex={15}>
                        <span>Correction</span>
                    </ParallaxElement>
                    <ParallaxElement type="word" incidence={paralaxIncidence} position={{x: 65, y: 20}} depth={2} zIndex={14}>
                        <span>Exercise sheets</span>
                    </ParallaxElement>
                    <ParallaxElement type="word" incidence={paralaxIncidence} position={{x: 0, y: 5}} depth={2.5} zIndex={13}>
                        <span>Missing words</span>
                    </ParallaxElement>
                </div>
                <div className='content'>
                    <div className='text'>
                        <h2>
                            <Trans t={t} i18nKey="pages.presentation.introduction">
                                A creative and educative plateform to boost French learners.<br /> Resources for educators and students. 
                            </Trans>
                        </h2>
                    </div>
                </div>
                <div className='parallax-container right'>
                    <ParallaxElement type="word" incidence={paralaxIncidence} position={{x: 30, y: 70}} depth={1.2} zIndex={4}>
                        <span>Hidden words</span>
                    </ParallaxElement>
                    <ParallaxElement type="word" incidence={paralaxIncidence} position={{x: -20, y: 20}} depth={1.7} zIndex={3}>
                        <span>Quizzes</span>
                    </ParallaxElement>
                    <ParallaxElement type="word" incidence={paralaxIncidence} position={{x: 15, y: 30}} depth={2.2} zIndex={2}>
                        <span>Flashcards</span>
                    </ParallaxElement>
                    <ParallaxElement type="word" incidence={paralaxIncidence} position={{x: 35, y: 0}} depth={2.6} zIndex={1}>
                        <span>Crosswords</span>
                    </ParallaxElement>
                </div>
            </div>
        </section>
    );
}