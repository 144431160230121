import React from 'react';
import './ExplorerFolderLine.scss';
import FileLink from '../FileLink';

export default function ExplorerFolderLine(props) {

    const formatDate = (date) => {
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${date.getFullYear()}-${month < 10 ? 0 : ''}${month}-${day < 10 ? 0 : ''}${day}`;
    };

    const file = props.file;
    const date = new Date(file.modificationDate * 1000);

    return <div className='ExplorerFolderLine' onClick={props.handleClick}>
        <div className="name">
            <FileLink file={file} setFile={props?.setFile} />
        </div>
        <div className="details">
            <span className="date">{formatDate(date)}</span>
        </div>
    </div>;
}