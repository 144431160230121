import React, {  useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useMountAwareState from '../../../../hooks/useMountAwareState';
import correct_icon from '../../../../images/icons/correct.svg';
import wrong_icon from '../../../../images/icons/wrong.svg';
export default function QuizLongAnswer(props) {

    const [answer, setAnswer] = useMountAwareState(props.answer ?? "");
    const [ t ] = useTranslation('main');
    const isCorrect = useMemo(() => props.verifier(answer, props.module.answer), [answer, props]);

    useEffect(() => {
        const reset = (e) => {
            if(e.detail !== props.rid) return;
            setAnswer("");
        };

        document.addEventListener('quiz-reset', reset);
        return () => {
            document.removeEventListener('quiz-reset', reset);
        }
    }, [props.rid, setAnswer]);
    
    const handleChange = (e) => {
        setAnswer(e.target.value);
        props.setAnswer(e.target.value.length > 0 ? e.target.value : null);
    };

    const m = props.module;
    return (
        <div className='QuizLongAnswer quizz-module'>
            <header>
                <h2>{props.id + 1}. {m.text}</h2>
                <div className='result'>
                    {props.showAnswers ?
                        (isCorrect ?
                            <img width={123} height={88} src={correct_icon} alt="correct" />
                        :
                            <img width={89} height={85} src={wrong_icon} alt="wrong" />
                        )
                    : undefined}
                </div>
            </header>
            {props.error !== null ?
                <div className='error'>
                    {t(`pages.explorer.apps.quiz.errors.${m.type}.${props.error}`)}
                </div>
            : undefined}
            <div className='widget singlechoice'>
                {props.showAnswers ?
                    <>
                        <textarea onChange={handleChange} value={answer} disabled={true} readOnly={true} />
                        <div>{m.answer}</div>
                    </>
                : 
                    <textarea onChange={handleChange} value={answer} />
                }
            </div>
        </div>
    );
}