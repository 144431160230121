import React, {  useEffect, useRef, useCallback, useContext } from 'react';
import Loading from '../../../Loading';
import './FlashcardsListeningGame.scss';

import sound_icon from '../../../../images/icons/sound.svg';
import AssistedTextInput from '../../../assistedInput/AssistedTextInput';
import { useTranslation } from 'react-i18next';
import useMountAwareState from '../../../../hooks/useMountAwareState';
import SettingsContext from '../../../../Contexts/SettingsContext';

import uppercase_sensitivity_icon from '../../../../images/icons/uppercase_sensitivity.svg';
import ponctuation_sensitivity_icon from '../../../../images/icons/ponctuation_sensitivity.svg';

export default function FlashcardsListeningGame(props) {

    const [answers, setAnswers] = useMountAwareState([]);
    const [currentAnswer, setCurrentAnswer] = useMountAwareState('');
    const [index, setIndex] = useMountAwareState(0);
    const [audios, setAudios] = useMountAwareState([]);
    const [loading, setLoading] = useMountAwareState(true);
    const [loadingCount, setLoadingCount] = useMountAwareState(0);
    const [loadingErrors, setLoadingErrors] = useMountAwareState(0);
    const [lock, setLock] = useMountAwareState(false);
    const [error, setError] = useMountAwareState(false);

    const { settings } = useContext(SettingsContext);

    const ref = useRef(null);

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');
    
    useEffect(() => {
        setAudios(props.cards.map((card, i) => {
            const audio = new Audio(card.audio);
            audio.addEventListener('error', () => {
                setLoadingErrors(count => count + 1);
            });
            audio.addEventListener('loadeddata', () => {
                setLoadingCount(count => count+1);
            });
            return audio;
        }));
        
        const focusDelayer = setTimeout(() => {
            const elem = document.getElementById('input');
            if(elem) elem.focus();
        }, 800);
    
        return () => {
            if(focusDelayer !== null) clearTimeout(focusDelayer);
        }
    }, [props.cards, setAudios, setLoadingCount, setLoadingErrors]);

    const playFirstAudio = useCallback(
        () => {audios[0].play()},
        [audios],
    );

    useEffect(() => {
        if(loadingErrors > 0) {
            setError(true);
        }else if(loadingCount >= props.cards.length) {
            setLoading(false);
            playFirstAudio();
        }
    }, [loadingCount, loadingErrors, playFirstAudio, props.cards.length, setError, setLoading]);
    

    useEffect(() => {
        if(audios.length === 0 || index === 0) return;
        audios.forEach(audio => {
            audio.pause();
        });
        audios[index].play();
    
        return () => {
        
        }
    }, [index, audios]);
      

    const playAudio = (index) => {
        audios.forEach(audio => {
            audio.pause();
        });
        audios[index].play();
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();

        if(currentAnswer.length === 0) return;

        if(index < props.cards.length - 1) {
            setAnswers(prevAnswers => ([...prevAnswers, currentAnswer]));
            setCurrentAnswer('');
            setIndex(index+1);
        }else {
            end([...answers, currentAnswer]); 
        }
    };

    const handleChange = (e) => {
        setCurrentAnswer(e.target.value);
    };

    const end = (a) => {
        const results = a.map((answer, i, a) => {
            return {
                card: props.cards[i],
                answer: answer
            };
        });
        setLock(true);
        if(ref) {
            ref.current.querySelector('input[type="text"]').blur();
        }
        props.setResults(results);
    };

    return (
        <div ref={ref} className={`FlashcardsListeningGame ${props.animateOut ? 'animateOut' : ''} ${props.animateIn ? 'animateIn' : ''}`}>
            {error ?
                <div>
                    Unable to load {loadingErrors} audios.<br/>
                    They will be added soon.
                </div> 
            :    
                (loading ?
                    <Loading>
                        {`${loadingCount}/${props.cards.length} audios`}
                    </Loading>
                :
                    <>
                        {props?.token === undefined ?
                            <button className="back" onClick={props.reset}>{t('pages.explorer.apps.flashcards.back')}</button>
                        : undefined}
                        <div className='instructions'>
                            <span>{t(`pages.explorer.apps.flashcards.instructions.listening.${props.lang === 'fr' ? 'lfe' : 'lff'}`)}</span>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="flashcards-container">
                                {/* Invisible flashcard to have a constant size */}
                                <div className="flashcard flashcard-sizer">
                                    <button><img width={109} height={109} src={sound_icon} alt="" /></button>
                                </div>
                                {/* The actual displayed flashcard */}
                                {props.cards.map((c, i, a) => {
                                    return <div key={i} className={`flashcard${index === i ? ' current' : ''}${index > i ? ' previous' : ''}${index < i ? ' coming' : ''}`}>
                                        <div className='top'>
                                            <div className='number'>{i+1}/{a.length}</div>
                                            <div className='warnings'>
                                                {(props?.token === undefined && settings.uppercaseSensitivity) || (props?.token && (props?.share?.options?.uppercase_sensitivity ?? false)) ?
                                                    <img src={uppercase_sensitivity_icon} alt="uppercase on" title={tcommon('settings_instructions.uppercase')} />
                                                : undefined}
                                                {(props?.token === undefined && settings.ponctuationSensitivity) || (props?.token && (props?.share?.options?.ponctuation_sensitivity ?? false)) ?
                                                    <img src={ponctuation_sensitivity_icon} alt="ponctuation on" title={tcommon('settings_instructions.ponctuation')} />
                                                : undefined}
                                            </div>
                                        </div>
                                        <div className='content'>
                                            <button disabled={lock} onClick={(e) => { e.preventDefault(); playAudio(i); }}><img width={109} height={109} src={sound_icon} alt="" /></button>
                                            {c?.plural ?
                                                <div>Plural word</div>
                                            : undefined}
                                        </div>
                                    </div>;
                                })}
                            </div>
                            
                            <div className='input'>
                                <AssistedTextInput
                                    id="input"
                                    value={currentAnswer}
                                    onChange={(e) => {handleChange(e)}}
                                    autoComplete="off"
                                    autoCorrect="false"
                                    disabled={lock}
                                />
                                <input type="submit" value="&#10004;"/>
                            </div>
                        </form>
                    </>
                )
            }
        </div>
    );
}