import React, { useEffect, useRef, useContext } from 'react';
import ZoomImageContext from '../../Contexts/ZoomImageContext';
import useMountAwareState from '../../hooks/useMountAwareState';
import Loading from '../Loading';
import './ZoomedImage.scss';
export default function ZoomedImage(props) {

    const ref = useRef(null);
    const initialResizeRef = useRef(null);
    const { setZoomImage } = useContext(ZoomImageContext);
    const [resized, setResized] = useMountAwareState(0);
    const [loaded, setLoaded] = useMountAwareState(false);

    useEffect(() => {
        const resize = (e) => {
            if(ref.current) {
                const ww = window.innerWidth;
                const wh = window.innerHeight;

                const img = ref.current.querySelector('img');

                const imgWidth = img.offsetWidth;
                const imgHeight = img.offsetHeight;

                const ratio = imgWidth / imgHeight;

                const desiredWidth = Math.round(ww * 0.9);

                if(desiredWidth / ratio > 0.9 * wh) {
                    img.style.width = 'auto';
                    img.style.height = `${Math.round(wh * 0.9)}px`;
                }else{
                    img.style.height = 'auto';
                    img.style.width = `${desiredWidth}px`;
                }
                setResized(v => v+1);
            }
        }
        const setResizeTimeout = (e, time = 100) => {
            setLoaded(true);
            initialResizeRef.current = setTimeout(resize, time);
        };
    
        window.addEventListener('resize', resize);
        ref.current.querySelector('img').addEventListener('load', setResizeTimeout);
        setResizeTimeout();
        return () => {
            window.removeEventListener('resize', resize);
            if(initialResizeRef.current) clearTimeout(initialResizeRef.current);
        }
    }, [setLoaded, setResized]);

    const close = () => {
        setZoomImage(null);
    };
    
    return (
        <div ref={ref} className={`ZoomedImage ${props?.rounded === true ? 'rounded' : ''}`} onClick={close}>
            <img className={resized >= 2 ? 'resized' : 'loading'} width={props.width} height={props.height} src={props.src} alt={props.alt ?? ''} />
            <div className={`loading ${loaded ? 'loaded' : ''}`}>
                <Loading />
            </div>
        </div>
    );
}