import React, {  useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './ResourceLinksPopup.scss';
import FileLink from '../FileLink';
import Popupable from './Popupable';
export default function ResourceLinksPopup(props) {

    const sortedExercises = useMemo(() => {
        let sorted = undefined;
        if(props.resource.links?.exercise !== undefined) {
            sorted = {};
            props.resource.links.exercise.forEach(e => {
                if(sorted[e.type] === undefined) {
                    sorted[e.type] = {
                        title: e.stylized_type,
                        links: [e]
                    };
                }else{
                    sorted[e.type].links.push(e);
                }
            });
        };
        return sorted;
    }, [props.resource.links]);

    // const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');
    
    return <Popupable title="Resource links" close={props.close}>
        <div className='ResourceLinksPopup'>
            {props.resource.links?.document !== undefined ?
                <section>
                    <h2>{tcommon('general.documents')}</h2>
                    <div className='list-horizontal'>
                        {props.resource.links.document.map((link, i) => {
                            return <React.Fragment key={i}>
                                <FileLink onClick={props.close} file={link} />
                            </React.Fragment>;
                        })}
                    </div>
                </section>
            : undefined}
            {sortedExercises !== undefined ?
                <section>
                    <h2>{tcommon('general.exercises')}</h2>
                    <div className='list-vertical exercises-list'>
                        {Object.keys(sortedExercises).map((key) => {
                            return <div key={key} className='exercises-list-section'>
                                <h3><span className='bull'>&bull;</span>{sortedExercises[key].title}</h3>
                                {/* <h3><ResourceIcon type={key} />{links.exercise[key].title}</h3> */}
                                <div key={key} className='list-horizontal'>
                                    {sortedExercises[key].links.map((link, i) => {
                                        return <React.Fragment key={link.rid}>
                                            <FileLink onClick={props.close} file={link} />
                                        </React.Fragment>;
                                    })}
                                </div>
                            </div>;
                        })}
                    </div>
                </section>
            : undefined}
        </div>
    </Popupable>;
}