import React from 'react';
import { useTranslation } from 'react-i18next';
import ZoomableImage from '../../../utils/ZoomableImage';
export default function QuizImage(props) {
    
    const [ t ] = useTranslation('main');

    const m = props.module;
    return (
        <section className='QuizImage quizz-module'>
            <header>
                <h2>{props.id + 1}. {m.text}</h2>
            </header>
            {props.error !== null ?
                <div className='error'>
                    {t(`pages.explorer.apps.quiz.errors.${m.type}.${props.error}`)}
                </div>
            : undefined}
            <div className='widget video'>
                <ZoomableImage src={m.url} alt={m?.alt ?? ''} />
            </div>
        </section>
    );
}