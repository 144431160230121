import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import API from '../../API/API';
import useMountAwareState from '../../hooks/useMountAwareState';
import ErrorPage from '../ErrorPage';
import FileIcon from '../FileIcon';
import Loading from '../Loading';
import TimeDiffDisplay from '../utils/TimeDiffDisplay';
import SharedIdentify from './SharedIdentify';
import './SharedUnauthenticated.scss';
export default function SharedUnauthenticated(props) {

    const [share, setShare] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);
    const [ tcommon ] = useTranslation('common');
    const { sharePid = undefined } = useParams();

    useEffect(() => {
        API.getInstance()
        .get(`/resource-shares/${sharePid}/unauthenticated-data`, false)
        .then(({status, data}) => {
            if(status === 200) {
                setShare(data);
            }else{
                setError(tcommon('error.fetch.default', {code: status}));
            }
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
        });
    }, [setError, setShare, sharePid, tcommon]);
    
    
    if(error !== null) {
        if(error === 403) {
            return <ErrorPage
                code={error}
                error={tcommon('error.page.forbidden')}
                noReturnToHome={true}
            />;
        }else if(error === 404) {
            return <ErrorPage
                code={error}
                error={tcommon('error.page.not_found')}
                noReturnToHome={true}
            />;
        }else{
            return <ErrorPage message="test" noReturnToHome={true} />;
        }
        
    }
    if(share === null) return <Loading />;
    return (
        <div className='SharedUnauthenticated'>
            <div className='content'>
                <div className='info'>
                    <h1>New activity due!</h1>
                    <table>
                        <tbody>
                            <tr>
                                <td>Resource:</td>
                                <td>
                                    <div>
                                        <FileIcon type={share.resource.type} />{share.resource.name}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Due:</td>
                                <td>
                                    <div>
                                    {new Date(share.due_date * 1000).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric', hour12: true, hour: 'numeric', minute: 'numeric'})}
                                        <TimeDiffDisplay to={new Date(share.due_date * 1000)} beforeSuffix={'ago'} afterSuffix={'left'} updateInterval={1000} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <SharedIdentify />
            </div>
        </div>
    );
}