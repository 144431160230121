import React, { useCallback, useEffect, useRef } from "react";
import "./Flashcard.scss";

import logo from "../../../../images/logo.svg";
import trun_around_icon from "../../../../images/icons/turn-around.svg";
import sound_icon from "../../../../images/icons/sound.svg";
import loading_audio_error_icon from "../../../../images/icons/loading_audio_error.svg";
import loading_audio_icon from "../../../../images/icons/loading_audio.svg";
import useMountAwareState from "../../../../hooks/useMountAwareState";
import { useTranslation } from "react-i18next";

export default function Flashcard(props) {
  const [turned, setTurned] = useMountAwareState(null); // null as initial state to not have the turning animation on first render
  const [tcommon] = useTranslation("common");
  const playAudioButtonRef = useRef(null);

  const turn = useCallback(() => {
    setTurned((turned) => !(turned ?? false));
  }, [setTurned]);

  const playAudio = useCallback(() => {
    props.audio.audio.play().catch((error) => {
      console.error("Unable to play audio:", error);
    });
  }, [props.audio]);

  const handleKeyDown = useCallback(
    (e) => {
      const inputTags = ["INPUT", "TEXTAREA"];
      if (
        document?.activeElement &&
        !inputTags.includes(document.activeElement.tagName)
      ) {
        if (e.code === "Space") return turn();
        if (e.code === "KeyP") return turned ? playAudio() : null;
      }
    },
    [playAudio, turn, turned]
  );

  const ratio = 0.6;

  useEffect(() => {
    const handleCardChange = () => {
      setTurned(null);
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("change-flashcard", handleCardChange);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("change-flashcard", handleCardChange);
    };
  }, [handleKeyDown, playAudio, props, props.data.audio, setTurned, turn]);

  const handleClick = (e) => {
    if (
      playAudioButtonRef.current &&
      playAudioButtonRef.current.contains(e.target)
    ) {
      if (props.audio.audio !== null) playAudio();
    } else {
      turn();
    }
  };

  const data = props.data;

  const front_state = turned === null ? "" : turned ? "visible" : "hidden";
  const back_state = turned === null ? "" : turned ? "hidden" : "visible";

  return (
    <div
      className={`Flashcard ${props.animate ? props.animate : ""}`}
      onClick={handleClick}
    >
      <img width={1778} height={1069} src={ratio} className="ratio" alt="" />
      <div className={`front ${front_state}`}>
        <div className="topbar">
          <div className="id">
            {props.number + 1}/{props.totalCards}
          </div>
          <img width={128} height={128} className="logo" src={logo} alt="" />
        </div>
        <div className="content">
          {data.front?.image !== undefined ? (
            <img
              className="logo"
              style={{ borderColor: data.color ?? "#ffc114" }}
              src={data.front.image}
              alt=""
            />
          ) : undefined}
          <h2>{data.front.text}</h2>
        </div>
        <div className="controls">
          <button>
            <img
              width={102}
              height={102}
              src={trun_around_icon}
              alt="Turn card"
            />
          </button>
        </div>
      </div>
      <div className={`back ${back_state}`}>
        <div className="topbar"></div>
        <div className="content">
          {data.front?.image !== undefined ? (
            <img
              className="logo"
              style={{ borderColor: data.color ?? "#ffc114" }}
              src={data.front.image}
              alt=""
            />
          ) : undefined}
          <h2>{data.back.text}</h2>
        </div>
        <div className="controls">
          <button>
            <img
              width={102}
              height={102}
              src={trun_around_icon}
              alt="Turn card"
            />
          </button>
          <button
            ref={playAudioButtonRef}
            className={`${props.audio === null ? "error" : ""}`}
            onMouseDown={handleClick}
          >
            <img
              width={109}
              height={109}
              src={sound_icon}
              alt="Listen card"
              className="play-audio"
            />
            {props.audio?.error ?? true ? (
              <span className="error">
                <img
                  src={loading_audio_error_icon}
                  title={tcommon("error.general.default")}
                  alt={tcommon("error.general.default")}
                  width={102}
                  height={102}
                />
              </span>
            ) : !props.audio.loaded ? (
              <span className="loading">
                <img
                  src={loading_audio_icon}
                  title={tcommon("general.loading")}
                  alt={tcommon("general.loading")}
                  width={102}
                  height={102}
                />
              </span>
            ) : undefined}
          </button>
        </div>
      </div>
    </div>
  );
}
