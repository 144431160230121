import React, {  useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import API from '../../../API/API';
import Loading from '../../Loading';
import student_icon from '../../../images/icons/person.svg';
import useMountAwareState from '../../../hooks/useMountAwareState';
export default function ClassStudentsPreview(props) {

    const [students, setStudents] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);
    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');
    const classPid = props.classPid;

    useEffect(() => {
        API.getInstance()
        .get(`/users/self/classes/${classPid}/students`)
        .then(({status, data}) => {
            if(status === 200) {
                setStudents(data);
            }else if(status === 404) {
                setError(tcommon('error.page.not_found'));
            }else{
                setError(tcommon('error.fetch.default', {code: status}));
            }
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
        });
    
      
    }, [classPid, setError, setStudents, tcommon]);
    
    return (
        <div className='ClassStudentsPreview'>
            <h2>
                <img src={student_icon} alt="" />
                Students:
            </h2>
            <div className='manage'>
                <Link to={`/classes/${classPid}/students`} className='see-all'>Manage</Link>
            </div>
            <div className='list'>
                {error ?
                    <div>{error}</div>
                :
                    (students === null ?
                        <Loading />
                    :
                        (students.length === 0 ?
                            <div className='empty'>{t('pages.classes.class_details.previews.no_students')}</div>
                        : 
                            students.map(s => {
                                return <Link key={s.pid} to={`/classes/${classPid}/students/${s.pid}`}>
                                    <span>{s.first_name} {s.last_name}</span>
                                    <div className='separator'></div>
                                </Link>;
                            })
                        )
                    )
                }
            </div>
        </div>
    );
}