import React, { useEffect } from 'react';
import './HiddenWordsViewer.scss';

import HiddenWordsGenerator from '../../../../helpers/HiddenWordsGenerator';
import HiddenWordsGrid from './HiddenWordsGrid';
import HiddenWordsList from './HiddenWordsList';
import useMountAwareState from '../../../../hooks/useMountAwareState';

export default function HiddenWordsViewer(props) {

    const [ grid ] = useMountAwareState((new HiddenWordsGenerator(props.data.words)).generate());
    const [validatedWords, setValidatedWords] = useMountAwareState([]);

    useEffect(() => {
        if(props.data.words.length === validatedWords.length) {
            console.log('WIN!');
        }
    }, [props.data.words, validatedWords]);

    const addValidatedWord = (word) => {
        setValidatedWords(curr => [...curr, word]);
    };
    

    return <div className='HiddenWordsViewer'>
        <HiddenWordsGrid grid={grid} words={props.data.words} validatedWords={validatedWords} addValidatedWord={addValidatedWord} />
        <HiddenWordsList title={props.data.title} words={props.data.words} validatedWords={validatedWords} />
    </div>;
}