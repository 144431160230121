import React from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../API/API';
import Popupable from '../Popup/Popupable';
import './DeleteClass.scss';
import useFeedback from '../../hooks/useFeedback'; 
import useMountAwareState from '../../hooks/useMountAwareState';
export default function DeleteClass(props) {

    const feedback = useFeedback();
    const [error, setError] = useMountAwareState(null);
    const [lock, setLock] = useMountAwareState(false);
    const [ tcommon ] = useTranslation('common');
    const [ t ] = useTranslation('main');

    const deleteClass = () => {
        setLock(true);
        API.getInstance()
        .delete(`/users/self/classes/${props.class.pid}`)
        .then(({status, data}) => {
            if(status === 200 || status === 204) {
                feedback.add(t('pages.classes.dialogs.delete_class.class_deleted'));
                const refreshEvent = new CustomEvent('class-update');
                document.dispatchEvent(refreshEvent);
                props.close();
                props?.success?.();
            }else{
                setError(tcommon('error.fetch.default', {code: status}));
            }
            setLock(false);
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
            setLock(false);
        });
    };
    
    return (
        <Popupable title={t('pages.classes.dialogs.delete_class.title')} close={props.close}>
            <form className='DeleteClass' onSubmit={e => e.preventDefault()}>
                <div>
                    <h2>{t('pages.classes.dialogs.delete_class.confirm', {class_name: props.class.name})}</h2>
                    <p>
                        {t('pages.classes.dialogs.delete_class.warning')}
                    </p>
                    {error ?
                        <div>{error}</div>
                    : undefined}
                    <div>
                        <button onClick={props.close} disabled={lock}>{tcommon('general.cancel')}</button>
                        <button className='red' onClick={deleteClass}>{tcommon('general.delete')}</button>
                    </div>
                </div>
            </form>
        </Popupable>
    );
}