import React, { useContext } from 'react';
import './ShareLine.scss';
import { useNavigate } from 'react-router-dom';
import TimeDiffDisplay from '../../utils/TimeDiffDisplay';
import FileIcon from '../../FileIcon';
import ScoreDisplay from '../ScoreDisplay';
import HideStudentResultsContext from '../../../Contexts/HideStudentResultsContext';
import hide_icon from '../../../images/icons/hide.svg';

export default function ShareLine(props) {

    const navigate = useNavigate();
    const { hideStudentResults } = useContext(HideStudentResultsContext);

    const share = props.share;


    // const canResend = useMemo(() => {
    //     return (!lockResend) && (!student.is_confirmed) && (student.last_invitation_success === null || (student.last_invitation_success !== null && (student.last_invitation_success * 1000 < ((new Date()).getTime() - resendDelay))))
    // }, [lockResend, resendDelay, student.is_confirmed, student.last_invitation_success]);

    return <tr className='ShareLine' key={share.pid} onClick={() => navigate(`/classes/${props.classPid}/shares/${share.pid}`)}>
        <td className='resource'>
            <div>
                <FileIcon type={share.resource.type} /> {share.resource.name}
            </div>
        </td>
        <td className='score'>
            <div className='container'>
                <div className='score-group'>
                    <ScoreDisplay score={share.average_score} noText={true} />
                    {hideStudentResults ?
                        <>
                            <img src={hide_icon} alt="hidden" />
                            %
                        </>
                    :
                        (share.average_score >= 0 ?
                            <span>{Math.round(share.average_score)}%</span>
                        :
                            undefined
                        )
                    }
                </div>
            </div>
        </td>
        <td className='created'>
            <div>
                {new Date(share.creation_date * 1000).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric', hour12: true, hour: 'numeric', minute: 'numeric'})}
            </div>
        </td>
        <td className='due'>
            <div>
                {new Date(share.due_date * 1000).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric', hour12: true, hour: 'numeric', minute: 'numeric'})}
                <TimeDiffDisplay to={new Date(share.due_date * 1000)} beforeSuffix={'ago'} afterSuffix={'left'} /> 
            </div>
        </td>
        <td className='results'>
            <div>
                {share.students_with_results.length} / {share.students_count_at_sending}
            </div>
        </td>
    </tr>;
}