import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import API from "../../../../API/API";
import useCookieConsent from "../../../../hooks/useCookieConsent";
import useMountAwareState from "../../../../hooks/useMountAwareState";
import "./BookletShortcuts.scss";
import BookletShortcutsExplorer from "./BookletShortcutsExplorer";
import BookletShortcutsMenu from "./BookletShortcutsMenu";
import loadingIcon from "../../../../images/icons/spinner.svg";

export default function BookletShortcuts(props) {
  const { functionality } = useCookieConsent();
  const [data, setData] = useMountAwareState(null);
  const [error, setError] = useMountAwareState(null);
  if (localStorage.getItem("home-widget-booklets") === "menu") {
    localStorage.setItem("home-widget-booklets", "booklets");
  }
  const sessionIndex =
    localStorage.getItem("home-widget-booklets") || "booklets";
  const [index, setIndex] = useMountAwareState(sessionIndex);
  const [isLoading, setIsLoading] = useState(false);
  const [tcommon] = useTranslation("common");

  useEffect(() => {
    if (!index) return;
    localStorage.setItem("home-widget-booklets", index);
    setData(null);
    setIsLoading(true);
    setError(null);
    API.getInstance()
      .get(`/resources/${index}?admin_data=${true}`)
      .then(({ status, data }) => {
        setIsLoading(false);
        if (status === 200) {
          setData(data);
        } else {
          setIndex("booklets");
          setError(tcommon("error.general.default"));
        }
      })
      .catch((err) => {
        console.error(err);
        setIndex("booklets");
        setIsLoading(false);
        setError(tcommon("error.general.default"));
      });
  }, [setData, setError, tcommon, index, setIndex]);

  const selectIndex = (index) => {
    if (functionality) localStorage.setItem("home-widget-booklets", index);
    setIndex(index);
  };

  return (
    <section className="BookletShortcuts">
      <div className="content">
        {isLoading && (
          <img
            src={loadingIcon}
            alt="icon"
            className="loading-icon spinner-loader"
          />
        )}
        {error ? (
          <div>{error}</div>
        ) : index === "booklets" ? (
          !isLoading && (
            <BookletShortcutsMenu
              selectBooklet={selectIndex}
              booklets={data?.children_object}
            />
          )
        ) : (
          data && (
            <BookletShortcutsExplorer booklet={data} setIndex={selectIndex} />
          )
        )}
      </div>
    </section>
  );
}
