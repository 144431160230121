import { useContext, useEffect } from 'react';
import API from '../API/API';
import CookieConsentContext from '../Contexts/CookieConsentContext';
export default function useCookieConsent() {
    
    const { cookieConsent } = useContext(CookieConsentContext);

    useEffect(() => {
        API.getInstance().setCookieConsent(parseInt([
            cookieConsent?.marketing ? 1 : 0,
            cookieConsent?.tracking ? 1 : 0,
            cookieConsent?.functionality ? 1 : 0,
        ].join(''), 2))
    }, [cookieConsent]);
    
    return {
        functionality: cookieConsent?.functionality ?? false,
        tracking: cookieConsent?.tracking ?? false,
        marketing: cookieConsent?.marketing ?? false,
        list: {
            cookies: {
                necessary: [
                    'CookieConsent',
                ]
            },
            localStorage: {
                necessary: [
                    'AccessToken',
                    'User',
                    '_grecaptcha',   
                ],
                functionality: [ 
                    'lang',    
                    'requested-subscription',
                    'see-results-period',
                    'see-results-first-only',
                    'see-class-results-sort',
                    'hide-student-results',
                    'display-preference',
                    'home-widget-booklets',
                    'settings.uppercase-sensitivity',
                    'settings.ponctuation-sensitivity',
                    'share.uppercase-sensitivity',
                    'share.ponctuation-sensitivity',
                ]
            }
        }
    };
}