import React, {  useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../../../API/API';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import ErrorPage from '../../ErrorPage';
import Loading from '../../Loading';
import ClassesBreadcrumb from '../ClassesBreadcrumb';
import './ClassDetails.scss';
import ClassScoreInfo from './ClassScoreInfo';
import ClassSharesPreview from './ClassSharesPreview';
import ClassStudentsPreview from './ClassStudentsPreview';
import ContextMenuContext from '../../../Contexts/ContextMenuContext';

import ContextMenu from '../../ContextMenu';
import RenameClass from '../RenameClass';
import DeleteClass from '../DeleteClass';
import useMountAwareState from '../../../hooks/useMountAwareState';
import PopupContext from '../../../Contexts/PopupContext';

import email_icon from '../../../images/icons/email.svg';
import links_icon from '../../../images/icons/link.svg';

export default function ClassDetails(props) {

    const navigate = useNavigate();

    const { setContextMenu } = useContext(ContextMenuContext);

    const [classData, setClassData] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);
    const [errorCode, setErrorCode] = useMountAwareState(null);
    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');
    const { classPid } = useParams();
    const { setPopup } = useContext(PopupContext);

    const fetchClassData = useCallback(
        (seemless = false) => {
            if(!seemless) setClassData(null);
            setError(null);
            setErrorCode(null);
            API.getInstance()
            .get(`/users/self/classes/${classPid}`)
            .then(({status, data}) => {
                if(status === 200) {
                    setClassData(data);
                }else if(status === 404) {
                    setError(tcommon('error.page.not_found'));
                    setErrorCode(404);
                }else{
                    setError(tcommon('error.fetch.default', {code: status}));
                }
            })
            .catch(err => {
                setError(tcommon('error.general.default'));
            });
        },
        [classPid, setClassData, setError, setErrorCode, tcommon],
    );

    useEffect(() => {
        fetchClassData();

        const seemlessRefresh = () => {
            fetchClassData(true);
        };

        document.addEventListener('class-update', seemlessRefresh);
        return () => {
            document.removeEventListener('class-update', seemlessRefresh);
        }
    }, [fetchClassData]);
    
    useDocumentTitle(classData === null ? 'Loading' : classData.name);

    const openRenamePopup = () => {
        setPopup(
            <RenameClass class={classData} close={() => setPopup(null)} />
        );
    };

    const openDeletePopup = () => {
        setPopup(
            <DeleteClass class={classData} close={() => setPopup(null)} success={() => navigate('/classes')} />
        );
    };

    const getContextMenu = (position) => {
        return <ContextMenu position={position}>
            <button onClick={e => {openRenamePopup(); setContextMenu(null);}}>{tcommon('context_menu.rename')}</button>
            <button className='red' onClick={() => {openDeletePopup(); setContextMenu(null);}}>{tcommon('context_menu.delete')}</button>
        </ContextMenu>;
    };

    const handleContextMenu = (e) => {
        e.preventDefault();
        const position = {
            x: e.pageX,
            y: e.pageY
        };
        setContextMenu(
            getContextMenu(position)
        );
    };
    
    if(error) return <ErrorPage code={errorCode ?? undefined} error={error} noReturnToHome={true} />;
    if(classData === null) return <Loading>Fetching class data</Loading>;
    return (
        <section className='ClassDetails'>
            <header>
                <ClassesBreadcrumb links={[
                    {to: `/classes/${classPid}`, label: classData.name}
                ]} />
            </header>
            <main>
                <div className='general-info'>
                    <div className='header'>
                        <h1>{classData.name}</h1> {/* <img src={class_icon} alt="" /> */}
                        <button className='dotdotdot' onClick={handleContextMenu}>
                            <div>
                                &#9679;&#9679;&#9679;
                            </div>
                        </button>
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                <td>{t('pages.classes.class_details.info.type.label')}:</td>
                                <td>
                                    <div className='type'>
                                        {classData.type === 'links' ?
                                            <img src={links_icon} alt="" />
                                        :
                                            <img src={email_icon} alt="" />
                                        }
                                        {t(`pages.classes.class_details.info.type.${classData.type}`)}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('pages.classes.class_details.info.created')}:</td>
                                <td>{new Date(classData.creation_date * 1000).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric'})}</td>
                            </tr>
                            <tr>
                                <td>{t('pages.classes.class_details.info.students')}:</td>
                                <td>{classData.students_count}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='score-info'>
                    <ClassScoreInfo classPid={classPid} classData={classData} />
                </div>
                <div className='previews'>
                    <ClassStudentsPreview classPid={classPid} />
                    <ClassSharesPreview classPid={classPid} />
                </div>
            </main>
        </section>
    );
}