import DateDiff from 'date-diff';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getRelevantDiffUnit } from '../../helpers/DateDiffHelper';
import useMountAwareState from '../../hooks/useMountAwareState';
import './TimeDiffDisplay.scss';
export default function TimeDiffDisplay(props) {

    const updateInterval = props.updateInterval ?? 1000;
    const update = props?.from === undefined && updateInterval > 0;
    const beforeSuffix = props.beforeSuffix ?? 'late';
    const afterSuffix = props.afterSuffix ?? 'early';

    const [diff, setDiff] = useMountAwareState(new DateDiff(props.to, props?.from ?? new Date()));
    const [diffRelevantUnit, setDiffRelevantUnit] = useMountAwareState(getRelevantDiffUnit(diff));
    const [suffix, setSuffix] = useMountAwareState(diffRelevantUnit.late ? beforeSuffix : afterSuffix);
    const [ tcommon ] = useTranslation('common');

    const intervalIdRef = useRef(null);

    useEffect(() => {
        if(update) {
            intervalIdRef.current = setInterval(() => {
                const newDiff = new DateDiff(props.to, props?.from ?? new Date());
                const newRelevantUnit = getRelevantDiffUnit(newDiff);
                setDiff(newDiff);
                setSuffix(newRelevantUnit.late ? beforeSuffix : afterSuffix);
                setDiffRelevantUnit(newRelevantUnit);
            }, updateInterval);
        }
        
        return () => {
            if(intervalIdRef.current) clearInterval(intervalIdRef.current);
        }
    }, [props.to, props?.from, beforeSuffix, afterSuffix, updateInterval, intervalIdRef, update, setDiff, setSuffix, setDiffRelevantUnit]);
    
    return (
        <span className={`TimeDiffDisplay time-diff ${suffix}`} title={props.to.toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit'})}>
            {tcommon(`duration.${suffix}`, {count: Math.abs(Math.round(diffRelevantUnit.value)), unit: diffRelevantUnit.unit})}
        </span>
    );
}