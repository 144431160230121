import React from 'react';
import './HiddenWordsList.scss';
export default function HiddenWordsList(props) {


    
    return (
        <div className='HiddenWordsList'>
            <h1>{props.title}</h1>
            <ul>
                {props.words.map((w, i) => {
                    return <li key={i} className={props.validatedWords.includes(w) ? 'found' : ''}>{w}</li>
                })}
            </ul>
        </div>
    );
}