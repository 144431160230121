import React, { useContext } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import './Login.scss';

import API from '../../API/API';
import AuthContext from '../../Contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import Dot3 from '../utils/Dot3';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import logo from '../../images/logo.svg';
import useMountAwareState from '../../hooks/useMountAwareState';

export default function Login(props) {
    useDocumentTitle('Login');
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useContext(AuthContext);

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');
    
    const from = location.state?.from?.pathname || "/";

    const [username, setUsername] = useMountAwareState('');
    const [password, setPassword] = useMountAwareState('');
    const [error, setError] = useMountAwareState(null);
    const [attempting, setAttempting] = useMountAwareState(false);

    if(auth.auth && props.onSuccess === undefined) return <Navigate to={from} replace />;
    
    const handleSubmit = (e) => {
        e.preventDefault();
        if(username === '' || password === '') {
            setError('form.error.incomplete');
            return;
        }
        setAttempting(true);
        API.getInstance().login({
            username: username,
            password: password
        })
        .then(({status, data}) => {
            if(status === 201) {
                auth.setAuth(true);
                if(props.onSuccess === undefined) {
                    navigate(from, { replace: true });
                }else{
                    props.onSuccess();
                }
                
            }else{
                setError('form.error.invalid_credentials')
            }
            setAttempting(false);
        })
        .catch((error) => {
            setAttempting(false);
        });
    };
    
    return (
        <div className='content login'>
            <img width={128} height={128} src={logo} alt="" />
            <div className='display'>
                <form className='Login' onSubmit={handleSubmit}>
                    <h1>{props.alternativeTitle ?? t('pages.sign_in.welcome_back')}</h1>
                    <label>
                        <input type="mail"
                                name="email" 
                                placeholder={`${tcommon('form.email')}...`}
                                onChange={e => setUsername(e.target.value)} 
                                value={username} 
                                required
                                autoComplete="email"
                        />
                    </label>
                    <label>
                        <input type="password"
                                name="password" 
                                placeholder={`${tcommon('form.password')}...`} 
                                onChange={e => setPassword(e.target.value)} 
                                value={password} 
                                required
                                autoComplete="current-password"
                        />
                    </label>
                    <div className='forgot-password'>
                        <a target="_blank" rel="noreferrer" href={`https://waeelearning.com/login/forgotten-password${username.length > 0 ? `?email=${username}` : ''}`}>{tcommon('form.forgot_password')}</a>
                    </div>
                    {error ?
                        <div className='error'>
                            <div className='bubble error'>
                                <div><span>error</span></div>
                                <div>{tcommon(error)}</div>
                            </div>
                        </div>
                    : ''}
                    <button disabled={attempting} className='btn-yellow'>
                        {attempting ? <>{tcommon('form.signing_in')}<Dot3 /></> : tcommon('form.sign_in')}
                    </button>
                    <div className='no-account'>
                        {tcommon('form.no_account_yet')} 
                        {props.changeMode === undefined ? 
                            <Link to="/register">{tcommon('form.sign_up_here')}</Link>
                        :
                            <button onClick={props.changeMode}>{tcommon('form.sign_up_here')}</button>
                        }
                    </div>
                    <div className="legals">
                        <a target="_blank" rel='noreferrer' href="https://waeelearning.com/terms-of-service">{tcommon('form.tos')}</a>
                        <span className='separator'></span>
                        <a target="_blank" rel='noreferrer' href="https://waeelearning.com/privacy-policy">{tcommon('form.privacy_policy')}</a>
                    </div>
                </form>      
            </div>
        </div>
    );
}