import React from 'react';
import './Loading.scss';
import logo from '../images/logo.svg';
import { useTranslation } from 'react-i18next';

export default function Loading(props) {

    const [ t ] = useTranslation('common');

    return (
        <div className='Loading'>
            <div className='animation'>
                <img width={46} height={50} src={logo} alt={`${t('info.loading')}...`} />
                <div>
                    <div></div>
                </div>
            </div>
            <h2>{props.children}</h2>
        </div>
    );
}