import React, { useContext } from 'react';
import HideStudentResultsContext from '../../Contexts/HideStudentResultsContext';
import CircularPercentage from './CircularPercentage';
// import './ScoreDisplay.scss';
export default function ScoreDisplay(props) {

    const { hideStudentResults } = useContext(HideStudentResultsContext);
    
    if(hideStudentResults) {
        return <CircularPercentage value={0} noText={true} emptyColor={props.emptyColor} colors={props.colors} />
    }else{
        return <CircularPercentage value={props.score} noText={props.noText} emptyColor={props.emptyColor} colors={props.colors} />
    }
}