import React, { useMemo } from 'react';
// import useGrowingNumber from '../../hooks/useGrowingNumber';
import './CircularPercentage.scss';
export default function CircularPercentage(props) {
    const value = props.value ?? -1;
    //useGrowingNumber(props.value ?? -1, 'linear', 5);

    const emptyColor = props.emptyColor ?? '#eaeaea';
    const defaultColors = {
        80: '#34c95c',
        50: '#ff7300',
        0: '#e0041e',
    };
    const colors = props.colors ?? defaultColors;

    const color = useMemo(() => {
        if(value <= 0) return emptyColor;
        let max = 0;
        for (const [i] of Object.entries(colors)) {
            if(value > i && max < i) max = i;
        }
        return colors[max];
    }, [colors, value, emptyColor]);

    const clipPath = useMemo(() => {
        let points = [
            {
                x: 50,
                y: 50
            },
            {
                x: 55,
                y: 0
            }
        ];
        if(value > 0) {
            points.push({
                x: (((value <= 12.5 ? value : 12.5) * 50) / 12.5) + 50,
                y: 0
            });
        }
        if(value > 7.5) {
            points.push({
                x: 100,
                y: ((((value <= 37.5 ? value : 37.5) - 12.5) * 100) / (37.5 - 12.5))
            });
        }
        if(value > 37.5) {
            points.push({
                x: 100 -((((value <= 62.5 ? value : 62.5) - 37.5) * 100) / (62.5 - 37.5)),
                y: 100
            });
        }
        if(value > 62.5) {
            points.push({
                x: 0,
                y: 100 - ((((value <= 87.5 ? value : 87.5) - 62.5) * 100) / (87.5 - 62.5))
            });
        }
        if(value > 87.5) {
            points.push({
                x: ((((value <= 100 ? value : 100) - 87.5) * 50) / (100 - 87.5)),
                y: 0 
            });
        }

        return points.map(p => `${p.x}% ${p.y}%`).join(',');
    }, [value]);
    
    return (
        <div className='CircularPercentage'>
            <div className='circles'>
                <div className='circle background' style={{backgroundColor: emptyColor}}></div>
                <div className='circle outer' style={{
                    clipPath: `polygon(${clipPath})`,
                    backgroundColor: color
                }}></div>
                <div className='circle inner'></div>
                <div className='tip tip1'>
                    <div style={{backgroundColor: color}}></div>
                </div>
                <div className='tip tip2' style={{transform: `rotate(${(value * 360) / 100}deg)`}}>
                    <div style={{backgroundColor: color}}></div>
                </div>
                <div className='circle value'>
                    <span>
                        {props.noText ?? false ?
                            undefined
                        :
                            (value >= 0 ?
                                <>{Math.round(value)}%</>
                            :
                                <div style={{textAlign: 'center', fontSize: '80%'}}>Nothing<br/>yet</div>
                            )
                        }
                    </span>
                </div>
            </div>
            {props.title ? 
                <div className='title'>{props.title}</div>
            : undefined}
        </div>
    );
}