import React, { useContext } from 'react';
import './MissingWordsViewer.scss';

import AssistedTextInput from '../../../assistedInput/AssistedTextInput';
import StringCleaner from '../../../../helpers/StringCleaner';
import API from '../../../../API/API';
import { useTranslation } from 'react-i18next';
import FileIcon from '../../../FileIcon';
import useMountAwareState from '../../../../hooks/useMountAwareState';
import SettingsContext from '../../../../Contexts/SettingsContext';

import uppercase_sensitivity_icon from '../../../../images/icons/uppercase_sensitivity.svg';
import ponctuation_sensitivity_icon from '../../../../images/icons/ponctuation_sensitivity.svg';

export default function MissingWordsViewer(props) {

    const texts = props.data.text.split(/\[[^\]]+\]/g);

    const missings = [...props.data.text.matchAll(/\[([^\]]+)\]/g)].map(m => m[1]);

    const emptyAnswers = Array(missings.length).fill('');

    const [answers, setAnswers] = useMountAwareState(props?.result?.data?.answers ??emptyAnswers);
    const [verification, setVerification] = useMountAwareState(props?.result?.data ?? null);
    const [verifiying, setVerifiying] = useMountAwareState(false);
    const [error, setError] = useMountAwareState(null);

    const { settings } = useContext(SettingsContext);

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    const focusInput = (i) => {
        const elem = document.getElementById(i + '');
        if(elem) elem.focus(); 
        return elem !== null;
    };

    const handleKeyDown = (e) => {
        if(e.code === 'Enter') {
            focusInput((parseInt(e.target.id) + 1));
        }
    };

    const handleChange = (e, i) => {
        let tmp = [...answers];
        tmp[i] = e.target.value;
        setAnswers(tmp);
    };

    const checkAnswer = (answer, correction) => {
        const cleaner = (new StringCleaner()).trimWhitespaces().normalizeWhitepaces();

        if(!settings.uppercaseSensitivity) {
            answer = answer.toLowerCase();
            correction = correction.toLowerCase();
        }
        if(!settings.ponctuationSensitivity) {
            answer = answer.replace(/[.,?!]/gm, ' ');
            correction = correction.replace(/[.,?!]/gm, ' ');
        }

        return cleaner.clean(answer) === cleaner.clean(correction);
    };

    const handleClick = () => {
        if(verification) {
            setVerification(null);
            setAnswers(emptyAnswers);
        }else{
            let empty = answers.findIndex((a) => {return a === ''});
            if(empty >= 0) {
                focusInput(empty);
                return;
            }
            verify();
        }
    };

    const verify = () => {
        setError(null);

        const emptyAnswers = answers.filter(v => v === '').length;
        if(emptyAnswers > 0) {
            return setError(t('pages.explorer.apps.missing_words.incomplete', {count: emptyAnswers}));
        }

        setVerifiying(true);

        if(props?.token === undefined) {
            setVerification({
                missings: missings,
                answers: JSON.parse( JSON.stringify( answers ) ),
                corrects: missings.filter((w, i) => checkAnswer(answers[i], w)).length,
            });
            setVerifiying(false);
        }else{
            API.getInstance()
            .post(`/resource-verify-answers/${props.resource.rid}${props?.token !== undefined ? `?t=${props.token}` : ''}`, {
                data: JSON.parse( JSON.stringify( answers ) )
            }, props?.token === undefined)
            .then(({status, data}) => {
                if(status === 200 || status === 201) {
                    setVerification(data);
                }else{
                    setError(data.message);
                }
                setVerifiying(false);
            })
            .catch((error) => {
                setError(tcommon('general.default'));
                setVerifiying(false);
            });
        }
    };
    
    return (
        <div className='MissingWordsViewer'>
            <div className='title'>
                <FileIcon type="missing-words" /> 
                <h1>{props.data.title}</h1>
                <div className='warnings'>
                    {(props?.token === undefined && settings.uppercaseSensitivity) || (props?.token && (props?.share?.options?.uppercase_sensitivity ?? false)) ?
                        <img src={uppercase_sensitivity_icon} alt="uppercase on" title={tcommon('settings_instructions.uppercase')} />
                    : undefined}
                    {(props?.token === undefined && settings.ponctuationSensitivity) || (props?.token && (props?.share?.options?.ponctuation_sensitivity ?? false)) ?
                        <img src={ponctuation_sensitivity_icon} alt="ponctuation on" title={tcommon('settings_instructions.ponctuation')} />
                    : undefined}
                </div>
            </div>
            <div className="text">
                {texts.map((t, i, a) => {
                    return <React.Fragment key={i}>
                        {t.split('\n').map((f, i, a) => {
                            return <React.Fragment key={i}>
                                <span>{f}</span>
                                {i < a.length - 1 ? <br/> : ''}
                            </React.Fragment>
                        })}
                        {missings[i] ? 
                            verification ?
                                <div className="verification-box" style={{width: `${missings[i].length}em`}}>
                                    {checkAnswer(answers[i], verification.missings[i]) ?
                                        <span className="correct">
                                            {answers[i]}
                                        </span>
                                    :
                                        <>
                                            <span className="correct">
                                                {missings[i]}
                                            </span>
                                            <span className="incorrect">
                                                {answers[i]}
                                            </span>
                                        </>
                                    }
                                </div>
                            :
                                <AssistedTextInput 
                                    id={`${i}`}
                                    style={{width: `${missings[i].length}em`}}
                                    value={answers[i]}
                                    onChange={(e) => {handleChange(e, i)}}
                                    autoComplete="off"
                                    autoCorrect="false"
                                    autoFocus={i === 0}
                                    onKeyDown={handleKeyDown}
                                    disabled={verifiying}
                                />
                        : ''}
                    </React.Fragment>;
                })}
            </div>
            <div className="verify">
                {error ?? undefined}
                {verification ? 
                    <div className="results">
                        {verification.corrects}/{missings.length} ({Math.round((100 * verification.corrects) / missings.length)}%)
                    </div>
                : undefined}
                {props?.result?.data === undefined ?
                    <button onClick={handleClick} id="button" disabled={verifiying}>
                        {verification ? 
                            t('pages.explorer.apps.missing_words.reset')
                        :
                            t('pages.explorer.apps.missing_words.validate')
                        }
                        
                    </button>
                :
                    undefined
                }
                
            </div>
        </div>
    );
}