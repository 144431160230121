import React from 'react';
import './DocumentResultViewer.scss';
export default function DocumentResultViewer(props) {
    
    return (
        <div className='DocumentResultViewer'>
            {(props.result.share?.options?.questions?.length ?? 0) > 0 ?
                <table>
                    <tbody>
                        {props.result.share.options.questions.map((question, i) => {
                            return <React.Fragment key={`${question}-${i}`}>
                                <tr className='question'>
                                    <td rowSpan={3}>{i+1}</td>
                                    <td>Question</td>
                                    <td>{question.question}</td>
                                </tr>
                                <tr className='correct'>
                                    <td>Correct</td>
                                    <td>{question.answer}</td>
                                </tr>
                                <tr className={`answer ${props.result.data[i][1] ? 'correct' : 'wrong'}`}>
                                    <td>Answer</td>
                                    <td>{props.result.data[i][0]}</td>
                                </tr>
                            </React.Fragment>;
                        })}
                    </tbody>
                </table>
            :
                <div>
                    Read
                </div>
            }
            
        </div>
    );
}