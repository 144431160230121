import React, {  useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useMountAwareState from '../../../../hooks/useMountAwareState';
import correct_icon from '../../../../images/icons/correct.svg';
import wrong_icon from '../../../../images/icons/wrong.svg';
export default function QuizMultiChoiceGrid(props) {

    const [answer, setAnswer] = useMountAwareState(props.answer ?? []);
    const [ t ] = useTranslation('main');
    const isCorrect = useMemo(() => props.verifier(answer, props.module.answer), [answer, props]);

    const correctGrid = useMemo(() => {
        let g = [];
        props.module.choices.vertical.forEach(v => {
            g[v] = [];
            props.module.choices.horizontal.map(h => g[v][h] = false);
        });
        props.module.answer.forEach(a => {g[a[0]][a[1]] = true;});
        return g;
    }, [props.module.choices.horizontal, props.module.choices.vertical, props.module.answer]);

    useEffect(() => {
        const reset = (e) => {
            if(e.detail !== props.rid) return;
            setAnswer([]);
        };

        document.addEventListener('quiz-reset', reset);
        return () => {
            document.removeEventListener('quiz-reset', reset);
        }
    }, [props.rid, setAnswer]);
    
    const handleChange = (e, v1, v2) => {
        if(e.target.checked) {
            const newAnswer = [...answer, [v1, v2]];
            setAnswer(newAnswer);
            props.setAnswer(newAnswer);
        }else{
            const newAnswer = answer.filter(v => !(v[0] === v1 && v[1] === v2) || (v[0] === v2 && v[1] === v1));
            setAnswer(newAnswer);
            props.setAnswer(newAnswer.length === 0 ? null : newAnswer);   
        }
    };
    
    const m = props.module;
    return (
        <section className='QuizMultiChoiceGrid quizz-module'>
            <header>
                <h2>{props.id + 1}. {m.text}</h2>
                <div className='result'>
                    {props.showAnswers ?
                        (isCorrect ?
                            <img width={123} height={88} src={correct_icon} alt="correct" />
                        :
                            <img width={89} height={85} src={wrong_icon} alt="wrong" />
                        )
                    : undefined}
                </div>
            </header>
            {props.error !== null ?
                <div className='error'>
                    {t(`pages.explorer.apps.quiz.errors.${m.type}.${props.error}`)}
                </div>
            : undefined}
            <div className='widget singlechoice'>
                <table>
                    <tbody>
                        <tr>
                            <td></td>
                            {m.choices.horizontal.map((v1, i) => {
                                return <td key={i}>{v1}</td>;
                            })}
                        </tr>
                        {m.choices.vertical.map((v1, j) => {
                            return <tr key={j}>
                                <td>{v1}</td>
                                {m.choices.horizontal.map((v2, i) => {
                                    return <td key={i} className={`${props.showAnswers ? (correctGrid[v1][v2] ? 'correct' : 'incorrect') : ''} ${answer.filter(v => v[0] === v1 && v[1] === v2).length === 1 ? 'checked' : ''}`}>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                name={props.id} 
                                                onChange={e => handleChange(e, v1, v2)} 
                                                checked={answer.filter(v => v[0] === v1 && v[1] === v2).length === 1} 
                                                readOnly={props.showAnswers} 
                                                disabled={props.showAnswers} 
                                            />
                                        </label>
                                    </td>;
                                })}
                            </tr>;
                        })}
                    </tbody>
                </table>
            </div>
        </section>
    );
}