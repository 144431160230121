import React, { useEffect, useRef, useContext } from 'react';
import ZoomImageContext from '../../Contexts/ZoomImageContext';
import './ZoomableImage.scss';
export default function ZoomableImage(props) {

    const ref = useRef(null);
    const { setZoomImage } = useContext(ZoomImageContext);

    useEffect(() => {
        const setSize = () => {
            if(ref.current) {
                const ww = window.innerWidth;
                const wh = window.innerHeight;

                const img = ref.current.querySelector('img');

                const imgWidth = img.offsetWidth;
                const imgHeight = img.offsetHeight;

                const ratio = imgWidth / imgHeight;

                const desiredWidth = Math.round(ww * 0.9);

                if(desiredWidth / ratio > 0.9 * wh) {
                    ref.current.style.width = 'auto';
                    ref.current.style.height = `${Math.round(wh * 0.9)}px`;

                    img.style.width = 'auto';
                    img.style.height = '100%';
                }else{
                    ref.current.style.height = 'auto';
                    ref.current.style.width = `${desiredWidth}px`;

                    img.style.height = 'auto';
                    img.style.width = '100%';
                }
            }
        };
        
        window.addEventListener('resize', setSize);
        return () => {
            window.removeEventListener('resize', setSize); 
        }
    }, [props.alt, props.src, setZoomImage]);

    const zoom = () => {
        setZoomImage({
            src: props.fullSrc ?? props.src,
            alt: props.alt ?? ''
        });
    };

    return (
        <img 
            className={`ZoomableImage ${props.className ?? ''}`} 
            src={props.thumbnailSrc ?? props.src} 
            alt={props.alt ?? ''}
            width={props.width} height={props.height}
            onClick={zoom}
        />
    );
}