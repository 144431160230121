import React, {  useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../../API/API';
import parseHeaderInt from '../../../helpers/ParseHeaderInt';
import Loading from '../../Loading';
import Dot3 from '../../utils/Dot3';
import TimeDiffDisplay from '../../utils/TimeDiffDisplay';
import share_icon from '../../../images/icons/link.svg';
import useMountAwareState from '../../../hooks/useMountAwareState';
export default function ClassSharesPreview(props) {

    const [shares, setShares] = useMountAwareState(null);
    const [pagination, setPagination] = useMountAwareState({current: null, previous: null, next: null, count: null, total: null});
    const [loading, setLoading] = useMountAwareState(false);
    const [error, setError] = useMountAwareState(null);
    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');
    const navigate = useNavigate();
    const classPid = props.classPid;

    const fetchShares = useCallback(
        (page = 1) => {
            setLoading(true);
            API.getInstance()
            .get(`/users/self/classes/${classPid}/shares?page=${page}&pageSize=10`)
            .then(({status, data, headers}) => {
                if(status === 200) {
                    setShares(data);
                    setPagination({
                        previous: parseHeaderInt(headers.get('x-pagination-previous')),
                        current: parseHeaderInt(headers.get('x-pagination-current')),
                        next: parseHeaderInt(headers.get('x-pagination-next')),
                        count: parseHeaderInt(headers.get('x-pagination-count')),
                        total: parseHeaderInt(headers.get('x-total-entities')),
                    });
                }else if(status === 404) {
                    setError(tcommon('error.page.not_found'));
                }else{
                    setError(tcommon('error.fetch.default', {code: status}));
                }
                setLoading(false);
            })
            .catch(err => {
                setError(tcommon('error.general.default'));
                setLoading(false);
            });
        },
        [classPid, setError, setLoading, setPagination, setShares, tcommon],
    );

    useEffect(() => {
        
        fetchShares();
      
    }, [fetchShares]);
    
    return (
        <div className='ClassSharesPreview'>
            <h2>
                <img src={share_icon} alt="" />
                {tcommon('general.shares')}:
            </h2>
            <div className='manage'>
                <Link to={`/classes/${classPid}/shares`} className='see-all'>{tcommon('general.manage')}</Link>
            </div>
            <div className='list'>
                {error ?
                    <div>{error}</div>
                :
                    (shares === null ?
                        <Loading />
                    :
                        (shares.length === 0 ?
                            <div className='empty'>{t('pages.classes.class_details.previews.no_shares')}</div>
                        : 
                            <table>
                                <thead>
                                    <tr>
                                        <td>{tcommon('general.resource')}</td>
                                        <td>{tcommon('general.due')}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {shares.map(s => {
                                        return <tr key={s.pid} style={{cursor: 'pointer'}} onClick={() => navigate(`/classes/${classPid}/shares/${s.pid}`)}>
                                            <td>[{s.resource.stylized_type}] {s.resource.name}</td>
                                            <td>
                                                <TimeDiffDisplay to={new Date(s.due_date * 1000)} beforeSuffix="ago" afterSuffix="left" updateInterval={1000} />
                                            </td>
                                        </tr>;
                                    })}
                                </tbody>
                            </table>
                        )
                    )   
                }
            </div>
            <div className='footer'>
                <div className='stats'>
                    <div>{tcommon('pagination.total')}: {pagination.total ? pagination.total : (shares === null ? <Dot3 /> : 0) }</div>
                </div>
                <div className='controls'>
                    <button 
                            className={loading ? 'loading' : ''}
                            onClick={() => {fetchShares(pagination.previous)}}
                            disabled={pagination.previous === null || loading}
                    >
                        {tcommon('pagination.previous')}
                    </button>
                    <div>
                        {pagination.current}/{pagination.count}
                    </div>
                    <button 
                            className={loading ? 'loading' : ''}
                            onClick={() => {fetchShares(pagination.next)}}
                            disabled={pagination.next === null || loading}
                    >
                        {tcommon('pagination.next')}
                    </button>
                </div>
            </div>
        </div>
    );
}