import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import API from '../../../API/API';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import ErrorPage from '../../ErrorPage';
import Loading from '../../Loading';
import TimeDiffDisplay from '../../utils/TimeDiffDisplay';
import ClassesBreadcrumb from '../ClassesBreadcrumb';
import './ClassManageShare.scss';
import ClassShareResultsList from './ClassShareResultsList';
import ClassManageShareInfo from './ClassManageShareInfo';
import useMountAwareState from '../../../hooks/useMountAwareState';
import ScoreDisplay from '../ScoreDisplay';
import HideStudentResultsContext from '../../../Contexts/HideStudentResultsContext';
export default function ClassManageShare(props) {
    
    const [share, setShare] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);
    const [errorCode, setErrorCode] = useMountAwareState(null);
    const [ tcommon ] = useTranslation('common');
    const { classPid, sharePid } = useParams();
    const { hideStudentResults } = useContext(HideStudentResultsContext);

    useEffect(() => {
        setError(null);
        setErrorCode(null);
        API.getInstance()
        .get(`/users/self/classes/${classPid}/shares/${sharePid}`)
        .then(({status, data}) => {
            if(status === 200) {
                setShare(data);
            }else if(status === 404) {
                setError(tcommon('error.page.not_found'));
                setErrorCode(404);
            }else{
                setError(tcommon('error.fetch.default', {code: status}));
            }
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
        });
    }, [classPid, tcommon, sharePid, setError, setErrorCode, setShare]);
    
    useDocumentTitle(share === null ? 'Loading' : `Share: [${share.resource.stylized_type}] ${share.resource.name}`);
    
    if(error) return <ErrorPage code={errorCode ?? undefined} error={error} />;
    if(share === null) return <Loading>Fetching student data</Loading>;
    return (
        <section className='ClassManageShare'>
            <header>
                <ClassesBreadcrumb links={[
                    {to: `/classes/${classPid}`, label: share.class.name},
                    {to: `/classes/${classPid}/shares`, label: 'Shares'},
                    {to: `/classes/${classPid}/shares/${sharePid}`, label: `[${share.resource.stylized_type}] ${share.resource.name}`},
                ]} />
            </header>
            <main>
                <ClassManageShareInfo share={share} />
                <div className='returns-info'>
                    <div className='returned'>
                        <div className='header'>
                            <h2>{tcommon('general.still_due')}:</h2>
                            {share.students_without_results.length > 0 ?
                                <TimeDiffDisplay from={new Date()} to={new Date(share.due_date * 1000)} beforeSuffix="late" afterSuffix="left" />
                            : undefined }
                        </div>
                        <div>
                            {share.students_without_results.length > 0 ?
                                <table>
                                    <tbody>
                                        {share.students_without_results.map(s => {
                                            return <tr key={s.pid}>
                                                <td><Link to={`/classes/${share.class.pid}/students/${s.pid}`}>{s.first_name} {s.last_name}</Link></td>
                                            </tr>;
                                        })}
                                    </tbody>
                                </table>
                            :
                                <div className='nothing'>
                                    Nothing
                                </div>
                            }
                            
                        </div>
                    </div>
                    <div className='returned'>
                        <h2>{tcommon('general.returned')}:</h2>
                        <div>
                            {share.students_with_results.length > 0 ?
                                <table>
                                    <tbody>
                                        {share.students_with_results.map(s => {
                                            return <tr key={s.student.pid}>
                                                <td><Link to={`/classes/${share.class.pid}/students/${s.student.pid}`}>{s.student.first_name} {s.student.last_name}</Link></td>
                                                <td><TimeDiffDisplay to={new Date(s.result.date * 1000)} from={new Date(share.due_date * 1000)} beforeSuffix={'early'} afterSuffix={'late'} />  </td>
                                                <td>
                                                    <div className='score'>
                                                        <ScoreDisplay score={s.result.score} noText={true} />
                                                        {hideStudentResults ? '' : s.result.score}%
                                                    </div>
                                                </td>
                                                <td>(<Link to={`/classes/${classPid}/shares/${sharePid}/result-${s.result.pid}`}>see result</Link>) </td>        
                                            </tr>;
                                        })}
                                    </tbody>
                                </table>
                            :
                                <div className='nothing'>
                                    Nothing
                                </div>   
                            }
                        </div>
                    </div>
                </div>
                <div className='results'>
                    <h2>{tcommon('general.results')}:</h2>
                    <ClassShareResultsList classPid={classPid} sharePid={sharePid} />
                </div>
            </main>
        </section>
    );
}