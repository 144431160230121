import React, { useRef } from 'react';
import './CrosswordsCell.scss';
export default function CrosswordsCell(props) {

    const ref = useRef(null);

    const handleKeyDown = e => {
        switch (e.key) {
            case "Tab":
                e.preventDefault();
                focusNextTab();
                break;
            default:
                break;
        }
    };

    const focusNextTab = () => {
        if(props.cell.nextPos[props.direction]) {
            const elem = document.getElementById(`cw-cell-${props.cell.nextPos[props.direction].x}-${props.cell.nextPos[props.direction].y}`);
            if(props.cell.lastCell[props.direction]) {
                props.setDirection(props.grid[props.cell.nextPos[props.direction].y][props.cell.nextPos[props.direction].x].direction);
            };
            if(elem) elem.focus();
        }
    };
    
    const setDirection = () => {
        if(!props.direction) {
            if(props.cell.direction) props.setDirection(props.cell.direction);
            else {
                const hasRightNeighbor = props.grid[props.y][props.x + 1]?.letter;
                const hasBottomNeighbor = props.grid[props.y + 1]?.[props.x]?.letter;
                if(hasRightNeighbor && hasBottomNeighbor) {
                    const rightNeighbor = document.getElementById(`cw-cell-${props.x + 1}-${props.y}`);
                    const bottomNeighbor = document.getElementById(`cw-cell-${props.x}-${props.y + 1}`);
                    if(rightNeighbor && rightNeighbor.value === "") {
                        props.setDirection("horizontal");
                    }else if(bottomNeighbor && bottomNeighbor.value === "") {
                        props.setDirection("vertical");
                    }else{
                        props.setDirection("horizontal");
                    }
                }else if(hasRightNeighbor) props.setDirection("horizontal");
                else props.setDirection("vertical");
            }
        }
    };

    const handleChange = e => {
        props.onChange(e.target.value);
        focusNextTab();
    };

    const handleFocus = () => {
        setDirection();
        ref.current?.select();
        // ref.current.value = "";
    };

    const handleBlur = e => {
        if(e.relatedTarget) {
            if(!e.relatedTarget.id.startsWith("cw-cell-")) props.setDirection(null);
            else {
                const [targetX, targetY] = e.relatedTarget.id.slice(8).split('-').map(v => parseInt(v));
                const targetCell = props.grid[targetY][targetX];

                if(props.cell.nextPos[props.direction] && props.cell.nextPos[props.direction].x === targetX && props.cell.nextPos[props.direction].y === targetY) {
                    // ??
                }else{
                    if(targetCell.direction) props.setDirection(targetCell.direction);
                    else {
                        setDirection();
                    }
                }
            }
        }else{
            props.setDirection(null);
        }
    };

    return (
        <div className={`CrosswordsCell ${props.neighbors.left ? 'n-left' : ""} ${props.neighbors.right ? 'n-right' : ""} ${props.neighbors.top ? 'n-top' : ""} ${props.neighbors.bottom ? 'n-bottom' : ""}`}>
            <div className='indexes'>
                {props.cell.indexes.vertical !== null ?
                    <label htmlFor={`cw-cell-${props.x}-${props.y}`} className={`index vertical ${props.verification ? (props.verification.answers[props.cell.indexes.vertical].toLowerCase() === props.verification.words[props.cell.indexes.vertical].word.toLowerCase() ? "correct" : "incorrect") : "e"}`}>{props.cell.indexes.vertical + 1}</label>
                : undefined}
                {props.cell.indexes.horizontal !== null ?
                    <label htmlFor={`cw-cell-${props.x}-${props.y}`} className={`index horizontal ${props.verification ? (props.verification.answers[props.cell.indexes.horizontal].toLowerCase() === props.verification.words[props.cell.indexes.horizontal].word.toLowerCase() ? "correct" : "incorrect") : "e"}`}>{props.cell.indexes.horizontal + 1}</label>
                : undefined}
            </div>
            {/* <div className='debug-pos'>
                {props.cell.nextPos.vertical !== null ?
                    <div className='debug vertical'>{props.cell.nextPos.vertical.x}-{props.cell.nextPos.vertical.y}</div>
                : undefined}
                {props.cell.nextPos.horizontal !== null ?
                    <div className='debug horizontal'>{props.cell.nextPos.horizontal.x}-{props.cell.nextPos.horizontal.y}</div>
                : undefined}
            </div> */}
            <input  id={`cw-cell-${props.x}-${props.y}`}
                    name={`cw-cell-${props.x}-${props.y}`}  
                    type="text" 
                    ref={ref}
                    maxLength={1} 
                    minLength={0} 
                    value={props.value} 
                    onChange={handleChange} 
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    autoComplete={"off"}
                    autoCorrect="off"
                    disabled={props.verification !== null}
            />
        </div>
    );
}