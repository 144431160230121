import React, {  useEffect } from 'react';
import './ExplorerSearch.scss';
import API from '../../API/API';
import Loading from '../Loading';
import { useSearchParams } from 'react-router-dom';
import ExplorerFolderLine from './ExplorerFolderLine';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../hooks/useDocumentTitle';

import Searcher from '../../helpers/Searcher';
import useMountAwareState from '../../hooks/useMountAwareState';

export default function ExplorerSearch(props) {

    let [ searchParams ] = useSearchParams();
    const [files, setFiles] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    let search = searchParams.get('s');

    useDocumentTitle(search);

    const query = (value, files) => {
        if(value === '') return [];

        const searcher = new Searcher(value);
        return searcher.queryIn(files);
    };

    useEffect(() => {
        setFiles(null);
        if(search !== null) {
            API.getInstance().get('/resources')
            .then(({status, data}) => {
                if(status === 200) setFiles(data);
            })
            .catch((error) => {
                setError('error');
            });
        }
    }, [search, setError, setFiles]);

    if(search === null) return <div>{t('pages.explorer.search.nothing_found')}</div>

    if(error !== null) {
        return <div>{tcommon('error.general.default')}</div>;
    }
    if(files === null) return <Loading />;

    const queryString = search.toLowerCase();
    const results = query(queryString, files);

    return (
        <div className="ExplorerSearch">
            <h1>{t('pages.explorer.search.results_for')}: {search}</h1>
            {results.length === 0 ? 
                <div className='no-results'>{t('pages.explorer.search.nothing_found')}</div>
            :
                <div className="results">
                    {results.map((r, i) => {
                        return <ExplorerFolderLine key={r.rid} file={r} />
                    })}
                </div>
            }
        </div>
    );
}