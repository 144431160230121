import React, { useContext } from 'react';
import Cookies from 'js-cookie';
import './Footer.scss';
import waee_icon from '../../images/logo_waee_text.svg';
import CookieConsentContext from '../../Contexts/CookieConsentContext';
import { Trans, useTranslation } from 'react-i18next';

export default function Footer(props) {

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');
    const { setCookieConsent } = useContext(CookieConsentContext);

    const resetCookiePreferences = () => {
        Cookies.remove('CookieConsent');
        setCookieConsent(null);
    };
    
    return (
        <footer className='Footer'>
            <div className="grid-container">
                <div className="box" id="footer-brand">
                    <a className="logo-link" target="_blank" rel="noreferrer" href="https://waeelearning.com">
                        <img width={393} height={99} src={waee_icon} alt="WAEE Logo" />
                    </a>
                    <p>It's all about french!</p>
                    <div id="footer-socials">
                        <a href="https://www.facebook.com/WAEE-French-107871524965845" className="social" target="_blank" rel="noreferrer"><img width={38} height={38} src="https://waeelearning.com/images/socials/f_logo_RGB-White_58.png" alt="Facebook" /></a>
                        <a href="https://twitter.com/WaeeFrench" className="social" target="_blank" rel="noreferrer"><img width={1034} height={851} src="https://waeelearning.com/images/socials/twitter.png" alt="Twitter" /></a>
                        <a href="https://www.instagram.com/waeefrench/" className="social" target="_blank" rel="noreferrer"><img width={504} height={504} src="https://waeelearning.com/images/socials/instagram.png" alt="Instagram" /></a>
                        <a href="/images/socials/wechat_qr.jpg" className="social" target="_blank" rel="noreferrer"><img width={47} height={38} src="https://waeelearning.com/images/socials/wechat_38.png" alt="Wechat" /></a>
                    </div>
                </div>
                <div className="box" id="footer-nav">
                    
                </div>
                <div className="box" id="footer-contact">
                    <h2>{t('pages.presentation.footer.contact.title')}</h2>
                    <address>
                        <Trans t={t} i18nKey="pages.presentation.footer.contact.email">
                            Email us at: <a target="_blank" rel='noreferrer' href="mailto:contact@waeelearning.com">contact@waeelearning.com</a>
                        </Trans>
                    </address>
                    <p>
                        <Trans t={t} i18nKey="pages.presentation.footer.contact.form">
                            Access our <a target="_blank" rel='noreferrer' href="https://waeelearning.com/contact">contact form</a>
                        </Trans>
                    </p>
                </div>
            </div>
            
            <div id="legal-stuff">
                Copyright &copy; 2021 WAEE Canada Holdings inc. All&nbsp;rights&nbsp;reserved. | <a target="_blank" rel="noreferrer" href="https://waeelearning.com/terms-of-service">{tcommon('legals.tos')}</a>&nbsp;&nbsp;&nbsp;<a target="_blank" rel="noreferrer" href="https://waeelearning.com/privacy-policy">{tcommon('legals.privacy_policy')}</a>&nbsp;&nbsp;&nbsp;<button onClick={resetCookiePreferences}>{t('pages.presentation.footer.reset_cookie')}</button> 
            </div>
        </footer>
    );
}