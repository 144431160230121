import { createContext } from 'react';

const HideStudentResultsContext = createContext({
    hideStudentResults: true,
    setHideStudentResults: (val) => {},
});

export const HideStudentResultsProvider = HideStudentResultsContext.Provider;
export const HideStudentResultsConsumer = HideStudentResultsContext.Consumer;

export default HideStudentResultsContext;