import React from 'react';
import { Outlet } from 'react-router-dom';
import './Authentication.scss';

export default function Authentication(props) {
    
    return (
        <div className='Authentication'>
            {/* <div className='background'></div> */}
            <div className='container'>
                <Outlet />
            </div>
        </div>
    );
}