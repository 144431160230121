import React from 'react';
import '../explorer/flashcards/FlashcardsViewer.scss';
import './SharedFlashcardsViewer.scss';

import FlashcardsListeningGame from '../explorer/flashcards/listeningMode/FlashcardsListeningGame';
import FlashcardsReadingGame from '../explorer/flashcards/readingMode/FlashcardsReadingGame';
import FlashcardsLearning from '../explorer/flashcards/learningMode/FlashcardsLearning';
import SharedFlashcardsStart from './flashcards/SharedFlashcardsStart';
import FlashcardsResults from '../explorer/flashcards/FlashcardsResults';
import useMountAwareState from '../../hooks/useMountAwareState';

export default function SharedFlashcardsViewer(props) {

    const cards_set = props.data.cards;

    const [cards, setCards] = useMountAwareState(null);
    const [shuffleCount, setShuffleCount] = useMountAwareState(0);
    const [stage, setStage] = useMountAwareState(0);
    const [previousStage, setPreviousStage] = useMountAwareState(null);
    const [results, setResults] = useMountAwareState(null);

    const languages = {
        e: 'english',
        f: 'french'
    }

    const modes = {
        'ref': 'writing',
        'rfe': 'reading',
        'lff': 'listening',
        'lfe': 'listening',
        'lrn': 'learning',
    };

    const mode_details = props.share.options.mode.split('');

    const mode = modes[props.share.options.mode];
    const source = languages[mode_details[1]];
    const destination = languages[mode_details[2]];
    
    const start = () => {
        setCards(shuffleCards(cards_set));
        setShuffleCount(shuffleCount+1);
        goToStage(1);
    };

    const goToStage = (newStage) => {
        setPreviousStage(stage);
        setStage(newStage);
    };

    const goToResults = (newResults) => {
        setResults(newResults);
        goToStage(2);
    };

    const reset = () => {
        goToStage(0);
    };

    const shuffleCards = (cards) => {
        for (var i = cards.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = cards[i];
            cards[i] = cards[j];
            cards[j] = temp;
        }
        return cards;
    };

    if(mode === "learning") return (
        <div className="SharedFlashcardsViewer learning-container">
            <FlashcardsLearning data={props.data} />
        </div>
    );
    return (
        <div className='FlashcardsReading SharedFlashcardsViewer'>
            {(stage === 0 || previousStage === 0) && 
                <SharedFlashcardsStart 
                    mode={mode}
                    source={source}
                    destination={destination}
                    animateIn={previousStage !== 0 && previousStage !== null}
                    animateOut={previousStage === 0}
                    start={start}
                />
            }
            {stage === 1 || previousStage === 1 ? 
                (mode !== 'listening' ?
                    <FlashcardsReadingGame 
                        key={shuffleCount}
                        cards={cards}
                        lang={source.slice(0, 2)}
                        reset={reset}
                        setResults={goToResults}
                        animateIn={stage === 1}
                        animateOut={previousStage === 1}
                        token={props.token}
                        share={props.share}
                    />
                :
                    <FlashcardsListeningGame 
                        key={shuffleCount}
                        cards={cards}
                        lang={source.slice(0, 2)}
                        reset={reset}
                        setResults={goToResults}
                        animateIn={stage === 1}
                        animateOut={previousStage === 1}
                        token={props.token}
                        share={props.share}
                    />
                )
            : undefined }
            {stage === 2 || previousStage === 2 ? 
                (mode !== 'listening' ?
                    <FlashcardsResults
                        results={results}
                        lang={source.slice(0, 2)}
                        reset={reset}
                        animateIn={stage === 2}
                        animateOut={previousStage === 2}
                        token={props.token}
                        resource={props.resource}
                    />
                :
                    <FlashcardsResults
                        results={results}
                        lang={destination.slice(0, 2) === 'fr' ? 'en' : 'fr'}
                        reset={reset}
                        animateIn={stage === 2}
                        animateOut={previousStage === 2}
                        token={props.token}
                        resource={props.resource}
                    />
                )
            :
                undefined
            }        
        </div>
    );
}