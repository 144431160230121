import { createContext } from 'react';

const PrintContext = createContext({
    toPrint: null,
    setToPrint: (val) => {},
});

export const PrintProvider = PrintContext.Provider;
export const PrintConsumer = PrintContext.Consumer;

export default PrintContext;