export default class StringCleaner {
    constructor(rules = []) {
        this.rules = rules;
    };

    addRule = (r) => {
        this.rules.push(r);
        return this;
    };

    static removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };
    removeAccents = () => {
        this.rules.push(StringCleaner.removeAccents);
        return this;
    };
    
    static removeWhiteSpaces = (str) => {
        return str.replace(/\s*/g, '');
    };
    removeWhiteSpaces = () => {
        this.rules.push(StringCleaner.removeWhiteSpaces);
        return this;
    };

    static trimWhitespaces = (str) => {
        return str.replace(/^\s+/, '').replace(/\s+$/, '');
    };
    trimWhitespaces = () => {
        this.rules.push(StringCleaner.trimWhitespaces);
        return this;
    };

    static normalizeWhitepaces = (str) => {
        return str.replace(/\s{2,}/g, ' ');
    };
    normalizeWhitepaces = () => {
        this.rules.push(StringCleaner.normalizeWhitepaces);
        return this;
    };

    static toLowerCase = (str) => {
        return str.toLowerCase();
    }
    toLowerCase = () => {
        this.rules.push(StringCleaner.toLowerCase);
        return this;
    }

    static toUpperCase = (str) => {
        return str.toUpperCase();
    }
    toUpperCase = () => {
        this.rules.push(StringCleaner.toUpperCase);
        return this;
    }

    static removeSpecialChars = (str) => {
        return str.replace(/[[\]!?\-+*\\/%$#`~<>"';:()]/g, '');;
    }
    removeSpecialChars = () => {
        this.rules.push(StringCleaner.removeSpecialChars);
        return this;
    }

    clean(str) {
        if(typeof str === 'undefined' || !str.toString) {
            throw new Error(
                "str does not have a toString method"
              );
        }
        str = str.toString();
        this.rules.forEach((r) => {str = r(str);});
        return str;
    }
}