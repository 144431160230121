import { useEffect, useRef } from 'react';
import useMountAwareState from './useMountAwareState';
export default function usePrefersReducedMotion(props) {

    const mediaQueryRef = useRef(window.matchMedia("(prefers-reduced-motion: reduce)"));
    const [ prefersReducedMotion, setPrefersReducedMotion ] = useMountAwareState(!mediaQueryRef.current || mediaQueryRef.current.matches);
    
    useEffect(() => {
        const mediaQuery = mediaQueryRef.current;
        const handleMediaQueryChange = (e) => {
            setPrefersReducedMotion(!mediaQuery || mediaQuery.matches);
        };
        mediaQuery.addEventListener("change", handleMediaQueryChange);
        
        return () => {
            mediaQuery.removeEventListener("change", handleMediaQueryChange);
        }
    }, [setPrefersReducedMotion]);

    return prefersReducedMotion;
}