import React, {  useContext, useEffect, useCallback } from 'react';
import './FileLink.scss';

import { Link } from 'react-router-dom';

import star_icon from '../images/icons/star.svg';
import empty_star_icon from '../images/icons/empty-star.svg';

import ContextMenu from './ContextMenu';
import ContextMenuContext from '../Contexts/ContextMenuContext';
import API from '../API/API';
import useResourceIcon from '../hooks/useResourceIcon';
import useResourceType from '../hooks/useResourceType';
import { useTranslation } from 'react-i18next';
import useMountAwareState from '../hooks/useMountAwareState';

export default function FileLink(props) {
    const [ t ] = useTranslation('common');
    const contextMenu = useContext(ContextMenuContext);
    const [file, setFile] = useMountAwareState(props.file);

    const type = useResourceType(file);
    const icon = useResourceIcon(type);    

    const disabled = props?.disabled ?? false;

    const refreshData = useCallback(
        () => {
            API.getInstance().get('/resources/' + file.rid)
            .then(({status, data}) => {
                if(status === 200) {
                    setFile(data);
                    props.updateFavorites?.();
                }
            })
        },
        [file.rid, props, setFile],
    );

    useEffect(() => {
        const handleFavoritesUpdate = e => {
            if(e.detail === file.rid) refreshData();
        };
        
        document.addEventListener('favorites-update', handleFavoritesUpdate);
        return () => {
            document.removeEventListener('favorites-update', handleFavoritesUpdate);
        }
    }, [refreshData, file.rid]);

    const addFavorite = () => {
        API.getInstance().post('/users/self/favorite-resources', {
            rid: file.rid
        })
        .then(({status, data}) => {
            if(status === 201) {
                refreshData();
                closeOptions();
                document.dispatchEvent(new CustomEvent('favorites-update', {detail: file.rid}));
            }
        })
        .catch((error) => {

        });
    };

    const removeFavorite = () => {
        API.getInstance().delete('/users/self/favorite-resources/' + file.rid)
        .then(({status, data}) => {
            if(status === 200) {
                refreshData();
                closeOptions();
                document.dispatchEvent(new CustomEvent('favorites-update', {detail: file.rid}));
            }
        })
        .catch((error) => {

        });
    };

    const getContextMenu = (position) => {
        return <ContextMenu position={position}>
            {!disabled ?
                <Link to={`/explorer/view/${file.rid}`}>{t('context_menu.open')}</Link>
            : undefined}
            <button onClick={copyLink}>{t('context_menu.copy_link')}</button>
            {file.favorite ?
                <button onClick={removeFavorite}><img width={74} height={68} src={empty_star_icon} alt="" />{t('context_menu.remove_fav')}</button>
            :
                <button onClick={addFavorite}><img width={71} height={65} src={star_icon} alt="" />{t('context_menu.add_fav')}</button>
            }
            
        </ContextMenu>;
    };

    const handleContextMenu = (e) => {
        e.preventDefault();
        const position = {
            x: e.pageX,
            y: e.pageY
        };
        contextMenu.setContextMenu(
            getContextMenu(position)
        );
    };

    const closeOptions = (e) => {
        contextMenu.setContextMenu(null);
    };

    const copyLink = () => {
        navigator.clipboard.writeText(process.env.REACT_APP_DOMAIN + '/view/' + file.rid);
        closeOptions();
    };

    const handleClick = e => {
        props?.onClick?.(e);

        if(props?.setFile !== undefined && (file?.expanded_children ?? false)) {
            e.preventDefault();
            props.setFile(file);
        }        
    };
  
    if(!file) return null;

    return (
        <>
            <Link title={`${file.name}${file.favorite ? ' ♥' : ''}`} className={`FileLink${file.favorite ? ' fav' : ''} ${disabled ? 'disabled' : ''}`} to={`/explorer/view/${file.rid}`} onClick={handleClick} onContextMenu={handleContextMenu} disabled={disabled}>
                {(props.noIcon ?? false) ? undefined : 
                    <div className='icon'>
                        <img width={142} height={142} className="icon-img" src={icon} alt={type} />
                    </div>
                }
                {(props?.noSplitName ?? false) ?
                        <>{file.name} {file.favorite ? <>&hearts;</> : undefined}</>
                    :
                        <div className='name'>
                            {file.name.split(' ').map((w, i, a) => {
                                // So the fav heart doesn't go to the next next
                                return <span key={i}>
                                    {w}
                                    {file.favorite && i === a.length - 1 ? <> &hearts;</> : ''}
                                </span>
                            })}
                        </div>
                    }
                
            </Link>
        </>
    );
}