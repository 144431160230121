import React from 'react';
import './ResourceInfo.scss';
import useResourceType from '../../../hooks/useResourceType';
import info_icon from '../../../images/icons/info.svg';
import ResourceInfoPopup from './ResourceInfoPopup';
import DocumentReadButton from './DocumentReadButton';
import DocumentAnswerButton from './DocumentAnswerButton';
import FileIcon from '../../FileIcon';
import TimeDiffDisplay from '../../utils/TimeDiffDisplay';
import useMountAwareState from '../../../hooks/useMountAwareState';
export default function ResourceInfo(props) {

    const type = useResourceType(props.resource)

    const [openInfo, setOpenInfo] = useMountAwareState(false);

    return (
        <div className='ResourceInfo'>
            <FileIcon type={type} />
            <div className='name'>{props.resource.name}</div>
            <div className='non-overflowing'>
                <div className='info-container'>
                    <button className='info' onClick={() => setOpenInfo(true)}>
                        <img width={63} height={63} src={info_icon} alt="info" />
                    </button>
                    {openInfo ? 
                        <ResourceInfoPopup share={props.share} resource={props.resource} close={() => setOpenInfo(false)} />
                    : undefined}
                </div>
                <div className='time-left-container'>
                    <TimeDiffDisplay to={new Date(props.share.due_date * 1000)} />
                </div>
            </div>
            
            {props.resource.type === 'document' ? 
                (props.share.options !== null && props.share.options.questions.length > 0 ?
                    <DocumentAnswerButton  token={props.token} resource={props.resource} share={props.share} />
                :
                    <DocumentReadButton token={props.token} resource={props.resource} />
                )
            : undefined}
            {props.resource.type === "flashcards" && props.share.options.mode === "lrn" ?
                <DocumentReadButton token={props.token} resource={props.resource} />
            : undefined }
        </div>
    );
}