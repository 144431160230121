import { createContext } from 'react';

const ZoomImageContext = createContext({
    zoomImage: null,
    setZoomImage: (val) => {},
});

export const ZoomImageProvider = ZoomImageContext.Provider;
export const ZoomImageConsumer = ZoomImageContext.Consumer;

export default ZoomImageContext;