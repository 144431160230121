import React, {  useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useMountAwareState from '../../../hooks/useMountAwareState';

export default function QandA(props) {

    const [question, setQuestion] = useMountAwareState('');
    const [answer, setAnswer] = useMountAwareState('');
    const [error, setError] = useMountAwareState(null);

    const qInput = useRef(null);
    const aInput = useRef(null);

    const add = () => {
        setError(null);
        if(question === '') {
            return setError('Question cannot be empty');
        }
        if(answer === '') {
            return setError('Answer cannot be empty');
        }
        props.setQuestions([...props.questions, {
            question: question,
            answer: answer,
            id: uuidv4(),
        }]);
        setQuestion('');
        setAnswer('');
        qInput.current.focus();
    };

    const remove = (qa) => {
        props.setQuestions(props.questions.filter(e => e !== qa));
    };

    const edit = (qa) => {
        setQuestion(qa.question);
        setAnswer(qa.answer);
        remove(qa);
    };

    const keyDown = (e) => {
        if(e.code === 'Enter') {
            e.preventDefault();
            if(e.target === qInput.current) {
                aInput.current.focus();
            }else{
                add();
            }
        }
    };
    
    return (
        <div className='questions'>
            <div className='label'>Add a Q&A:</div>
            <table>
                <tbody>
                    {props.questions.map((q, i) => {
                        return <tr key={i}>
                            <td>{q.question}</td>
                            <td>{q.answer}</td>
                            <td>
                                <button onClick={() => {remove(q)}}>-</button>
                                <button onClick={() => {edit(q)}}>edit</button>
                            </td>
                        </tr>;
                    })}
                    <tr>
                        <td><input ref={qInput} type="text" placeholder='Question' value={question} onChange={e => setQuestion(e.target.value)} autoFocus={true} onKeyDown={keyDown} /></td>
                        <td><input ref={aInput} type="text" placeholder='Answer' value={answer} onChange={e => setAnswer(e.target.value)} onKeyDown={keyDown} /></td>
                        <td><button onClick={add}>+</button></td>
                    </tr>
                </tbody>
            </table>
            <div>{error ?? undefined}</div>
        </div>
    );
}