import React, { useEffect } from 'react';
import useFocusTrap from '../../hooks/useFocusTrap';
import './Popupable.scss';
export default function Popupable(props) {

    const wrapperRef = useFocusTrap();

    useEffect(() => {
        const handleKeyDown = (e) => {
            if(e.code === 'Escape') {
                if(wrapperRef && wrapperRef.current && wrapperRef.current.querySelectorAll('.Popup, .Popupable').length === 0) {
                    props?.close?.();
                }
            }
        };

        const handleOutsideInteraction = (e) => {
            const contextMenu = document.querySelector('.ContextMenu');
            if (wrapperRef.current && wrapperRef.current && !wrapperRef.current.contains(e.target) && (contextMenu  === null || (!contextMenu.contains(e.target)))) {
                props.cancel?.();
                props.close?.();
            }
        };

        document.addEventListener('mousedown', handleOutsideInteraction, {capture: true});
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('mousedown', handleOutsideInteraction, {capture: true});
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [props, wrapperRef]);
    
    return (
        <div className={`Popupable ${props.className ?? ''}`}>
            <section ref={wrapperRef} className='box'>
                <header>
                    <h1>{props?.title ?? ''}</h1>
                    <div>
                        {props.dotdotdot !== undefined ?
                            <button className='dotdotdot' onClick={props.dotdotdot}><div>&#9679;&#9679;&#9679;</div></button>
                        : undefined}
                        <button onClick={props.close} className="close">&#10005;</button>
                    </div>
                </header>
                <main>
                    {props.children}
                </main>
            </section>
            <div tabIndex={0}>{/* For the focus trap to work */}</div>
        </div>
    );
}