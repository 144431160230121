import React, { useContext, useEffect, useMemo, useRef } from 'react';
import './PDFViewer.scss';
import { pdfjs, Document, Page } from 'react-pdf';
import PDFViewerControls from './PDFViewerControls';
import ErrorPage from '../../ErrorPage';
import { useTranslation } from 'react-i18next';
import useMountAwareState from '../../../hooks/useMountAwareState';
import UserContext from '../../../Contexts/UserContext';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

export default function PDFViewer(props) {
    const [ tcommon ] = useTranslation('common');
    const [width, setWidth] = useMountAwareState(null);
    const [numPages, setNumPages] = useMountAwareState(null);
    const [error, setError] = useMountAwareState("");
    const [zoom, setZoom] = useMountAwareState(1.0); // actual zoom used to render pdf
    const [zoomRangeDisplay, setZoomRangeDisplay] = useMountAwareState(1.0); // to display cursor position in controls
    const [documentRatio, setDocumentRatio] = useMountAwareState(11 / 8.5); // used to size the resizing loader, default: US Letter
    const resizingSize = useMemo(() => {
        if(width === null) return 1;
        let w = width * 0.8;
        if(w > 600) w = 600;
        w = w * zoomRangeDisplay; 
        return {
            width: w,
            height: w * documentRatio
        };
    }, [zoomRangeDisplay, width, documentRatio]); // used to display zoom feedback to visualize the size while resizing
    const [resizing, setResizing] = useMountAwareState(false);
    const ref = useRef(null);
    const resizingTimoutIdRef = useRef(null);
    const displayRef = useRef(null);
    const scrollBeforeZoomRef = useRef(null);
    const { user } = useContext(UserContext);

    const pageWidth = useMemo(() => {
        if(width === null) return undefined;
        let w = width * 0.8;
        if(w > 600) w = 600;
        return w * zoom; 
    }, [zoom, width]);

    const handleZoom = (value, force=false) => {
        const resizeWait = 250; // trying with a quarter of a second
        // Keep the scroll by finding the scroll % (scrollBeforeZoomRef / maxScrollBeforeZoom) * maxScrollAfterZoom

        const confirmResize = () => {
            if(value !== zoom) {
                setZoom(value);
                setResizing(false);
                scrollBeforeZoomRef.current = null;
            }
        };
        
        if(scrollBeforeZoomRef.current === null && displayRef.current) {
            const display = displayRef.current;
            scrollBeforeZoomRef.current = {
                vertical: {
                    value: display.scrollTop,
                    max: display.scrollHeight,
                    ratio: display.scrollTop / display.scrollHeight,
                },
                horizontal: {
                    value: display.scrollLeft,
                    max: display.scrollWidth,
                    ratio: display.scrollLeft / display.scrollWidth,
                },
            };
        }
        
        setZoomRangeDisplay(value);
        if(resizingTimoutIdRef.current) {
            clearTimeout(resizingTimoutIdRef.current);
            resizingTimoutIdRef.current = null;
            if(scrollBeforeZoomRef.current !== null && displayRef.current) {
                const display = displayRef.current;
                display.scrollTop = display.scrollHeight * scrollBeforeZoomRef.current.vertical.ratio;
                display.scrollLeft = display.scrollWidth * scrollBeforeZoomRef.current.horizontal.ratio;
            }
        }
        if(force) {
            confirmResize();
        }else{
            setResizing(true);
            resizingTimoutIdRef.current = setTimeout(confirmResize, resizeWait);
        }
    };    

    // Update width when resizing + find initial zoom value
    useEffect(() => {
        const updateWidth = () => {
            if(!ref.current) return; 
            setWidth(ref.current.offsetWidth);
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        }
    }, [ref, setWidth]);
    
    const onDocumentLoadSuccess = (pdf) => {
        pdf.getPage(1).then(page => {
            setDocumentRatio(page.view[3] / page.view[2]);
        });
        setNumPages(pdf.numPages);
    };

    const onDocumentLoadError = (error) => {
        setError(error);
    };

    const printDoc = () => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        let url = props.data;
        a.href = url;
        a.download = props.resource.name;
        a.click();
    };

    const loader = useMemo(() => {
        return new Array(numPages).fill(false).map((v, i) => {
            return <div className='page-resizing' key={i} style={{width: `${resizingSize.width}px`, height: `${resizingSize.height}px`}}>
                <div></div>
            </div>;
        });
    }, [numPages, resizingSize.height, resizingSize.width]);


    if(error) {
        return <ErrorPage
            error={tcommon('error.pdf.default')} noReturnToHome={true}
        />;
    }
    return (
        <div className='PDFViewer'>
            {user.type >= 2 ?
                <div className='print'>
                    <button onClick={printDoc}>Print</button>
                </div>
            : undefined}
            <PDFViewerControls 
                zoom={zoom} 
                zoomRangeDisplay={zoomRangeDisplay} 
                handleZoom={handleZoom} 
                displayRef={displayRef}
                setZoom={setZoom}
                setZoomRangeDisplay={setZoomRangeDisplay}
            />
            {/* {error ? <div className='error'>Error: {error}</div> : undefined} */}
            <div ref={displayRef} className='display'>
                <div ref={ref} className={`document-container ${resizing ? 'resizing' : ''}`}>
                    <div className='document-resizing' style={{width: `${resizingSize.width}px`}}>
                        {loader}
                    </div>
                    <Document 
                        className="document"
                        file={props.data}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={onDocumentLoadError}
                        loading={loader}
                    >
                        {Array.from(
                            new Array(numPages),
                            (el, index) => (
                            <Page
                                className="page"
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                width={pageWidth}
                                style={{ width: `${100 * zoom}%` }}
                                loading={loader[index]}
                            />
                            ),
                        )}
                    </Document>
                </div>
            </div>
        </div>
    );
}