import React from 'react';
import './LogoLink.scss';
import { Link } from 'react-router-dom';
import logo from '../../images/waee_resources.svg';
import tiny_logo from '../../images/waee_resources_tiny.svg';

export default function LogoLink(props) {
    
    if(props?.noLink === true) {
        return <div className='LogoLink'>
            <img width={257} height={40} className='logo' src={logo} alt="Home" />
            <img width={161} height={68} className='tiny-logo' src={tiny_logo} alt="Home" />
        </div>;
    }
    return (
        <Link className='LogoLink' to="/">
            <img width={257} height={40} className='logo' src={logo} alt="Home" />
            <img width={161} height={68} className='tiny-logo' src={tiny_logo} alt="Home" />
        </Link>
    );
}