import React from 'react';
import './HiddenWordsChar.scss';

import square from '../../../../images/square-ratio.svg';

export default function HiddenWordsChar(props) {
    return <div className={`HiddenWordsChar${props.locked ? ' locked' : ''}${props.selected ? ' selected' : ''}`}>
        <img width={10} height={10} src={square} alt="" />
        <button 
            onClick={props.onClick}
            onMouseOver={props.onMouseOver}
        >
            <div className="character">
                {props.children}
            </div>
        </button>
    </div>;
}