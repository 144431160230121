import React, { useCallback, useContext, useEffect, useRef } from 'react';
import AssistedTextInputIndicator from './AssistedTextInputIndicator';
import Popupable from '../Popup/Popupable';
import HelpAssistedTextInput from '../help/HelpAssistedTextInput';
import useMountAwareState from '../../hooks/useMountAwareState';
import PopupContext from '../../Contexts/PopupContext';
export default function AssistedTextInput(props) {

    const { setPopup } = useContext(PopupContext);
    const [focus, setFocus] = useMountAwareState(false);
    const ref = useRef(null);
    const indicatorRef = useRef(null);

    const accentsMap = {
        'e': {
            '/': 'é',
            '\\': 'è',
            '`': 'è',
            '^': 'ê',
            '"': 'ë',
            ':': 'ë',
        },
        'E': {
            '/': 'É',
            '\\': 'È',
            '`': 'È',
            '^': 'Ê',
            '"': 'Ë',
            ':': 'Ë',
        },
        'a': {
            '\\': 'à',
            '`': 'à',
            '^': 'â',
        },
        'A': {
            '\\': 'À',
            '`': 'À',
            '^': 'Â',
        },
        'u': {
            '^': 'û',
            '\\': 'ù',
            '`': 'ù',
            '"': 'ü',
            ':': 'ü',
        },
        'U': {
            '^': 'Û',
            '\\': 'Ù',
            '`': 'Ù',
            '"': 'Ü',
            ':': 'Ü',
        },
        'i': {
            '"': 'ï',
            ':': 'ï',
            '^': 'î',
        },
        'I': {
            '"': 'Ï',
            ':': 'Ï',
            '^': 'Î',
        },
        'o': {
            '^': 'ô',
        },
        'O': {
            '^': 'Ô',
        },
        'c': {
            ',': 'ç',
        },
        'C': {
            ',': 'Ç',
        },
    };
    const regex = new RegExp('[' + Object.keys(accentsMap).join('') + ']$');
    

    const handleChange = (e) => {
        let value = e.target.value;
        if(regex.test(value)) {
            value = value.split('');
            const key = value.pop();
            const symbol = value.pop();
            const map = accentsMap[key];
            if(symbol in map) {
                value.push(map[symbol]);
            }else{
                value.push(symbol);
                value.push(key);
            }
            value = value.join('');
        }

        e.target.value = value;

        props.onChange?.(e);
    }

    const closeHelp = useCallback(() => {
        setPopup(null);
        if(ref.current) ref.current.focus();
    }, [setPopup]);

    const openHelp = useCallback(() => {
        setPopup(
            <Popupable title="Assisted input help" close={closeHelp}>
                <HelpAssistedTextInput title={false} input={false} />
            </Popupable>
        );
    }, [closeHelp, setPopup]);

    const handleOutsideInteraction = useCallback(
        (e) => {
            if (ref && !ref.current.contains(e.target) ) {
                if(indicatorRef.current && indicatorRef.current.contains(e.target)) {
                    openHelp();
                }
                setFocus(false);
            }
        },
        [openHelp, setFocus],
    );
    const handleFocus = (e) => {
        setFocus(true);
    }

    const handleBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            if(indicatorRef.current && indicatorRef.current.contains(e.target)) {
                openHelp();
            }
            setFocus(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideInteraction);
        return () => {
            document.removeEventListener('mousedown', handleOutsideInteraction);
        }
    }, [handleOutsideInteraction]);
    
    return (<>
        <input 
            ref={ref}
            type="text"
            style={props.style}
            id={props.id}
            onChange={handleChange} 
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={props.onKeyDown}
            value={props.value} 
            className='AssistedTextInput' 
            autoComplete={props.autoComplete}
            autoCorrect={props.autoCorrect ?? "false"}
            autoFocus={props.autoFocus}
            disabled={props?.disabled ?? false}
            spellCheck={props?.spellCheck ?? false}
            autoCapitalize={props?.autoCapitalize ?? "off"}
        />
        {focus ? 
            <AssistedTextInputIndicator indicatorRef={indicatorRef}/>
        : undefined}
    </>);
}