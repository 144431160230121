import React, { useCallback, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import UserContext from '../../Contexts/UserContext';
import Loading from '../Loading';
import Greetings from './Greetings';
import './Home.scss';

import explorer_icon from '../../images/icons/explorer.svg';
import star_icon from '../../images/icons/star.svg';
import API from '../../API/API';
import ExplorerFolderBox from '../explorer/ExplorerFolderBox';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import BookletShortcuts from './widgets/bookletShortcuts/BookletShortcuts';
import Presentation from '../presentation/Presentation';
import useMountAwareState from '../../hooks/useMountAwareState';
// import ThemesWidget from './widgets/themes/ThemesWidget';

export default function Home(props) {

    useDocumentTitle('Home');
    
    const { user } = useContext(UserContext);   
    const [favorites, setFavorites] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    const fetchFavorites = useCallback(() => {
        API.getInstance().get('/users/self/favorite-resources')
        .then(({status, data}) => {
            if(status === 200) setFavorites(data);
            else setError(tcommon('error.fetch.general', {code: status}));
        })
        .catch((error) => {
            setError(tcommon('error.general.default'));
        });
    }, [tcommon]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchFavorites();
        document.addEventListener('favorites-update', fetchFavorites);
        return () => {
            document.removeEventListener('favorites-update', fetchFavorites);
        }
    }, [fetchFavorites]);
    

    if(user === null) return <Loading />;
    if(user.type === 0) return <Presentation />;
    return (
        <div className='Home'>
            <section className="banner">
                <Greetings name={user.first_name} />
            </section>
            <section className='explorer'>
                <Link to="/explorer">
                    <img src={explorer_icon} alt="" /> Explore resources
                </Link>
            </section>
            <BookletShortcuts />
            {/* <ThemesWidget /> */}
            <section className="links-list favorites">
                <header className="title">
                    <h2>{t('pages.home.favorites.title')}<img width={71} height={65} src={star_icon} alt="" /></h2>
                </header>
                <main className={`list ${favorites !== null && favorites.length === 0 ? 'empty' : ''} ${favorites === null && error === null ? 'loading' : 'loaded'}`}>
                    {error !== null ?
                        <div>{error}</div>
                    :
                        (favorites === null ? <Loading /> :
                            (favorites.length === 0 ? 
                                <div className="empty">
                                    <div>{t('pages.home.favorites.empty')}</div>
                                    <div>{t('pages.home.favorites.tip')}</div>
                                </div>
                            :
                                favorites.map((fav, i) => {
                                    return <ExplorerFolderBox key={fav.rid} file={fav} />;
                                })
                            )
                        )   
                    }
                </main>
            </section>
        </div>
    );
}