import React from 'react';
import { Link } from 'react-router-dom';
import './SubscribeButton.scss';
export default function SubscribeButton(props) {
    
    return (
        <div className='SubscribeButton'>
            <div className='shine'></div>
            <Link to="/subscribe">Subscribe</Link>
        </div>
    );
}