import React from 'react';
import { Outlet } from 'react-router-dom';
import Topbar from './layout/Topbar';
export default function WithTopbar(props) {
    return (
        <>
            <Topbar />
            <div id="display" className={`display${props.authDisplay ? ' auth' : ''}`}>
                <Outlet />
            </div>
        </>
    );
}