import React from 'react';
import './Welcome.scss';

import logo_text from '../../images/logo_waee_text.svg';
import logo_notext from '../../images/logo.svg';
import { useTranslation } from 'react-i18next';

export default function Welcome(props) {
    
    const [ t ] = useTranslation('main');

    return (
        <section className='Welcome'>
            <div className='background'></div>
            <div>{t('pages.presentation.welcome')}</div>
            <div>{t('pages.presentation.to')}</div>
            <img width={393} height={99} src={logo_text} alt="" />
            <h1>
                <img width={128} height={128} src={logo_notext} alt="WAEE" /><span>French Portal</span>
            </h1>
        </section>
    );
}