import React, { useContext, useEffect } from 'react';
import UserContext from '../../Contexts/UserContext';
import subscription_elementary_image from '../../images/divers/subscription-elementary.svg';
import subscription_advanced_image from '../../images/divers/subscription-advanced.svg';
import Loading from '../Loading';
import './CheckoutSuccess.scss';
import { Link, useSearchParams } from 'react-router-dom';
import useMountAwareState from '../../hooks/useMountAwareState';
export default function CheckoutSuccess(props) {

    const { user } = useContext(UserContext);
    const [ ok, setOk ] = useMountAwareState(false);
    const [ searchParams, setSearchParams ] = useSearchParams();

    useEffect(() => {
        if(user.subscriptions.length === 0) {
            searchParams.delete('status');
            setSearchParams(searchParams);
            props.previous();
        }else{
            setOk(true);
        }
    }, [user.subscriptions, searchParams, props, setSearchParams, setOk]);
    
    
    return (
        <div className='CheckoutSuccess'>
            {ok ? 
                <>
                    <h1>You are subscribed to WAEE Resources!</h1>
                    <div>
                        <h2>Your subscription(s):</h2>
                        <ul>
                            {user.subscriptions.includes('resources-subscription-elementary') ?
                                <li>
                                    <div><img width={354} height={354} src={subscription_elementary_image} alt=""/></div>
                                    <div>
                                        <h3>Elementary</h3>
                                        <p>
                                            Gives access to every resource related to the Elementary Booklets
                                        </p>
                                    </div>
                                </li>
                            : undefined}
                            {user.subscriptions.includes('resources-subscription-advanced') ?
                                <li>
                                    <div><img width={354} height={354} src={subscription_advanced_image} alt=""/></div>
                                    <div>
                                        <h3>Advanced</h3>
                                        <p>
                                            Gives access to every resource related to the Advanced Booklets
                                        </p>
                                    </div>
                                </li>
                            : undefined}
                        </ul>
                        {user.subscriptions.length < 2 ?
                            <div className='subscribe-to-more'>
                                <button onClick={props.previous}>Subscribe to more</button>
                            </div>
                        : undefined}
                        
                        <div className='go-to-home'>
                            <Link to="/">Go to homepage</Link>
                        </div>
                    </div>
                </>
            : 
                <Loading />
            }
        </div>
    );
}