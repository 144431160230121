import React, {  useEffect } from 'react';
import API from '../../API/API';
import useMountAwareState from '../../hooks/useMountAwareState';
import ErrorPage from '../ErrorPage';
import CrosswordsViewer from '../explorer/activities/crosswords/CrosswordsViewer';
import MissingWordsViewer from '../explorer/activities/missing_words/MissingWordsViewer';
import QuizViewer from '../explorer/activities/quiz/QuizViewer';
import PDFViewer from '../explorer/pdf/PDFViewer';
import Loading from '../Loading';
import './SharedDisplay.scss';
import SharedFlashcardsViewer from './SharedFlashcardsViewer';
export default function SharedDisplay(props) {
    
    const [blob, setBlob] = useMountAwareState(null);
    const [display, setDisplay] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);

    useEffect(() => {
        API.getInstance()
        .file(`/resource-shares/${props.token}/file`, false)
        .then(({status, data}) => {
            if(status === 200) {
                setBlob(data);
            }else{
                setError(data.message);
            }
        })
        .catch((error) => {
            setError('Something went wrong');
        });
    
      return () => {
        
      };
    }, [props.token, setBlob, setError]);

    useEffect(() => {
        const getFileDisplay = async () => {
            switch (props.resource.extension) {
                case 'pdf':
                    return <PDFViewer data={URL.createObjectURL(blob)} />;
                case 'fc':
                    return <SharedFlashcardsViewer resource={props.resource} data={JSON.parse(await blob.text())} token={props.token} share={props.share} />;
                case 'mw':
                    return <MissingWordsViewer resource={props.resource} data={JSON.parse(await blob.text())} token={props.token} share={props.share} />;
                case 'xw':
                    return <CrosswordsViewer resource={props.resource} data={JSON.parse(await blob.text())} token={props.token} share={props.share} />;
                case 'hw':
                    return <div>hidden-words: WIP</div>;
                case 'qz':
                    return <QuizViewer resource={props.resource} data={JSON.parse(await blob.text())} token={props.token} share={props.share} />;
                default:
                    return <iframe
                        title={props.resource.name}
                        src={URL.createObjectURL(blob) + "#toolbar=0"}
                        className="file-container"
                        width="100%"
                    ></iframe>;
            }
        }
        if(blob === null) return;
        getFileDisplay()
        .then((display) => {
            setDisplay(display);
        });
    
      return () => {
        
      }
    }, [blob, props.resource, props.token, props.share, setDisplay]);
    
    if(error !== null) return <ErrorPage error={error} />;
    if(null === display) return <Loading>Fetching resource</Loading>;
    if(null === blob) return <Loading>Preparing resource</Loading>;
    
    return (
        <div className="SharedDisplay">
            {display}
        </div>
    );
}