import React, { useContext,  useEffect, useMemo } from 'react';
import './CheckoutSelection.scss';
import UserContext from '../../Contexts/UserContext';

import subscription_elementary_image from '../../images/divers/subscription-elementary.svg';
import subscription_advanced_image from '../../images/divers/subscription-advanced.svg';
import API from '../../API/API';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Dot3 from '../utils/Dot3';
import { useSearchParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import useMountAwareState from '../../hooks/useMountAwareState';

export default function CheckoutSelection(props) {

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    const monthly = useMemo(() => parseFloat(process.env.REACT_APP_MONTHLY_PLAN), []);
    const yearly = useMemo(() => parseFloat(process.env.REACT_APP_YEARLY_PLAN), []);

    const potentialSaving =  (Math.round((((monthly * 24) - (yearly * 24)) + Number.EPSILON) * 100) / 100).toFixed(2);

    useDocumentTitle('Subscribe');

    const [ searchParams, setSearchParams ] = useSearchParams();

    const [checkElementary, setCheckElementary] = useMountAwareState(false);
    const [checkAdvanced, setCheckAdvanced] = useMountAwareState(false);
    const [plan, setPlan] = useMountAwareState('monthly');
    const actualSaving = useMemo(() => {
        const monthlyPlanAnnualCost = monthly * 12;
        const yearlyPlanAnnualCost = yearly * 12
        const factor = (checkElementary ? 1 : 0) + (checkAdvanced ? 1 : 0);
        
        return ((Math.round(((monthlyPlanAnnualCost - yearlyPlanAnnualCost) + Number.EPSILON) * 100) / 100) * factor).toFixed(2);
    }, [checkElementary, checkAdvanced, monthly, yearly]);
    const [lock, setLock] = useMountAwareState(false);
    const [formError, setFormError] = useMountAwareState(null);
    const userContext = useContext(UserContext);

    useEffect(() => {
        const subscriptions = ['elementary', 'advanced'];
        const plans = ['monthly', 'yearly'];

        if(searchParams.has('subscriptions')) {
            const subs = searchParams.get('subscriptions').split(' ').filter(v => subscriptions.includes(v));
            searchParams.set('subscriptions', subs.join(' '));

            subs.forEach(sub => {
                if(sub === 'elementary') {
                    if(userContext.user.subscriptions.includes('resources-subscription-elementary')) {
                        searchParams.set('subscriptions', 'advanced');
                        setSearchParams(searchParams);
                    }else{
                        setCheckElementary(true);
                    }
                }
                if(sub === 'advanced') {
                    if(userContext.user.subscriptions.includes('resources-subscription-advanced')) {
                        searchParams.set('subscriptions', 'elementary');
                        setSearchParams(searchParams);
                    }else{
                        setCheckAdvanced(true);
                    }
                }
            });
        }
        if(searchParams.has('plan')) {
            const p = searchParams.get('plan');
            if(plans.includes(p)) {
                setPlan(p);
            }else{
                searchParams.delete('plan');
                setSearchParams(searchParams);
            }
        }
    }, [searchParams, setCheckAdvanced, setCheckElementary, setPlan, setSearchParams, userContext.user.subscriptions]);
    
    useEffect(() => {
        if(userContext.user.subscriptions.length === 2) {
            props.next();
        }
    }, [props, userContext.user.subscriptions.length]);

    const getUrlQueryString = (subs) => {
       return encodeURIComponent(`${subs.map((v) => `${v}-${plan}`).join(',')}`);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(lock) return;
        setLock(true);
        setFormError(null);
        if((checkElementary || checkAdvanced) === false) {
            setLock(false);
            setFormError('You must select at least one subscription');
            return;
        }

        let subs = [];
        if(checkElementary) subs.push('resources-subscription-elementary');
        if(checkAdvanced) subs.push('resources-subscription-advanced');

        if(subs.filter((val => userContext.user.subscriptions.includes(val))).length > 0) {
            setLock(false);
            setFormError('You cannot subscribe multiple times to the same subscription');
            return;
        }

        API.getInstance().get(`/subscription/checkout?subscription-list=${getUrlQueryString(subs)}`)
        .then(({status, data}) => {
            if(status === 200) {
                window.location.replace(data.url);
            }else if(status === 403 && data?.message === 'Already subscribed'){
                setFormError('Already subscribed');
                setLock(false);
            }else{
                setFormError('Something went wrong');
                setLock(false);
            }
        })
        .catch((error) => {
            setFormError('Something went wrong');
            setLock(false);
        });
        
    };
    
    return (
        <form className='CheckoutSelection' onSubmit={handleSubmit}>
            <div className='disclaimer'>
                <b>{t('pages.checkout.selection.note.title')}:</b><br/>
                <Trans t={t} i18nKey='pages.checkout.selection.note.text'>
                    If you are looking for a <b>School or School district</b> access,
                    please <a target="_blank" rel="noreferrer" href="https://waeelearning.com/contact?subject=WAEE%20Resources%20Quote">contact us</a> for a quote.
                </Trans>
            </div>
            <div className='selection'>
                <h2>{t('pages.checkout.selection.title')}</h2>
                <div className='subscriptions'>
                   <label className={`checkbox elementary ${checkElementary ? 'checked' : 'not-checked'}`}>
                        <input type="checkbox" name="elementary" onChange={(e) => {setCheckElementary(e.target.checked)}} checked={checkElementary} disabled={userContext.user.subscriptions.includes('resources-subscription-elementary') || lock} />
                        <div className="subscription">
                            <div className='details'>
                                <h3><img width={354} height={354} src={subscription_elementary_image} alt="" className={userContext.user.subscriptions.includes('resources-subscription-elementary') ? 'lock' : ''}/>Elementary</h3>
                                <p>
                                    {t('pages.checkout.selection.elementary')}
                                </p>
                            </div>
                            <div className='price'>
                                {userContext.user.subscriptions.includes('resources-subscription-elementary') ? 
                                    <span>{tcommon('general.subscribed')}</span>
                                : 
                                    <div>
                                        <span>CA${plan === 'monthly' ? `${monthly}/${tcommon('general.month')}` : `${Math.round(((yearly * 12) + Number.EPSILON) * 100) / 100}/${tcommon('general.year')}`}</span>
                                        <div className='tax-included'>
                                            {tcommon('general.tax_inc')}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </label> 
                    <label className={`checkbox advanced ${checkAdvanced ? 'checked' : 'not-checked'}`}>
                        <input type="checkbox" name="advanced" onChange={(e) => {setCheckAdvanced(e.target.checked)}} checked={checkAdvanced} disabled={userContext.user.subscriptions.includes('resources-subscription-advanced') || lock} />
                        <div className="subscription">
                            <div className='details'>
                                <h3><img width={354} height={354} src={subscription_advanced_image} alt="" className={userContext.user.subscriptions.includes('resources-subscription-advanced') ? 'lock' : ''}/>Advanced</h3>
                                <p>
                                    {t('pages.checkout.selection.advanced')}
                                </p>
                            </div>
                            <div className='price'>
                                {userContext.user.subscriptions.includes('resources-subscription-advanced') ? 
                                    <span>{tcommon('general.subscribed')}</span>
                                : 
                                    <div>
                                        <span>CA${plan === 'monthly' ? `${monthly}/${tcommon('general.month')}` : `${Math.round(((yearly * 12) + Number.EPSILON) * 100) / 100}/${tcommon('general.year')}`}</span>
                                        <span className='tax-included'>
                                            {tcommon('general.tax_inc')}
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>
                    </label>
                </div>
                <div className='plan'>
                    <h2>{t('pages.checkout.selection.billing_plan')}:</h2>
                    <div className='selector'>
                        <button className={`elementary ${plan === 'monthly' ? 'checked' : ''}`} onClick={() => setPlan('monthly')} disabled={plan === 'monthly' || lock}>
                            <h3>{tcommon('general.monthly')}</h3>
                            <div className={`background elementary ${plan === 'monthly' ? 'checked' : ''}`}></div>
                        </button>
                        <button className={`advanced ${plan === 'yearly' ? 'checked' : ''}`} onClick={() => setPlan('yearly')} disabled={plan === 'yearly' || lock}>
                            <h3>{tcommon('general.yearly')}</h3>
                            <div className='save'>
                                {!checkElementary && !checkAdvanced ? 
                                    t('pages.checkout.selection.save_up_to', {value: potentialSaving})
                                : 
                                    t('pages.checkout.selection.save', {value: actualSaving})
                                }
                            </div>
                            <div className={`background advanced ${plan === 'yearly' ? 'checked' : ''}`}></div>
                        </button>
                    </div>
                </div>
            </div>
            <div className='summary'>
                <div className='amount'>
                    {checkElementary || checkAdvanced ?
                        <>
                            ${plan === 'monthly' ?
                                `${(checkElementary ? monthly : 0) + (checkAdvanced ? monthly : 0)}/${tcommon('general.month')}`
                            :
                                `${(checkElementary ? Math.round(((yearly * 12) + Number.EPSILON) * 100) / 100 : 0) + (checkAdvanced ? Math.round(((yearly * 12) + Number.EPSILON) * 100) / 100 : 0)}/${tcommon('general.year')}`
                            }
                            <div className='tax-included'>
                                {tcommon('general.tax_inc')}
                            </div>
                        </>
                    :
                        undefined
                    }
                </div>
                {formError ? 
                    <div className='error'>
                        {formError}
                    </div> 
                : undefined}
                <button className={lock ? 'lock' : ''} disabled={lock || (!checkElementary && !checkAdvanced)}>{!lock ? t('pages.checkout.selection.go_to_payment') : <>{tcommon('general.redirecting')}<Dot3/></>}</button>
            </div>
        </form>
    );
}