import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import SettingsContext from "../../../../Contexts/SettingsContext";
import StringCleaner from "../../../../helpers/StringCleaner";
import useMountAwareState from "../../../../hooks/useMountAwareState";
import correct_icon from "../../../../images/icons/correct.svg";
import wrong_icon from "../../../../images/icons/wrong.svg";
import AssistedTextInput from "../../../assistedInput/AssistedTextInput";
export default function QuizConjugation(props) {
  const pronouns = props.module?.pronouns ?? [
    "Je",
    "Tu",
    "Il / Elle / On",
    "Nous",
    "Vous",
    "Ils / Elles",
  ];

  const [answer, setAnswer] = useMountAwareState(
    props.answer ?? pronouns.map((v) => "")
  );
  const [t] = useTranslation("main");
  const isCorrect = useMemo(
    () => props.verifier(answer, props.module.answer),
    [answer, props]
  );
  const { settings } = useContext(SettingsContext);
  const cleaner = useMemo(() => {
    let c = new StringCleaner();

    if (!settings.uppercaseSensitivity) {
      c.addRule((str) => str.toLowerCase());
    }
    if (!settings.ponctuationSensitivity) {
      c.addRule((str) => str.replace(/[.,?!]/gm, " "));
    }

    return c
      .addRule((str) => str.replace(/\([^()]*\)/gim, ""))
      .trimWhitespaces()
      .normalizeWhitepaces();
  }, [settings]);

  useEffect(() => {
    const reset = (e) => {
      if (e.detail !== props.rid) return;
      setAnswer(
        (
          props.module?.pronouns ?? [
            "Je",
            "Tu",
            "Il / Elle / On",
            "Nous",
            "Vous",
            "Ils / Elles",
          ]
        ).map((v) => "")
      );
    };

    document.addEventListener("quiz-reset", reset);
    return () => {
      document.removeEventListener("quiz-reset", reset);
    };
  }, [props, setAnswer]);

  const handleChange = (e, i) => {
    const tmp = answer.map((v, j) => (i === j ? e.target.value : v));
    setAnswer(tmp);
    props.setAnswer(tmp.map((a) => (a.length > 0 ? a : null)));
  };

  const m = props.module;
  return (
    <div className="QuizConjugation quizz-module">
      <header>
        <h2>
          {props.id + 1}. {m.text}
        </h2>
        <div className="result">
          {props.showAnswers ? (
            isCorrect ? (
              <img width={123} height={88} src={correct_icon} alt="correct" />
            ) : (
              <img width={89} height={85} src={wrong_icon} alt="wrong" />
            )
          ) : undefined}
        </div>
      </header>
      {props.error !== null ? (
        <div className="error">
          {t(`pages.explorer.apps.quiz.errors.${m.type}.${props.error}`)}
        </div>
      ) : undefined}
      <div className="widget singlechoice">
        <table>
          <tbody>
            {pronouns.map((p, i) => {
              return (
                <tr key={i}>
                  <td>{p}</td>
                  <td>
                    {props.showAnswers ? (
                      <div className="correction">
                        {m.answer.includes(cleaner.clean(answer[i])) ? (
                          <span className="correct">{m.answer[i]}</span>
                        ) : (
                          <>
                            <span className="incorrect">{answer[i]}</span>
                            <span className="correct">{m.answer[i]}</span>
                          </>
                        )}
                      </div>
                    ) : (
                      <AssistedTextInput
                        type="text"
                        onChange={(e) => handleChange(e, i)}
                        value={answer[i]}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
