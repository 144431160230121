import { useContext } from 'react';
import SettingsContext from '../Contexts/SettingsContext';
export default function useSettings() {
    
    const { settings, setSettings } = useContext(SettingsContext);

    const setUppercaseSensitivity = (val) => {
        setSettings({...settings, uppercaseSensitivity: val});
    }

    const setPonctuationSensitivity = (val) => {
        setSettings({...settings, ponctuationSensitivity: val});
    }

    return {
        settings,
        setUppercaseSensitivity,
        setPonctuationSensitivity
    };
}