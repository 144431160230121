import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import API from '../../../API/API';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useMountAwareState from '../../../hooks/useMountAwareState';
import ErrorPage from '../../ErrorPage';
import Loading from '../../Loading';
import TimeDiffDisplay from '../../utils/TimeDiffDisplay';
import ClassesBreadcrumb from '../ClassesBreadcrumb';
import ResultViewer from '../ResultViewer/ResultViewer';
import ScoreDisplay from '../ScoreDisplay';
import './SeeStudentResult.scss';
export default function SeeStudentResult(props) {
    const source = props.source ?? 'unknown';

    const [result, setResult] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(null);
    const [errorCode, setErrorCode] = useMountAwareState(null);
    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');
    const { classPid, studentPid, sharePid, resultPid } = useParams();

    useEffect(() => {
        const url = (source === 'student' ?
            `/users/self/classes/${classPid}/students/${studentPid}/results/${resultPid}`
        :
            `/users/self/classes/${classPid}/shares/${sharePid}/results/${resultPid}`
        );
        setError(null);
        setErrorCode(null);
        API.getInstance()
        .get(url)
        .then(({status, data}) => {
            if(status === 200) {
                setResult(data);
            }else if(status === 404) {
                setError(tcommon('error.page.not_found'));
                setErrorCode(404);
            }else{
                setError(tcommon('error.fetch.default', {code: status}));
            }
        })
        .catch(err => {
            setError(tcommon('error.general.default'));
        });
    }, [classPid, studentPid, sharePid, resultPid, tcommon, source, setError, setErrorCode, setResult]);
    
    useDocumentTitle(result === null ? tcommon('general.loading') : `${tcommon('general.result')}: [${result.resource.stylized_type}] ${result.resource.name} - ${result.student.first_name} ${result.student.last_name}`);
    
    if(error) return <ErrorPage code={errorCode ?? undefined} error={error} />;
    if(result === null) return <Loading/>;
    const breadcrumbs = {
        student: [
            {to: `/classes/${classPid}`, label: result.share.class.name},
            {to: `/classes/${classPid}/students`, label: tcommon('general.students')},
            {to: `/classes/${classPid}/students/${studentPid}`, label: `${result.student.first_name} ${result.student.last_name}`},
            {to: `/classes/${classPid}/students/${studentPid}/result-${resultPid}`, label: `${tcommon('general.result')}: [${result.resource.stylized_type}] ${result.resource.name}`},
        ],
        share: [
            {to: `/classes/${classPid}`, label: result.share.class.name},
            {to: `/classes/${classPid}/shares`, label: tcommon('general.shares')},
            {to: `/classes/${classPid}/shares/${sharePid}`, label: `[${result.resource.stylized_type}] ${result.resource.name}`},
            {to: `/classes/${classPid}/shares/${sharePid}/result-${resultPid}`, label: `${result.student.first_name} ${result.student.last_name}`},
        ],
    };
    
    const date = new Date(result.date * 1000);

    return (
        <div className='SeeStudentResult'>
            <header>
                <ClassesBreadcrumb links={breadcrumbs[source]} />
            </header>
            <main>
                <div className='info'>
                    <div className='score'>
                        <h2>{tcommon('general.score')}:</h2>
                        <ScoreDisplay score={result.score} />
                    </div>
                    <div className='list'>
                        <h2>{tcommon('general.informations')}:</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>{tcommon('general.resource')}:</td>
                                    <td>[{result.resource.stylized_type}] {result.resource.name}</td>
                                </tr>
                                <tr>
                                    <td>{tcommon('general.student')}:</td>
                                    <td>
                                        <Link to={`/classes/${classPid}/students/${result.student.pid}`}>{result.student.first_name} {result.student.last_name}</Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{tcommon('general.due_on')}:</td>
                                    <td>
                                        {new Date(result.share.due_date * 1000).toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit'})}
                                        <TimeDiffDisplay from={new Date()} to={new Date(result.share.due_date * 1000)} beforeSuffix="ago" afterSuffix="left" />
                                        (<Link to={`/classes/${classPid}/shares/${result.share.pid}`}>{tcommon('general.see_share')}</Link>)
                                    </td>
                                </tr>
                                <tr>
                                    <td>{tcommon('general.returned_on')}:</td>
                                    <td>
                                        {date.toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit'})}
                                        <TimeDiffDisplay from={new Date(result.share.due_date * 1000)} to={new Date(result.date * 1000)} beforeSuffix="early" afterSuffix="late" />
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td>Class result:</td>
                                    <td>
                                        <Link to={`/classes/${classPid}/shares/${result.share.pid}/result`}>
                                            See result
                                        </Link>
                                    </td>
                                </tr> */}
                                {result.resource.extension === 'fc' ?
                                    <tr>
                                        <td>{tcommon('general.mode')}:</td>
                                        <td>
                                            {t(`pages.shared.instructions.flashcards-${result.share.options.mode}`)}
                                        </td>
                                    </tr>
                                : undefined}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='results'>
                    <ResultViewer result={result} />
                </div>
            </main>
        </div>
    );
}