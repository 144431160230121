import React, { useEffect,  useContext, useMemo } from 'react';
import './Breadcrumb.scss';
import home from '../../images/icons/home.svg';
import chevron from '../../images/icons/chevron.svg';
import { Link } from 'react-router-dom';
import Dot3 from '../utils/Dot3';
import UserContext from '../../Contexts/UserContext';
import institution_icon from '../../images/icons/institution.svg';
import link_icon from '../../images/icons/link.svg';
import ShareResourceDialog from './ShareResourceDialog';
import ResourceLinksPopup from '../Popup/ResourceLinksPopup';
import useMountAwareState from '../../hooks/useMountAwareState';
import PopupContext from '../../Contexts/PopupContext';
import FileLink from '../FileLink';

export default function Breadcrumb(props) {
    
    const [data, setData] = useMountAwareState(null);
    const { setPopup } = useContext(PopupContext);
    // const [openMiddlePathView, setOpenMiddlePathView] = useMountAwareState(false);
    const { user } = useContext(UserContext);
    const showLinksButton = useMemo(() => {
        if(props.file?.folder ?? false) return false;
        return ((props.file?.links?.document?.length ?? 0) > 0) || ((props.file?.links?.exercise?.length ?? 0) > 0);
    }, [props.file]);

    useEffect(() => {
        setData(null);
        if(props.file === null) return;
        setData(props.file.path);
    
        return () => {
            
        };
    }, [props.file, setData]);

    const openSharePopup = () => {
        setPopup(<ShareResourceDialog resource={props.file} close={closeSharePopup} />);
    };

    const closeSharePopup = () => {
        setPopup(null);
    };

    const openLinksPopup = () => {
        setPopup(
            <ResourceLinksPopup
                resource={props.file}
                close={() => setPopup(null)}
            />
        );
    };

    // const getMiddlePathElements = (elements) => {
    //     if(elements.length === 0) return null;
    //     const e = elements.shift();
    //     return <div className='middle-path-element'>
    //         <div className='middle-path-link'>
    //             <img width={49} height={95} src={chevron} alt="" />
    //             <Link to={`/explorer/view/${e.rid}`}><span>{e.name}</span></Link>
    //         </div>
    //         {getMiddlePathElements(elements)}
    //     </div>;
    // };

    return (
        <div className='Breadcrumb'>
            <div className='path'>
                <Link to="/">
                    <img width={96} height={102} src={home} alt="Back to home" />
                </Link>
                <img width={49} height={95} src={chevron} alt="" />
                {data === null ?
                    <Dot3 />
                :
                    <>
                        {data.length === 0 ?
                            <span>Explorer</span>
                        :
                            <Link to="/explorer">
                                <span>Explorer</span>
                            </Link>
                        }
                        {/* {data.length > 2 ?
                            <div className={`middle-path ${openMiddlePathView ? 'expand' : 'retract'}`}>
                                <button onClick={() => setOpenMiddlePathView(!openMiddlePathView)}>({data.length - 2})</button>
                                {data.slice(0, -2).map((e, i, a) => {
                                    return <React.Fragment key={i}>
                                        <img width={49} height={95} src={chevron} alt="" />
                                        <Link to={`/explorer/view/${e.rid}`}><span>{e.name}</span></Link>
                                    </React.Fragment>;
                                })}
                            </div>
                        : undefined} */}
                        {data.map((e, i, a) => {
                            return <React.Fragment key={e.rid}>
                                <img width={49} height={95} src={chevron} alt="" />
                                <FileLink setFile={props.setFile} file={e} noIcon={true} noSplitName={true} disabled={i === a.length - 1} />
                            </React.Fragment>;
                        })}
                    </>
                }
            </div>
            <div className='buttons'>
                {showLinksButton ?
                    <div className='links-container'>
                        <button onClick={openLinksPopup}>
                            <img width={571} height={571} src={link_icon} alt="" />links
                        </button>
                    </div>
                : undefined}
                {user !== null && (user.roles.includes('institutional_teacher') || user.roles.includes('institutional_admin')) && !(props.file?.folder ?? true) ?
                    <div className='share-container'>
                        <button onClick={openSharePopup}>
                            <img width={287} height={287} src={institution_icon} alt="" />share
                        </button>
                    </div>
                : undefined}
            </div>
        </div>
    );
}