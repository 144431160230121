export function getRelevantDiffUnit(diff) {
    const late = diff.seconds() < 0;

    if(Math.abs(diff.days()) >= 1) {
        return {
            value: diff.days(),
            unit: 'day',
            late: late
        };
    }else if(Math.abs(diff.hours()) >= 1) {
        return {
            value: diff.hours(),
            unit: 'hour',
            late: late
        };
    }else if(Math.abs(diff.minutes()) >= 1) {
        return {
            value: diff.minutes(),
            unit: 'minute',
            late: late
        };
    }else{
        return {
            value: diff.seconds(),
            unit: 'second',
            late: late
        };
    }
}