import React, { useEffect, useMemo } from 'react';
import './ExplorerFolder.scss';
import API from '../../API/API';
import ExplorerFolderLine from './ExplorerFolderLine';
import Loading from '../Loading';
import ExplorerFolderBox from './ExplorerFolderBox';

import icon_inline from '../../images/icons/display-inline.svg';
import icon_box from '../../images/icons/display-box.svg';
import { useTranslation } from 'react-i18next';
import ExplorerEmptyFolder from './ExplorerEmptyFolder';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useCookieConsent from '../../hooks/useCookieConsent';
import useMountAwareState from '../../hooks/useMountAwareState';

export default function ExplorerFolder(props) {
    
    useDocumentTitle(props.file.name === 'root' ? 'Explorer' : props.file.name);

    const { functionality } = useCookieConsent();

    const [children, setChildren] = useMountAwareState(null);
    const [error, setError] = useMountAwareState(false);
    const [displayMode, setDisplayMode] = useMountAwareState(localStorage.getItem('display-preference') || 'inline');

    const [ t ] = useTranslation('main');
    const [ tcommon ] = useTranslation('common');

    const sortedChildren = useMemo(() => {
        if(children === null) return [];
        let folders = children.filter((e) => e.folder).sort((a, b) => {
            return a.name.localeCompare(b.name, undefined, { numeric: true });
        });
        let files = children.filter((e) => !e.folder).sort((a, b) => {
            return a.name.localeCompare(b.name, undefined, { numeric: true });
        });

        return folders.concat(files);
    }, [children]);

    const toggleDisplay = () => {
        if(displayMode === 'inline') {
            if(functionality) localStorage.setItem('display-preference', 'box');
            setDisplayMode('box');
        }else{
            if(functionality) localStorage.setItem('display-preference', 'inline');
            setDisplayMode('inline');
        }
    };
    
    useEffect(() => {
        if(props.file?.expanded_children) setChildren(props.file.children);
        API.getInstance().get('/resources/' + props.file.rid + '/children')
        .then(({status, data}) => {
            if(status === 200) {
                setChildren(data);
            }   
        })
        .catch((error) => {
            setError(true);
        });
    }, [props.file.children, props.file?.expanded_children, props.file.rid, setChildren, setError]);

    const file = props.file;
    const displayModeIcon = (displayMode === 'inline' ?  icon_box : icon_inline );

    if(error || !file.folder) return <div>{tcommon('error.general.default')}</div>;

    return (
        <section className='ExplorerFolder'>
            <header>
                {displayMode === 'inline' ? 
                    <>
                        <div className="name"><span>{t('pages.explorer.name')}</span></div>
                        <div className="details">
                            {t('pages.explorer.last_updated')}
                            <button key="inline" className="style-switch" onClick={toggleDisplay}>
                                <img width={96} height={96} src={displayModeIcon} alt={`Switch to display ${displayMode === 'inline' ? 'box' : 'inline'}`} />
                            </button>
                        </div>
                    </>
                    : 
                    <>
                        <div className="name"></div>
                        <div className="details">
                            <div></div>
                            <button key="box" className="style-switch" onClick={toggleDisplay}>
                                <img width={96} height={96} src={displayModeIcon} alt={`Switch to display ${displayMode === 'inline' ? 'box' : 'inline'}`} />
                            </button>
                        </div>
                    </>
                    }
            </header>
            <main className={`display-${displayMode}`}>
                {null === children ?
                    <div className='loading'>
                        <Loading />
                    </div>
                :
                    <>
                        {children.length === 0 ? 
                            <ExplorerEmptyFolder />
                        : 
                            sortedChildren.map(f => {
                                if(f.rid === file.rid) return null;
                                return (displayMode === 'inline' ? 
                                    <ExplorerFolderLine key={`${f.rid}${f.expanded_children}`} file={f} setFile={props.setFile} />
                                : 
                                    <ExplorerFolderBox key={`${f.rid}${f.expanded_children}`} file={f} setFile={props.setFile} />
                                );
                            })
                        }
                    </>
                }
            </main>
        </section>
    );
}