import { useEffect, useRef } from 'react';
export default function useFocusTrap() {

    const ref = useRef(null);

    useEffect(() => {
        const current = (ref ? ref.current : null);

        const focusFirstFocusable = () => {
            if(current) {
                const focusables = current.querySelectorAll('button, a, input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])');
                if(focusables.length === 0) return;
                focusables[0].focus();
            }
        };

        const handleFocusChange = (e) => {
            if(current && !current.contains(e.relatedTarget)) {
                e.preventDefault();
                focusFirstFocusable();
            }
        };
        focusFirstFocusable();

        if(current !== null) current.addEventListener('focusout', handleFocusChange);
        
        return () => {
            if(current !== null) current.removeEventListener('focusout', handleFocusChange);
        }
    }, [ref]);
    
    return ref;
}