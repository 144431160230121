import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../API/API';
import Dot3 from '../../utils/Dot3';
import subscription_elementary from '../../../images/divers/subscription-elementary.svg';
import subscription_advanced from '../../../images/divers/subscription-advanced.svg';
import checkmark from '../../../images/icons/correct.svg';
import crossmark from '../../../images/icons/wrong.svg';
import { Link } from 'react-router-dom';
import useMountAwareState from '../../../hooks/useMountAwareState';
export default function Subscription(props) {

    const [subscriptionElementary, setSubscriptionElementary] = useMountAwareState(null);
    const [subscriptionAdvanced, setSubscriptionAdvanced] = useMountAwareState(null);
    const [ loading, setLoading ] = useMountAwareState(false);

    const [ t ] = useTranslation('main');

    useEffect(() => {
        setLoading(true);
        API.getInstance().get('/users/self/subscriptions')
        .then(({status, data}) => {
                if(status === 200) {
                    data.forEach(sub => {
                        if(sub.name === 'resources-subscription-elementary') setSubscriptionElementary(sub);
                        else if(sub.name === 'resources-subscription-advanced') setSubscriptionAdvanced(sub);
                    });
                }
                setLoading(false);
        });
    }, [setLoading, setSubscriptionAdvanced, setSubscriptionElementary]);
    
    const formatDate = (date) => {
        return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}-${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
    };

    return (
        <div className='Subscription'>
            <div className='subscription elementary'>
                <h3><img width={354} height={354} src={subscription_elementary} alt="" />Elementary</h3>
                <table>
                    <tbody>
                        <tr>
                            <td>{t('pages.account.sections.subscription.status')}:</td>
                            <td>
                                {loading ?
                                    <Dot3 />
                                :
                                    (subscriptionElementary ?
                                        t('pages.account.sections.subscription.subscribed')
                                    :
                                        t('pages.account.sections.subscription.not_subscribed')
                                    )
                                }
                            </td>
                        </tr>
                        {subscriptionElementary ?
                            <>
                                <tr>
                                    <td>{subscriptionElementary.autorenewal ? 'Renew on' : t('pages.account.sections.subscription.subscribed_until')}:</td>
                                    <td>{subscriptionElementary === null ? <Dot3 /> : formatDate(new Date(subscriptionElementary.period_end * 1000))}</td>
                                </tr>
                                <tr>
                                    <td>{t('pages.account.sections.subscription.autorenewal')}:</td>
                                    <td>{subscriptionElementary === null ? <Dot3 /> : (subscriptionElementary.autorenewal ? <span className='autorenew'><img width={123} height={88} src={checkmark} alt=""/>yes</span> : <span className='autorenew'><img width={89} height={85} src={crossmark} alt=""/>no</span>)}</td>
                                </tr>
                            </>
                        : 
                            <tr>
                                <td colSpan={2}>
                                    <div className='subscribe-button'>
                                        <Link to="/subscribe?subscriptions=elementary" className='btn-yellow'>{t('pages.account.sections.subscription.subscribe')}</Link>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                
            </div>
            <div className='subscription advanced'>
                <h3><img width={354} height={354} src={subscription_advanced} alt="" />Advanced</h3>
                <table>
                    <tbody>
                        <tr>
                            <td>{t('pages.account.sections.subscription.status')}:</td>
                            <td>
                                {loading ?
                                    <Dot3 />
                                :
                                    (subscriptionAdvanced ?
                                        t('pages.account.sections.subscription.subscribed')
                                    :
                                        t('pages.account.sections.subscription.not_subscribed')
                                    )
                                }
                            </td>
                        </tr>
                        {subscriptionAdvanced ?
                            <>
                                <tr>
                                    <td>{subscriptionAdvanced.autorenewal ? 'Renew on' : t('pages.account.sections.subscription.subscribed_until')}:</td>
                                    <td>{subscriptionAdvanced === null ? <Dot3 /> : formatDate(new Date(subscriptionAdvanced.period_end * 1000))}</td>
                                </tr>
                                <tr>
                                    <td>{t('pages.account.sections.subscription.autorenewal')}:</td>
                                    <td>{subscriptionAdvanced === null ? <Dot3 /> : (subscriptionAdvanced.autorenewal ? <span className='autorenew'><img width={123} height={88} src={checkmark} alt=""/>yes</span> : <span className='autorenew'><img width={89} height={85} src={crossmark} alt=""/>no</span>)}</td>
                                </tr>
                            </>
                        : 
                            <tr>
                                <td colSpan={2}>
                                    <div className='subscribe-button'>
                                        <Link to="/subscribe?subscriptions=advanced" className='btn-yellow'>{t('pages.account.sections.subscription.subscribe')}</Link>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}