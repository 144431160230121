import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import "./QuizViewer.scss";
import QuizMultiChoice from "./QuizMultiChoice";
import QuizMultiChoiceGrid from "./QuizMultiChoiceGrid";
import QuizShortAnswer from "./QuizShortAnswer";
import QuizLongAnswer from "./QuizLongAnswer";
import QuizSingleChoice from "./QuizSingleChoice";
import Loading from "../../../Loading";
import FileIcon from "../../../FileIcon";
import StringCleaner from "../../../../helpers/StringCleaner";
import API from "../../../../API/API";
import { useTranslation } from "react-i18next";
import QuizVideo from "./QuizVideo";
import QuizImage from "./QuizImage";
import QuizConjugation from "./QuizConjugation";
import useMountAwareState from "../../../../hooks/useMountAwareState";
import SettingsContext from "../../../../Contexts/SettingsContext";

import uppercase_sensitivity_icon from "../../../../images/icons/uppercase_sensitivity.svg";
import ponctuation_sensitivity_icon from "../../../../images/icons/ponctuation_sensitivity.svg";
import UserContext from "../../../../Contexts/UserContext";
import PrintContext from "../../../../Contexts/PrintContext";

import print_icon from "../../../../images/icons/print.svg";

export default function QuizViewer(props) {
  const quizz = props.data;

  const [answers, setAnswers] = useMountAwareState(props.answers ?? null);
  const [showAnswers, setShowAnswers] = useMountAwareState(undefined);
  const [errors, setErrors] = useMountAwareState(null);
  const [loading, setLoading] = useMountAwareState(true);
  const [lock, setLock] = useMountAwareState(false);
  const [score, setScore] = useMountAwareState(props.score ?? null);
  const [fetchError, setFetchError] = useMountAwareState(null);
  const [incomplete, setIncomplete] = useMountAwareState(false);

  const { settings } = useContext(SettingsContext);
  const { user } = useContext(UserContext);
  const { setToPrint } = useContext(PrintContext);

  const ref = useRef(null);

  const [t] = useTranslation("main");
  const [tcommon] = useTranslation("common");

  useEffect(() => {
    if (props.answers === undefined) {
      setAnswers(
        props.data.modules.map((m) => {
          return null;
        })
      );
    } else {
      setAnswers(props.answers);
      setScore(props.result?.data?.corrects?.length);
      setShowAnswers(true);
    }
    setErrors(
      props.data.modules.map((m) => {
        return null;
      })
    );
    setLoading(false);
  }, [
    props.data,
    props.answers,
    props.result?.data?.corrects?.length,
    setErrors,
    setLoading,
    setAnswers,
    setScore,
    setShowAnswers,
  ]);

  const verifiers = useMemo(() => {
    return {
      shortanswer: (a, b) => {
        const cleaner = new StringCleaner()
          .addRule((str) => str.replace(/\([^()]*\)/gim, ""))
          .trimWhitespaces()
          .normalizeWhitepaces();

        if (!settings.uppercaseSensitivity) {
          a = a.toLowerCase();
          b = b.toLowerCase();
        }
        if (!settings.ponctuationSensitivity) {
          a = a.replace(/[.,?!]/gm, " ");
          b = b.replace(/[.,?!]/gm, " ");
        }
        const correctAnswers = b.split("/").map((w) => cleaner.clean(w));
        const answers = a.split("/").map((w) => cleaner.clean(w));

        return (
          correctAnswers.filter((value) => answers.includes(value)).length ===
          answers.length
        );
      },
      longanswer: (a, b) => {
        const cleaner = new StringCleaner()
          .addRule((str) => str.replace(/\([^()]*\)/gim, ""))
          .trimWhitespaces()
          .normalizeWhitepaces();

        if (!settings.uppercaseSensitivity) {
          a = a.toLowerCase();
          b = b.toLowerCase();
        }
        if (!settings.ponctuationSensitivity) {
          a = a.replace(/[.,?!]/gm, " ");
          b = b.replace(/[.,?!]/gm, " ");
        }
        const correctAnswers = b.split("/").map((w) => cleaner.clean(w));
        const answers = a.split("/").map((w) => cleaner.clean(w));

        return (
          correctAnswers.filter((value) => answers.includes(value)).length ===
          answers.length
        );
      },
      multichoice: (a, b) => {
        return b.filter((v) => a.includes(v)).length === b.length;
      },
      multichoicegrid: (a, b) => {
        if (b.length !== a.length) return false;
        const userAnsers = [...a];
        let moduleAnswers = [...b];
        userAnsers.forEach((ua) => {
          let found = false;
          for (let i = 0; i < moduleAnswers.length; i++) {
            const ma = moduleAnswers[i];
            if (ua[0] === ma[0] && ua[1] === ma[1]) {
              found = true;
              moduleAnswers.splice(i, 1);
              break;
            }
          }
          if (!found) return false;
        });
        return true;
      },
      singlechoice: (a, b) => {
        return b === a;
      },
      conjugation: (a, b) => {
        const cleaner = new StringCleaner()
          .addRule((str) => str.replace(/\([^()]*\)/gim, ""))
          .trimWhitespaces()
          .normalizeWhitepaces();

        if (!settings.uppercaseSensitivity) {
          a = a.map((w) => w.toLowerCase());
          b = b.map((w) => w.toLowerCase());
        }
        if (!settings.ponctuationSensitivity) {
          a = a.map((w) => w.replace(/[.,?!]/gm, " "));
          b = b.map((w) => w.replace(/[.,?!]/gm, " "));
        }
        const correctAnswers = b.map((w) => cleaner.clean(w));
        const answers = a.map((w) => cleaner.clean(w));

        return (
          correctAnswers.filter((v) => answers.includes(cleaner.clean(v)))
            .length === answers.length
        );
      },
    };
  }, [settings]);

  const reset = () => {
    setShowAnswers(false);
    setAnswers(
      props.data.modules.map((m) => {
        return null;
      })
    );
    setErrors(
      props.data.modules.map((m) => {
        return null;
      })
    );
    setScore(null);

    const resetEvent = new CustomEvent("quiz-reset", {
      detail: props.resource.rid,
    });
    document.dispatchEvent(resetEvent);
    if (ref.current) ref.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  const setAnswer = useCallback(
    (i, a) => {
      let prev = answers;
      prev[i] = a;
      setAnswers(prev);
    },
    [answers, setAnswers]
  );

  const validate = () => {
    setIncomplete(false);
    setLock(true);
    let errorsTmp = props.data.modules.map((m) => {
      return null;
    });
    let err = false;
    answers.forEach((a, i) => {
      if (a === null && props.data.modules[i]?.answer !== undefined) {
        errorsTmp[i] = "missing";
        err = true;
      }
    });
    setErrors(errorsTmp);
    if (err) {
      setIncomplete(true);
      setLock(false);
      return;
    }

    if (props.token === undefined) {
      let s = 0;
      props.data.modules.forEach((m, i) => {
        if (
          verifiers[m.type] !== undefined &&
          verifiers[m.type](answers[i], m.answer)
        )
          s++;
      });
      setScore(s);
      setLock(false);
      setShowAnswers(true);
      if (ref.current) ref.current.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      API.getInstance()
        .post(
          `/resource-verify-answers/${props.resource.rid}${
            props?.token !== undefined ? `?t=${props.token}` : ""
          }`,
          {
            data: answers,
          },
          props?.token === undefined
        )
        .then(({ status, data }) => {
          if (status === 200 || status === 201) {
            setScore(data.corrects.length);
            setLock(false);
            setShowAnswers(true);
            if (ref.current)
              ref.current.scrollTo({ top: 0, behavior: "smooth" });
          } else {
            setFetchError(data.message);
            setLock(false);
          }
        })
        .catch((error) => {
          setFetchError(tcommon("error.general.default"));
          setLock(false);
        });
    }
  };

  const modules = useMemo(() => {
    if (loading) return undefined;
    return (
      <div className="QuizViewerModules">
        {props.data.modules.map((m, i) => {
          switch (m.type) {
            case "multichoice":
              // For the quizzes exported from Google where checkbox questions only have 1 answer, change for singlechoice
              // Bad idea, will script a change of type for multichoices with only one answer directly in the files
              /* if(m.answer.length > 1) */ return (
                <QuizMultiChoice
                  rid={props.resource.rid}
                  key={i}
                  id={i}
                  module={m}
                  error={errors[i]}
                  answer={answers[i]}
                  setAnswer={(a) => setAnswer(i, a)}
                  showAnswers={showAnswers}
                  verifier={verifiers.multichoice}
                />
              );
            // const mod = m;
            // mod.answer = m.answer[0];
            // mod.type = 'singlechoice';
            // return <QuizSingleChoice rid={props.resource.rid} key={i} id={i} module={mod} error={errors[i]} answer={answers[i]} setAnswer={(a) => setAnswer(i, a)} showAnswers={showAnswers} verifier={verifiers.singlechoice} />;
            case "singlechoice":
              return (
                <QuizSingleChoice
                  rid={props.resource.rid}
                  key={i}
                  id={i}
                  module={m}
                  error={errors[i]}
                  answer={answers[i]}
                  setAnswer={(a) => setAnswer(i, a)}
                  showAnswers={showAnswers}
                  verifier={verifiers.singlechoice}
                />
              );
            case "multichoicegrid":
              return (
                <QuizMultiChoiceGrid
                  rid={props.resource.rid}
                  key={i}
                  id={i}
                  module={m}
                  error={errors[i]}
                  answer={answers[i]}
                  setAnswer={(a) => setAnswer(i, a)}
                  showAnswers={showAnswers}
                  verifier={verifiers.multichoicegrid}
                />
              );
            case "shortanswer":
              return (
                <QuizShortAnswer
                  rid={props.resource.rid}
                  key={i}
                  id={i}
                  module={m}
                  error={errors[i]}
                  answer={answers[i]}
                  setAnswer={(a) => setAnswer(i, a)}
                  showAnswers={showAnswers}
                  verifier={verifiers.shortanswer}
                />
              );
            case "longanswer":
              return (
                <QuizLongAnswer
                  rid={props.resource.rid}
                  key={i}
                  id={i}
                  module={m}
                  error={errors[i]}
                  answer={answers[i]}
                  setAnswer={(a) => setAnswer(i, a)}
                  showAnswers={showAnswers}
                  verifier={verifiers.longanswer}
                />
              );
            case "conjugation":
              return (
                <QuizConjugation
                  rid={props.resource.rid}
                  key={i}
                  id={i}
                  module={m}
                  error={errors[i]}
                  answer={answers[i]}
                  setAnswer={(a) => setAnswer(i, a)}
                  showAnswers={showAnswers}
                  verifier={verifiers.conjugation}
                />
              );
            // NO VERIFICATION MODULES BELOW
            case "video":
              return (
                <QuizVideo
                  rid={props.resource.rid}
                  key={i}
                  id={i}
                  module={m}
                  error={errors[i]}
                />
              );
            case "image":
              return (
                <QuizImage
                  rid={props.resource.rid}
                  key={i}
                  id={i}
                  module={m}
                  error={errors[i]}
                />
              );
            default:
              return <div>Unknown module</div>;
          }
        })}
      </div>
    );
  }, [
    loading,
    props.data.modules,
    props.resource.rid,
    errors,
    answers,
    showAnswers,
    verifiers.multichoice,
    verifiers.singlechoice,
    verifiers.multichoicegrid,
    verifiers.shortanswer,
    verifiers.longanswer,
    verifiers.conjugation,
    setAnswer,
  ]);

  const printQuiz = useCallback(() => {
    setToPrint(modules ?? <div>Nothing to print</div>);
  }, [modules, setToPrint]);

  const totalPoints = quizz.modules.filter(
    (m) => verifiers[m.type] !== undefined
  ).length;
  if (loading) return <Loading />;
  return (
    <div ref={ref} className="QuizViewer">
      <div className="content to">
        <div className="header">
          <div className="info">
            <FileIcon type="quiz" />
            <h1>{props.resource.name}</h1>
            <div className="warnings">
              {(props?.token === undefined && settings.uppercaseSensitivity) ||
              (props?.token &&
                (props?.share?.options?.uppercase_sensitivity ?? false)) ? (
                <img
                  src={uppercase_sensitivity_icon}
                  alt="uppercase on"
                  title={tcommon("settings_instructions.uppercase")}
                />
              ) : undefined}
              {(props?.token === undefined &&
                settings.ponctuationSensitivity) ||
              (props?.token &&
                (props?.share?.options?.ponctuation_sensitivity ?? false)) ? (
                <img
                  src={ponctuation_sensitivity_icon}
                  alt="ponctuation on"
                  title={tcommon("settings_instructions.ponctuation")}
                />
              ) : undefined}
            </div>
          </div>
          {(user?.type ?? 0) > 1 ? (
            <button onClick={printQuiz}>
              <img src={print_icon} alt="print" title="print" />
            </button>
          ) : undefined}
        </div>
        {showAnswers ? (
          <div className="score">
            {score}/{totalPoints} ({Math.round((score / totalPoints) * 100)}%)
          </div>
        ) : undefined}
        {modules}
        {fetchError ? (
          <div className="fetch-error">{fetchError}</div>
        ) : undefined}
        {incomplete ? (
          <div className="fetch-error">
            {t(`pages.explorer.apps.quiz.errors.incomplete`)}
          </div>
        ) : undefined}
        <div className="validate-container">
          {props.answers === undefined ? (
            showAnswers ? (
              <button onClick={reset} disabled={lock}>
                Reset
              </button>
            ) : (
              <button onClick={validate} disabled={lock}>
                Validate
              </button>
            )
          ) : undefined}
        </div>
      </div>
    </div>
  );
}
