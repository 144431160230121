import icon_folder from '../images/icons/folder.svg';
import icon_folder_activities from '../images/icons/folder-activities.svg';
import icon_folder_documents from '../images/icons/folder-documents.svg';
import icon_folder_flashcards from '../images/icons/folder-flashcards.svg';

import icon_file from '../images/icons/file.svg';
import icon_file_pdf from '../images/icons/file-pdf.svg';
import icon_file_txt from '../images/icons/file-txt.svg';
import icon_file_video from '../images/icons/file-video.svg';
import icon_file_audio from '../images/icons/file-audio.svg';
import icon_file_flashcards from '../images/icons/file-flashcards.svg';
import icon_file_missing_words from '../images/icons/file-missing-words.svg';
import icon_file_hidden_words from '../images/icons/file-hidden-words.svg';
import icon_file_crosswords from '../images/icons/file-crosswords.svg';
import icon_file_quiz from '../images/icons/file-quiz.svg';

export default function useResourceIcon(type) {
    const icons = {
        'folder': icon_folder,
        'folder-documents': icon_folder_documents,
        'folder-flashcards': icon_folder_flashcards,
        'folder-activities': icon_folder_activities,
        'pdf': icon_file_pdf,
        'txt': icon_file_txt,
        'video': icon_file_video,
        'audio': icon_file_audio,
        'flashcards': icon_file_flashcards,
        'missing-words': icon_file_missing_words,
        'hidden-words': icon_file_hidden_words,
        'crosswords': icon_file_crosswords,
        'quiz': icon_file_quiz,
    };

    const icon = icons[type];
    if(icon === undefined) return icon_file;
    return icon;
}