import React, { useEffect, useMemo } from 'react';
import './FlashcardsViewer.scss';
import learning_icon from '../../../images/icons/learning.svg';
import writing_icon from '../../../images/icons/writing.svg';
// import match_up_icon from '../../../images/icons/match-up.svg';
import reading_icon from '../../../images/icons/reading.svg';
import listening_icon from '../../../images/icons/listening.svg';
import FlashcardsViewerReducedMenu from './FlashcardsViewerReducedMenu';

import FlashcardsLearning from './learningMode/FlashcardsLearning';
import FlashcardsReading from './readingMode/FlashcardsReading';
import { useParams, useNavigate, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import FlashcardsListening from './listeningMode/FlashcardsListening';
import { useTranslation } from 'react-i18next';
import FileIcon from '../../FileIcon';

export default function FlashcardsViewer(props) {
    const [ t ] = useTranslation('main');
    const navigate = useNavigate();
    const location = useLocation();

    const { subsection = undefined } = useParams();

    const buttons = useMemo(() => {return {
        'learning': <><img width={58} height={58} src={learning_icon} alt="" />{t('pages.explorer.apps.flashcards.menu.learning')}</>,
        'reading': <><img width={81} height={81} src={reading_icon} alt="" />{t('pages.explorer.apps.flashcards.menu.reading')}</>,
        'writing': <><img width={537} height={537} src={writing_icon} alt="" />{t('pages.explorer.apps.flashcards.menu.writing')}</>,
        'listening': <><img width={63} height={63} src={listening_icon} alt="" />{t('pages.explorer.apps.flashcards.menu.listening')}</>
    }}, [t]);

    const sideMenu = useMemo(() => {
        return Object.keys(buttons).map(key => {
            return <Link to={`/explorer/view/${props.resource.rid}/${key}`} key={key} className={subsection === key ? 'active' : ''}>{buttons[key]}</Link>;
        });
    }, [buttons, subsection, props.resource]);
    
    useEffect(() => {
        props.data.cards.forEach((card) => {
            new Image().src = card.front.image;
        });
    }, [props.data.cards]);

    useEffect(() => {
        if(!location.pathname.startsWith(`/explorer/view/${props.resource.rid}`)) return;
        if(!Object.keys(buttons).includes(subsection)) {
            navigate(`/explorer/view/${props.resource.rid}/learning`, {replace: true});
        }
    }, [subsection, buttons, navigate, props.resource.rid, location])
    

    const display = useMemo(() => {
        switch (subsection) {
            case 'learning':
                return <FlashcardsLearning key={props.data} data={props.data} />;
            case 'reading':
                return <FlashcardsReading key={'fr'} lang="fr" data={props.data} />;
            case 'writing':
                return <FlashcardsReading key={'en'} lang="en" data={props.data} />;
            case 'listening':
                return <FlashcardsListening data={props.data} />;
            default:
                return <FlashcardsLearning data={props.data} />;
        }
    }, [subsection, props.data] );

    return (
        <div className='FlashcardsViewer'>
            <div className="sideMenu">
                {sideMenu}
            </div>
            <div className="content">
                <div className='header'>
                    <h1>
                        <FileIcon type="flashcards" />
                        <div className="title">{props.data.title}</div>
                    </h1>
                    <FlashcardsViewerReducedMenu resource={props.resource} buttons={buttons} />
                </div>                
                <div className="display">
                    {display}
                </div>
            </div>
        </div>
    );
}

/*
    Modes:
        - learning: Browse cards (and see both sides ?)
        - reading: See front/back of cards and write word in french/english
        - listening: Hear the word in french and write the corresponding in english (or write it in french)
        - match-up: match the front and faces of the cards
        - 
*/